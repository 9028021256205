import React, { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {
  // import Category from './elements/Category';
  Col,
  Container,
  Row,
} from "react-bootstrap";
import { getNewPartnerToken } from "../../config/cookie.config";

const PartnerCouponDetails = () => {
  var token;
  const { id, bid } = useParams();
  const [active, setActive] = useState(false);
  const [name, setName] = useState('');
  const [productname, setProductName] = useState('');
  const [faceValue, setFaceValue] = useState('');
  const [minValue, setMinValue] = useState('');
  const [maxValue, setMaxValue] = useState('');
  const [currency, setCurrency] = useState('');
  const [denominationtype, setDenominationType] = useState('');
  const [discount, setDiscount] = useState('');
  const [fee, setFee] = useState('');
  const [maxBonus, setMaxBonus] = useState('');
  const [minBonus, setMinBonus] = useState('');
  const [maxCost, setMaxCost] = useState('');
  const [minCost, setMinCost] = useState('');
  const [bonusFee, setBonusFee] = useState('');
  const [bonusType, setBonusType] = useState('');
  const [bonus, setBonus] = useState('');
  const [totalCost, setTotalCost] = useState('');
  const [description, setDescription] = useState('');
  const [redemptionInstructions, setRedemptionInstructions] = useState('');
  const [termsAndConditions, setTermsAndConditions] = useState('');
  const [imagepath, setImagePath] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    if (id) {
      getbrands();
    }
  }, []);

  const getbrands = () => {
    const user = JSON.parse(window.localStorage.getItem("business-client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }

    axios
        .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/partners/${id}/${bid}/coupons/details`, {
          headers: {
            'Authorization' : `Bearer ${getNewPartnerToken()}`
          }
        })
        .then((response) => {
          setName(response.data.brand.brandname);
          setDescription(response.data.brand.description);
          setRedemptionInstructions(response.data.brand.redemptionInstructions);
          setTermsAndConditions(response.data.brand.termsAndConditions);
          setImagePath(response.data.brand.img_path);
          setProductName(response.data.coupon.productname);
          setFaceValue(response.data.coupon.faceValue || 0);
          setMinValue(response.data.coupon.minValue || 0);
          setMaxValue(response.data.coupon.maxValue || 0);
          setCurrency(response.data.coupon.currency);
          setDenominationType(response.data.coupon.denominationtype);
          setDiscount(response.data.coupon.discount);
          setFee(response.data.coupon.fee || 0);
          setMaxBonus(response.data.coupon.maxBonus || 0);
          setMinBonus(response.data.coupon.minBonus || 0);
          setMaxCost(response.data.coupon.maxCost || 0);
          setMinCost(response.data.coupon.minCost || 0);
          setBonusFee(response.data.coupon.bonusFee);
          setBonusType(response.data.coupon.bonusType);
          setBonus(response.data.coupon.bonus || 0);
          setTotalCost(response.data.coupon.totalCost || 0);
        })
        .catch((error) => console.error(`Error: ${error}`));
        
  };
  return (
    <>
      <div className="p-3 p-lg-4 p-xl-5">
        <Container fluid className="bg-white p-3 rounded shadow">
          <Row className="align-items-center pb-5">
            <Col md={5}>
              <div>
                <Link
                onClick={()=>navigate(-1)}
                  to={`/admin/businesspartners/${id}/${bid}/coupons`}
                  className="p-0 btn btnOrangeTransparent fw-semibold"
                  variant="white"
                >
                  <span className="me-2 fw-semibold">
                    <img
                      src="/assets/images/back-org.png"
                      className="img-fluid"
                      alt=""
                    />
                  </span>
                  <span>Back</span>
                </Link>
              </div>
            </Col>
            <Col md={7}></Col>
            <Col md={12} className="mt-4">

              <div className="row fw-medium">
                <Col md={6}>
                  <h1 className="font20 text-dark fw-bold mb-4">Brand Details : {name}</h1>
                  <Row className="mb-4 align-items-center">
                    <Col lg={12} className="mb-4">
                      <img src={imagepath} className="img-fluid" alt="" />
                    </Col>
                    <Col lg={12}>
                      <div className="mb-3">
                        <h3 className="text-dark font14 mb-2">Description</h3>

                        <p style={{ textAlign: "justify" }}>{description}</p>
                      </div>

                      <div className="mb-3">
                        <h3 className="text-dark font14 mb-2">Redemption instructions</h3>

                        <p style={{ textAlign: "justify" }}>{redemptionInstructions}</p>
                      </div>

                      <div className="mb-3">
                        <h3 className="text-dark font14 mb-2">Terms and conditions</h3>
                        <p style={{ textAlign: "justify" }}>{termsAndConditions}</p>
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col md={6}>
                  <Row className="align-items-center">
                    <h1 className="font20 text-dark fw-bold mb-4">Coupon Details : {productname}</h1>
                    <Col lg={12}>
                      <img src={imagepath} className="img-fluid" alt="" />
                    </Col>
                    <Col lg={12}>
                      <div className="mb-3 mt-5">
                        <h2 className="text-dark font16 mb-2">Brand Information</h2>
                        <p>Currency : {currency}</p>
                        <p>Denomination Type : {denominationtype}</p>
                        <p>Business Type : {bonusType}</p>

                      </div>
                    </Col>

                    <Container>
                      <Row>
                        <Col lg={4}>
                          <div>
                            <p>Face Value : ${Number(faceValue).toFixed(2)}</p>
                            <p>Discount : {Number(discount).toFixed(2)}%</p>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div>
                            <p>Min Value : ${Number(minValue).toFixed(2)}</p>
                            <p>Fee : {Number(fee).toFixed(2)}</p>
                          </div>
                        </Col>
                        <Col lg={4} className="ps-lg-1">
                          <p>Max Value : ${Number(maxValue).toFixed(2)}</p>
                          <p>&nbsp;</p>
                        </Col>
                      </Row>
                    </Container>


                    <Col lg={12}>
                      <div className="mb-3 mt-3">
                        <h2 className="text-dark font16 mb-2">Pay-out Information</h2>
                        <p>Transfer to Bonus : {bonusType === "Bonus percentage" ? `${Number(bonusFee).toFixed(2)} %` : `${Number(bonusFee).toFixed(2)}`}</p>
                        <p>Bonus :  {bonusType === "Bonus percentage" || " " ?` ${Number(discount * (bonusFee / 100)).toFixed(2)} %` : `${Number(discount).toFixed(2) - Number(bonusFee).toFixed(2)} %`}</p>
                      </div>
                    </Col>
                    <Container>
                      <Row>
                        <Col lg={5}>
                          <div>
                            {denominationtype === "FIXED" ? <p>Bonus(Amount)  : ${Number(bonus).toFixed(2)}</p> : <p>Min Bonus : ${Number(minBonus).toFixed(2)}</p>}
                            {denominationtype === "FIXED" ? <p>Total Cost  : ${Number(totalCost).toFixed(2)}</p> : <p>Min Cost  : ${Number(minCost).toFixed(2)}</p>}
                          </div>
                        </Col>
                        <Col lg={5}>
                          <div>
                            {denominationtype === "FIXED" ? <p></p> : <p>Max Bonus  : ${Number(maxBonus).toFixed(2)}</p>}
                            {denominationtype === "FIXED" ? <p></p> : <p>Max Cost : ${Number(maxCost).toFixed(2)}</p>}
                          </div>
                        </Col>

                        <Col lg={4}>
                          <div>
                          </div>
                        </Col>
                      </Row>
                    </Container>

                  </Row>
                </Col>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PartnerCouponDetails;
