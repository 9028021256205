import React, { useState, useRef, useEffect } from 'react'
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
} from 'react-bootstrap'
import JoditEditor from "jodit-react";
import Select from "react-select"
import "../../src/App.scss"
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { Bars } from 'react-loader-spinner';
import { getNewToken } from '../config/cookie.config';
const Email = () => {
  useEffect(() => {
    const adminCheck = window.location.pathname;
    if (adminCheck.includes('admin')) {
      document.title = "ncentiva || Admin Mails"
    }
  }, [])
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [showPage, setShowPage] = useState(1);
  const [pageNumber1, setPageNumber1] = useState(0);
  const [numberOfPages1, setNumberOfPages1] = useState(0);
  const [showPage1, setShowPage1] = useState(1);
  const [pageNumber2, setPageNumber2] = useState(0);
  const [numberOfPages2, setNumberOfPages2] = useState(0);
  const [showPage2, setShowPage2] = useState(1);
  const [newEmailModal, setNewEmailModal] = useState(false)
  const [scheduleModal, setScheduleModal] = useState(false)
  const [mailHeader, setMailHeader] = useState("")
  const [mailBody, setMailBody] = useState("")
  const [noData, setNoData] = useState(false);
  const [sendPartnerID, setSendPartnerID] = useState('');
  const [selectedPartnerValidate, setselectedPartnerValidate] = useState('default');
  const [selectedCustomerValidate, setselectedCustomerValidate] = useState([]);
  const [buisnessValue, setBuisnessValue] = useState("");
  const [customerValue, setCustomerValue] = useState("");
  const [getbusinessCustomer, setbusinessCustomer] = useState([]);
  const [getbusinessPartner, setbusinessPartner] = useState([]);
  const [getbusinessPartnerss, setbusinessPartners] = useState([]);
  const [subject, setSubject] = useState("");
  const [scheduleDate, setScheduleDate] = useState("");
  const [scheduleTime, setScheduleTime] = useState("");
  const [allMailData, setAllMailData] = useState([])
  const [draftTable, setDraftTable] = useState(false);
  const [scheduledTable, setScheduledTable] = useState(false);
  const [sendTable, setSendTable] = useState(true);
  const [draftMails, setDraftMails] = useState([])
  const [scheduledMails, setScheduledMails] = useState([])
  const [sendMails, setSendMails] = useState([]);
  const [draftNumber, setDraftNumber] = useState("");
  const [scheduleNumber, setScheduleNumber] = useState("")
  const [sendMailNumber, setSendMailNumber] = useState("");
  const [modalContent, setModalContent] = useState(false);
  const [zero, setZero] = useState(false);
  const [showModal, setShowModal] = useState(false);


  const [zerosendMail, setZerosendMail] = useState(false);
  const[sentEmailLoading, setSentEmailLoading]=useState(false)  
  const [zerosheduleMail, setZerosheduleMail] = useState(false);

  const [mailSendAllCustomer, setMailSendAllCustomer] = useState(false);
  const [mailSendAllBuisness, setMailSendAllBuisness] = useState(false);
  const [viewEmailData, setViewEmailData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isMultiCustomer, setIsMultiCustomer] = useState(true);
  const [mailId,setMailId] = useState("");
  const [selectedPartnerValue ,setSelectedPartnerValue] = useState("");
  let navigate = useNavigate();

  const handleClose = () => setShowModal(false);






  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'var(--darkblue)' : 'var(--color)',
      fontSize: 14,
      padding: 10,
      marginBottom: 0,
      backgroundColor: state.isSelected ? 'rgba(215, 223, 233, 0.34);' : '#fff'
    }),

    control: () => ({
      width: 'auto',
      display: 'flex',
      border: '1px solid #ced4da',
      borderRadius: '.25rem'
    }),
  }

  let items = [];
  let leftSide = pageNumber - 1;

  if (leftSide <= 0) leftSide = 0;
  let rightSide = pageNumber + 2;

  if (rightSide > numberOfPages) rightSide = numberOfPages;
  for (let number = leftSide; number < rightSide; number++) {

    items.push(
      <li key={number} className={(number === pageNumber ? "active" : "")} onClick={() => { setPageNumber(number); setShowPage(number + 1) }}>
        {number + 1}
      </li>,
    );
  }

  let items1 = [];
  let leftSide1 = pageNumber1 - 1;

  if (leftSide1 <= 0) leftSide1 = 0;
  let rightSide1 = pageNumber1 + 2;

  if (rightSide1 > numberOfPages1) rightSide1 = numberOfPages1;
  for (let number = leftSide1; number < rightSide1; number++) {
    items1.push(
      <li key={number} className={(number === pageNumber1 ? "active" : "")} onClick={() => { setPageNumber1(number); setShowPage1(number + 1) }}>
        {number + 1}
      </li>,
    );
  }

  let items2 = [];
  let leftSide2 = pageNumber2 - 1;

  if (leftSide2 <= 0) leftSide2 = 0;
  let rightSide2 = pageNumber2 + 2;

  if (rightSide2 > numberOfPages2) rightSide2 = numberOfPages2;
  for (let number = leftSide2; number < rightSide2; number++) {
    items2.push(
      <li key={number} className={(number === pageNumber2 ? "active" : "")} onClick={() => { setPageNumber2(number); setShowPage2(number + 1) }}>
        {number + 1}
      </li>,
    );
  }



  const config = {
    placeholder: '',
    readonly: false, // Set to true if you want to make the editor read-only
    uploader: {
      insertImageAsBase64URI: true,
      url: `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/mail-image-upload`,
      format: 'json',
      method: 'POST',
      withCredentials: false,
      // Custom handler for uploaded images
      imagesHandlers: {
        insertImage: async (data, onSuccess, onError) => {
          try {
            let token;
            const user = JSON.parse(window.localStorage.getItem("client"));
            if (user) {
              token = user.token;
            } else {
              token = null;
            }
            const formData = new FormData();
            formData.append('image', data);

            const response = await axios.post(
              `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/mail-image-upload`,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  'Authorization': `Bearer ${getNewToken()}`
                },
              }
            );

            if (response.data && response.data.status === 'success') {
              onSuccess({ link: response.data.imageUrl });
            }
          } catch (error) {
            console.log(error);
            toast.error(`Error: ${error}`);

          }
        },
      },
    },
    buttons: [
      'image', 'source', 'bold', 'italic', 'underline', 'strikethrough',
      'font', 'fontsize', 'brush', 'paragraph', 'ol', 'ul',
      'indent', 'outdent', 'align', 'undo', 'redo', 'cut',
      'hr', 'link', 'unlink', 'table', 'selectAll', 'fullsize',
    ],
  };



  const addNewMail = () => {
    setNewEmailModal(true);
    setSubject('');
    setMailBody('');
    setMailHeader('');
    setMailId('');
    setselectedCustomerValidate('');
    setSelectedPartnerValue('');
    setbusinessPartners([]);
    setbusinessCustomer([]);
    setBuisnessValue('');
    setCustomerValue('');
  };

  const handleCloseEmailModal = () => {
    setNewEmailModal(false)
    setModalContent(false);
  };

  const handleScheduleModal = () => {
    if (buisnessValue?.length === 0) {
      toast.warning("BuisnessPartner cannot be empty");
    } else if (customerValue?.length === 0) {
      toast.warning("Customer cannot be empty");
    } else if (subject?.trim() === "") {
      toast.warning("Email Subject cannot be empty");
    } else if (isWhitespace(mailHeader)) {
      toast.warning("Email Header cannot be empty");
    } else {
      setScheduleModal(true)
      setNewEmailModal(false)

    }
  };

  const handleChangeScheduleModal = async () => {
    if (scheduleDate?.length === 0) {
      toast.warning("Date cannot be empty");
    } else if (scheduleTime?.length === 0) {
      toast.warning("Time cannot be empty");
    } else {
      const dateTimeString = `${scheduleDate} ${scheduleTime}`;
      //  const dateObject = new Date(dateTimeString);

      // if (!isNaN(dateObject.getTime())) {
      // const formattedDate = dateObject.toISOString();


      setScheduleModal(false);
      setNewEmailModal(true);
      let token;
      const user = JSON.parse(window.localStorage.getItem("client"));
      if (user) {
        token = user.token;
      } else {
        token = null;
      }

      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/create-email`,
          {
            business_partner_id: selectedPartnerValidate,
            customer_id: selectedCustomerValidate,
            emailSubject: subject,
            emailHead: mailHeader,
            emailBody: mailBody,
            scheduleDateTime: dateTimeString,
            all_customer: mailSendAllCustomer,
            all_business: mailSendAllBuisness,
            isMultiCustomer: isMultiCustomer,
            draft: false,
            sent: false,
            mailId:mailId,
            fileLink: selectedFile,
          }, {
          headers: {
            'Authorization': `Bearer ${getNewToken()}`
          }
        }

        );
        setSubject('');
        setMailBody('');
        setMailHeader('');
        setBuisnessValue('');
        setCustomerValue('');
        setScheduleDate('');
        setScheduleTime('');
        setSelectedFileName("");
        if (data.status === "success") {
          toast.success(data.message);
          schedulemailApi();
          setTimeout(() => {
            // window.location.reload()
          }, 1500);
          setNewEmailModal(false);
        } else {
          toast.error(data.message);
        }
      } catch (err) {
        console.error(`Error: ${err}`);
        if (err.response && err.response.data && err.response.data.errors) {
          err.response.data.errors.forEach((error) => {
            toast.error(error);
          });
        }
      }


    }
  }

  const handleCloseScheduleModal = () => {
    setScheduleModal(false)
  }
  const pagenumberSearch = (e) => {
    var pages = parseInt(e);
    var page = pages - 1;
    if (pages < 1) {
      setPageNumber(0);
      setShowPage(1);
    } else if (pages > numberOfPages) {
      setPageNumber(numberOfPages - 1);
      setShowPage(numberOfPages);
    } else if (pages >= 1 && pages <= numberOfPages) {
      setPageNumber(page);
      setShowPage(pages);
    }
    else {
      setPageNumber(0);
      setShowPage(pages);
    }
  }

  const pagenumberSearch1 = (e) => {
    var pages = parseInt(e);
    var page = pages - 1;
    if (pages < 1) {
      setPageNumber1(0);
      setShowPage1(1);
    } else if (pages > numberOfPages1) {
      setPageNumber1(numberOfPages1 - 1);
      setShowPage1(numberOfPages1);
    } else if (pages >= 1 && pages <= numberOfPages1) {
      setPageNumber1(page);
      setShowPage1(pages);
    } else {
      setPageNumber1(0);
      setShowPage1(pages);
    }
  }
  const pagenumberSearch2 = (e) => {
    var pages = parseInt(e);
    var page = pages - 1;
    if (pages < 1) {
      setPageNumber2(0);
      setShowPage2(1);
    } else if (pages > numberOfPages2) {
      setPageNumber2(numberOfPages2 - 1);
      setShowPage2(numberOfPages2);
    } else if (pages >= 1 && pages <= numberOfPages2) {
      setPageNumber2(page);
      setShowPage2(pages);
    } else {
      setPageNumber2(0);
      setShowPage2(pages);
    }
  }


  const formatDate = (date) => {

    const estDate = new Date(date);
    const estOptions = {
      timeZone: "America/New_York",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
    };

    return estDate.toLocaleString("en-US", estOptions).replace(",", "");
  };
  const nextPage = () => {
    if (pageNumber < numberOfPages) {
      setPageNumber(pageNumber + 1)
      setShowPage(pageNumber + 2);
    }
  }

  const nextPage1 = () => {
    if (pageNumber1 < numberOfPages1) {
      setPageNumber1(pageNumber1 + 1)
      setShowPage1(pageNumber1 + 2);
    }
  }

  const nextPage2 = () => {
    if (pageNumber2 < numberOfPages2) {
      setPageNumber2(pageNumber2 + 1)
      setShowPage2(pageNumber2 + 2);
    }
  }



  const prevPage = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1);
      setShowPage(pageNumber);
    }
  }

  const prevPage1 = () => {
    if (pageNumber1 > 0) {
      setPageNumber1(pageNumber1 - 1);
      setShowPage1(pageNumber1);
    }
  }

  const prevPage2 = () => {
    if (pageNumber2 > 0) {
      setPageNumber2(pageNumber2 - 1);
      setShowPage2(pageNumber2);
    }
  }
  const isWhitespace = (html) => {
   const plainText = html.replace(/<[^>]*>|&nbsp;/g, '');
    return /^\s*$/.test(plainText);
  };


  const sendEmail = async () => {
    let token;
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
   
    if (buisnessValue?.length === 0) {
      toast.warning("BuisnessPartner cannot be empty");
    } else if (customerValue?.length === 0) {
      toast.warning("Customer cannot be empty");
    } else if (subject?.trim() === "") {
      toast.warning("Email Subject cannot be empty");
    } else if (isWhitespace(mailHeader)) {
      toast.warning("Email Header cannot be empty");
    } else {
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/create-email`,
          {
            business_partner_id: selectedPartnerValidate,
            customer_id: selectedCustomerValidate,
            emailSubject: subject,
            emailHead: mailHeader,
            emailBody: mailBody,
            all_customer: mailSendAllCustomer,
            all_business: mailSendAllBuisness,
            draft: false,
            sent: true,
            isMultiCustomer: isMultiCustomer,
            fileLink: selectedFile,
            mailId:mailId,
          }, {
          headers: {
            'Authorization': `Bearer ${getNewToken()}`
          }
        }

        );
        setSubject('');
        setMailBody('');
        setMailHeader('');
        setBuisnessValue('');
        setCustomerValue('');
        setSelectedFileName("");
        setSelectedFile('');
        if (data.status === "success") {
          toast.success(data.message);
          setNewEmailModal(false);
          sendmailApi();
          // Reset your form fields or handle success scenario
        } else if (data.status === "failed") {
          toast.warning(data.message)
        }

      } catch (err) {
        console.error(`Error: ${err}`);
        if (err.response && err.response.data && err.response.data.errors) {
          err.response.data.errors.forEach((error) => {
            toast.error(error);
          });
        }
      }
    }


  }

  const draftEmail = async () => {
    let token;
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    if (buisnessValue?.length === 0) {
      toast.warning("BuisnessPartner cannot be empty");
    } else if (customerValue?.length === 0) {
      toast.warning("Customer cannot be empty");
    } else if (subject?.trim() === "") {
      toast.warning("Email Subject cannot be empty");
    } else if (isWhitespace(mailHeader)) {
      toast.warning("Email Header cannot be empty");
    } else {
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/create-email`,
          {
            business_partner_id: selectedPartnerValidate,
            customer_id: selectedCustomerValidate,
            emailSubject: subject,
            emailHead: mailHeader,
            emailBody: mailBody,
            draft: true,
            sent: false,
            all_customer: mailSendAllCustomer,
            all_business: mailSendAllBuisness,
            isMultiCustomer: isMultiCustomer,
            fileLink: selectedFile,
            mailId:mailId,
          }, {
          headers: {
            'Authorization': `Bearer ${getNewToken()}`
          }
        }

        );
        setSubject('');
        setMailBody('');
        setMailHeader('');
        setBuisnessValue('');
        setCustomerValue('');
        setSelectedFileName("");
        setSelectedFile('');
        if (data.status === "success") {
          toast.success(data.message);
          setNewEmailModal(false);
          setDraftNumber(data.totalMails);

        } else {
          toast.error(data.message);
        }
      } catch (err) {
        console.error(`Error: ${err}`);
        if (err.response && err.response.data && err.response.data.errors) {
          err.response.data.errors.forEach((error) => {
            toast.error(error);
          });
        }
      }
    }

  }


  const getbusinessPartners = () => {
    let token;
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios
      .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getallcustomersbrand`, {
        headers: {
          'Authorization': `Bearer ${getNewToken()}`
        }
      })
      .then((response) => {
        const customers = response.data.customersbrand;
        const businessFilter = response.data.businessFilter
        // const uniqueIds = [];
        // const unique = customers.filter(element => {
        //   const isDuplicate = uniqueIds.includes(element?.value);
        //   if (!isDuplicate) {
        //     uniqueIds.push(element?.value);
        //     return uniqueIds;
        //   }
        //   return false;
        // });
        // if (response.data.message == "Please Login") {
        //   localStorage.removeItem('client');
        //   navigate('/admin');
        //   toast.error("Session Expired Please Login Again")
        // }

        setbusinessPartner(businessFilter);

        const customerss = response.data.customersbrands;
        const uniqueIdss = [];
        if(customerss?.length > 0){
          const uniques = customerss?.filter(element => {
            const isDuplicate = uniqueIdss?.includes(element?.value);
            if (!isDuplicate) {
              uniqueIdss.push(element?.value);
              return uniqueIdss;
            }
            return false;
          });
          setbusinessPartners(uniques);
        }
       

      })
      .catch((error) => {
        console.error(`Error: ${error}`)
        if (error?.response?.data?.message) {
          localStorage.removeItem('client');
          localStorage.removeItem('user');
          navigate('/admin');
          // toast.error("Session Expired. Please Login Again")
        }
      });
  };




  useEffect(() => {

    const getData = setTimeout(() => {


      getbusinessPartners();

    }, 600)
    return () => clearTimeout(getData)
  }, [])

  const heading = useRef(null)
  const body = useRef(null);

  const handleScheduleClosed = () => {
    setScheduleModal(false)
    setNewEmailModal(true)
  }
  let token;

  const removelink = (indexToRemove) => {
    setSelectedFileName((prevFiles) => prevFiles.filter((_, index) => index !== indexToRemove));

    setSelectedFile('');
  }
  const sendPartner = (partnerId) => {
    setSendPartnerID(partnerId)

    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/business-customer/${partnerId}`, {
        headers: {
          'Authorization': `Bearer ${getNewToken()}`
        }

      }
      )
      .then((response) => {
        if (response.data.message === "Please Login") {
          localStorage.removeItem('client');
          navigate('/admin');
          toast.error("Session Expired Please Login Again")
        }
        const businessCustomer = response?.data?.customers
        setbusinessCustomer(businessCustomer);

      })
      .catch((error) => console.error(`Error: ${error}`));
  };

  const defaultObj = {
    value: "All Customer",
    label: "All Customer"
  };

  const customeroptions = (selectedPartnerValue === "All Partners")
  ? [defaultObj]
  : [
      {
        value: "All Business Customers",
        label: "All Customers"
      },
      ...getbusinessCustomer.map(item => ({
        value: item?._id,
        label: `${item.first_name} ${item.last_name}`
      }))
    ];
  

  const sendmailApi = () => {
    let token;
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/get-send-emails?page=${pageNumber}`, {
      headers: {
        'Authorization': `Bearer ${getNewToken()}`
      }
    })
      .then((res) => {

        if (res.data.mails?.length < 1) {
          setSentEmailLoading(false)
          setZerosendMail(true);
        } else {
          setSentEmailLoading(true)
          setZerosendMail(false);
        }
        setSendMails(res.data.mails)
        setSendMailNumber(res.data.totalMails);
        const totalpages = res.data.totalPages;
        setNumberOfPages(totalpages)
        // setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        if (err.response.data.message) {
          localStorage.removeItem('client');
          localStorage.removeItem('user');
          navigate('/admin');
          // toast.error("Session Expired. Please Login Again")
        }
        // setIsLoading(false);
      });

  }

  const schedulemailApi = () => {
    let token;
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/get-scheduled-emails?page=${pageNumber2}`, {
      headers: {
        'Authorization': `Bearer ${getNewToken()}`
      }
    })
      .then((res) => {
        if (res.data.mails?.length < 1) {
          setZerosheduleMail(true);
        } else {
          setZerosheduleMail(false);
        }
        setScheduledMails(res.data.mails)
        const scheduleLength = res.data.totalMails
        setScheduleNumber(scheduleLength);
        const totalSchedulePages = res.data.totalPages
        setNumberOfPages2(totalSchedulePages)
        // setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);

        // setIsLoading(false);
      });

  }

  const draftmailApi = () => {
    let token;
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/get-draft-emails?page=${pageNumber1}`, {
      headers: {
        'Authorization': `Bearer ${getNewToken()}`
      }
    })
      .then((res) => {
        if (res.data.mails?.length < 1) {
          setZero(true);
        } else {
          setZero(false);
        }
        setDraftMails(res.data.mails)
        const draftLength = res.data.totalMails
        setDraftNumber(draftLength);
        const totalDraftPage = res.data.totalPages;
        setNumberOfPages1(totalDraftPage);
        // setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);

        // setIsLoading(false);
      });
  }

  const handlePartnerSelection = (selectedOption) => {
    const selectedPartnerId = selectedOption.id;
    const allPartner = selectedOption?.value;
    setSelectedPartnerValue(allPartner);
    setBuisnessValue(selectedOption)
    // sendPartner(selectedPartnerId);
    setselectedPartnerValidate(selectedPartnerId);


    // Reset customer selection and set default values when a new partner is selected

    if (allPartner === 'All Partners') {
      setMailSendAllBuisness(true);
    } else {
      setMailSendAllBuisness(false);
      sendPartner(selectedPartnerId);
    }
  };

  const handleCustomerSelection = (customerOption) => {
    const customerSelect = customerOption[0]?.value
    setCustomerValue(customerOption);
    const selectedCustomers = customerOption.map(option => option?.value);
    setselectedCustomerValidate(selectedCustomers);
    const isAllCustomerSelected = selectedCustomers.includes("All Customer");
    setMailSendAllCustomer(isAllCustomerSelected);
    if (customerSelect === "All Business Customers") {
      setMailSendAllCustomer(true)
    }else{
      setMailSendAllCustomer(false)
    }

  }
  const draftedMail = () => {

    setDraftTable(true);
    setSendTable(false);
    setScheduledTable(false);
    draftmailApi();


  }

  useEffect(() => {
    sendmailApi(pageNumber);
  }, [pageNumber]);

  useEffect(() => {
    draftmailApi(pageNumber1);
  }, [pageNumber1]);

  useEffect(() => {
    schedulemailApi(pageNumber2)
  }, [pageNumber2])



  const scheduledMail = () => {
    setScheduledTable(true);
    setDraftTable(false);
    setSendTable(false);
    schedulemailApi();


  }
  const sendMail = () => {
    setSendTable(true);
    setDraftTable(false);
    setScheduledTable(false);
    sendmailApi();

  }

  const handleEditShedule = (draftId) => {
    setMailId(draftId);
    setNewEmailModal(true);
    setModalContent(true);
    let token;
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/draft-mail/${draftId}`, {
      headers: {
        'Authorization': `Bearer ${getNewToken()}`
      }
    })
      .then((response) => {
        const updateDraftData = response.data.particularMail;
        const businessPartnerOption = {
          label: updateDraftData?.business_name,
          value: updateDraftData?.business_partner_id
        };
       const customerDraftOption = {
        label: updateDraftData?.customer_name,
        value: updateDraftData?.customer_id
       }
       const [newDate,newTime] = updateDraftData.scheduledTimeInString.split(' ');
       const fileLinks = updateDraftData.fileLink ;
       const linkValues = fileLinks.map(file => file.link);
       setSelectedFile(linkValues);
       const fileNames = fileLinks.map(file => file.link.substring(file.link.lastIndexOf('/') + 1));
       setSelectedFileName(fileNames);
        setselectedPartnerValidate(updateDraftData.business_partner_id);
        setselectedCustomerValidate(updateDraftData.customer_id.split(','));
        setBuisnessValue(businessPartnerOption);
        setCustomerValue(customerDraftOption);
        setSubject(updateDraftData.emailSubject);
        setMailHeader(updateDraftData.emailHead);
        setMailBody(updateDraftData.emailBody);
        setScheduleDate(newDate);
        setScheduleTime(newTime);
        setViewEmailData(updateDraftData)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleEditDraft = (draftId) => {
    setMailId(draftId)
    setNewEmailModal(true);
    setModalContent(true);
    let token;
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/draft-mail/${draftId}`, {
      headers: {
        'Authorization': `Bearer ${getNewToken()}`
      }
    })
      .then((response) => {
        const updateDraftData = response.data.particularMail;
        const businessPartnerOption = {
          label: updateDraftData.business_name,
          value: updateDraftData.business_partner_id
        };
       const customerDraftOption = {
        label: updateDraftData.customer_name,
        value: updateDraftData.customer_id
       }
       const fileLinks = updateDraftData.fileLink ;
       const linkValues = fileLinks.map(file => file.link);
       setSelectedFile(linkValues);
       const fileNames = fileLinks.map(file => file.link.substring(file.link.lastIndexOf('/') + 1));
       setSelectedFileName(fileNames);
       setselectedPartnerValidate(updateDraftData.business_partner_id);
        setselectedCustomerValidate(updateDraftData.customer_id.split(','));
        setBuisnessValue(businessPartnerOption);
        setCustomerValue(customerDraftOption);
        setSubject(updateDraftData.emailSubject);
        setMailHeader(updateDraftData.emailHead);
        setMailBody(updateDraftData.emailBody);
        setViewEmailData(updateDraftData)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleShow = (viewid) => {
    setShowModal(true);
    let token;
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/draft-mail/${viewid}`, {
      headers: {
        'Authorization': `Bearer ${getNewToken()}`
      }
    })
      .then((response) => {
        const updateDraftData = response?.data?.particularMail;
        setViewEmailData(updateDraftData)
      })
      .catch((err) => {
        console.log(err);
      });
  }

const deletesend=(id)=>{
  let token;
  const user = JSON.parse(window.localStorage.getItem("client"));
  if (user) {token = user.token;} else {// console.error("User not authenticated");
    return;}
  axios.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/delete-mail/${id}`, {headers: {'Authorization': `Bearer ${getNewToken()}`}})
    .then((response) => {sendmailApi(pageNumber1);toast.success(response.data.message);})
    .catch((err) => {console.error(err);});
}

const deleteshedule=(id)=>{
  let token;
  const user = JSON.parse(window.localStorage.getItem("client"));
  if (user) {token = user.token;} 
  else {// console.error("User not authenticated");
    return;
  }
  axios.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/delete-mail/${id}`, {headers: {'Authorization': `Bearer ${getNewToken()}`}})
    .then((response) => {schedulemailApi(pageNumber1);toast.success(response.data.message);})
    .catch((err) => {console.error(err);});
}

  const deleteDrafted = (delId) => {
    let token;
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {

      // console.error("User not authenticated");
      return;
    }
    axios.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/delete-mail/${delId}`, {
      headers: {
        'Authorization': `Bearer ${getNewToken()}`
      }
    })
      .then((response) => {
        draftmailApi(pageNumber1)
        toast.success(response.data.message);
      })
      .catch((err) => {

        console.error(err);
      });
  }


  const removeHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };

  const [selectedFile, setSelectedFile] = useState([]);
  const [selectedFileName, setSelectedFileName] = useState([]);


  const handleFileChange = async (acceptedFiles) => {
    const validateFiletype = acceptedFiles.map(file => file.type);
    if (validateFiletype.includes("image/png") ||
    validateFiletype.includes("image/jpeg") ||
    validateFiletype.includes("application/pdf") ||
    validateFiletype.includes("application/msword")) {
      const maxSizeInMB = 5;
    const isFileSizeValid = acceptedFiles.every(file => file.size <= maxSizeInMB * 1024 * 1024);
    if(isFileSizeValid){
      setLoading(true);
      try {
        const file = acceptedFiles[0];
  
        const formData = new FormData();
        formData.append('image', file);
  
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/mail-image-upload`,
          formData
        );
        const imageUrl = response.data.imageUrl;
        const imageUrlsArray = imageUrl.split(',');
        const filename = response.data.imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
        const filenameArray = filename.split(',');
  
        setSelectedFile(prevFiles => [...prevFiles, ...imageUrlsArray]);
        setSelectedFileName((prevFiles) => [...prevFiles, ...filenameArray])
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error('Error uploading file:', error);
      }
    }else{
      toast.warning("File Size Can not Be More than 5MB")
    }
      
    }else{
      toast.warning("Please Upload Valid File")
    }
   
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'application/pdf,image/png,image/jpeg',
    onDrop: handleFileChange,
  })

  
  useEffect(() => {
    let token;
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/get-scheduled-emails`, {
      headers: {
        'Authorization': `Bearer ${getNewToken()}`
      }
    })
      .then((res) => {
        setScheduledMails(res.data.mails)
        const scheduleLength = res.data.totalMails
        setScheduleNumber(scheduleLength)
        // setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);

        // setIsLoading(false);
      });
  }, [])


  return (
    <>
      <div className='p-3 p-lg-4 p-xl-5'>
        <div className='container-fluid bg-white p-3 rounded shadow'>
          <div className='col-12 d-flex justify-content-between align-items-center mt-3'>
            {draftTable === true ?
              <p style={{ fontSize: "24px", fontWeight: "bold" }}>Drafted Mails</p>
              : sendTable === true ?
                <p style={{ fontSize: "24px", fontWeight: "bold" }}>Sent Mails</p>
                : scheduledTable === true ?
                  <p style={{ fontSize: "24px", fontWeight: "bold" }}>Scheduled Mails</p>
                  : ""

            }

            <Button
              variant="primary"
              title="New Email"
              className="addEmail_button d-flex justify-content-center gap-2  align-items-center"
              onClick={addNewMail}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
              </svg>
              New Email
            </Button>
          </div>
          <div className='col-12 mt-4'>
            <ul className='list-unstyled Emaillist_button g-3'>
              <li className='d-flex '>

                <button
                  // style={{backgroundColor:"white",color:"black",fontWeight:"800"}} 

                  className={`new_hover ${sendTable === true ? "active" : ""}`} onClick={sendMail}>
                  Sent
                </button>





                <button
                  // style={{backgroundColor:"white",color:"black",fontWeight:"800"}} 
                  className={`new_hover ${scheduledTable === true ? "active" : ""}`} onClick={scheduledMail}>
                  Schedule
                </button>


                <button
                  // style={{backgroundColor:"white",color:"black",fontWeight:"800"}} 
                  className={`new_hover ${draftTable === true ? "active" : ""}`} onClick={draftedMail}>
                  Drafted
                </button>
                {/* {draftNumber > 0 && (
        <span
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            transform: 'translate(5%, 33%)',
            borderRadius: "50%",
            backgroundColor: 'black',
            color: 'white',
            padding: "1px 8px",
          }}
        >
          {draftNumber}
        </span>
      )} */}
              </li>
            </ul>
          </div>
          <div className='col-12 my-4'>
            {sendTable && (
              <p>Sent Mail: <span style={{ fontSize: "18px", fontWeight: "600" }}>{sendMailNumber}</span></p>
            )}
            {draftTable && (
              <p>Drafted Mail: <span style={{ fontSize: "18px", fontWeight: "600" }}>{draftNumber}</span></p>
            )}
            {scheduledTable && (
              <p>Schedule Mail: <span style={{ fontSize: "18px", fontWeight: "600" }}>{scheduleNumber}</span></p>
            )}

          </div>
          {draftTable === true ?
            <div className='col-12 mt-0 '>
              <div className="table-scroll">
                <table className="table table_style">
                  <thead className='bgLightRed font14'>
                    <tr>
                      <th style={{ width: "135px" }}>Send to</th>
                      <th style={{ width: "230px" }} className='text-center'>Customers</th>
                      <th style={{ width: "251px" }}>Subject</th>
                      <th style={{ width: "234px" }} >Date & Time</th>
                      <th style={{ width: "154px" }} className='text-center'>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {zero ? <tr >
                        <td colSpan="9" className="text-center">
                          <h1 className="font16 text-dark" >No Data</h1>
                        </td>
                      </tr> :

                        draftMails?.map((mail) => (
                          <tr key={mail._id}>
                            <td className="font14">
                              {mail.business_name}
                            </td>
                            <td className='font14 text-center'>


                              <Link to={`/admin/Email/Customers/${mail.business_partner_id}/${mail.customer_id}`}>
                                {mail.customer_count} Customers
                              </Link>

                            </td>
                            <td className="font14 fw-medium">{mail.emailSubject}</td>
                            <td className="font14 fw-medium">{formatDate(mail.updatedAt).slice(0, 16).replace("T", "      ")}</td>
                            <td style={{ cursor: "pointer" }} className='svg_style text-center'>

                              <button className="email_delete_btn ms-2" onClick={() => handleEditDraft(mail._id)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                  <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z" />
                                </svg>
                              </button>

                             
                              <button className="email_delete_btn ms-2" onClick={() => deleteDrafted(mail._id)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                                  <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                                </svg>
                              </button>

                            </td>

                          </tr>
                        ))}
                    </>
                  </tbody>
                </table>
              </div>
            </div>
            : sendTable === true ?
              <div className='col-12 mt-0 '>
                <div className="table-scroll">
                  <table className="table table_style">
                    <thead className='bgLightRed font14'>
                      <tr>
                        <th style={{ width: "135px" }}>Sent to</th>
                        <th style={{ width: "230px" }} className='text-center'>Customers</th>
                        <th style={{ width: "251px" }}>Subject</th>
                        <th style={{ width: "234px" }} >Date & Time</th>
                        <th style={{ width: "100px" }} className='text-center'>Action</th>
                        {/* <th style={{width:"154px"}}>
                      Action
                      </th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {!sentEmailLoading? (<tr style={{ backgroundColor: "#eee" }}>
                      <td colSpan="8">
                        <Bars 
                          height="80"
                          width="100%"
                          margin='auto'
                          color="#FF7020"
                          ariaLabel="bars-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                        />
                      </td>
                    </tr> ) :
                      <>
                        {zerosendMail ? <tr >
                          <td colSpan="9" className="text-center">
                            <h1 className="font16 text-dark" >No Data</h1>
                          </td>
                        </tr> :
                          sendMails?.map((mail) => (
                            <tr key={mail._id}>
                              <td className="font14">
                                {mail.business_name}
                              </td>
                              <td className='font14 text-center'>

                                <Link to={`/admin/Email/Customers/${mail.business_partner_id}/${mail.customer_id}`}>
                                  {mail.customer_count} Customers
                                </Link>

                              </td>
                              <td className="font14 fw-medium">{mail.emailSubject}</td>
                              <td className="font14 fw-medium">{formatDate(mail.updatedAt).slice(0, 16).replace("T", "      ")}</td>
                              <td className="font14 fw-medium text-center"> <button className='email_view_btn' variant="primary" onClick={() => handleShow(mail._id)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                                  <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                  <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                </svg>
                              </button>
                                <button className="email_delete_btn ms-2" onClick={() => deletesend(mail?._id)}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                                    <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                                  </svg>
                                </button>
                              </td>

                            </tr>
                          ))}
                      </>}
                    </tbody>
                  </table>
                </div>
              </div>
              : scheduledTable === true ?
                <div className='col-12 mt-0 '>
                  <div className="table-scroll">
                    <table className="table table_style">
                      <thead className='bgLightRed font14'>
                        <tr>
                          <th style={{ width: "135px" }}>Schedule to</th>
                          <th style={{ width: "230px" }} className='text-center'>Customers</th>
                          <th style={{ width: "251px" }}>Subject</th>
                          <th style={{ width: "234px" }} >Date & Time</th>
                          <th style={{ width: "100px" }} className='text-center' >Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <>
                          {zerosheduleMail ? <tr >
                            <td colSpan="9" className="text-center">
                              <h1 className="font16 text-dark" >No Data</h1>
                            </td>
                          </tr> :
                            scheduledMails?.map((mail) => (
                              <tr key={mail._id}>
                                <td className="font14">
                                  {mail.business_name}
                                </td>
                                <td className='font14 text-center'>

                                  <Link to={`/admin/Email/Customers/${mail.business_partner_id}/${mail.customer_id}`}>
                                    {mail.customer_count} Customers
                                  </Link>

                                </td>
                                <td className="font14 fw-medium">{mail.emailSubject}</td>
                                <td className="font14 fw-medium">{mail.scheduledTimeInString}</td>
                                <td className="font14 fw-medium text-center" >
                                <button className="email_delete_btn ms-2" onClick={() =>handleEditShedule(mail?._id)} > 
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                  <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z" />
                                </svg>
                              </button>

                              
                              <button className="email_delete_btn ms-2" onClick={() => deleteshedule(mail?._id)} >
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                                  <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                                </svg>
                              </button>
                                </td>
                              </tr>
                            ))}
                        </>
                      </tbody>
                    </table>
                  </div>
                </div>
                :
                <div className='col-12 mt-5 '>
                  <div className="table-responsive">
                    <table className="table table_style">
                      <thead className='bgLightRed font14'>
                        <tr>
                          <th scope="col">Sent to</th>
                          <th scope="col">Subject</th>
                          <th scope="col">Date & Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allMailData.map((mail) => (
                          <tr key={mail._id}>
                            <td className="font14">
                              {mail.customer_name}
                            </td>
                            <td className="font14 fw-medium">{mail.emailSubject}</td>
                            <td className="font14 fw-medium">{mail.createdAt}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
          }

          <div>
            {
              sendTable  && numberOfPages > 0 ?
                <Col className="my-3">
                  {
                    <ul className="custPagination d-flex justify-content-center list-unstyled font14">
                      {pageNumber === 0 ? (
                        ""
                      ) : (

                        <li onClick={prevPage}>Previous </li>

                      )}

                      {items}


                      {numberOfPages - 1 === pageNumber ? (
                        ""
                      ) : (
                        <li onClick={nextPage}>Next</li>
                      )}
                      <li className="font14"><input style={{ width: "25px", border: '1px solid #4C00EF', borderRadius: '4px', textAlign: 'center' }} value={showPage ? showPage : ''} type="text" name="number" id="" onChange={(e) => { pagenumberSearch(e.target.value) }}  ></input> <span style={{ color: '#4C00EF' }}>/</span> {numberOfPages}</li>
                    </ul>
                  }
                </Col>
                : ""
            }
            {
              draftTable && numberOfPages1 > 0 ?
                <Col className='my-3' >
                  {
                    <ul className="custPagination d-flex justify-content-center list-unstyled font14">
                      {pageNumber1 === 0 ? (
                        ""
                      ) : (
                        <li onClick={prevPage1}>Previous </li>
                      )}

                      {items1}

                      {numberOfPages1 - 1 === pageNumber1 ? (
                        ""
                      ) : (
                        <li onClick={nextPage1}>Next</li>
                      )}

                      <li className="font14">
                        <input
                          style={{ width: "25px", border: '1px solid #4C00EF', borderRadius: '4px', textAlign: 'center' }}
                          value={showPage1 ? showPage1 : ''}
                          type="text"
                          name="number"
                          id=""
                          onChange={(e) => { pagenumberSearch1(e.target.value) }}
                        />
                        <span style={{ color: '#4C00EF' }}>/</span> {numberOfPages1}
                      </li>
                    </ul>
                  }
                </Col>
                : ""
            }

            {
              scheduledTable && numberOfPages2 > 0 ?
                <Col className='my-3'>
                  {
                    <ul className="custPagination d-flex justify-content-center list-unstyled font14">
                      {pageNumber2 === 0 ? (
                        ""
                      ) : (
                        <li onClick={prevPage2}>Previous </li>
                      )}

                      {items2}

                      {numberOfPages2 - 1 === pageNumber2 ? (
                        ""
                      ) : (
                        <li onClick={nextPage2}>Next</li>
                      )}

                      <li className="font14">
                        <input
                          style={{ width: "25px", border: '1px solid #4C00EF', borderRadius: '4px', textAlign: 'center' }}
                          value={showPage2 ? showPage2 : ''}
                          type="text"
                          name="number"
                          id=""
                          onChange={(e) => { pagenumberSearch2(e.target.value) }}
                        />
                        <span style={{ color: '#4C00EF' }}>/</span> {numberOfPages2}
                      </li>
                    </ul>
                  }
                </Col>
                : ""
            }
          </div>
        </div>

      </div>

      <Modal

        show={newEmailModal}
        onHide={handleCloseEmailModal}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="font20 text-dark mb-0"
            id="contained-modal-title-vcenter"
          >
            {modalContent ? "Edit Draft Mail" : "Send Email"}

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid >
            <Form className=' row g-3'>
              <Col lg={6}>
                <Select
                  styles={customStyles}
                  onChange={handlePartnerSelection}
                  options={getbusinessPartner}
                  value={buisnessValue}
                  placeholder="Choose Buisness Partner"
                 
                  components={{
                    IndicatorSeparator: () => null
                  }}
                />
              </Col>
              <Col lg={6}>
              </Col>
              <Col lg={6}>
                <Select
                  styles={customStyles}
                  onChange={handleCustomerSelection}
                  options={customeroptions}
                  value={customerValue}
                  isMulti={true}
                  placeholder="Choose Customer"
                  
                  components={{
                    IndicatorSeparator: () => null
                  }}
                />
              </Col>
              <Col lg={6}>
              </Col>
              <Col lg={12}>
                <p className='text-dark'>Subject<span style={{color:"red",fontSize:"16px", marginLeft:"2px"}} >*</span></p>
                <Form.Control
                  name='subject'
                  className="font12"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  type="text"
                  placeholder="Add subject of the email"

                />


              </Col>
              <Col lg={12}>
                <p className='text-dark'>Mail Heading<span style={{color:"red",fontSize:"16px", marginLeft:"2px"}} >*</span></p>
                <JoditEditor
                  config={config}
                  ref={heading}
                  value={mailHeader}
                  onBlur={e => setMailHeader(e)}

                />


              </Col>
              
              <Col lg={12}>
                <p className='text-dark'>Mail body</p>
                <JoditEditor
                  config={config}
                  ref={body}
                  value={mailBody}
                  onBlur={e => setMailBody(e)}
                />



              </Col>
              <Col lg={12} className='mb-1'>
                <div>
                <Card style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                  <Card.Body>
                    {loading ? (
                      <Bars
                        height="80"
                        width="100%"
                        margin="auto"
                        color="#FF7020"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    ) : (
                      <>
                        <Card.Title className='text-dark text-center'>Upload Files<br/>
                        <span style={{fontSize:"17px",fontWeight:"200"}}>Upload document you want to share <br/> with your team</span></Card.Title>
                        <Container>
                          <div {...getRootProps()} className="dropzone d-flex align-items-center justify-content-around flex-column" style={{height:"100%",minHeight:"100px",width:"100%",maxWidth:"300px", padding:"30px 55px", border:"2px dashed #8b3f3fa1",cursor:"pointer"}}>
                            <input {...getInputProps({ name: 'image' })} />
                            <img src='/assets/images/upload.svg'style={{width:"50px"}} alt='.....'/>
                            <p style={{ cursor: "pointer",  }} className='text-center'>Drag & Drop your files here </p>
                            <p style={{marginTop:"5px"}}>OR</p>
                            <p style={{height:"35px",width:"100px",backgroundColor:"blue",borderRadius:"5px",color:"white",marginTop:"8px"}} className='d-flex justify-content-center align-items-center'>Browse Files</p>
                          </div>
                          {loading && <p>Uploading...</p>}
                          
                        </Container>
                      </>
                    )}
                  </Card.Body>
                </Card>
                <div >
                  {selectedFileName.length > 0 ?
                  <p className='text-center'>Uploaded Files</p>
                  :""}
                  
                  
                  <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}} >
                  {selectedFileName && selectedFileName.map((fileName, index) =>  (
                    <span key={index} className='d-flex justify-content-around align-items-center upload_files_style m-1'>
                            <p  className='text-center ' style={{maxWidth:"160px"}}> {fileName} </p>
                            <p onClick={() => removelink(index)} style={{ cursor: "pointer", color: "black", marginLeft: "10px",height:"18px",width:"18px",borderRadius:"50px",backgroundColor:"white",fontSize:"14px"}} className='text-center'>X</p>
                            </span>
                         ))}
                  </div>
                </div>
                </div>
              </Col>
              <Col lg={2} className='responsiveEmailbutton'>
                <Button
                  variant="primary"
                  // title="New Email"
                  className="addEmail_button d-flex justify-content-center gap-2  align-items-center"
                  onClick={sendEmail}

                >
                  Send
                </Button>
              </Col>
              <Col lg={2} className='responsiveEmailbutton'>
                <Button
                  variant="primary"
                  // title="New Email"
                  className="addEmail_button d-flex justify-content-center gap-2  align-items-center"
                  onClick={handleScheduleModal}

                >
                  Schedule

                </Button>
              </Col>
              <Col lg={3} className='responsiveEmailbutton'>
                <Button
                  variant="primary"
                  // title="New Email"
                  className="addEmail_button d-flex justify-content-center gap-2  align-items-center"
                  onClick={draftEmail}
                >
                  Save Draft
                </Button>
              </Col>
              {/* <Col lg={3}>
              </Col> */}
            </Form>
          </Container>
        </Modal.Body>
      </Modal>


      <Modal

        show={scheduleModal}
        onHide={handleCloseScheduleModal}
        size="xl"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton >
          <Modal.Title
            className="font20 text-dark mb-0"
            id="contained-modal-title-vcenter"
          >
            Schedule Mail
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid >
            <Row className='g-5'>
              <Col lg={6}>
                <p>Choose Date</p>
                <Form.Control
                  name='selectedDate'
                  type="date"
                  className="font16"
                  value={scheduleDate}
                  onChange={(e) => setScheduleDate(e.target.value)}
                  style={{ height: "46px" }}
                  placeholder='Choose Date'
                />
              </Col>
              <Col lg={6}>
                <p>Choose Time (EST)</p>
                <Form.Control
                  name='selectedDate'
                  type="time"
                  className="font16"
                  value={scheduleTime}
                  onChange={(e) => setScheduleTime(e.target.value)}
                  style={{ height: "46px" }}
                  placeholder='Choose Date'
                />
              </Col>

              <Col lg={4}>

              </Col>
              <Col lg={4} className='button_save'>
                <Button
                  variant="primary"
                  // title="New Email"
                  className="addEmail_button d-flex justify-content-center gap-2  align-items-center"
                  onClick={handleChangeScheduleModal}
                >
                  Schedule
                </Button>
              </Col>
              <Col lg={4}>
                <Button
                  variant="primary"
                  // title="New Email"
                  className="addEmail_button d-flex justify-content-center gap-2  align-items-center"
                  onClick={handleScheduleClosed}

                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>

      <Modal show={showModal} onHide={handleClose} centered size="lg" backdrop="static"
        aria-labelledby="contained-modal-title-vcenter">
        <Modal.Header className='py-2' closeButton style={{ backgroundColor: '#fff3ed' }}>
          <Modal.Title><h4>Mail Sent (Details)</h4></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            <li>
              <p><b>Business Partner:</b> <span style={{ marginLeft: "8px" }}>{viewEmailData.business_name}</span></p></li>
            <li><p><b>Customer Name:</b> <span style={{ marginLeft: "15px" }}>{viewEmailData.customer_name}</span></p></li>
            <li><p><b>Subject:</b> <span style={{ marginLeft: "76px" }}>{viewEmailData.emailSubject}</span></p></li>
            <li><p><b>Mail Heading:</b> <span style={{ marginLeft: "37px" }}>{removeHtmlTags(viewEmailData.emailHead)}</span></p></li>
            <li><p><b>Mail Body:</b> <br /><span >{removeHtmlTags(viewEmailData.emailBody)}</span></p></li>


          </ul>

        </Modal.Body>
      </Modal>

    </>
  )
}

export default Email;