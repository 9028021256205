import { useState, useEffect } from "react";
import { Button, Col, Container, Row, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import {BiPlusMedical} from "react-icons/bi";
import Modal from 'react-bootstrap/Modal';
import { useParams } from "react-router-dom";
import Select from "react-select";
import { getNewPartnerToken, getNewToken } from "../config/cookie.config";
function ContactModal(props) {
  const [show, setShow] = useState(false);
  const {id} = useParams();
  const [email, setEmail] = useState("");
  const [first_name, setFirst_Name] = useState("");
  const [last_name, setLast_Name] = useState("");
  const [phone, setPhone] = useState("");
  const [contact_type, setContact_type] = useState("");
  const [code,setCode] = useState("");
  const [nameError,setNameError] = useState(false);
  const [phoneError,setPhoneError] = useState(false);
  const [contact_type_Error,setContact_type_Error] = useState(false);
  const [error, setError] = useState(null);
  const [role,setRole] =useState("");
//   const [email,setEmail] = useState("");
   
function isValidEmail(email) {
  return /\S+@\S+\.\S+/.test(email);
}

let firstName;
let lastName;


const handleChange = event => {
  if (!isValidEmail(event.target.value)) {
    setError('Email is invalid');
  } else {
    setError(null);
  }

  setEmail(event.target.value);
};

useEffect(() => {
    if (props.id) {
      getAllCustomers();
    }
  }, []);
  const getAllCustomers = () => {
    var token;
    let para;
    if(props.admin === true){
      const user = JSON.parse(window.localStorage.getItem("client"));
      para="admin"
    if (user) {
      // token = user.token;
      token = getNewToken();
    } else {
      token = null;
    }
    }else{
      const user = JSON.parse(window.localStorage.getItem("business-client"));
      para="partner"
      if (user) {
        // token = user.token;
        token = getNewPartnerToken();
      } else {
        token = null;
      }
    }
    axios
      .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/${para}/getbusinesscontact/${props.id}`, {
          headers: {
            'Authorization' : `Bearer ${token}`
          }
        })
      .then((response) => {
        // setCode(response.data.businessContact.code);
        setRole(response.data.businessContactId.role)
        setEmail(response.data.businessContactId.email);
        let names = response.data.businessContactId.name;
        const namesArray = names.split(", ");
        const hasSpace = namesArray.some(name => name.includes(' '));
        if (hasSpace) {
          const name = names;
         const namesArray = name.split(" ");
         firstName = namesArray[0];
         lastName = namesArray[1];
         if(firstName.length>0 && lastName.length>0){
        setFirst_Name(firstName);
        setLast_Name(lastName);
         }else{
          setFirst_Name(firstName);
         }
        }
        setPhone(response.data.businessContactId.phone);
        setCode(response.data.businessContactId.code)
      })
      .catch((error) => console.error(`Error: ${error}`));
  };

    const handleClose =async () =>{ 
      if(first_name.length<3 || role.length<2 || phone.length<10 || error ==="Email is invalid"){
      if(first_name.length<3 ){
          setNameError(true);
      }else{
        setNameError(false);
      }
      if(role.length<2){
        setContact_type_Error(true);
      }
      if(phone.length<10){
        setPhoneError(true);
      }
      if(error ==="Email is invalid"){
        setError('Email is invalid')
      }
    }
      else{
        setNameError(false);
        setContact_type_Error(false);
        try {
          // const user = JSON.parse(window.localStorage.getItem("client"));
          var token;
          let para;
    if(props.admin === true){
      const user = JSON.parse(window.localStorage.getItem("client"));
      para="admin"
    if (user) {
      // token = user.token;
      token = getNewToken();
    } else {
      token = null;
    }
    }else{
      const user = JSON.parse(window.localStorage.getItem("business-client"));
      para="partner"
      if (user) {
        token = getNewPartnerToken();
        // token = user.token;
      } else {
        token = null;
      }
    }

    let name;
    if(first_name && last_name){
      name = first_name +' '+last_name;
    }else if(first_name){
      name=first_name.trim();
    }
          const { data } = await axios.post(
            `${process.env.REACT_APP_BACKEND_BASE_URL}api/${para}/businesscontact/create`,
            {
              user_id:props.id,
              role,
              name,
              phone,
              email,
              code,
            }, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          },
          );
          toast.success(data.message);
          if (data.status === "success") {
            window.location.reload();
            setShow(false);
          } 
        } catch (err) {
          console.log(`Error : ${err}`);
          toast.error(err.response.data.message);
        }
      }
        };
    const handleShow = () => setShow(true);
    const handleClosee = () => setShow(false);

    function phoneFormat(input) {
      input = input.replace(/\D/g, "");
  
      input = input.substring(0, 10);
  
      var size = input.length;
      if (size == 0) {
        input = input;
      } else if (size < 4) {
        input = "(" + input;
      } else if (size < 7) {
        input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6);
      } else {
        input =
          "(" +
          input.substring(0, 3) +
          ") " +
          input.substring(3, 6) +
          " - " +
          input.substring(6, 10);
      }
      return input;
    }

    const handleContactType =(e)=>{
      
      setRole(e.target.value)
      if(role.length<2 && e.target.value==='c' ){
        setContact_type_Error(true);
      }else{
        setContact_type_Error(false);
      }
    }
  const handleName =(e)=>{
    setFirst_Name(e.target.value)
    if(first_name.length<3 ){
      setNameError(true);
  }else{
    setNameError(false);
  }
    
    
  }
  
  const handlePhone =(e)=>{
    setPhone(e.target.value)
    if(phone.length<10){
      setPhoneError(true);
    }else{
      setPhoneError(false);
    }
  }
  const phoneCode = [
    { value: "+1", label: "+1 USA" },
    { value: "+7", label: "+7 RUS" },
    { value: "+20", label: "+20 EGY" },
    { value: "+27", label: "+27 ZAF" },
    { value: "+30", label: "+30 GRC" },
    { value: "+31", label: "+31 NLD" },
    { value: "+32", label: "+32 BEL" },
    { value: "+33", label: "+33 FRA" },
    { value: "+34", label: "+34 ESP" },
    { value: "+36", label: "+36 HUN" },
    { value: "+39", label: "+39 ITA" },
    { value: "+40", label: "+40 ROU" },
    { value: "+41", label: "+41 SWZ" },
    { value: "+43", label: "+43 AUT" },
    { value: "+44", label: "+44 GBR" },
    { value: "+45", label: "+45 DNK" },
    { value: "+46", label: "+46 SWE" },
    { value: "+47", label: "+47 NOR" },
    { value: "+48", label: "+48 POL" },
    { value: "+49", label: "+49 DEU" },
    { value: "+51", label: "+51 PER" },
    { value: "+52", label: "+52 MEX" },
    { value: "+53", label: "+53 CUB" },
    { value: "+54", label: "+54 ARG" },
    { value: "+55", label: "+55 BRA" },
    { value: "+56", label: "+56 CHL" },
    { value: "+57", label: "+57 COL" },
    { value: "+58", label: "+58 VEN" },
    { value: "+60", label: "+60 MYS" },
    { value: "+61", label: "+61 AUS" },
    { value: "+62", label: "+62 IDN" },
    { value: "+63", label: "+63 PHL" },
    { value: "+64", label: "+64 NZL" },
    { value: "+65", label: "+65 SGP" },
    { value: "+66", label: "+66 THA" },
    { value: "+81", label: "+81 JPN" },
    { value: "+82", label: "+82 KOR" },
    { value: "+84", label: "+84 VNM" },
    { value: "+86", label: "+86 CHN" },
    { value: "+90", label: "+90 TUR" },
    { value: "+91", label: "+91 IND" },
    { value: "+92", label: "+92 PAK" },
    { value: "+93", label: "+93 AFG" },
    { value: "+94", label: "+94 LKA" },
    { value: "+95", label: "+95 MMR" },
    { value: "+98", label: "+98 IRN" },
    { value: "+211", label: "+211 SSD" },
    { value: "+212", label: "+212 MAR" },
    { value: "+213", label: "+213 DZA" },
    { value: "+216", label: "+216 TUN" },
    { value: "+218", label: "+218 LBY" },
  ];

  const handleKeyPress = (e) => {
    const keyCode = e.keyCode || e.which;
    const char = String.fromCharCode(keyCode);
    const regex = /^[0-9]+$/;
    if (!regex.test(char)) {
      e.preventDefault();
    }
  };

    const handleSubmit =(e)=>{
        e.preventDefault();
    }
  return (
    <>
       <div
                style={{width:"125%",marginLeft:'-1rem'}}
                className="font14 fw-medium"
                onClick={handleShow}>
      
                <span className="ps-3">Edit</span>
      </div>

      <Modal
        show={show}
        onHide={handleClosee}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Contact Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Container fluid className="mt-4">
            <Row>  
            <Col lg={12} md={12}>
            <Form 
            className="bg-white p-1 rounded containerShadow"
            onSubmit={handleSubmit}
            >
        <Row>

        <Col md={6}>
        <Form.Group className="mb-3">
        <Form.Label className="font14 text-dark fw-medium">
                          Contact Type{" "}
                          <span className="text-danger">*</span>
        </Form.Label>
        {/* <Form.Control type="text" value={contact_type} placeholder="Contact_type"  /> */}
        {contact_type_Error?
        <>
        <Form.Select className="font14 form-select" name='role'value={role} onChange={handleContactType}
         style={{ border: '1px solid red', height: '31px', borderRadius: '5px' }}
         type='text'>
          <option value="c" label="Contact type" />
          <option value="Primary" label="Primary" />
          <option value="Management" label="Management" />
          <option value="Marketing" label="Marketing" />
          <option value="Technical" label="Technical" />
          <option value="Acounting" label="Acounting" />
          <option value="Customer Service" label="Customer Service" />
        </Form.Select>
        <div className="text-danger font14">
                              Please choose a valid contact type
                            </div>
        </>:<Form.Select className="font14 form-select" name='role'value={role} onChange={handleContactType}
         style={{ border: '1px solid #cfc6c6', height: '31px', borderRadius: '5px' }}
         type='text'>
          <option label="Contact type" />
          <option value="Primary" label="Primary" />
          <option value="Management" label="Management" />
          <option value="Marketing" label="Marketing" />
          <option value="Technical" label="Technical" />
          <option value="Acounting" label="Acounting" />
          <option value="Customer Service" label="Customer Service" />
        </Form.Select>}
      </Form.Group>
        </Col>

       
        <Col md={6}>
      <Form.Group className="mb-3" controlId="formBasicText1">
      <Form.Label className="font14 text-dark fw-medium">
        First Name <span className="text-danger">*</span>
        </Form.Label>
        
        <Form.Control
        name="first_name"
        className="font14"
        style={nameError?{ border:'1px solid red'}:{}}
        value={first_name}
        onChange={handleName}
        type="text"
        placeholder="Enter First Name"
        />
        {nameError?<div className="text-danger font14">
                              Enter valid First Name
                            </div>:''}
      </Form.Group>
      </Col>
        <Col md={6}>
      <Form.Group className="mb-3">
      <Form.Label className="font14 text-dark fw-medium">
         Last Name
        </Form.Label>
        {/* <Form.Control type="text" value={name} placeholder="Enter name" onChange={(e)=>setName(e.target.value)} /> */}
        <Form.Control
        name="last_name"
        className="font14"
        value={last_name}
        onChange={(e)=>setLast_Name(e.target.value)}
        type="text"
        placeholder="Enter Last Name"
        />
        
        
      </Form.Group>
      </Col>
      

          <Col md={6}>
                        <Form.Group className="mb-md-3 mb-lg-0">
                          <Form.Label className="font14 text-dark fw-medium">
                            Phone Number{" "}
                            <span className="text-danger">*</span>
                          </Form.Label>
                        <div className="d-flex justify-content-center align-items-center">
                          <div className="setect_code">
                            <Select
                              className={`font12 form-select${
                                code.length > 10 ? "sdd" : "jk"
                              }`}
                              name="code"
                              value={phoneCode.find(
                                (option) => option.value === code
                              )}
                              onChange={(selectedOption) =>
                                setCode(selectedOption.value)
                              }
                              options={phoneCode}
                              isSearchable
                              placeholder="Code"
                            />
                          </div>
                          {/* <Form.Select className="font14 form-select" name='code' value={code} onChange={(e)=>setCode(e.target.value)}
                              style={{ border: '1px solid #cfc6c6', height: '31px', borderRadius: '5px', width: '6rem' }}
                              type='text'>
                              <option value="" label="code" />
                              <option value="+1" label="+1 USA" />
                              <option value="+7" label="+7 RUS" />
                              <option value="+20" label="+20 EGY" />
                              <option value="+27" label="+27 ZAF" />
                              <option value="+30" label="+30 GRC" />
                              <option value="+31" label="+31 NLD" />
                              <option value="+32" label="+32 BEL" />
                              <option value="+33" label="+33 FRA" />
                              <option value="+34" label="+34 ESP" />
                              <option value="+36" label="+36 HUN" />
                              <option value="+39" label="+39 ITA" />
                              <option value="+40" label="+40 ROU" />
                              <option value="+41" label="+41 SWZ" />
                              <option value="+43" label="+43 AUT" />
                              <option value="+44" label="+44 GBR" />
                              <option value="+45" label="+45 DNK" />
                              <option value="+46" label="+46 SWE" />
                              <option value="+47" label="+47 NOR" />
                              <option value="+48" label="+48 POL" />
                              <option value="+49" label="+49 DEU" />
                              <option value="+51" label="+51 PER" />
                              <option value="+52" label="+52 MEX" />
                              <option value="+53" label="+53 CUB" />
                              <option value="+54" label="+54 ARG" />
                              <option value="+55" label="+55 BRA" />
                              <option value="+56" label="+56 CHL" />
                              <option value="+57" label="+57 COL" />
                              <option value="+58" label="+58 VEN" />
                              <option value="+60" label="+60 MYS" />
                              <option value="+61" label="+61 AUS" />
                              <option value="+62" label="+62 IDN" />
                              <option value="+63" label="+63 PHL" />
                              <option value="+64" label="+64 NZL" />
                              <option value="+65" label="+65 SGP" />
                              <option value="+66" label="+66 THA" />
                              <option value="+81" label="+81 JPN" />
                              <option value="+82" label="+82 KOR" />
                              <option value="+84" label="+84 VNM" />
                              <option value="+86" label="+86 CHN" />
                              <option value="+90" label="+90 TUR" />
                              <option value="+91" label="+91 IND" />
                              <option value="+92" label="+92 PAK" />
                              <option value="+93" label="+93 AFG" />
                              <option value="+94" label="+94 LKA " />
                              <option value="+95" label="+95 MMR" />
                              <option value="+98" label="+98 IRN" />
                              <option value="+211" label="+211 SSD" />
                              <option value="+212" label="+212 MAR" />
                              <option value="+213" label="+213 DZA" />
                              <option value="+216" label="+216 TUN" />
                              <option value="+218" label="+218 LBY" />
                            </Form.Select> */}
                            
                          &nbsp;
                            {code === '' ?
                              <Form.Control
                               required
                                type="text"
                                name="phone"
                                value={phoneFormat(phone)}
                                onChange={(e)=>handlePhone(e)}
                                placeholder="Enter Phone"
                                className="font14 "
                                disabled
                              />

                              :
                              <>
                              {phoneError?
                              <>
                              <Form.Control
                              type="text"
                              name="phone"
                            
                              value={phoneFormat(phone)}
                              onChange={(e)=>handlePhone(e)}
                              placeholder="Enter Phone"
                              className="font14"
                              onKeyPress={handleKeyPress}

                              style={{border:'1px solid red'}}
                              />
                              
                              </>
                              :<Form.Control
                              type="text"
                              name="phone"
                              maxLength={16}
                              value={phoneFormat(phone)}
                              onChange={(e)=>setPhone(e.target.value)}
                              placeholder="Enter Phone"
                              className="font14 "
                              onKeyPress={handleKeyPress}

                              onPaste={(e) => {
                                e.preventDefault();
                                const pastedText = e.clipboardData.getData('text/plain');
                                const formattedPhoneNumber = phoneFormat(pastedText);
                                handleChange({ target: { name: 'phone', value: formattedPhoneNumber } });
                              }}
                              />}
                              
                              </>
                            }

                          </div>
                          <div className="d-flex">
                            

                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                           

                          </div>
                        </Form.Group>
                      </Col>



       <Col md={6}>
        <Form.Group className="mb-3">
                          <Form.Label className="font14 text-dark fw-medium">
                            Email Address <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                              className="font14"
                              style={error?{ border:'1px solid red'}:{}}
                              type="email"
                              name="email"
                              value={email}
                              onChange={handleChange}                
                              placeholder="Enter Email Address"
                            />
                            {error && <div className="text-danger font14">{error}</div>}
        {/* <Form.Control type="email" placeholder="Enter email" value={email}  onChange={(e)=>setEmail(e.target.value)}/> */}
      </Form.Group>
        </Col>
        </Row>
        <Row className="mt-4">
                      <Col>
                        <Button type="submit" variant="primary fullRounded fw-bold font14" onClick={handleClose}>
          <span className="me-2">
                  {/* <img
                    src="/assets/images/plus.svg"
                    className="img-fluid"
                    alt=""
                  /> */}
                   <BiPlusMedical />
                </span>
                <span>{props.id ? "Save Changes" : "Create Contact Info"}</span>
          </Button>
                      </Col>
                    </Row>
         
    </Form>
            </Col>
            </Row>
        </Container>
        {/* <ToastContainer position="top-center" /> */}
        </Modal.Body>
        <Modal.Footer>
          
          {/* <Button variant="primary">Understood</Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ContactModal;