import React,{useState,useEffect} from 'react'
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from 'axios';
import { Col, Form, Button } from 'react-bootstrap';
import { Bars } from "react-loader-spinner";
import CsvForm from './CsvForm';
import { getNewPartnerToken } from '../../config/cookie.config';

const  UploadCsv = () => {
const [showCsvModal, setshowCsvModal] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [filename, setFilename] = useState('');
  const [loadingCsv,setLoadingCsv] = useState(false);
  const [disable, setDisable] = useState(false);
  let token;
  const navigate = useNavigate();
  const businessPartner = JSON.parse(window.localStorage.getItem('business-client'));
  token = businessPartner?.token
  const id = businessPartner?.user?._id;
  const b_id = businessPartner?.user?.business_partner_id;
  const [partnerId, setPartnerId] = useState(id);
  const [previewModal,setPreviewModal] = useState(false);
  const [previewData,setPreviewData] = useState();
  const [previewData1,setPreviewData1] = useState();
  const [businessName, setBusinessName] = useState('');

  const handleClosehandleCsvModal = () => {
       setFilename('');
        setshowCsvModal(!showCsvModal);
        navigate("/partner/customer")
      };

      const handleClosePreviewModal =()=>{
        setPreviewModal(false);
        setshowCsvModal(false);
        navigate("/partner/customer")
       };

       const handleClearData =()=>{
        setFilename('');
       }


      useEffect(()=>{
        setshowCsvModal(true);
      },[])

      const setCustomers = (userDataInString) => {
        var userDataInJSON = [];
    
        // Coverting String to Array of JSON Objects
        var rows = userDataInString.split("\n");
    
        var er = rows[0].search("\r");
        if (er > 1) {
          // rows[0] = rows[0].slice(0);
          rows[0] = rows[0].slice(0, -1);
        } else {
          rows[0] = rows[0].slice(0);
          //  rows[0] = rows[0].slice(0, -1);
        }
    
        var fields = rows[0].split(",");
    
        if(fields[0] =="first_name" && fields[1] =="last_name" &&  fields[2] == "email" && fields[3] == "code" && fields[4] == "phone" && fields[5] == "balance"&& fields[6] == "business_partner"){  
    
        for (var i = 1; i < rows.length; i++) {
          // To check null fields
          var flag = false;
    
          // Creating individual JSON objects from string
          var singleUserObject = {};
    
          // To handle the extra space in the end
          // rows[i] = rows[i].slice(0, -1);
    
    
          var ers = rows[i].search("\r");
          if (ers >= 0) {
            // rows[i] = rows[i].slice(0);
            rows[i] = rows[i].slice(0, -1);
    
          } else {
            rows[i] = rows[i].slice(0);
            //  rows[i] = rows[i].slice(0, -1);
          }
    
    
    
          // Extracting the data for the objects
          var singleRow = rows[i].split(",");
    
          for (var j = 0; j < fields.length; j++) {
            if (singleRow[j] === "") {
              flag = true;
            }
    
            // Key-Value Pairs
            singleUserObject[fields[j]] = singleRow[j];
            singleUserObject["partner_id"] = partnerId;
            singleUserObject["password"] = "cust@1234";
            singleUserObject["status"] = 1;
          }
    
    
          if (flag) {
            // To be added to Invalid Users
          } else {
            // To be sent to backend
            userDataInJSON.push(singleUserObject);
          }
        }
        setCsvData(userDataInJSON);
        axios
        .post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/csv-preview`,
          userDataInJSON,{
            headers: {
              'Authorization' : `Bearer ${getNewPartnerToken()}`
            }
          },
        )
        .then((res) => {
          if (res.data?.status === "failed") {
             toast.warning(res.data.message);
            return false;
          }else {
            // toast.success(res.data.message);
            setPreviewData(res.data.customers);
            setPreviewData1(res.data);
            setBusinessName(res.data.businessName)
            setLoadingCsv(true)
            setTimeout(() => {
              setLoadingCsv(false)
              setPreviewModal(true)
            }, 800);
          }        
          // toast.success(res.data.message);
          // setPreviewData(res.data.customers);
          // setPreviewData1(res.data);
          // setBusinessName(res.data.businessName)
         
        })
        .catch((err) => {
          console.log(err);
          if(err?.response?.data?.message){
            toast.error(err?.response?.data?.message);
          }
        });
      }else{
        toast.warning("Invalid CSV File")
        setCsvData([]);
    
      }
      };

      const handleSubmit = async (e) => {
        setLoadingCsv(true);
        e.preventDefault();
        setDisable(true)
        setTimeout(() => {
          setDisable(false);
        }, 2000);
    
        if (csvData.length == 0) {
          toast.warning("Please Upload A CSV File");
          setTimeout(() => {
            setLoadingCsv(false);
          }, 1000);
          return false;
        }
    
    
    
        for (var i = 0; i < csvData.length; i++) {
          if(csvData[i].phone){
            if (csvData[i].phone.length < 9) {
              toast.warning("Phone number must be 10 digits");
              return false;
            }
    
          }
    
    
          let business_partner =csvData[i].business_partner;
          if(csvData[i].business_partner){  
          business_partner =business_partner?.replace(/^\s+|\s+$/gm,'')
          }   
    
          let texts = csvData[i].first_name;
          let results;
          if(csvData[i].first_name){
            results = texts.replace(/^\s+|\s+$/gm, '');
          }
          
    
          if (results.length <= 0) {
    
            toast.warning("Please enter a Name");
            return false;
          }
    
          let text = csvData[i].email
          let result;
          if(csvData[i].email){
            result = text.replace(/^\s+|\s+$/gm, '');
          }
    
          if(business_partner.includes("NCT-B-XXXXXX") || business_partner.includes("X")){
            toast.warning("Please enter Business partner");
            return false;
          }
          
          if (result.length <= 0) {
            toast.warning("Please enter a valid email address");
            return false;
          }
        }
        setLoadingCsv(true)
        axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/customer/createcsv/${partnerId}`,
          csvData, {
          headers: {
            'Authorization': `Bearer ${getNewPartnerToken()}`
          }
        },
        )
          .then((res) => {
            setLoadingCsv(false);
            setPreviewModal(false)
            if (res.data?.status === "failed") {
              toast.warning(res.data.message);
              return false;
            }
            handleClosehandleCsvModal();
            setFilename('')
            setTimeout(() => {
              // getAllCustomers();
              setLoadingCsv(false)
              handleClosehandleCsvModal();
            //   window.location.reload();
              // getAllCustomers();
            }, 400);
    
            toast.success("Csv Uploaded Successfully");
            // window.location.reload();
            navigate("/partner/customer")
            // getAllCustomers();
            // navigate("/admin/customers");
          })
          .catch((err) => {
            console.log(err);
            // if(err.response.data.msg){
            //           toast.warning(err.response.data.msg);
            // }
    
          });
      };

  return (
    <>
<Modal
        show={showCsvModal}
        onHide={handleClosehandleCsvModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title
              className="font20 text-dark mb-0"
              id="contained-modal-title-vcenter"
            >
              File Upload
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {loadingCsv ? <Bars
                        height="80"
                        width="100%"
                        margin='auto'
                        color="#FF7020"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        />
             :
            <>
              <div className="d-flex justify-content-between align-items-center">
            {/* <h4 className="fs-6 text-dark mb-2">Select CSV File</h4>
            {previewData?.length>0 ?<div className="d-flex align-items-center">
              <h6 className="me-3 mb-2">{filename}</h6>
              <Button
            onClick={()=>handlePreview()}
              variant="primary"
              className="fullRounded px-4 py-2 fw-bold font12 mb-2"
            >
              Preview
            </Button>
            </div>:''} */}
              </div>
              <CsvForm variant="primary" setCustomerData={setCustomers} business_id={b_id} setFilename={setFilename} filename={filename}/>
            </>
}
          </Modal.Body>
          <Modal.Footer>{filename.length>0 ? <Button
             variant="secondary"
            className="fullRounded px-4 py-2 fw-bold font12"
            disabled

          >
            Upload
          </Button>
            :
            <Button
              variant="primary"
              type="submit"
              className="fullRounded px-4 py-2 fw-bold font12"
            >
              Upload
            </Button>
          }

            <Button
              variant="secondary"
              className="fullRounded px-4 py-2 fw-bold font12"
              onClick={() => handleClosehandleCsvModal()}
            >
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
      scrollable={true}
      show={previewModal}
      onHide={handleClosePreviewModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      >
          <Modal.Header closeButton>
            <Modal.Title
              className="font20 text-dark mb-0"
              id="contained-modal-title-vcenter"
            >
             CSV Preview
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
            <div className="d-flex justify-content-between">
            <h6>Total Funds : $ {previewData1?.totalFunds?.toFixed(2)}</h6>
              <h6>Register Customers : {previewData1?.registerCustomer}</h6>
            </div>
            <div className="table-responsive">
              <table
                className="table fontGilroy"
                style={{ verticalAlign: "middle" }}
              >
                <thead className="bgLightRed font14">
                  <tr>
                  <th className="text-center">
                      <div className="d-flex align-items-center">
                       
                        <span className="me-1">Name</span>
                     
                      </div>
                    </th>
                   
                    <th>
                      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="me-1"> Email
                      
                        </span>

                      </div>
                    </th>
                    <th>
                      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="me-1"> Phone
                      
                        </span>

                      </div>
                    </th>
                    <th>
                      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="me-1"> Partner</span>
                     
                      </div>
                    </th>

                    <th>
                      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="me-1">Available Funds</span>
                     
                      </div>
                    </th>
                
                  </tr>
                </thead>
                <tbody className="font12 fw-medium">
              
                   
                       
                   {

                     previewData?.map((cust) => (
                       <tr key={cust._id}>
                        <td className="font14">
                             
                              <span>
                                <Link
                                  className="link"
                                
                                >
                                  {cust.first_name} {cust.last_name}
                                </Link>
                              </span>
                            </td>
                      
                        <td className="font14 fw-medium">
                          {/* <div> */}
                            {cust.email} {cust.id}
                          {/* </Link> */}
                        </td>
                        <td className="font14 fw-medium">{cust.code} {cust.phone}</td>
                        <td className="font14 fw-medium">{cust.business_name}</td>
                        <td className="font14 fw-medium">${Number(cust.balance)?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td>
                        {/* <td className="font14 fw-medium">
                        {formatDate(cust.updatedAt).slice(0, 16).replace("T", "      ")}
                          </td> */}
                       
                        
                        
                      </tr>
                    ))
                  
                      }
                </tbody>
              </table>
            </div>
            </>
            
          </Modal.Body>
          <Modal.Footer>
            
            <Button
              variant="primary"
              className="fullRounded px-4 py-2 fw-bold font12"
              onClick={handleSubmit}
              disabled={loadingCsv}
            >
              Confirm
            </Button>
            <Button
              variant="secondary"
              className="fullRounded px-4 py-2 fw-bold font12"
              onClick={() => {
                handleClosePreviewModal();
                handleClearData(); 
              }}
            >
              Close
            </Button>
          </Modal.Footer>
    
      </Modal>

    </>
  )
}

export default UploadCsv;