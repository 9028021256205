import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation, useSearchParams, Link } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Alert, Modal } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import { toast } from "react-toastify";

const UserLoginAndChangePasswordWithLink = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newConfirmPassword, setNewConfirmPassword] = useState('');
    const [passwordType, setPasswordType] = useState('password');
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
    const [stillSendingOtp, setStillSendingOtp] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [token, setToken] = useState('');
    const [isLoad, setIsLoad] = useState(false);
    const [btnColor, setBtnColor] = useState('');
    const [btnText, setBtnText] = useState('');
    const [userId, setUserId] = useState('')
    const [isSms, setIsSms] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    

    const handleInnerCircleClick = () => {
        setIsSms(!isSms);
    };

    const [showRadio, setShowRadio] = useState(false);
    const handleCaretClick = () => {
        setShowRadio(!showRadio);
    };

    useEffect(() => {
        const tokenParam = searchParams.get('token');

        const decryptedData = CryptoJS.AES.decrypt(decodeURIComponent(tokenParam), process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);

        if (decryptedData) {
            const { email, token, userId } = JSON.parse(decryptedData);
            setEmail(email);
            setToken(token);
            setUserId(userId);

            // API call to check if the link is expired
            const checkLinkValidity = async () => {
                try {

                    const credentials = {
                        email: email,
                        token: token,
                        userId: userId,
                        timestamp: Date.now()
                    };
                    const jsonString = JSON.stringify(credentials);
                    const securedLogin = CryptoJS.AES.encrypt(jsonString, process.env.REACT_APP_SECRET_KEY).toString();

                    const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/user/check-link-validity`, {
                        securedLogin
                    });

                    if (response.data.expired) {
                        setModalMessage("This account activation link has expired. If you require a new activation link please contact us at support@ncentiva.com");
                        setShowModal(true);
                    }else{
                        setIsLoad(true);
                    }
                } catch (error) {
                    console.error('Error validating the link:', error);
                    setModalMessage("Failed to validate the link. Please try again or contact support at support@ncentiva.com");
                    setShowModal(true);
                }
            };

            checkLinkValidity();
        }

    }, [searchParams]);

    const togglePassword = () => {
        setPasswordType(passwordType === 'password' ? 'text' : 'password');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (newPassword !== newConfirmPassword) {
            toast.warning('Passwords do not match!'); // Set error message
            return;
        }

        setIsSubmitting(true);
        setStillSendingOtp(true);

        try {

            const credentials = {
                email: email,
                newPassword: newPassword,
                newConfirmPassword: newConfirmPassword,
                userId: userId,
                isSms: isSms,
                timestamp: Date.now()
            };
            const jsonString = JSON.stringify(credentials);
            const securedLogin = CryptoJS.AES.encrypt(jsonString, process.env.REACT_APP_SECRET_KEY).toString();

            const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/user/savepassword`, {
                securedLogin
            });

            if (response.data.status === "success") {
                setNewPassword("");
                setNewConfirmPassword("");
                window.localStorage.setItem("user", JSON.stringify(response.data));
                navigate(`/admin/verify?isSms=false`)
                setStillSendingOtp(false);
            } else if (response.status === "failed") {
                setTimeout(() => setStillSendingOtp(false), 2000);
            }

        } catch (error) {
            console.error(error);
            if (error.response) {
                toast.error(error.response.data.message || 'Failed to set password. Please try again.'); // Set error message
            } else if (error.request) {
                toast.error('No response from server. Please check your network connection.'); // Set error message for no response
            } else {
                toast.error('An unexpected error occurred. Please try again.'); // Set generic error message
            }
        } finally {
            setIsSubmitting(false);
            setStillSendingOtp(false);
        }
    };




    return (
        <>
            {isLoad ?
                <Container fluid className="d-flex flex-column justify-content-center align-items-center" style={{ height: "100vh", backgroundColor: "#fff8f3" }}>
                    <Container className="bg-white loginContainer p-0 rounded" style={{ maxWidth: "1065px" }}>
                        <div className="d-flex align-items-center justify-content-center">
                            <Link
                                to='/'
                            >
                                <img
                                    src="/assets/images/logo.png"
                                    className="imgLogo img-fluid mt-4"
                                    alt=""
                                    style={{
                                        width: '200px',
                                        height: '100px',
                                        objectFit: 'contain'
                                    }}
                                />
                            </Link>
                        </div>
                        <Row className="align-items-center g-0">
                            <Col md="6" style={{ height: "100%", borderRight: "1px solid #ccc" }} className="loginLeft j_login_left">
                                <h1 className="loginH1Title text-dark mb-3">Set New Password</h1>


                                <Form className="form mt-5" onSubmit={handleSubmit}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label className="text-dark">Email</Form.Label>
                                        <Form.Control type="email" value={email} disabled />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label className="text-dark">New Password</Form.Label>
                                        <div className="position-relative">
                                            <Form.Control
                                                type={passwordType}
                                                onChange={(e) => setNewPassword(e.target.value)}
                                                value={newPassword}
                                                placeholder="Enter New Password"
                                            />
                                            <button type="button" className="bg-transparent border-0 position-absolute eyBtn" onClick={togglePassword}>
                                                {passwordType === "password" ? <FaEyeSlash /> : <FaEye />}
                                            </button>
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label className="text-dark">Confirm New Password</Form.Label>
                                        <div className="position-relative">
                                            <Form.Control
                                                type={showConfirmNewPassword ? 'text' : 'password'}
                                                onChange={(e) => setNewConfirmPassword(e.target.value)}
                                                value={newConfirmPassword}
                                                placeholder="Enter Confirm New Password"
                                            />
                                            {newConfirmPassword.length > 0 && (
                                                <button type="button" className="bg-transparent border-0 position-absolute eyBtn" onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}>
                                                    {!showConfirmNewPassword ? <FaEyeSlash /> : <FaEye />}
                                                </button>
                                            )}
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        {
                                            stillSendingOtp && isSubmitting ? (
                                                <Button
                                                    type="submit"
                                                    variant="primary"
                                                    style={{ backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }}
                                                    className="w-100 fullRounded font12 fw-bold py-3 shadowPrimary"
                                                    disabled
                                                >
                                                    Please wait ....
                                                </Button>
                                            ) : (
                                                <Button
                                                    type="submit"
                                                    style={{ backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }}
                                                    variant="primary"
                                                    className="w-100 fullRounded font12 fw-bold py-3 shadowPrimary button_hover"
                                                >
                                                    Set Password
                                                </Button>
                                            )
                                        }
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col md="6" className="loginRight j_login_right align-items-center" style={{ height: "100%" }}>

                                <div>
                                    <p className="verification-text text-dark">
                                        <span className="verification-title" style={{ fontWeight: '500' }}>One time password verification.</span> <br /><br />
                                        We will send an OTP code to your email.<br />
                                        Please use it before it expires within 5 minutes.<br />
                                        <br />
                                        <span
                                            style={{ fontWeight: 'lighter', cursor: 'pointer' }} onClick={handleCaretClick}
                                            className={`use-another-method ${showRadio ? 'visible' : 'hidden'}`}
                                        >
                                            Use another method.

                                            <span className="ms-2" >
                                                {!showRadio ?
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                    </svg> :
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                    </svg>}
                                            </span>
                                        </span>
                                        <br />
                                        {showRadio ?
                                            <span className="iconsss" style={{ position: 'absolute' }}>
                                                <div
                                                    className="round-icon"
                                                    style={{
                                                        display: 'inline-block',
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <div className="round">
                                                        <input
                                                            type="checkbox"
                                                            id="html"
                                                            name="fav_language"
                                                            value="HTML"
                                                            checked={isSms}
                                                            onChange={handleInnerCircleClick}
                                                        />
                                                        <label htmlFor="html"></label>
                                                    </div>

                                                </div>
                                                <span className="ms-2">
                                                    SMS
                                                </span>
                                            </span> : ""}
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Container>
                : null}
            
            <Modal show={showModal} onHide={() => setShowModal(false)} centered backdrop="static" keyboard={false}>
                <Modal.Header >
                    <Modal.Title>Account Activation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{modalMessage}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => navigate('/admin')}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default UserLoginAndChangePasswordWithLink