// Function to get a cookie value
const getCookie = (name) => {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
};

// Function to delete a cookie
const deleteCookie = (name) => {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;';
};

// Function to set a cookie
const setCookie = (name, value, days) => {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

// Function to get the JWT token for admin
const getNewToken = () => {
    let decodedPayload;
    let decodedSignature;
    let decodedHeader;

    // Retrieve and decode from cookie
    const encodedPayloadData = getCookie('OAC-01');
    const encodedSignatureData = getCookie('AAC-02');

    if (encodedPayloadData && encodedSignatureData) {
        decodedPayload = atob(encodedPayloadData);
        decodedSignature = atob(encodedSignatureData);
    }

    // Retrieve from localStorage and decode
    const storedEncodedData = localStorage.getItem('CAACAO-012');
    if (storedEncodedData) {
        decodedHeader = atob(storedEncodedData);
    }

    // Generate the new token
    if (decodedHeader && decodedPayload && decodedSignature) {
        return decodedHeader + '.' + decodedPayload + '.' + decodedSignature;
    } else {
        return null;
    }
};

// Function to get the JWT token for business partner
const getNewPartnerToken = () => {
    let decodedPayload;
    let decodedSignature;
    let decodedHeader;

    // Retrieve and decode from cookie
    const encodedPayloadData = getCookie('business_OAC-01');
    const encodedSignatureData = getCookie('business_AAC-02');

    if (encodedPayloadData && encodedSignatureData) {
        decodedPayload = atob(encodedPayloadData);
        decodedSignature = atob(encodedSignatureData);
    }

    // Retrieve from localStorage and decode
    const storedEncodedData = localStorage.getItem('business_CAACAO-012');
    if (storedEncodedData) {
        decodedHeader = atob(storedEncodedData);
    }

    // Generate the new token
    if (decodedHeader && decodedPayload && decodedSignature) {
        return decodedHeader + '.' + decodedPayload + '.' + decodedSignature;
    } else {
        return null;
    }
};

// Function to get the JWT token for customer
const getNewCustomerToken = () => {
    let decodedPayload;
    let decodedSignature;
    let decodedHeader;

    // Retrieve and decode from cookie
    const encodedPayloadData = getCookie('customer_OAC-01');
    const encodedSignatureData = getCookie('customer_AAC-02');

    if (encodedPayloadData && encodedSignatureData) {
        decodedPayload = atob(encodedPayloadData);
        decodedSignature = atob(encodedSignatureData);
    }

    // Retrieve from localStorage and decode
    const storedEncodedData = localStorage.getItem('customer_CAACAO-012');
    if (storedEncodedData) {
        decodedHeader = atob(storedEncodedData);
    }

    // Generate the new token
    if (decodedHeader && decodedPayload && decodedSignature) {
        return decodedHeader + '.' + decodedPayload + '.' + decodedSignature;
    } else {
        return null;
    }
};

module.exports = {
    getCookie,
    deleteCookie,
    setCookie,
    getNewToken,
    getNewPartnerToken,
    getNewCustomerToken
};
