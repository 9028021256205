import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row, Form } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import * as Yup from "yup";
import { Field, Formik } from "formik";
import { Bars } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { getNewCustomerToken } from "../../config/cookie.config";

const CustomerProfile = (props) => {
    useEffect(()=>{
        document.title = "ncentiva || Customer Profile"
    },[])
       
    

    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");
    const [checkEmail, setCheckEmail] = useState(false);
    const [code, setCode] = useState("");
    const [user_id, setId] = useState("");
    const [customer_id, setCustId] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [balance, setBalance] = useState("");
    const [business_partner, setBusinessPartner] = useState("");
    const [wait, setWait] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [businesspartnerId, setbusinessPartnerId] = useState("");
    const [balanceError, setBalanceError] = useState(false);
    const [phoneError, setPhoneError] = useState();
    const [btnColor,setBtnColor] = useState('');
    const [btnText,setBtnText] = useState('');
    const nameDomain = ( window.location.host.split('.')); 
    const [image,setImage] = useState("");
    const [isButtonDisabled, setButtonDisabled] = useState(false); 

    let navigate = useNavigate();
   
    let userId;
    let token;
    let id;
    const getallData = () => {
        const user = JSON.parse(window.localStorage.getItem("customer"));
        if (user) {
            token = user.token;
            id = user.customer._id;
        } else {
            token = null;
        }
         
       
        axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/getcustomer/${id}`, {
            headers: {
                'Authorization': `Bearer ${getNewCustomerToken()}`
            }
        })
            .then((response) => {
                if (response.data.message === "Please Login") {
                    localStorage.removeItem('customer');
                    // localStorage.removeItem('listItem');
                    navigate('/login');
                    toast.error("Session Expired Please Login Again")
                    // window.location.reload();
                }
                const profiledata = response.data.customer;
                setFirstName(profiledata.first_name);
                setLastName(profiledata.last_name);
                setEmail(profiledata.email);
                setCode(profiledata.code);
                setPhone(profiledata.phone);
                setBalance(profiledata.balance);
                setbusinessPartnerId(profiledata.business_partner_id);
                setBusinessPartner(profiledata.business_partner);
                setCustId(profiledata._id);
               
            }).catch((error) =>{ console.error(`Error: ${error}`)

            if (error.message === "Request failed with status code 401") {
                localStorage.removeItem('customer');
                navigate('/login');
                toast.warning("Session Expired Please Login Again")
                window.location.reload();
            }

        }           

            );
    }
    useEffect(() => {
        getallData()
    }, [])

    const [primaryColor,setPrimaryColor]= useState('');
    useEffect(() => {
        if (nameDomain[0] !== "dev" || "staging" || "payouts" || "uat") {
            axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getcms/${nameDomain[0]}`)
                .then((res) => {
                    if (res.data?.cms?.default_ncentiva === false) {
                        setBtnColor(res.data?.cms?.btn_color);
                        setBtnText(res.data?.cms?.btn_text);
                        setPrimaryColor(res.data?.cms?.primary_color);
                        if (res.data?.cms?.brand_logo) {
                            setImage(res.data?.cms?.brand_logo)
                        }
                    } else {
                        setImage("https://dev.ncentiva.com/assets/images/logo.png");
                        setBtnColor('#3D05B6');
                        setBtnText('white');
                        setPrimaryColor("#FF7020");
                    }
                    setIsLoading(false);
                }).catch((err) => {
                    console.log(err);
                })
        } else {
            setIsLoading(false);
        }
    }, []);



    const handleSubmitForm = async (data) => {
        if(phoneError){
            return
        }
        
        let {
            first_name,
            last_name,
            code,
            phone,
            balance,
            business_partner,
            customer_id
        } = data;
        try {
            let token;
            const user = JSON.parse(window.localStorage.getItem("customer"));
            if (user) {
              token = user.token;
            } else {
              token = null;
            }
                setButtonDisabled(true)
            const { data } = await axios.post(
              `${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/customerprofile`,
              {
                "first_name":first_name,
                "last_name":last_name,
                "business_partner": businesspartnerId,
                //   business_partner,
                "customer_id": customer_id,
                "balance":balance,
                code,
                phone,
              },
              {
                headers: {
                  Authorization: `Bearer ${getNewCustomerToken()}`,
                },
              }
            );
            setButtonDisabled(true)
            if (data.status === "success") {
              toast.success(data.message);
              setTimeout(() => {
                window.location.reload()
              }, 1500);
              setTimeout(() => {
                setButtonDisabled(false)
            }, 5000);
            } else if (data.status === "failed") {
              toast.error(data.message);

            } 
          } catch (error) {
            console.error(`Error: ${error}`);
          }   
    };

    const handleKeyPress = (e) => {
        const keyCode = e.keyCode || e.which;
        const char = String.fromCharCode(keyCode);
        const regex = /^[0-9]+$/;
        if (!regex.test(char)) {
          e.preventDefault();
        }
      };

  


            function isValidEmail(email) {
                return /\S+@\S+\.\S+/.test(email);
            }

            const handleChangeEmail = event => {
                
                const emailValue = event.target.value;
                const trimmedEmail = emailValue.replace(/\s/g, '');

                if (trimmedEmail !== emailValue || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(trimmedEmail)) {
                   
                    setError(true);
                } else if (!isValidEmail(trimmedEmail)) {
                   
                    setError(true);
                } else {
                   
                    setError(false);
                }

                setEmail(trimmedEmail);
            };
            


            const formikInitialValue = {
                first_name,
                last_name,
                customer_id,
                user_id,
                // email,
                code,
                phone,
                balance,
                business_partner,
                checkEmail,
            };

            let formSchema;

                formSchema = Yup.object().shape({

                    first_name: Yup.string()
                        .min(2, "Too Short!")
                        .max(21, "Maximum 21 characters allowed for Name")
                        .matches(/^[aA-zZ0-9\s]+$/, "Alphabets and numbers are allowed for Name")
                        .required(" First Name cannot be empty"),
                    last_name: Yup.string()
                        .min(2, "Too Short!")
                        .max(21, "Maximum 21 characters allowed for Name")
                        .matches(/^[aA-zZ0-9\s]+$/, "Alphabets and numbers are allowed for Name"),
                    checkEmail: Yup.boolean(),
                    phone: Yup.string()
                    
                    .min(10, "Minimum 7 digits required for Phone number")
                    .max(14, "Maximum 10 digits allowed for Phone number")
                 
                    // .matches( /^\d{15}$/,'Phone number must be 10 digits')
                    .required("Phone Number cannot be empty")
                    
                    // .matches( /^\d{14}$/,'Phone number must be 10 digits')
                    .required('Phone Number cannot be empty'),
                    balance: Yup.string()
                        .min(1, "Minimum 1 digits required for Balance Amount")
                        // .matches(/^\d+(\.\d{2})?$/, 'Invalid balance format')
                        // .test('decimal', 'Decimal part must have exactly two digits', (value) => {
                        //     if (!value) return true;
                        //     const decimalPart = value.split('.')[1];
                         
                        //     if (decimalPart) {
                               
                        //         if (decimalPart.length === 1 && decimalPart.length === 3) {
                        //             const roundedValue = Number(value).toFixed(2);
                        //             setBalance(roundedValue);
                        //             // return parseFloat(value).toFixed(2);
                        //         } else {
                                    
                        //             return decimalPart.length === 2;
                        //         }
                        //     }
                        //     return true;
                            
                        // })
                        .required("Balance cannot be empty"),
                    business_partner: Yup.string()
                        .required("Business Partner cannot be empty"),
                });

            

            function phoneFormat(input) {
                input = input?.replace(/\D/g, "");
                input = input?.trim()
                input = input?.substring(0, 10);

                var size = input?.length;
                if (size === 0) {
                    input = input;
                }
                else if (size < 4) {
                    input = "(" + input;
                }
                else if (size < 7) {
                    input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6);
                }

                else if (size <= 11)
                    input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6) + "-" + input.substring(6, 11);

                if (input?.length > 10 && input?.length <= 14 || input.length === 0) {
                    setPhoneError(false);
                } else {
                    setPhoneError(true);
                }
                return input;
            }

          
       
          

            function nameFormat(input) {
                return input?.trimStart();
            }
            function lastNameFormat(input) {
                if (input) {
                    return input?.trimStart();
                }
            }


            // const handlePaste = (event) => {
            //     const pastedText = event.clipboardData.getData('text/plain');
            //     const formattedText = pastedText.trim(); 
            //     event.preventDefault(); 
            //     document.execCommand('insertText', false, formattedText);
            // }

  const cmsData = useSelector((state) => state.cmsReducer);
  const defaultNcentiva = cmsData?.data?.cms?.default_ncentiva;

           
            return (


                isLoading === true ? (
                    <div className="d-flex justify-content-center mt-5 pt-5">
                        <Bars
                            height="80"
                            width="100%"
                            margin='auto'
                            color={primaryColor}
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                    </div>
                ) : (
                    <>
                        <div className="p-3 p-lg-4 p-xl-5">
                           
                        <Container fluid>
                    <h3 className='mt-5 fs-5 text-dark'>Profile</h3> 
                          </Container>
                            <Container fluid className="mt-4">
                                <Row>
                                    <Col lg={12} md={12}>
                                        <Formik
                                            enableReinitialize={true}
                                            initialValues={formikInitialValue}
                                            validationSchema={formSchema}
                                            onSubmit={(values, { setSubmitting }) => {
                                                if (email.length < 5) {
                                                    setError(true);
                                                } else {
                                                    setDisabled(true);
                                                    setTimeout(() => {
                                                        setDisabled(false);
                                                    }, 2000);
                                                    setTimeout(() => {
                                                        handleSubmitForm(values);
                                                        setSubmitting(false);
                                                    }, 400);
                                                }
                                            }}
                                        >
                                            {({
                                                values,
                                                errors,
                                                touched,
                                                handleChange,
                                                handleBlur,
                                                handleSubmit,
                                                isSubmitting 
                                            }) => (
                                                <Form
                                                    className="bg-white p-4 rounded containerShadow"
                                                    // onSubmit={handleSubmit}
                                                >
                                                    <h2 className="font16 mb-4 text-dark">Profile Info</h2>

                                                    <Row>
                                                        <Col md={6}>
                                                            <Form.Control
                                                                type="hidden"
                                                                value={customer_id}
                                                                onChange={(e) => setCustId(e.target.value)}
                                                                placeholder="Enter Name"
                                                                className="font14"
                                                            />
                                                            <Form.Control
                                                                type="hidden"
                                                                value={userId}
                                                                onChange={(e) => setId(e.target.value)}
                                                                placeholder="Enter Name"
                                                                className="font14"
                                                            />
                                                            <Form.Group className="mb-3" controlId="formBasicText1">
                                                                <Form.Label className="font12 text-dark fw-medium">
                                                                    First Name <span className="text-danger">*</span>
                                                                </Form.Label>
                                                                {props.admin !== true && first_name ? <Form.Control
                                                                    name="first_name"
                                                                    className="font12"
                                                                    value={nameFormat(values.first_name.charAt(0).toUpperCase() + values.first_name.slice(1))}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    type="text"
                                                                    placeholder="Enter First Name"
                                                                    isValid={touched.first_name && !errors.first_name}
                                                                    isInvalid={!!errors.first_name}
                                                                    // onPaste={handlePaste}
                                                                    disabled

                                                                /> : <Form.Control
                                                                    name="first_name"
                                                                    className="font12"
                                                                    value={nameFormat(values.first_name.charAt(0).toUpperCase() + values.first_name.slice(1))}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    type="text"
                                                                    placeholder="Enter First Name"
                                                                    isValid={touched.first_name && !errors.first_name}
                                                                    isInvalid={!!errors.first_name}
                                                                    // onPaste={handlePaste}
                                                                
                                                                />}
                                                                {errors.first_name && touched.first_name ? (
                                                                    <div className="text-danger font12">
                                                                        {errors.first_name}
                                                                    </div>
                                                                ) : null}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={6}>
                                                            <Form.Group className="mb-3" controlId="formBasicText2">
                                                                <Form.Label className="font12 text-dark fw-medium">
                                                                    Last Name <span className="text-danger">*</span>{" "}
                                                                </Form.Label>
                                                                {props.admin !== true && id ? <Form.Control
                                                                    className="font12"
                                                                    name="last_name"
                                                                    value={lastNameFormat(values.last_name.charAt(0).toUpperCase() + values.last_name.slice(1))}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    type="text"
                                                                    placeholder="Enter Last Name"
                                                                    isValid={touched.last_name && !errors.last_name}
                                                                    isInvalid={!!errors.last_name}
                                                                    // onPaste={handlePaste}
                                                          
                                                                /> : <Form.Control
                                                                    className="font12"
                                                                    name="last_name"
                                                                    value={lastNameFormat(values.last_name?.charAt(0).toUpperCase() + values.last_name?.slice(1))}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    type="text"
                                                                    placeholder="Enter Last Name"
                                                                    isValid={touched.last_name && !errors.last_name}
                                                                    isInvalid={!!errors.last_name}
                                                                    // onPaste={handlePaste}
                                                                    disabled
                                                                />}
                                                                {errors.last_name && touched.last_name ? (
                                                                    <div className="text-danger font12">
                                                                        {errors.last_name}
                                                                    </div>
                                                                ) : null}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={6}>
                                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                <Form.Label className="font12 text-dark fw-medium">
                                                                    Email Address <span className="text-danger">*</span>
                                                                </Form.Label>
                                                                {customer_id ? (
                                                                    <Form.Control
                                                                        className="font12"
                                                                        type="email"
                                                                        value={email}
                                                                        onChange={(e) => setEmail(e.target.value)}
                                                                        placeholder="Enter Email Address"
                                                                        disabled
                                                                    />
                                                                ) : (
                                                                    <Form.Control
                                                                        className="font12"
                                                                        type="email"
                                                                        name="email"
                                                                        value={email}
                                                                        onChange={handleChangeEmail}
                                                                        onKeyDown={(e) => {
                                                                            if (e.key === " ") {
                                                                                e.preventDefault();
                                                                            }
                                                                        }}
                                                                        onFocus={() => {
                                                                            if (email.length === 0) {
                                                                                setError(true);
                                                                            }
                                                                        }}
                                                                        onBlur={(e) => {
                                                                            handleBlur(e);
                                                                            if (errors.email) {
                                                                                setEmail(e.target.value.trim());
                                                                            }
                                                                        }}
                                                                        placeholder="Enter Email Address"
                                                                        // onPaste={handlePaste}
                                                                        disabled
                                                                    />
                                                                )}
                                                              
                                                            </Form.Group>

                                                        </Col>

                                                        <Col md={6}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label className="font12 text-dark fw-medium">
                                                                    Phone Number{" "}
                                                                    <span className="fw-normal text-secondary">
                                                                        (Optional)
                                                                    </span>
                                                                </Form.Label>
                                                                <div className='d-flex justify-content-center align-items-center'>

                                                                    {props.admin !== true && id ? <Field
                                                                        as='select'
                                                                        className="font12 form-select"
                                                                        name='code'
                                                                        value={(values.code.startsWith('+') ? values.code : '+' + values.code)}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        style={{ border: '1px solid #cfc6c6', height: '31px', borderRadius: '5px', width: '6rem' }}
                                                                        isValid={touched.code && !errors.code}
                                                                        isInvalid={!!errors.code}
                                                                        type='text'
                                                                    >
                                                                        <option value="" label="Code" />
                                                                        <option value="+1" label="+1 USA" />
                                                                        <option value="+7" label="+7 RUS" />
                                                                        <option value="+20" label="+20 EGY" />
                                                                        <option value="+27" label="+27 ZAF" />
                                                                        <option value="+30" label="+30 GRC" />
                                                                        <option value="+31" label="+31 NLD" />
                                                                        <option value="+32" label="+32 BEL" />
                                                                        <option value="+33" label="+33 FRA" />
                                                                        <option value="+34" label="+34 ESP" />
                                                                        <option value="+36" label="+36 HUN" />
                                                                        <option value="+39" label="+39 ITA" />
                                                                        <option value="+40" label="+40 ROU" />
                                                                        <option value="+41" label="+41 SWZ" />
                                                                        <option value="+43" label="+43 AUT" />
                                                                        <option value="+44" label="+44 GBR" />
                                                                        <option value="+45" label="+45 DNK" />
                                                                        <option value="+46" label="+46 SWE" />
                                                                        <option value="+47" label="+47 NOR" />
                                                                        <option value="+48" label="+48 POL" />
                                                                        <option value="+49" label="+49 DEU" />
                                                                        <option value="+51" label="+51 PER" />
                                                                        <option value="+52" label="+52 MEX" />
                                                                        <option value="+53" label="+53 CUB" />
                                                                        <option value="+54" label="+54 ARG" />
                                                                        <option value="+55" label="+55 BRA" />
                                                                        <option value="+56" label="+56 CHL" />
                                                                        <option value="+57" label="+57 COL" />
                                                                        <option value="+58" label="+58 VEN" />
                                                                        <option value="+60" label="+60 MYS" />
                                                                        <option value="+61" label="+61 AUS" />
                                                                        <option value="+62" label="+62 IDN" />
                                                                        <option value="+63" label="+63 PHL" />
                                                                        <option value="+64" label="+64 NZL" />
                                                                        <option value="+65" label="+65 SGP" />
                                                                        <option value="+66" label="+66 THA" />
                                                                        <option value="+81" label="+81 JPN" />
                                                                        <option value="+82" label="+82 KOR" />
                                                                        <option value="+84" label="+84 VNM" />
                                                                        <option value="+86" label="+86 CHN" />
                                                                        <option value="+90" label="+90 TUR" />
                                                                        <option value="+91" label="+91 IND" />
                                                                        <option value="+92" label="+92 PAK" />
                                                                        <option value="+93" label="+93 AFG" />
                                                                        <option value="+94" label="+94 LKA " />
                                                                        <option value="+95" label="+95 MMR" />
                                                                        <option value="+98" label="+98 IRN" />
                                                                        <option value="+211" label="+211 SSD" />
                                                                        <option value="+212" label="+212 MAR" />
                                                                        <option value="+213" label="+213 DZA" />
                                                                        <option value="+216" label="+216 TUN" />
                                                                        <option value="+218" label="+218 LBY" />
                                                                    </Field>
                                                                        :
                                                                        <Field
                                                                            as='select'
                                                                            className="font12 form-select"
                                                                            name='code'
                                                                            value={(values.code.startsWith('+') ? values.code : '+' + values.code)}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            style={{ border: '1px solid #cfc6c6', height: '31px', borderRadius: '5px', width: '6rem' }}
                                                                            isValid={touched.code && !errors.code}
                                                                            isInvalid={!!errors.code}
                                                                            type='text'
                                                                           
                                                                        >
                                                                            <option value="+" label="Code"  selected />
                                                                            <option value="+1" label="+1 USA" />
                                                                            <option value="+7" label="+7 RUS" />
                                                                            <option value="+20" label="+20 EGY" />
                                                                            <option value="+27" label="+27 ZAF" />
                                                                            <option value="+30" label="+30 GRC" />
                                                                            <option value="+31" label="+31 NLD" />
                                                                            <option value="+32" label="+32 BEL" />
                                                                            <option value="+33" label="+33 FRA" />
                                                                            <option value="+34" label="+34 ESP" />
                                                                            <option value="+36" label="+36 HUN" />
                                                                            <option value="+39" label="+39 ITA" />
                                                                            <option value="+40" label="+40 ROU" />
                                                                            <option value="+41" label="+41 SWZ" />
                                                                            <option value="+43" label="+43 AUT" />
                                                                            <option value="+44" label="+44 GBR" />
                                                                            <option value="+45" label="+45 DNK" />
                                                                            <option value="+46" label="+46 SWE" />
                                                                            <option value="+47" label="+47 NOR" />
                                                                            <option value="+48" label="+48 POL" />
                                                                            <option value="+49" label="+49 DEU" />
                                                                            <option value="+51" label="+51 PER" />
                                                                            <option value="+52" label="+52 MEX" />
                                                                            <option value="+53" label="+53 CUB" />
                                                                            <option value="+54" label="+54 ARG" />
                                                                            <option value="+55" label="+55 BRA" />
                                                                            <option value="+56" label="+56 CHL" />
                                                                            <option value="+57" label="+57 COL" />
                                                                            <option value="+58" label="+58 VEN" />
                                                                            <option value="+60" label="+60 MYS" />
                                                                            <option value="+61" label="+61 AUS" />
                                                                            <option value="+62" label="+62 IDN" />
                                                                            <option value="+63" label="+63 PHL" />
                                                                            <option value="+64" label="+64 NZL" />
                                                                            <option value="+65" label="+65 SGP" />
                                                                            <option value="+66" label="+66 THA" />
                                                                            <option value="+81" label="+81 JPN" />
                                                                            <option value="+82" label="+82 KOR" />
                                                                            <option value="+84" label="+84 VNM" />
                                                                            <option value="+86" label="+86 CHN" />
                                                                            <option value="+90" label="+90 TUR" />
                                                                            <option value="+91" label="+91 IND" />
                                                                            <option value="+92" label="+92 PAK" />
                                                                            <option value="+93" label="+93 AFG" />
                                                                            <option value="+94" label="+94 LKA " />
                                                                            <option value="+95" label="+95 MMR" />
                                                                            <option value="+98" label="+98 IRN" />
                                                                            <option value="+211" label="+211 SSD" />
                                                                            <option value="+212" label="+212 MAR" />
                                                                            <option value="+213" label="+213 DZA" />
                                                                            <option value="+216" label="+216 TUN" />
                                                                            <option value="+218" label="+218 LBY" />
                                                                        </Field>
                                                                    }
                                                                    &nbsp;

                                                                    {values.code === '' ?
                                                                        <Form.Control
                                                                            type="text"
                                                                            name="phone"
                                                                        
                                                                            maxLength={14}
                                                                            value={phoneFormat(values.phone)}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            isValid={touched.phone && !errors.phone}
                                                                            isInvalid={!!errors.phone}
                                                                            placeholder="Enter Phone"
                                                                            className="font12 "
                                                                            onKeyPress={handleKeyPress}
                                                                        />
                                                                        :
                                                                        <Form.Control
                                                                        type="text"
                                                                        name="phone"
                                                                        maxLength={14}
                                                                        value={phoneFormat(values.phone?.trim())}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        isValid={touched.phone && !phoneError}
                                                                        isInvalid={phoneError}
                                                                        placeholder="Enter Phone Number"
                                                                        className="font12"
                                                                        onKeyPress={handleKeyPress}
                                                                        onPaste={(e) => {
                                                                          e.preventDefault();
                                                                          const pastedText = e.clipboardData.getData('text/plain');
                                                                          const formattedPhoneNumber = phoneFormat(pastedText);
                                                                          handleChange({ target: { name: 'phone', value: formattedPhoneNumber } });
                                                                        }}
                                                                        required
                                                                      />
                                                                      
                                                                    }
                                                                </div>
                                                                <div className="d-flex">
                                                                    {values.phone === '' ? '' : (errors.code && touched.code ? (
                                                                        <div className="text-danger font12">
                                                                            {errors.code}
                                                                        </div>
                                                                    ) : null)
                                                                    }

                                                                    &nbsp;
                                                                    &nbsp;
                                                                    &nbsp;
                                                                    &nbsp;
                                                                    {phoneError && touched.phone ? (
                                                                        <div className="text-danger font12">
                                                                            {errors.phone}
                                                                        </div>
                                                                    ) : null}

                                                                </div>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={6}>
                                                            <Form.Group className="mb-3" controlId="formBasicText3">
                                                                <Form.Label className="font12 text-dark fw-medium">
                                                                    Balance{" "}
                                                                    <span className="text-danger">*</span>
                                                                </Form.Label>
                                                                {id ? <Form.Control
                                                                    style={{ border: balanceError ? "1px solid red" : "" }}
                                                                    className="font12"
                                                                    name="balance"
                                                                    value={values.balance}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    type="text"
                                                                    placeholder="$ xxxx.xx"
                                                                    
                                                                    // onPaste={handlePaste}
                                                                    disabled
                                                                /> : <Form.Control
                                                                    style={{ border: balanceError ? "1px solid red" : "" }}
                                                                    className="font12"
                                                                    name="balance"
                                                                    value={Number(values.balance).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    type="text"
                                                                    placeholder="$ xxxx.xx"
                                                                    
                                                                    // onPaste={handlePaste}
                                                                    disabled
                                                                />}
                                                                {errors.balance && touched.balance ? (
                                                                    <div className="text-danger font12">
                                                                        {errors.balance}
                                                                    </div>
                                                                ) : null}
                                                                {balanceError === true && !errors.balance ? <div className="text-danger font12 mt-1">Invalid balance Format</div> : ""}
                                                            </Form.Group>
                                                        </Col>
                                                        {<Col md={6}>
                                                        <Form.Group className="mb-3" controlId="formBasicText1">
                                                                <Form.Label className="font12 text-dark fw-medium">
                                                                Business Partner <span className="text-danger">*</span>
                                                                </Form.Label>
                                                                {props.admin !== true && business_partner ? <Form.Control
                                                                    name="first_name"
                                                                    className="font12"
                                                                    value={nameFormat(values.business_partner.charAt(0).toUpperCase() + values.business_partner.slice(1))}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    type="text"
                                                                  
                                                                    isValid={touched.business_partner && !errors.business_partner}
                                                                    isInvalid={!!errors.business_partner}
                                                                    // onPaste={handlePaste}
                                                                    disabled

                                                                /> : <Form.Control
                                                                    name="first_name"
                                                                    className="font12"
                                                                    value={nameFormat(values.business_partner.charAt(0).toUpperCase() + values.business_partner.slice(1))}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    type="text"
                                                                    
                                                                    isValid={touched.business_partner && !errors.business_partner}
                                                                    isInvalid={!!errors.business_partner}
                                                                    // onPaste={handlePaste}
                                                                    disabled
                                                                />}
                                                                {errors.business_partner && touched.business_partner ? (
                                                                    <div className="text-danger font12">
                                                                        {errors.first_name}
                                                                    </div>
                                                                ) : null}
                                                            </Form.Group>
                                                        </Col>}
                                                    </Row>
                                                    <Row className="mt-4">
                                                        <Col>
                                                            {/* {disabled ? <Button
                                                                variant="primary"
                                                                className="px-4 py-2 fullRounded shadowPrimary font12"
                                                                disabled
                                                            
                                                            >
                                                                {id ? 'Save Changes' : 'Update Profile'}
                                                            </Button> 
                                                            
                                                            :  */}
                                                            
                                                            <Button
                                                                type="button"
                                                                style={defaultNcentiva
                                                                    ? { backgroundColor: `${btnColor}80`, color: btnText, border: `1px solid transparent` }
                                                                    : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }}
                                                                variant={defaultNcentiva ? "primary" : "outline-primary"}
                                                                className="px-4 py-2 fullRounded shadowPrimary font12 button_hover"
                                                                disabled={isButtonDisabled}
                                                                onClick={(e) => {
                                                                    handleSubmitForm(values)  
                                                                   
                                                                }}
                                                                
                                                            >
                                                                {id ? 'Save Changes' : 'Update Profile'}
                                                            </Button>
                                                            {/* } */}
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            )}
                                        </Formik>
                                    </Col>
                                </Row>
                            </Container>

                        </div>
                    </>
                )
            );

        };
        export default CustomerProfile;