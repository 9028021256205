// import logo from './logo.svg';
import "./App.css";
import "./App.scss";
import "./styles/global.css";
import "./styles/responsive.css";
import Home from "./pages/Home";
import Products from "./pages/Products";
import DashboardLayout from "./components/DashboardLayout";
import Login from "./components/Login";
import CustomerLogin from "./components/CustomerLogin";
import Verify from "./components/Verify";
import CustomerVerify from "./components/CustomerVerify";
import Dashboard from "./components/Dashboard";
import Coupons from "./components/Coupons";
import CouponEdit from "./components/CouponEdit";
import CouponByBrand from "./components/CouponByBrand";
import CouponByPartner from "./components/CouponByPartner";
import CouponDetails from "./components/CouponDetails";
import Brands from "./components/Brands";
import BrandEdit from "./components/BrandEdit";
import BrandDetails from "./components/BrandDetails";
import Transaction from "./components/Transaction";
import AdditionalDiscount from "./components/AdditionalDiscount";
import BussinessBonus from "./pages/BussinessBonus";
import Customers from "./components/Customers";
import CustomersDetails from "./components/CustomersDetails";
import UsersManagement from "./components/UsersManagement";
import UsersManagementDetails from "./components/UsersManagementDetails";
import SendPasswordResetEmail from "./components/SendPasswordResetEmail";
import ResetPassword from "./components/ResetPassword";
import CustomerSendPasswordResetEmail from "./components/CustomerSendPasswordResetEmail";
import CustomerResetPassword from "./components/CustomerResetPassword";
import AddCustomers from "./components/AddCustomers";
import Error404 from "./components/Error404";
import GiftCards from './pages/GiftCards';
import OnBoard from './pages/OnBoard';
import PayOut from './pages/PayOut';
import CheckOut from './pages/CheckOut';
import TransactionDetails from './components/TransactionDetails'
import BussinessPartnerCoupon from './pages/BussinessPartnerCoupon';
import BussinessPartnerCouponDetails from './pages/BussinessPartnerCouponDetails';
import Orders from './pages/Orders';
import BusinessPartner from './pages/BusinessPartner';
import Business from './pages/Business';
import AddContact from './pages/AddContact';
import { ToastContainer } from 'react-toastify';
import TransactionsId from './pages/TransactionsId';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    useNavigate,
} from "react-router-dom";
import NavbarComponent from "./components/Navbar";
import GiftId from './pages/GiftId';
import CustomerByBusinessPartner from "./pages/CustomersByBusinessPartner";
import Customize from './pages/Customize';
import CreatePassword from './components/CreatePassword';
import Monetary from './pages/Monetary';
import AccountHistory from './pages/AccountHistory';
import BusinessPartnerGift from "./pages/BusinessPartnerGift";
import ApiKey from "./pages/ApiKey";
import ApiDoc from './views/ApiDoc';
import Widget from './views/Widget';
import BusinessLogin from "./pages/BusinessPartner/BusinessLogin";
import BusinessVerify from "./pages/BusinessPartner/BusinessVerify";
import Businessdashboard from "./pages/BusinessPartner/BusinessDashboard";
import BusinessDasboardLayout from "./components/BusinessPartner/BusinessDashboardLayout";
import BusinessSendPassword from "./pages/BusinessPartner/BusinessSendPassword";
import BusinessResetPassword from "./pages/BusinessPartner/BusinessResetPassword";
import Profile from "./pages/BusinessPartner/Profile";
import BusinessBrands from "./pages/BusinessPartner/BusinessBrands";
import BusinessMonetary from "./pages/BusinessPartner/BusinessMonetary";
import Users from "./pages/BusinessPartner/Users";
import BusinessCustomer from "./pages/BusinessPartner/BusinessCustomer";
import BusinessTransaction from "./pages/BusinessPartner/BusinessTransaction";
import BusinessGiftId from "./pages/BusinessPartner/BusinessGiftId";
import BusinessTransactionId from "./pages/BusinessPartner/BusinessTransactionId";
import PartnerCoupon from "./pages/BusinessPartner/PartnerCoupon";
import PartnerCouponDetails from "./pages/BusinessPartner/PartnerCouponDetails";
import BusinessCreatePassword from "./pages/BusinessPartner/BusinessCreatePassword";
import UserManagementTrash from "./components/UserManagementTrash";
import BusinessTrash from "./components/BusinessTrash";
import CustomersTrash from "./components/CustomerTrash";
import CustomerCreatePassword from "./components/CustomerCreatePassord";

import { useEffect } from "react";
import { BsEmojiSmileUpsideDown } from "react-icons/bs";

import CustomerDashboardLayout from "./pages/customer/CustomerDashboardLayout";
import CustomerTransaction from "./pages/customer/CustomerTransaction";
import CustomerMonetaryTransaction from"./pages/customer/CustomerMonetaryTransaction";
import CustomerProfile from "./pages/customer/CustomerProfile";
import CustomerTransctionDetail from "./pages/customer/CustomerTransctionDetail"
import BusinessAcountHistory from "./pages/BusinessPartner/BusinessAcountHistory";
import Email from "./pages/Email";
import CustomerOfBuisnessPartner from "./pages/CustomerOfBusinessPartner";
import ModifyFund from "./pages/BusinessPartner/ModifyFund";
import UploadCsv from "./pages/BusinessPartner/UploadCsv";
import Maintenance from "./pages/Maintenance";
import Reminders from "./pages/BusinessPartner/Reminders";
import PaymentAndInvoice from "./pages/PaymentAndInvoice";
import BusinessPartnerBrands from "./pages/BusinessPartnerBrands";
import Maintanance from "./pages/Maintanance";
import NewCustomerLogin from "./pages/NewCustomerLogin";
import BusinessLoginAndChangePasswordWithLink from "./pages/BusinessPartner/BusinessLoginAndChangePasswordWithLink";
import UserLoginAndChangePasswordWithLink from "./pages/UserLoginAndChangePasswordWithLink";


function App() {

//     const currentURL = window.location.href;
// if( !currentURL.includes('localhost')){
//  const script = document.createElement('script');
//         script.type = 'text/javascript';
//         script.innerHTML = ` // test
//         var _tip = _tip || [];
//         (function(d,s,id){
//             var js, tjs = d.getElementsByTagName(s)[0];
//             if(d.getElementById(id)) { return; }
//             js = d.createElement(s); js.id = id;
//             js.async = true;
//             js.src = d.location.protocol + '//app.truconversion.com/ti-js/33041/3ed53.js';
//             tjs.parentNode.insertBefore(js, tjs);
//         }(document, 'script', 'ti-js'));
//         `;
//         document.head.appendChild(script);
// }


// const currentURL = window.location.href;

// if (!currentURL.includes('localhost')) {
//     // Define _tip array in the global scope
//     var _tip = _tip || [];

//     const script = document.createElement('script');
//     script.type = 'text/javascript';
    
//     // Define js variable outside the template string
//     var js = document.createElement('script'); // Create the script element here

//     script.innerHTML = `
//         // test
//         (function(d,s,id){
//             var tjs = d.getElementsByTagName(s)[0];
//             if(d.getElementById(id)) { return; }
//             js = d.createElement(s); js.id = id;
//             js.async = true;
//             js.src = d.location.protocol + '//app.truconversion.com/ti-js/33039/a1d99.js';
//             tjs.parentNode.insertBefore(js, tjs);
//         }(document, 'script', 'ti-js'));
//     `;
//     document.head.appendChild(script);

//     // Define onExternalScriptLoad function
//     function onExternalScriptLoad() {
//         _tip.push(['_trackIdentity', 'dev']);
//     }

//     // Wait for the dynamically created script to load
//     js.onload = function() {
//         onExternalScriptLoad();
//     };
// }


    return (
        <Router>
            <Routes>

                <Route exact path="/new/login" element={<NewCustomerLogin/>}/>
                <Route exact path="/new/business/user/login" element={<BusinessLoginAndChangePasswordWithLink/>}/>
                <Route exact path="/new/user/login" element={<UserLoginAndChangePasswordWithLink/>}/>
                <Route exact path="/maintenance" element={<Maintanance/>}/>
                <Route exact path="/" element={<GiftCards />} />
                {/* <Route exact path="/giftcards" element={<GiftCards />} />      */}
                <Route exact path="/api/docs" element={<ApiDoc />} />
                <Route exact path="/api/widget" element={<ApiDoc />} />
                {
                    // <Route exact path="/brands" element={<Products />} />
                }
                <Route exact path="/transactiondetails" element={<TransactionDetails/> } />
                <Route exact path="/" element={<DashboardLayout />}>
                    <Route path="/admin/dashboard" element={<Dashboard />} />
                    <Route path="/admin/customers" element={<Customers />} />
                    <Route path="/admin/mails" element={<Email />} />
                    <Route path="/admin/customers/add" element={<AddCustomers admin={true}/>} />
                    <Route path="/admin/customers/:id/edit" element={<AddCustomers admin={true} />} />
                    <Route path="/admin/customer_trash" element={<CustomersTrash />} />
                    <Route path="/admin/users-management" element={<UsersManagement />} />
                    <Route path="/admin/users-management/add" element={<UsersManagementDetails />} />
                    <Route path="/admin/users-management/:id/edit" element={<UsersManagementDetails />}/>
                    <Route path="/admin/user_trash" element={<UserManagementTrash />}/>
                    <Route path="/admin/coupons" element={<Coupons />} />
                    <Route path="/admin/coupons/:id" element={<CouponByBrand />} />
                    <Route path="/admin/:id/partner/coupons" element={<CouponByPartner />} />
                    <Route path="/admin/coupons/:id/edit" element={<CouponEdit />} />
                    <Route path="/admin/businesspartners/:id"  element={<CustomerByBusinessPartner admin={true}/>} />
                    <Route path="/admin/coupons/details/:id/:coupon" element={<CouponDetails />} />
                    <Route path="/admin/transaction" element={<Transaction />} />
                    <Route path="/admin/maintenance" element={<Maintenance />} />
                    <Route path="/admin/bonus-configuration" element={<AdditionalDiscount />} />
                    <Route path="/admin/brands" element={<Brands />} />
                    <Route path="/admin/businesspartners" element={<Business />} />
                    <Route path="/admin/businesspartners/add" element={<BusinessPartner/>} />
                    <Route path="/admin/businesspartners/customize/:id" element={<Customize admin={true}/>} />
                    <Route path="/admin/businesspartners/monetary/:id" element={<Monetary admin={true}/>} />
                    <Route path="/admin/businesspartners/payment-invoice/:id" element={<PaymentAndInvoice admin={true}/>} />
                    <Route path="/admin/businesspartners/brands/:id" element={<BusinessPartnerBrands admin={true}/>} />
                    <Route path="/admin/businesspartners/acounthistory/:id" element={<AccountHistory admin={true}/>} />
                    <Route path="/admin/businesspartners/apikey/:id" element={<ApiKey admin={true}/>} />
                    <Route path="/admin/:id/bussiness-bonus" element={<BussinessBonus />} />
                    <Route path="/admin/businesspartners/:id/edit" element={<BusinessPartner />} />
                    <Route path="/admin/businesspartners/:id/addUser" element={<AddContact admin={true}/>} />
                    <Route path="/admin/businesspartners/:id/:bid/coupons" element={<BussinessPartnerCoupon />} />
                    <Route path="/admin/businesspartners/:id/:bid/coupons/details" element={<BussinessPartnerCouponDetails />} />
                    <Route path="/admin/Email/Customers/:id/:customerId" element={<CustomerOfBuisnessPartner />} />
                    <Route path="/admin/bussiness_trash" element={<BusinessTrash />} />
                    <Route path="/admin/brand/:id/details" element={<BrandDetails />} />
                    <Route path="/admin/brands/edit/:id" element={<BrandEdit />} />
                    {/* <Route path="/admin/order" element={<Orders />} /> */}
                    <Route path="/admin/transaction/:id" element={<TransactionsId />} />
                    <Route path="/admin/transaction/:id/giftcards" element={<GiftId />} />
                </Route>
                
                <Route path="/admin" element={<Login />} />
                <Route path="/send-password-reset-email" element={<SendPasswordResetEmail />} />
                <Route path="/api/user/reset/:id/:token" element={<ResetPassword />} />
                <Route path="/customer-send-password-reset-email" element={<CustomerSendPasswordResetEmail />} />
                <Route path="/api/customer/reset/:id/:token" element={<CustomerResetPassword />} />
                <Route path="/login" element={<CustomerLogin />} />
                <Route path="/customer/setPassword/:id" element={<CustomerCreatePassword/>} />
                <Route path="/customer/verify" element={<CustomerVerify />} />
                <Route path="/admin/verify" element={<Verify />} />
                <Route path="/admin/setPassword/:id" element={<CreatePassword />} />
                <Route path="*" element={<Error404 />} />
                <Route path="/business-login" element={<BusinessLogin/>} />
                <Route path="/business/verify" element={<BusinessVerify/>} />
                <Route path="/business/setpassword/:id" element={<BusinessCreatePassword/>} />
                <Route path="/send-business-reset-password" element={<BusinessSendPassword/>} />
                <Route path="/api/business/reset/:id/:token" element={<BusinessResetPassword/>}/>
                         
                <Route exact path="/onboard" element={<OnBoard />} />

                <Route exact path="/payout" element={<PayOut />} />
                <Route exact path="/checkout" element={<CheckOut />} />
                <Route exact path="/giftcardss" element={<BusinessPartnerGift />} />

                <Route exact path ="/partner" element={<BusinessDasboardLayout/>}>
                <Route path="/partner/dashboard" element={<Businessdashboard />} />
                <Route path="/partner/profile" element={<Profile />} />
                <Route path="/partner/modify" element={<ModifyFund />} />
                <Route path="/partner/Upload" element={<UploadCsv />}/>
                <Route path="/partner/brands" element={<BusinessBrands />} />
                <Route path="/partner/customize" element={<Customize admin={false}/>} />
                <Route path="/partner/apikey" element={<ApiKey admin={false}/>} />
                {/* <Route path="/partner/reminder" element={<Reminders admin={false}/>} /> */}
                <Route path="/partner/transaction" element={<BusinessTransaction/>} />
                <Route path="/partner/monetary" element={<BusinessMonetary/>} />
                <Route path="/partner/acounthistory" element={<BusinessAcountHistory/>} />
                <Route path="/partner/customer"  element={<BusinessCustomer admin={false}/>} />
                <Route path="/partner/Contact" element={<Users admin={false}/>} />
                <Route path="/partner/customers/add" element={<AddCustomers admin={false}/>} />
                <Route path="/partner/customers/:id/edit" element={<AddCustomers admin={false} />} />
                <Route path="/partner/transaction/:id/giftcards" element={<BusinessGiftId />} />
                <Route path="/partner/transaction/:id" element={<BusinessTransactionId />} />
                <Route path="/partner/partners/:id/:bid/coupons" element={<PartnerCoupon/>} />
                <Route path="/partner/partners/:id/:bid/coupons/details" element={<PartnerCouponDetails />} />
               
                </Route>


                <Route exact path ="/customers" element={<CustomerDashboardLayout/>} >
                <Route path="/customers/transaction" element={<CustomerTransaction />} />
                <Route path="/customers/monetarytransaction" element={<CustomerMonetaryTransaction />} />
                <Route path="/customers/profile" element={<CustomerProfile />} />
                <Route path="/customers/transaction/:id/giftcard" element={<CustomerTransctionDetail />} />
                </Route>




            </Routes>
            <ToastContainer position="top-right"
            autoClose={3000}/>
        </Router>
    );
}

export default App;
