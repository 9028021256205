import React,{ useState, useEffect }from "react";
import { Button, Col, Container, Row, Form } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import * as Yup from "yup";
import { Field, Formik } from "formik";
import { Bars } from "react-loader-spinner";
import Select from "react-select";
import { getNewPartnerToken, getNewToken } from "../config/cookie.config";


const AddCustomers = (props) => {
  // const inputFileRef = useRef(null);
  const {id} = useParams();
  const businessPartner = JSON.parse( window.localStorage.getItem('business-client'));
  
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [checkEmail, setCheckEmail] = useState(false);
  const [code, setCode] = useState("");
  const [user_id, setId] = useState("");
  const [customer_id, setCustId] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [balance, setBalance] = useState("");
  const [business_partner, setBusinessPartner] = useState("");
  const [business_partner_id, setBusinessPartnerid] = useState("");
  
  let userId;
  
  let navigate = useNavigate();
  if(props.admin === true){
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      userId = user.user._id;
    } else {
      userId = null;
      navigate("/admin");
    }
  }else{
    // id=businessPartner?.user?._id;
    const user = JSON.parse(window.localStorage.getItem("business-client"));
    if (user) {
      userId = user.user._id;
      
    } else {
      userId = null;
      navigate("/business-login");
    }
  }
  

 


  const [disabled,setDisabled] = useState(false);
  const [buttonDisabled,setButtonDisabled] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [error,setError] = useState(false);
  const [getbusinessPartner, setbusinessPartner] = useState([]);
  const [balanceError, setBalanceError] = useState(false);
  const [phoneError,setPhoneError] = useState();


   useEffect(()=>{
    if(props.admin !== true){
      const user = JSON.parse(window.localStorage.getItem("business-client"));
    if (user) {
      userId = user.user._id;
      setBusinessPartner(user.user.business_partner_id);
    }
    }
   },[]) 


  // function isValidEmail(email) {
  //   return /\S+@\S+\.\S+/.test(email);
  // }
  
  // const handleChangeEmail = event => {

  //   const emailValue = event.target.value;
  //   const trimmedEmail = emailValue.replace(/\s/g, '');
  
  //   if (trimmedEmail !== emailValue || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(trimmedEmail)) {
  //     // The email contains spaces
  //     setError(true);
  //   } else if (!isValidEmail(trimmedEmail)) {
  //     // The email is invalid
  //     setError(true);
  //   } else {
  //     // The email is valid and doesn't contain spaces
  //     setError(false);
  //   }
  
  //   setEmail(trimmedEmail);
  // };
  

  const getbusinessPartners = () => {
    setIsLoading(true)
    axios
      .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/partnerfilter`)
      .then((response) => {
        setIsLoading(false)
        const customers = response.data.partnerFilter;
        const uniqueIds = [];
        const unique = customers.filter(element => {
          const isDuplicate = uniqueIds.includes(element.value);
          if (!isDuplicate) {
            uniqueIds.push(element.value);
            return uniqueIds;
          }
          return false;
        });
        setbusinessPartner(unique);
        
     
      })
      .catch((error) => {
        setIsLoading(false)
        console.error(`Error: ${error}`)
      });
  };

  
  const handleKeyPress = (e) => {
    const keyCode = e.keyCode || e.which;
    const char = String.fromCharCode(keyCode);
    const regex = /^[0-9]+$/;
    if (!regex.test(char)) {
      e.preventDefault();
    }
  };

 const phoneCode = [
   { value: "+1", label: "+1 USA" },
   { value: "+7", label: "+7 RUS" },
   { value: "+20", label: "+20 EGY" },
   { value: "+27", label: "+27 ZAF" },
   { value: "+30", label: "+30 GRC" },
   { value: "+31", label: "+31 NLD" },
   { value: "+32", label: "+32 BEL" },
   { value: "+33", label: "+33 FRA" },
   { value: "+34", label: "+34 ESP" },
   { value: "+36", label: "+36 HUN" },
   { value: "+39", label: "+39 ITA" },
   { value: "+40", label: "+40 ROU" },
   { value: "+41", label: "+41 SWZ" },
   { value: "+43", label: "+43 AUT" },
   { value: "+44", label: "+44 GBR" },
   { value: "+45", label: "+45 DNK" },
   { value: "+46", label: "+46 SWE" },
   { value: "+47", label: "+47 NOR" },
   { value: "+48", label: "+48 POL" },
   { value: "+49", label: "+49 DEU" },
   { value: "+51", label: "+51 PER" },
   { value: "+52", label: "+52 MEX" },
   { value: "+53", label: "+53 CUB" },
   { value: "+54", label: "+54 ARG" },
   { value: "+55", label: "+55 BRA" },
   { value: "+56", label: "+56 CHL" },
   { value: "+57", label: "+57 COL" },
   { value: "+58", label: "+58 VEN" },
   { value: "+60", label: "+60 MYS" },
   { value: "+61", label: "+61 AUS" },
   { value: "+62", label: "+62 IDN" },
   { value: "+63", label: "+63 PHL" },
   { value: "+64", label: "+64 NZL" },
   { value: "+65", label: "+65 SGP" },
   { value: "+66", label: "+66 THA" },
   { value: "+81", label: "+81 JPN" },
   { value: "+82", label: "+82 KOR" },
   { value: "+84", label: "+84 VNM" },
   { value: "+86", label: "+86 CHN" },
   { value: "+90", label: "+90 TUR" },
   { value: "+91", label: "+91 IND" },
   { value: "+92", label: "+92 PAK" },
   { value: "+93", label: "+93 AFG" },
   { value: "+94", label: "+94 LKA" },
   { value: "+95", label: "+95 MMR" },
   { value: "+98", label: "+98 IRN" },
   { value: "+211", label: "+211 SSD" },
   { value: "+212", label: "+212 MAR" },
   { value: "+213", label: "+213 DZA" },
   { value: "+216", label: "+216 TUN" },
   { value: "+218", label: "+218 LBY" },
 ];




  const formikInitialValue = {
    first_name,
    last_name,
    customer_id,
    user_id,
    email,
    code,
    phone,
    balance,
    business_partner,
    // checkEmail,
  };

   let formSchema;

 if(props.admin === true){
  formSchema = Yup.object().shape({
   
    first_name: Yup.string()
      .min(2, "Too Short!")
      .max(21, "Maximum 21 characters allowed for Name")
      .matches(/^[aA-zZ0-9\s]+$/, "Alphabets and numbers are allowed for Name")
      .required(" First Name cannot be empty"),
    last_name: Yup.string()
      .min(2, "Too Short!")
      .max(21, "Maximum 21 characters allowed for Name")
      .matches(/^[aA-zZ0-9\s]+$/, "Alphabets and numbers are allowed for Name"),
      email: Yup.string()
      .email("Invalid email")
      .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i,
          "Invalid email format"
      )
      .required("Email cannot be empty"),

    // code: Yup.string()
    //   .min(1, "minimum 1 digit require"),
    // phone: Yup.string()
    //   .min(14, "Minimum 10 digits required for Phone number")
    //   .max(14, "Maximum 10 digits allowed for Phone number"),

    balance: Yup.string()
      .min(1, "Minimum 1 digits required for Balance Amount")
      // .max(10, "Maximum 10 digits allowed for Balance Amount")
      .matches(/^\d+(\.\d{2})?$/, 'Invalid balance format')
      .test('decimal', 'Decimal part must have exactly two digits', (value) => {
        if (!value) return true;
        const decimalPart = value.split('.')[1];
        return decimalPart ? decimalPart.length === 2 : true;
      })
      .required("Balance cannot be empty"),
    business_partner: Yup.string()
      .required("Business Partner cannot be empty"),
  });

 }else{
  formSchema = Yup.object().shape({
   
    first_name: Yup.string()
      .min(2, "Too Short!")
      .max(21, "Maximum 21 characters allowed for Name")
      .matches(/^[aA-zZ0-9\s]+$/, "Alphabets and numbers are allowed for Name")
      .required(" First Name cannot be empty"),
    last_name: Yup.string()
      .min(2, "Too Short!")
      .max(21, "Maximum 21 characters allowed for Name")
      .matches(/^[aA-zZ0-9\s]+$/, "Alphabets and numbers are allowed for Name"),
      email: Yup.string()
      .email("Invalid email")
      .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i,
          "Invalid email format"
      )
      .required("Email cannot be empty"),

    // code: Yup.string()
    //   .min(1, "minimum 1 digit require"),
    // phone: Yup.string()
    //   .min(14, "Minimum 10 digits required for Phone number")
    //   .max(14, "Maximum 10 digits allowed for Phone number"),

    balance: Yup.string()
      .min(1, "Minimum 1 digits required for Balance Amount")
      // .max(10, "Maximum 10 digits allowed for Balance Amount")
      .matches(/^\d+(\.\d{2})?$/, 'Invalid balance format')
      .test('decimal', 'Decimal part must have exactly two digits', (value) => {
        if (!value) return true;
        const decimalPart = value.split('.')[1];
        return decimalPart ? decimalPart.length === 2 : true;
      })
      .required("Balance cannot be empty"),
  //   business_partner: Yup.string()
  //     .required("Business Partner cannot be empty"),
  });

 }


  useEffect(() =>{
    getbusinessPartners();
  },[])

  useEffect(() => {
    if (id) {
      getAllCustomers();

    }
  }, []);

  const getAllCustomers = () => {
    var token;
    let para;
    if(props.admin === true){
      const user = JSON.parse(window.localStorage.getItem("client"));
      para="admin"
    if (user) {
      // token = user.token;
      token = getNewToken();
    } else {
      token = null;
    }
    }else{
      para="partner"
      const user = JSON.parse(window.localStorage.getItem("business-client"));
      if (user) {
        // token = user.token;
        token = getNewPartnerToken();
      } else {
        token = null;
      }
    }
    axios
      .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/${para}/getcustomer/${id}`, {
          headers: {
            'Authorization' : `Bearer ${token}`
          }
        })
      .then((response) => {
        if(para == "admin") {
          if(response.data.message == "Please Login"){
            localStorage.removeItem('client');
            navigate('/admin');
            toast.error("Session Expired Please Login Again")
          }
        } else {
          if(response.data.message == "Please Login"){
            localStorage.removeItem('business-client');
            navigate('/business-login');
            toast.error("Session Expired Please Login Again")
          }
        }
        const roundedBalance = parseFloat(response.data.customer.balance).toFixed(2);
        setFirstName(response.data.customer.first_name);
        setLastName(response.data.customer.last_name);
        setCustId(response.data.customer._id);
        setEmail(response.data.customer.email);
        setCode(response.data.customer.code);
        setPhone(response.data.customer.phone);       
         setBalance(roundedBalance);
        setBusinessPartner(response.data.customer.business_partner_id);
        setBusinessPartnerid(response.data.customer.business_partner);
      })
      .catch((error) => console.error(`Error: ${error}`));
     };

   const handleSubmitForm = async (data) => {
    setButtonDisabled(true)
   
    const {
      first_name,
      last_name,
      code,
      phone,
      email,
      balance,
      business_partner,
      user_id,
    } = data;
    
    // if(props.admin !== true){
    //   const user = JSON.parse(window.localStorage.getItem("business-client"));
    // if (user) {  
    //   data.business_partner =user.user.business_name;
    // }
    // }
    if(data.balance<1 ){
      toast.warning("Please add at least $1 balance");
      // setButtonDisabled(false)
    }else if(data.business_partner_id === "Select Partner"){
      toast.warning("Please select a business partner");
      setButtonDisabled(false)
    }
    else{
    if(data.business_partner === "Select Partner"){
      toast.warning("Please select a business partner");
      setButtonDisabled(false)
      
    }else{    
    try {
      // const user = JSON.parse(window.localStorage.getItem("client"));
      var token;
      let para;
    if(props.admin === true){
      const user = JSON.parse(window.localStorage.getItem("client"));
      para="admin"
    if (user) {
      // token = user.token;
      token = getNewToken();
    } else {
      token = null;
    }
    }else{
      para="partner"
      const user = JSON.parse(window.localStorage.getItem("business-client"));
      if (user) {
        token = getNewPartnerToken();
        setBusinessPartner(user.user.business_name);
      } else {
        token = null;
      }
    }
    const lowercaseEmail = email.toLowerCase();
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/${para}/customer/create`,
        {
          first_name,
          last_name,
          code,
          phone,
          email: lowercaseEmail,
          balance,
          business_partner,
          user_id : userId,
          customer_id,
          loginUserId: userId
        },{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      },
      
      );
      
      if (data?.status==="success") {
        toast.success(data.message);
        if(props.admin === true){
          
          navigate("/admin/customers");
        }else{
          navigate("/partner/customer")
        }
      }else if(data?.status==="failed"){
       return
      }else {
        if(props.admin === true){
          
          navigate("/admin/customers");
        }else{
          navigate("/partner/customer")
        }
      }
      if(data.message === "Email already exists"){
        
        setError(true)
      }

      setTimeout(() => {
        setButtonDisabled(false)
      }, 2000);

    } catch (err) {
      console.log(`Error : ${err}`);
      // setIsLoading(true);
      // navigate("/admin/customers/add");
      window.location.reload();
      // setBalanceError(true)
      // toast(err.response.data.msg);
    } 
  }
}
  };

  function phoneFormat(input) {
    input = input?.replace(/\D/g, "");
    input = input?.trim()
    input = input?.substring(0, 10);

    var size = input?.length;
    if (size === 0) {
      input = input;
    }
     else if (size < 4) {
      input = "(" + input;
    } 
    else if (size < 7) {
      input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6);
    } 
    
    else if(size <= 11)
    input= "("+input.substring(0, 3)+") "+input.substring(3, 6)+"-" +input.substring(6, 11);

    if (input?.length > 10 && input?.length <= 14){
      setPhoneError(false);
  }else{
      setPhoneError(true);
  }
    return input;
  }
  
  function nameFormat(input) {
    return input?.trimStart();
  }
  function lastNameFormat(input) {
    if(input){
      return input?.trimStart();
    }
  }

  const handlePaste = (event) => {
    const pastedText = event.clipboardData.getData('text/plain');
    const formattedText = pastedText.trim(); // Remove spaces before and after the pasted text
    event.preventDefault(); // Prevent the default paste behavior
    document.execCommand('insertText', false, formattedText); // Insert the modified text into the input field
  }



  const userStatus = (userIds) => {
      axios
          .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getuser/${userIds}`)
          .then((response)=>{
              let users = response.data.user?.status
              if(users === false){
                  window.localStorage.removeItem('client');
                  navigate('/admin');
                  toast.error("Your account has been deactivated");
          }
          })
          .catch((error) => console.error(`Error: ${error}`));
      }
      let userIds;
  useEffect(()=>{
     if(props.admin === true){
      const userID = localStorage.getItem('client');
      const users = JSON.parse(userID);
       userIds = users?.user._id;
      userStatus(userIds);
     }
  } ,[userIds])

  return isLoading === true ? (
    <div className="d-flex justify-content-center mt-5 pt-5">
      <Bars
      height="80"
      width="100%"
      margin='auto'
      color="#FF7020"
      ariaLabel="bars-loading"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
      />
    </div>
  ) : (
    <>
      <div className="p-3 p-lg-4 p-xl-5">
        {props.admin ? <div className="d-flex align-items-center">
          <Link
            to="/admin/customers"
            className="p-0 btn btnOrangeTransparent me-3 fw-semibold"
            variant="white"
          >
            <span className="me-2 fw-semibold">
              <img
                src="/assets/images/back-org.png"
                className="img-fluid"
                alt=""
              />
            </span>
            <span>Back</span>
          </Link>
          <h1 className="font20  text-dark">{id ? "" : "Create Customers"}</h1>
        </div>:<div className="d-flex align-items-center">
          <Link
            to="/partner/customer"
            className="p-0 btn btnOrangeTransparent me-3 fw-semibold"
            variant="white"
          >
            <span className="me-2 fw-semibold">
              <img
                src="/assets/images/back-org.png"
                className="img-fluid"
                alt=""
              />
            </span>
            <span>Back</span>
          </Link>
          <h1 className="font20  text-dark">{id ? "" : "Create Customers"}</h1>
        </div>}

        <Container fluid className="mt-4">
          <Row>
            <Col lg={12} md={12}>
              <Formik
                enableReinitialize={true}
                initialValues={formikInitialValue}
                validationSchema={formSchema}
                onSubmit={(values, { setSubmitting }) => {
                  setButtonDisabled(true)
                  // if(email.length<5){
                  //   setError(true);
                  // }else{
                    // setDisabled(true);
                    setTimeout(()=>{
                      setDisabled(false);
                    },2000);
                    setTimeout(() => {
                      handleSubmitForm(values);
                      setSubmitting(false);
                    }, 400);
                  // }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting /* and other goodies */,
                }) => (
                  <Form
                    className="bg-white p-4 rounded containerShadow"
                    onSubmit={handleSubmit}
                  >
                    <h2 className="font16 mb-4 text-dark">Customers Info</h2>

                    <Row>
                      <Col md={6}>
                        <Form.Control
                          type="hidden"
                          value={customer_id}
                          onChange={(e) => setCustId(e.target.value)}
                          placeholder="Enter Name"
                          className="font14"
                        />
                        <Form.Control
                          type="hidden"
                          value={userId}
                          onChange={(e) => setId(e.target.value)}
                          placeholder="Enter Name"
                          className="font14"
                        />
                        <Form.Group className="mb-3" controlId="formBasicText1">
                          <Form.Label className="font12 text-dark fw-medium">
                            First Name <span className="text-danger">*</span>
                          </Form.Label>
                          {props.admin !== true && first_name ? <Form.Control
                            name="first_name"
                            className="font12"
                            value={nameFormat(values.first_name.charAt(0).toUpperCase() + values.first_name.slice(1))}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            placeholder="Enter First Name"
                            isValid={touched.first_name && !errors.first_name}
                            isInvalid={!!errors.first_name}
                            onPaste={handlePaste}
                            disabled
                          />:<Form.Control
                          name="first_name"
                          className={`font12 ${values.first_name.length > 1 ? 'sdd':''}`}
                          value={nameFormat(values.first_name.charAt(0).toUpperCase() + values.first_name.slice(1))}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="text"
                          placeholder="Enter First Name"
                          isValid={touched.first_name && !errors.first_name}
                          isInvalid={!!errors.first_name}
                          onPaste={handlePaste}
                        />}
                          {errors.first_name && touched.first_name ? (
                            <div className="text-danger font12">
                              {errors.first_name}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText2">
                          <Form.Label className="font12 text-dark fw-medium">
                            Last Name{" "}
                            <span className="fw-normal text-secondary">
                              (Optional)
                            </span>
                          </Form.Label>
                          {props.admin !== true && id ?<Form.Control
                            className="font12"
                            name="last_name"
                            value={lastNameFormat(values.last_name.charAt(0).toUpperCase() + values.last_name .slice(1))}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            placeholder="Enter Last Name"
                            isValid={touched.last_name && !errors.last_name}
                            isInvalid={!!errors.last_name}
                            onPaste={handlePaste}
                            disabled
                          />:<Form.Control
                          className="font12"
                          name="last_name"
                          value={lastNameFormat(values.last_name?.charAt(0).toUpperCase() + values.last_name?.slice(1))}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="text"
                          placeholder="Enter Last Name"
                          isValid={touched.last_name && !errors.last_name}
                          isInvalid={!!errors.last_name}
                          onPaste={handlePaste}
                        />}
                          {errors.last_name && touched.last_name ? (
                            <div className="text-danger font12">
                              {errors.last_name}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
  <Form.Label className="font12 text-dark fw-medium">
    Email Address <span className="text-danger">*</span>
  </Form.Label>
  {customer_id ? (
    <Form.Control
      className="font12"
      type="email"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      placeholder="Enter Email Address"
      disabled
    />
  ) : (
    <Form.Control
      style={{ border: error ? "1px solid red" : "" }}
      className="font12"
      type="email"
      name="email"
      value={values.email}
      onChange={handleChange}
      onKeyDown={(e) => {
        if (e.key === " ") {
          e.preventDefault();
        }
      }}
      onBlur={handleBlur}
      // onBlur={(e) => {
      //   handleBlur(e);
      //   if (errors.email) {
      //     setEmail(e.target.value.trim());
      //   }
      // }}
      placeholder="Enter Email Address"
      isValid={touched.email && !error && !errors.email }
      isInvalid={!!errors.email}
      onPaste={handlePaste}
    />
  )}
   {errors.email && touched.email ? (
    <div className="text-danger font12">{errors.email}</div>
   ) : null}
  {error === true ? <div className="text-danger font12 mt-1">Email Already Exists</div> : ""}
</Form.Group>

                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="font12 text-dark fw-medium">
                            Phone Number{" "}
                            <span className="fw-normal text-secondary">
                              (Optional)
                            </span>
                          </Form.Label>
                          <div className="d-flex justify-content-center align-items-center">
                            <div className="setect_code">
                              {props.admin !== true && id ? (
                                <Select
                                  className={`font12 form-select${
                                    values.code.length > 10 ? "sdd" : "jk"
                                  }`}
                                  type="text"
                                  name="code"
                                  value={phoneCode.find(
                                    (option) => option.value === values.code
                                  )}
                                  onChange={(selectedOption) =>
                                    handleChange({
                                      target: {
                                        name: "code",
                                        value: selectedOption.value,
                                      },
                                    })
                                  }
                                  onBlur={handleBlur}
                                  options={phoneCode}
                                  isSearchable
                                  placeholder="Code"
                                />
                              ) : (
                                <Select
                                  className={`font12 form-select${
                                    values.code.length > 10 ? "sdd" : "jk"
                                  }`}
                                  type="text"
                                  name="code"
                                  value={phoneCode.find(
                                    (option) => option.value === values.code
                                  )}
                                  onChange={(selectedOption) =>
                                    handleChange({
                                      target: {
                                        name: "code",
                                        value: selectedOption.value,
                                      },
                                    })
                                  }
                                  onBlur={handleBlur}
                                  options={phoneCode}
                                  isSearchable
                                  placeholder="Code"
                                />
                              )}
                            </div>
                            {/* {props.admin !== true && id ?  <Field
                                  as='select'
                                  className={`font12 form-select${values.code.length > 10 ? "sdd" :"jk"}`}
                                  name='code'
                                  value={(values.code.startsWith('+') ? values.code : '+' + values.code)}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  style={{ border: '1px solid #cfc6c6', height: '31px', borderRadius: '5px', width: '6rem' }}
                                  isValid={touched.code && !errors.code}
                                  isInvalid={!!errors.code}
                                  type='text'
                                >
                                  <option value="" label="Code" disabled />
                                  <option value="+1" label="+1 USA" />
                                  <option value="+7" label="+7 RUS" />
                                  <option value="+20" label="+20 EGY" />
                                  <option value="+27" label="+27 ZAF" />
                                  <option value="+30" label="+30 GRC" />
                                  <option value="+31" label="+31 NLD" />
                                  <option value="+32" label="+32 BEL" />
                                  <option value="+33" label="+33 FRA" />
                                  <option value="+34" label="+34 ESP" />
                                  <option value="+36" label="+36 HUN" />
                                  <option value="+39" label="+39 ITA" />
                                  <option value="+40" label="+40 ROU" />
                                  <option value="+41" label="+41 SWZ" />
                                  <option value="+43" label="+43 AUT" />
                                  <option value="+44" label="+44 GBR" />
                                  <option value="+45" label="+45 DNK" />
                                  <option value="+46" label="+46 SWE" />
                                  <option value="+47" label="+47 NOR" />
                                  <option value="+48" label="+48 POL" />
                                  <option value="+49" label="+49 DEU" />
                                  <option value="+51" label="+51 PER" />
                                  <option value="+52" label="+52 MEX" />
                                  <option value="+53" label="+53 CUB" />
                                  <option value="+54" label="+54 ARG" />
                                  <option value="+55" label="+55 BRA" />
                                  <option value="+56" label="+56 CHL" />
                                  <option value="+57" label="+57 COL" />
                                  <option value="+58" label="+58 VEN" />
                                  <option value="+60" label="+60 MYS" />
                                  <option value="+61" label="+61 AUS" />
                                  <option value="+62" label="+62 IDN" />
                                  <option value="+63" label="+63 PHL" />
                                  <option value="+64" label="+64 NZL" />
                                  <option value="+65" label="+65 SGP" />
                                  <option value="+66" label="+66 THA" />
                                  <option value="+81" label="+81 JPN" />
                                  <option value="+82" label="+82 KOR" />
                                  <option value="+84" label="+84 VNM" />
                                  <option value="+86" label="+86 CHN" />
                                  <option value="+90" label="+90 TUR" />
                                  <option value="+91" label="+91 IND" />
                                  <option value="+92" label="+92 PAK" />
                                  <option value="+93" label="+93 AFG" />
                                  <option value="+94" label="+94 LKA " />
                                  <option value="+95" label="+95 MMR" />
                                  <option value="+98" label="+98 IRN" />
                                  <option value="+211" label="+211 SSD" />
                                  <option value="+212" label="+212 MAR" />
                                  <option value="+213" label="+213 DZA" />
                                  <option value="+216" label="+216 TUN" />
                                  <option value="+218" label="+218 LBY" />
                                </Field>
                            : 
                            <Field
                                  as='select'
                                  className={`font12 form-select${values.code.length > 10 ? "sdd" :"jk"}`}
                                  name='code'
                                  value={(values.code.startsWith('+') ? values.code : '+' + values.code)}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  style={{ border: '1px solid #cfc6c6', height: '31px', borderRadius: '5px', width: '6rem' }}
                                  isValid={touched.code && !errors.code}
                                  isInvalid={!!errors.code}
                                  type='text'
                                >
                                  <option value="+" label="Code" disabled selected />
                                  <option value="+1" label="+1 USA" />
                                  <option value="+7" label="+7 RUS" />
                                  <option value="+20" label="+20 EGY" />
                                  <option value="+27" label="+27 ZAF" />
                                  <option value="+30" label="+30 GRC" />
                                  <option value="+31" label="+31 NLD" />
                                  <option value="+32" label="+32 BEL" />
                                  <option value="+33" label="+33 FRA" />
                                  <option value="+34" label="+34 ESP" />
                                  <option value="+36" label="+36 HUN" />
                                  <option value="+39" label="+39 ITA" />
                                  <option value="+40" label="+40 ROU" />
                                  <option value="+41" label="+41 SWZ" />
                                  <option value="+43" label="+43 AUT" />
                                  <option value="+44" label="+44 GBR" />
                                  <option value="+45" label="+45 DNK" />
                                  <option value="+46" label="+46 SWE" />
                                  <option value="+47" label="+47 NOR" />
                                  <option value="+48" label="+48 POL" />
                                  <option value="+49" label="+49 DEU" />
                                  <option value="+51" label="+51 PER" />
                                  <option value="+52" label="+52 MEX" />
                                  <option value="+53" label="+53 CUB" />
                                  <option value="+54" label="+54 ARG" />
                                  <option value="+55" label="+55 BRA" />
                                  <option value="+56" label="+56 CHL" />
                                  <option value="+57" label="+57 COL" />
                                  <option value="+58" label="+58 VEN" />
                                  <option value="+60" label="+60 MYS" />
                                  <option value="+61" label="+61 AUS" />
                                  <option value="+62" label="+62 IDN" />
                                  <option value="+63" label="+63 PHL" />
                                  <option value="+64" label="+64 NZL" />
                                  <option value="+65" label="+65 SGP" />
                                  <option value="+66" label="+66 THA" />
                                  <option value="+81" label="+81 JPN" />
                                  <option value="+82" label="+82 KOR" />
                                  <option value="+84" label="+84 VNM" />
                                  <option value="+86" label="+86 CHN" />
                                  <option value="+90" label="+90 TUR" />
                                  <option value="+91" label="+91 IND" />
                                  <option value="+92" label="+92 PAK" />
                                  <option value="+93" label="+93 AFG" />
                                  <option value="+94" label="+94 LKA " />
                                  <option value="+95" label="+95 MMR" />
                                  <option value="+98" label="+98 IRN" />
                                  <option value="+211" label="+211 SSD" />
                                  <option value="+212" label="+212 MAR" />
                                  <option value="+213" label="+213 DZA" />
                                  <option value="+216" label="+216 TUN" />
                                  <option value="+218" label="+218 LBY" />
                                </Field>
                            } */}
                            &nbsp;

                            {values.code === '' ?
                              <Form.Control
                                type="text"
                                name="phone" 
                                maxLength={14}
                                value={phoneFormat(values.phone).trim()}

                                onChange={handleChange}
                                onBlur={handleBlur}
                          
                                isValid={touched.phone && !errors.phone}
                                isInvalid={!!errors.phone}
                                placeholder="Enter Phone"
                                className="font12 "
                                onKeyPress={handleKeyPress}
                                disabled
                              />
                              :
                              <Form.Control
                                type="text"
                                name="phone"
                                maxLength={14}
                                value={phoneFormat(values.phone?.trim())}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.phone && !phoneError}
                                isInvalid={phoneError}
                                placeholder="Enter Phone"
                                className="font12 "
                                onKeyPress={handleKeyPress}
                                onPaste={(e) => {
                                  e.preventDefault();
                                  const pastedText = e.clipboardData.getData('text/plain');
                                  const formattedPhoneNumber = phoneFormat(pastedText);
                                  handleChange({ target: { name: 'phone', value: formattedPhoneNumber } });
                                }}
                                required
                              />
                            }
                          </div>
                          <div className="d-flex">
                            {values.phone === '' ? '' : (errors.code && touched.code ? (
                              <div className="text-danger font12">
                                {errors.code}
                              </div>
                            ) : null)
                            }

                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            {phoneError && touched.phone ? (
                              <div className="text-danger font12">
                                {errors.phone}
                              </div>
                            ) : null}

                          </div>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText3">
                          <Form.Label className="font12 text-dark fw-medium">
                            Redeemable Balance{" "}
                            <span className="text-danger">*</span>
                          </Form.Label>
                          {id ? <Form.Control
                            style={{ border : balanceError ? "1px solid red" : "" }}
                            className="font12"
                            name="balance"
                            value={values.balance ? Number(values.balance).toFixed(2) : ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            placeholder="$ xxxx.xx"
                            isValid={touched.balance && !errors.balance}
                            isInvalid={!!errors.balance}
                            onPaste={handlePaste}
                            disabled
                          />:<Form.Control
                          style={{ border : balanceError ? "1px solid red" : "" }}
                          className="font12"
                          name="balance"
                          value={values.balance}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="text"
                          placeholder="$ xxxx.xx"
                          isValid={touched.balance && !errors.balance}
                          isInvalid={!!errors.balance}
                          onPaste={handlePaste}
                        />}
                          {errors.balance && touched.balance ? (
                            <div className="text-danger font12">
                              {errors.balance}
                            </div>
                          ) : null}
                          {balanceError === true && !errors.balance ? <div className="text-danger font12 mt-1">Invalid balance Format</div> : ""}
                        </Form.Group>
                      </Col>
                      {props.admin !== true ? '':<Col md={6}>
                        <Form.Group className="mb-3" controlId="formControlsField">
                          <Form.Label className="font12 text-dark fw-medium">
                            Business Partner{" "}
                            <span className="text-danger">*</span>
                          </Form.Label>

                         {id?
                          <Field as="select"  style={{ height: '31px', width: '100%',borderWidth:'1px',backgroundColor:'#e9ecef',opacity:'1',borderRadius:'5px',borderColor: errors.business_partner &&
                            touched.business_partner ? 'red' : '#cccccc' }} className="font12"
                           name="business_partner"   onChange={handleChange} onBlur={handleBlur}  isValid={
                              touched.business_partner &&
                              !errors.business_partner
                            }
                            isInvalid={!!errors.business_partner} 
                            placeholder="Enter Business Partner"
                            disabled
                            >
                            {getbusinessPartner.map((partner, index )=> {
                               const key = partner.label === business_partner ? `selected_${index}` : `option_${index}`;
                               return(
                                <React.Fragment key={key}>
                                {partner.label === business_partner ? (
                                  <option value={business_partner} selected>
                                    {business_partner}
                                  </option>
                                ) : (
                                  <option value={partner.value}>
                                    {partner.label}
                                  </option>
                                )}
                              </React.Fragment>
                               )
                            })}
                           

                          </Field>
                          :

                        <Field as="select"  style={{ height: '31px', width: '100%',borderWidth:'1px',borderRadius:'5px',borderColor: errors.business_partner &&
                            touched.business_partner ? 'red' : '#cccccc' }} className="font12"
                           name="business_partner"   onChange={handleChange} onBlur={handleBlur}  isValid={
                              touched.business_partner &&
                              !errors.business_partner
                            }
                            isInvalid={!!errors.business_partner} 
                            placeholder="Enter Business Partner"
                            >
                            {getbusinessPartner.map((partner, index )=> {
                               const key = partner.label === business_partner ? `selected_${index}` : `option_${index}`;
                               return(
                                <React.Fragment key={key}>
                                {partner.label === business_partner ? (
                                  <option value={business_partner} selected>
                                    {business_partner}
                                  </option>
                                ) : (
                                  <option value={partner.value}>
                                    {partner.label}
                                  </option>
                                )}
                              </React.Fragment>
                               )
                            })}
                           

                          </Field>
                            }

                          {errors.business_partner &&
                            touched.business_partner ? (
                            <div className="text-danger font12">
                              {errors.business_partner}
                            </div>
                          ) : null}

                        </Form.Group> 
                      </Col>}
                    </Row>
                    <Row className="mt-4">
                      <Col>
                        {buttonDisabled ? <Button
                          variant="primary"
                          className="px-4 py-2 fullRounded shadowPrimary font12"
                          disabled
                        >
                           {id ? 'Save Changes' : 'Create Customer'}
                        </Button>:<Button
                          type="submit"
                          variant="primary"
                          className="px-4 py-2 fullRounded shadowPrimary font12"
                          
                        >
                           {id ? 'Save Changes' : 'Create Customer'}
                        </Button>}
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>

      </div>
    </>
  );
};

export default AddCustomers;
