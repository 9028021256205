import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Form,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { FaSortUp, FaSortDown, FaRecycle } from "react-icons/fa";
import {BiPlusMedical} from "react-icons/bi";
import { Bars} from "react-loader-spinner";

import Modal from 'react-bootstrap/Modal';
import { getNewToken } from "../config/cookie.config";

const Business = () => {
  useEffect(()=>{
    const adminCheck= window.location.pathname;
    if(adminCheck.includes('admin')){
    document.title = "ncentiva || Admin Business partner"
    }
},[])
  const user = JSON.parse(window.localStorage.getItem("client"));
  let userrole;
  let usersid;
  let navigate = useNavigate();
  if (user) {
    userrole = user.user.role;
    usersid = user.user._id;
  } else {
    userrole = null;
    usersid = null;
    navigate("/admin");
  }

  const [search, setSearch] = useState("");
  const [order, setOrder] = useState("ASC");
  const [creditOrder, setCreditOrder] = useState("ASC");
  const [balanceOrder, setBalanceOrder] = useState("ASC");
  const [cityorder, setCityorder] = useState("ASC");
  const [phoneorder, setPhoneorder] = useState("ASC");
  const [statusorder, setStatusorder] = useState("ASC");
  const [customerOrder, setCustomerOrder] = useState("ASC");
  const [brandOrder, setBrandOrder] = useState("ASC");
  const [usedFund, setUsedFund] = useState("ASC");
  const [role] = useState(userrole);
  const [customerData, setCustomerData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const shouldLog = useRef(true);
  const [sort, setSort] = useState("");
  const [noData, setNoData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPage,setShowPage] = useState(1);
  
  let token;
  const pages = new Array(numberOfPages).fill(null).map((v, i) => i);

  if (user) {
    token = user.token;
  } else {
    token = null;
  }

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRowsPerPageChange = (event) => {
    pagenumberSearch(1)
    const selectedRowsPerPage = event.target.value;
    setRowsPerPage(selectedRowsPerPage);
    getAllUsers(pageNumber, selectedRowsPerPage);
  };



  useEffect(() => {
    setIsLoading(true);
    const getData = setTimeout(() => {
      getAllUsers(pageNumber, rowsPerPage);
      redirectToLogin();
      // setIsLoading(false);
    },600)
    return () => clearTimeout(getData)
  }, [pageNumber, search, sort])

  const getAllUsers = (pageNumber, pageSize) => {
    if (pageSize === undefined) {
      pageSize = 10
    }
    
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    setIsLoading(true);
    setNoData(false);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/businesspartners?page=${pageNumber}&pageSize=${pageSize}&search=${search}&sort=${sort}`, {
          headers: {
            'Authorization' : `Bearer ${getNewToken()}`
          }
      }
      )
      .then((response) => {
      setIsLoading(false);
        if(response.data.message === "Please Login"){
          localStorage.removeItem('client');
        localStorage.removeItem('user');
        navigate('/admin');
        toast.warning("Session Expired Please Login Again")
        // window.location.reload();
        }
        const businessPartner = response.data.businessPartner;
        if(businessPartner.length <1){
          setNoData(true);
        }else{
          setNoData(false);
        }
        const totalPages = response.data.totalPages;
        //const slice = businessPartner.slice(offset - 1 , offset - 1 + postsPerPage)
        setCustomerData(businessPartner);
        setNumberOfPages(totalPages);
        //setPageCount(Math.ceil(businessPartner.length / postsPerPage))
      })
        .catch((error) => {
        setIsLoading(false);
        console.error(`Error: ${error}`)
        if (error.response.data.message) {
          localStorage.removeItem('client');
          localStorage.removeItem('user');
          navigate('/admin');
          // toast.error("Session Expired. Please Login Again")
        }
      });
  };




  let items = [];
  let leftSide = pageNumber - 1;

  if (leftSide <= 0) leftSide = 0;
  let rightSide = pageNumber + 2;

  if (rightSide > numberOfPages) rightSide = numberOfPages;
  for (let number = leftSide; number < rightSide; number++) {

    items.push(
      <li key={number} className={(number === pageNumber ? "active" : "")} onClick={() => { setPageNumber(number);setShowPage(number+1) }}>
        {number + 1}
      </li>,
    );
  }

  const pagenumberSearch = (e)=>{
    var pages =parseInt(e) ;
    var page= pages -1;
    if(pages<1){
      setPageNumber(0);
      setShowPage(1);
    }else if(pages>numberOfPages){
      setPageNumber(numberOfPages-1);
      setShowPage(numberOfPages);
    }else if(pages>=1 && pages<=numberOfPages){
      setPageNumber(page);
      setShowPage(pages);
    }
    else{
      setPageNumber(0);
      setShowPage(pages);
    }
    }



  const nextPage = () => {
    if (pageNumber < numberOfPages) {
      setPageNumber(pageNumber + 1);
      setShowPage(pageNumber+2);
    }
  }

  const prevPage = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1);
      setShowPage(pageNumber);
    }
  }


  const [showModal, setShowModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUserPartnerId, setSelectedUserPartnerId] = useState(null);

  const handleDeleteUser = (userId,brandId) => {
    setSelectedUserId(userId);
    setSelectedUserPartnerId(brandId);
    setShowModal(true);
  };

  const handleConfirmDelete = () => {
    if (selectedUserId) {

      const user = JSON.parse(window.localStorage.getItem("client"));
      if (user) {
        token = user.token;
      } else {
        token = null;
      }
      axios
        .delete(
          `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/deletebusinesspartner/${selectedUserId}`,
          {
            data: { loginUserId: usersid },
            headers: {
              Authorization: `Bearer ${getNewToken()}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.message);
          getAllUsers(pageNumber)
          setShowModal(false);
        })
        .catch((error) => {
          setShowModal(false);
          toast.error("Failed to delete business partner");
          console.error(`Error: ${error}`)
        });
    }
  };

  const handleCloseModal = () => {
    setShowModal(false); 
  };




  //   sorting

  // name

  const sorting = (order) => {
    if (order === "DSC") {
      setSort("dscName");

      setOrder("DSC");
    } else {
      setSort("ascName");

      setOrder("ASC");
    }
  };

  //   email   creditOrder

  const creditSorting = (creditOrder) => {
    if (creditOrder === "DSC") {
      setSort("dscCreditAmount");

      setCreditOrder("DSC");
    } else {
      setSort("ascCreditAmount");

      setCreditOrder("ASC");
    }
  };
  const balanceSorting = (balanceOrder) => {
    if (creditOrder === "DSC") {
      setSort("dscBalanceAmount");

      setBalanceOrder("DSC");
    } else {
      setSort("ascBalanceAmount");

      setBalanceOrder("ASC");
    }
  };

  const usedFundSorting = (usedFund) => {
    if (usedFund === "DSC") {
      setSort("dscFundAmount");

      setUsedFund("DSC");
    } else {
      setSort("ascFundAmount");

      setUsedFund("ASC");
    }
  };


  //   type of user

  const citysorting = (cityorder) => {
    if (cityorder === "DSC") {
      setSort("dscCity");

      setCityorder("DSC");
    } else {
      setSort("ascCity");

      setCityorder("ASC");
    }
  };

  //   email

  //   type of user

  //   phone
  const phonesorting = (col) => {
    if (phoneorder === "ASC") {
      const sorted = [...customerData].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setCustomerData(sorted);
      setPhoneorder("DSC");
    }
    if (phoneorder === "DSC") {
      const sorted = [...customerData].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setCustomerData(sorted);
      setPhoneorder("ASC");
    }
  };

  //   status

  const statussorting = (col) => {
    if (statusorder === "ASC") {
      const sorted = [...customerData].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setCustomerData(sorted);
      setStatusorder("DSC");
    }
    if (statusorder === "DSC") {
      const sorted = [...customerData].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setCustomerData(sorted);
      setStatusorder("ASC");
    }
  };


  const customerSorting = (customerOrder) => {
    if (customerOrder === "DSC") {
      setSort("dscCustomerCount");

      setCustomerOrder("DSC");
    } else {
      setSort("ascCustomerCount");

      setCustomerOrder("ASC");
    }
  };

  const brandSorting = (brandOrder) => {
    if (brandOrder === "DSC") {
      setSort("dscBrandCount");

      setBrandOrder("DSC");
    } else {
      setSort("ascBrandCount");

      setBrandOrder("ASC");
    }
  };


  //   sorting

  //   sorting

  //  search
  const handleSearch = (value) => {
    setSearch(value);
    setPageNumber(0);
    setShowPage(1);
  };
  // search

  const redirectToLogin = () => {
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }

    if (token === null) {
      navigate("/admin");
    }
  };
  const deleteUser = async (userId,brandId) => {
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
  try {
    const isDeleted = await  axios.put( `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/buisness/soft-delete`,
    {
      soft_delete: true,
      type:"id",
      id:userId,
      status:false,
      brandId:brandId
    }, {
      headers: {
        'Authorization': `Bearer ${getNewToken()}`
      }
    })  
    toast.success("Business Partner Move to Trash");
    // navigate("/admin/bussiness_trash");
    setTimeout(function() {
      window.location.reload();
    }, 3000);

  } catch (error) {
    console.log(error);
  }
  };

  function handleStatus(id, status) {
    if (status === false) {
      status = true;
    } else {
      status = false;
    }
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios
      .post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/businesspartner/status`, {
        id,
        status,
      }, {
        headers: {
          'Authorization': `Bearer ${getNewToken()}`
        }
      },)
      .then((response) => {
        if (response.data.status === "success") {
          toast.success(response.data.message);
        } else {
          toast.warn(response.data.message);
        }
        getAllUsers(pageNumber, rowsPerPage);
      })
      .catch((error) => console.error(`Error: ${error}`));
  }

  const softDelete=async(e)=>{
    const {name ,checked,value}=e.target
    if(name==="allSelect"){
      let tempUser=customerData.map(user=> {return {...user,isChecked : checked}})
      setCustomerData(tempUser)
      localStorage.setItem("buss_Delete","All")
    }else{
      localStorage.setItem("buss_Delete","id")
    let tempUser=customerData.map(user=> user._id === name ? {...user,isChecked : checked} : user)
    setCustomerData(tempUser);
    const user = tempUser.filter((e) => e.isChecked);
    localStorage.setItem("buss_checked",JSON.stringify(user))
  }
  }

  const moveTrash = async () => {
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    const result = localStorage.getItem("buss_Delete");  
    if (result === "All") {
      // Move all customers to trash
      const isDeleted = await axios.put(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/buisness/soft-delete`,
        {
          soft_delete: true,
          type:"All"
        },
        {
          headers: {
            Authorization: `Bearer ${getNewToken()}`,
          },
        }
        );
        if (isDeleted.data.partenerStatus.acknowledged === true) {
          toast.success("All Business Partner Deleted Successfully");
          localStorage.setItem("buss_Delete"," ");
        } else {
          toast.error("Failed to Trashed");
        }
        navigate("/admin/bussiness_trash");
      } 
      else if (result === "id") {
        // Move selected customers to trash
        const store = JSON.parse(localStorage.getItem("buss_checked"));
        try {
          
          await store.map((e) =>
          axios.put( `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/buisness/soft-delete`,
          {
            soft_delete: true,
            type:"id",
            id:e._id
          },
          {
            headers: {
              Authorization: `Bearer ${getNewToken()}`,
            },
          }
            )
            )
        toast.success("Selected Partner Delete");
        navigate("/admin/bussiness_trash");
        localStorage.setItem("buss_Delete"," ");

      } catch (error) {
        toast.error("Failed to  Delete Partner");
      }
    }else{
      toast.warning("Please Select Any Input")
    }
  };


  // const userID = localStorage.getItem('client');
  // const users = JSON.parse(userID);
  // const userIds = users?.user._id;


  // const userStatus = (userIds) => {
  //     axios
  //         .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getuser/${userIds}`)
  //         .then((response)=>{
  //             let users = response.data.user?.status
  //             if(users === false){
  //                 window.localStorage.removeItem('client');
  //                 navigate('/admin');
  //                 toast.warning("Your account has been deactivated");
  //         }
  //         })
  //         .catch((error) => console.error(`Error: ${error}`));
  //     }

  // useEffect(()=>{
  //     userStatus(userIds);
  // } ,[userIds])



  return (
    <div className="p-3 p-lg-4 p-xl-5">
      <h1 className="font20 mb-4 text-dark">Business Partner</h1>
      <Container fluid className="bg-white p-3 rounded shadow">
        <Row className="align-items-center">
        
            <Col md={3}  className="remove_width" >
              <div  style={{ maxWidth: "214px" }}>
                <div className="d-flex align-items-center searchWrapper w-100 ms-0">
                  <Form.Control
                    type="text"
                    className="border-0 searchInput"
                    placeholder="Search"
                    onChange={({ currentTarget: input }) =>
                      handleSearch(input.value)
                    }
                  />
                  <Button
                    variant="primary"
                    title="Click here to search"
                    className="searchBtn d-flex justify-content-center align-items-center"
                  >
                    <img
                      src="/assets/images/search.png"
                      className="img-fluid"
                      alt=""
                    />
                  </Button>
                </div>
              </div>
            </Col>
            <Col md={9} className="button_div d-md-flex align-items-center justify-content-end" >
            <div className="mt-2 mt-lg-1 pe-1">
              {/* <div className="d-flex"> */}
              <Button
                variant="primary"
                type="button"
                className="fullRounded px-4 py-2 fw-bold font12"
                onClick={() => {
                  navigate("/admin/businesspartners/add");
                }}
              >
                <span className="me-2">
                <BiPlusMedical />
                </span>
                <span>Add Business Partner</span>
              </Button>
              {/* </div> */}
            </div>
            

            </Col>
            {/* <Col className="mt-2" style={{ marginLeft: '10px' }}>
              <label htmlFor="rowsPerPage">Rows Per Page:</label>
              <div className="position-relative rows_per_page">
                <select
                  id="rowsPerPage"
                  
                  value={rowsPerPage}
                  onChange={handleRowsPerPageChange}
                  style={{ maxWidth: '102px' }}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </Col> */}

          
          <Col md={12} className="mt-4">
            <div className="table-scroll">
              <table
                className="table fontGilroy couponTable"
                style={{ verticalAlign: "middle" }}
              >
                <thead className="bgLightRed font14">
                  <tr>
                  <th className="text-center">
                      <div className="d-flex align-items-center">
                        <span className="me-1">Business Name</span>
                        {order === "ASC" ? (
                          <FaSortDown onClick={() => sorting("DSC")} />
                        ) : (
                          <FaSortUp onClick={() => sorting("ASC")} />
                        )}
                      </div>
                    </th>
                    <th className="text-center">
                      <div className="d-flex align-items-center">
                        <span className="me-1">Business Partner Type</span>
                        
                      </div>
                    </th>
                    <th className="text-center">
                      <div className="d-flex align-items-center">
                        <span className="me-1">Business Partner ID</span>
                        
                      </div>
                    </th>

                    
                    <th className="text-center">
                      <div className="d-flex align-items-center">
                        <span className="me-1" >Available Funds</span>
                        {creditOrder === "ASC" ? (
                          <FaSortDown onClick={() => creditSorting("DSC")} />
                        ) : (
                          <FaSortUp onClick={() => creditSorting("ASC")} />
                        )}
                      </div>
                    </th>

                                      
                    <th className="text-center">
                      <div className="d-flex align-items-center">
                        <span >Customers</span>
                        {customerOrder === "ASC" ? (
                          <FaSortDown onClick={() => customerSorting("DSC")} />
                        ) : (
                          <FaSortUp onClick={() => customerSorting("ASC")} />
                        )}
                      </div>
                    </th>
                       

                    <th className="text-center">

                      <div className="d-flex align-items-center">
                        <span className="me-1 ps-lg-6">Brands</span>
                        {brandOrder === "ASC" ? (
                          <FaSortDown onClick={() => brandSorting("DSC")} />
                        ) : (
                          <FaSortUp onClick={() => brandSorting("ASC")} />
                        )}
                      </div>
                    </th>
                  

                    <th >Status</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody className="font12 fw-medium">
                  {isLoading === true ? (
                      <tr style={{ backgroundColor: "#eee" }}>
                        <td colSpan="9">
                        <Bars
                        height="80"
                        width="100%"
                        margin='auto'
                        color="#FF7020"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        />
                        </td>
                      </tr>
                      ): (
                        <>
                        {noData ? <tr >
                          <td colSpan="9" className="text-center">
                          <h1 className="font16 text-dark" >No Business Partner</h1>
                          </td>
                        </tr>
                     :  
                    customerData.map((cust,i) =>
                    (

                      <tr key={cust._id}>
                        <td className="font14">

                          <span>
                            {cust.business_name}
                          </span>
                        </td>
                        <td className="font14">
                            {cust.business_partner_type}
                        </td>
                        <td className="font14">
                            {cust.business_partner_id}
                        </td>
      
                        {cust.payment_type === "Credit" ?
                         <td className="font14 ps-2">${Number(cust.available_balance).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td> :
                         <>
                         {cust.payment_type === "Funds" ?
                         <td className="font14 ps-2">${Number(cust.available_balance).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td> : <td className="font14 ps-1">$0.0</td>}
                         </>}
                        {/* <td className="font14 ps-1">${Number(cust.credit_amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td> */}
                        <td className="font14 ">
                          <Link to={`/admin/businesspartners/${cust._id}`}>
                            {cust.customer_count} Customers
                          </Link>
                        </td>
                        
                        <td className="font14 "  >{cust.brandsLength}  Brands</td>

                        <td className="font14">
                          <Form.Check
                            type="switch"
                            label={cust.status ? "Active" : "Inactive"}
                            className="switchLabel"
                            checked={cust.status}
                            onChange={(e) => {
                              handleStatus(cust._id, cust.status);
                            }}
                          />
                        </td>
                     

                        <td>
                          {role === "Admin" ? (
                            <DropdownButton
                              variant="white"
                              className="p-0  tableActionDropDown text-center"
                              title={
                                <div className="actionBtn">
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                </div>
                              }
                              drop="start"
                            >
                              <div style={{overflowY:"auto",maxHeight:"100px"}}>
                              <Dropdown.Item
                                className="font14 fw-medium"
                                style={customerData?.length === 1 ? {paddingTop:'0px'} : {}}
                                onClick={() => {
                                  navigate(
                                    `/admin/businesspartners/${cust._id}/edit`
                                  );
                                }}
                              >
                                BP Information
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="font14 fw-medium"
                                style={customerData?.length === 1 ? {paddingTop:'0px'} : {}}
                                onClick={() => {
                                  navigate(
                                    `/admin/businesspartners/brands/${cust._id}`
                                  );
                                }}
                              >
                               Brand List 
                              </Dropdown.Item>
                              <Dropdown.Item
                               style={customerData?.length === 1 ? {paddingTop:'0px'} : {}}
                                className="font14 fw-medium"
                                onClick={() => {
                                  navigate(
                                    `/admin/businesspartners/customize/${cust._id}`
                                  );
                                }}
                              >
                               Branding / Emails
                              </Dropdown.Item>
                              {cust.funds_type === "Primary" ? 
                              <Dropdown.Item
                                className="font14 fw-medium"
                                style={customerData?.length === 1 ? {paddingTop:'0px'} : {}}
                                onClick={() => {
                                  navigate(
                                    `/admin/businesspartners/payment-invoice/${cust.business_partner_id}`
                                  );
                                }}
                              >
                                Payment and Invoice
                              </Dropdown.Item> : null}
                              <Dropdown.Item
                               style={customerData?.length === 1 ? {paddingTop:'0px'}  : {}}
                                className="font14 fw-medium"
                                onClick={() => {
                                  navigate(
                                    `/admin/businesspartners/acounthistory/${cust.business_partner_id}`
                                  );
                                }}
                              >
                                Funding History
                              </Dropdown.Item>
                              <Dropdown.Item
                               style={customerData?.length === 1 ? {paddingTop:'0px'} : {}}
                                className="font14 fw-medium"
                                onClick={() => {
                                  navigate(
                                    `/admin/businesspartners/${cust._id}`
                                  );
                                }}
                              >
                                Customers
                              </Dropdown.Item>
                              <Dropdown.Item
                               style={customerData?.length === 1 ? {paddingTop:'0px'}  : {}}
                                className="font14 fw-medium"
                                onClick={() => {
                                  navigate(
                                    `/admin/businesspartners/monetary/${cust.business_partner_id}`
                                  );
                                }}
                              >
                                Monetary Transactions
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="font14 fw-medium"
                                style={customerData?.length === 1 ? {paddingTop:'0px'} : {}}
                                onClick={() => {
                                  navigate(
                                    `/admin/businesspartners/${cust._id}/addUser`
                                  );
                                }}
                              >
                                Users
                              </Dropdown.Item>
                              <Dropdown.Item
                               style={customerData?.length === 1 ? {paddingTop:'0px'} : {}}
                                className="font14 fw-medium"
                                onClick={() => {
                                  navigate(
                                    `/admin/businesspartners/apikey/${cust._id}`
                                  );
                                }}
                              >
                                Api Key
                              </Dropdown.Item>
                              <Dropdown.Item
                               style={customerData?.length === 1 ? {paddingTop:'0px'} : {}}
                                className="font14 fw-medium"
                                onClick={() => handleDeleteUser(cust._id,cust.business_partner_id)}

                              >
                                Delete
                              </Dropdown.Item>
                               </div>
                              </DropdownButton>
                          ) : (
                            <DropdownButton
                              variant="white"
                              className="p-0  tableActionDropDown text-center"
                              title={
                                <div className="actionBtn">
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                </div>
                              }
                              drop="start"
                            >
                              <div style={{overflowY:"auto",maxHeight:"100px"}}>
                              <Dropdown.Item
                               style={customerData?.length === 1 ? {paddingTop:'0px'} : {}}
                                className="font14 fw-medium"
                                onClick={() => {
                                  navigate(
                                    `/admin/businesspartners/${cust._id}/edit`
                                  );
                                }}
                              >
                                BP Information
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="font14 fw-medium"
                                style={customerData?.length === 1 ? {paddingTop:'0px'} : {}}
                                onClick={() => {
                                  navigate(
                                    `/admin/businesspartners/brands/${cust._id}`
                                  );
                                }}
                              >
                               Brand List 
                              </Dropdown.Item>
                              <Dropdown.Item
                               style={customerData?.length === 1 ? {paddingTop:'0px'} : {}}
                                className="font14 fw-medium"
                                onClick={() => {
                                  navigate(
                                    `/admin/businesspartners/customize/${cust._id}`
                                  );
                                }}
                              >
                               Branding / Emails
                              </Dropdown.Item>
                              <Dropdown.Item
                               style={customerData?.length === 1 ? {paddingTop:'0px'} : {}}
                                className="font14 fw-medium"
                                onClick={() => {
                                  navigate(
                                    `/admin/businesspartners/payment-invoice/${cust.business_partner_id}`
                                  );
                                }}
                              >
                                Payment and Invoice
                              </Dropdown.Item>
                              <Dropdown.Item
                               style={customerData?.length === 1 ? {paddingTop:'0px'} : {}}
                                className="font14 fw-medium"
                                onClick={() => {
                                  navigate(
                                    `/admin/businesspartners/${cust._id}`
                                  );
                                }}
                              >
                                Customers
                              </Dropdown.Item>
                              <Dropdown.Item
                               style={customerData?.length === 1 ? {paddingTop:'0px'} : {}}
                                className="font14 fw-medium"
                                onClick={() => {
                                  navigate(
                                    `/admin/businesspartners/monetary/${cust.business_partner_id}`
                                  );
                                }}
                              >
                                Monetary Transactions
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="font14 fw-medium"
                                style={customerData?.length === 1 ? {paddingTop:'0px'} : {}}
                                onClick={() => {
                                  navigate(
                                    `/admin/businesspartners/${cust._id}/addUser`
                                  );
                                }}
                              >
                                Users
                              </Dropdown.Item>
                              <Dropdown.Item
                               style={customerData?.length === 1 ? {paddingTop:'0px'} : {}}
                                className="font14 fw-medium"
                                onClick={() => {
                                  navigate(
                                    `/admin/businesspartners/apikey/${cust._id}`
                                  );
                                }}
                              >
                                Api Key
                              </Dropdown.Item>
                              <Dropdown.Item
                               style={customerData?.length === 1 ? {paddingTop:'0px'} : {}}
                                className="font14 fw-medium"
                                onClick={() => {
                                  handleDeleteUser(cust._id,cust.business_partner_id);
                                }}
                              >
                                Delete
                              </Dropdown.Item>
                              </div>
                            </DropdownButton>
                          )}
                        </td>
                      </tr>
                    )) 
                   }
                  </>
                  )}
                </tbody>
              </table>
            </div>
          </Col>

          <Col md={3} className="mt-3 mb-4">
              <label htmlFor="rowsPerPage">Rows Per Page:</label>
              <div className="position-relative rows_per_page">
                <select
                  id="rowsPerPage"
                  
                  value={rowsPerPage}
                  onChange={handleRowsPerPageChange}
                  style={{ maxWidth: '102px' }}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </Col>
          {
              numberOfPages === 0 ? "" :
              <Col md={6} className="mt-5 mb-4 text-center">
              {
                <ul className="custPagination d-flex justify-content-center list-unstyled font14">
                  {pageNumber === 0 ? (
                    ""
                  ) : (

                    <li onClick={prevPage}>Previous </li>

                  )}


                  {items}


                  {numberOfPages - 1 === pageNumber ? (
                    ""
                  ) : (
                    <li onClick={nextPage}>Next</li>
                  )}
                   <li className="font14"><input  style={{width:"25px",border:'1px solid #4C00EF',borderRadius:'4px',textAlign:'center'}} value={showPage?showPage:''} type="text" name="number" id="" onChange={(e)=> {pagenumberSearch(e.target.value)}}  ></input> <span style={{color:'#4C00EF'}}>/</span> {numberOfPages}</li>
                </ul>
              }
            </Col>
            }

        </Row>
      </Container>


      <Modal show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false} size="md" centered>
        <Modal.Body>
        <Col>
        <img className="modal-img"
            src="/assets/images/exclamation.svg"
            // className="img-fluid"
            alt=""
        />
        <br />
        <br />
        <h3 className="modal-text">Are you sure, you want to Delete?</h3>
        <br />
        <Row className="modal-row">
            <Col md ={1}></Col>
            <Col md={4}>
            <Button
              className="btn btn-danger danger-btn"
              onClick={() => {
                handleConfirmDelete(); 
              }}
            >
                    
                    <span><b>Delete</b></span>
                </Button>
            </Col>
            <Col className="middle-col" md ={2}></Col>
            <Col md={4}>
            <Button className="btn btn-success success-btn" onClick={handleCloseModal}>
                    <span><b>Cancel</b></span>
                </Button>
             </Col>
            <Col md ={1}></Col>

        </Row>
        <br />
        </Col>
        </Modal.Body>

  </Modal> 

    </div>
  );
};

export default Business;
