import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import { Row } from 'react-bootstrap';
import { Col, Form, Button } from 'react-bootstrap';
import { toast } from "react-toastify";
// import { DropdownButton } from 'react-bootstrap';
// import { Dropdown } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { FaSortUp, FaSortDown } from "react-icons/fa";
import { Bars } from "react-loader-spinner";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import axios from 'axios';
import styles from './customersByBusiness.module.css'
import { getNewPartnerToken, getNewToken } from '../config/cookie.config';

const CustomerByBusinessPartner = (props) => {
  
  let navigate = useNavigate();
  var token;
  var userrole;
  var usersid;
  const user = JSON.parse(window.localStorage.getItem("client"));
  let id;
  const businessPartner = JSON.parse( window.localStorage.getItem('business-client'));
  const params = useParams();
  if(props?.admin === true){
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      userrole = user.user.role;
      usersid = user.user._id
    } else {
      userrole = null;
      usersid = null;
      navigate("/admin");
    }
    id=params.id;
  }else{
    id=businessPartner?.user?._id;
  }
const [customerData, setCustomerData] = useState([]);
const [downloadCustomerData, setDownloadCustomerData] = useState([]);
  const [order, setOrder] = useState("ASC");
  const [emailorder, setEmailorder] = useState("ASC");
  const [partnerorder, setPartnerorder] = useState("ASC");
  const [fundorder, setFundorder] = useState("ASC");
  const [updateorder, setUpdateorder] = useState("ASC");
  const [sort, setSort] = useState("");
  const [search, setSearch] =useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(true); 
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [partnerId, setPartnerId] = useState(id);
  const [showPage,setShowPage] = useState(1);
  const [zero,setZero] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRowsPerPageChange = (event) => {
    const selectedRowsPerPage = event.target.value;
    setRowsPerPage(selectedRowsPerPage);
    getCustomerByBusinessPartner(partnerId, pageNumber, selectedRowsPerPage);
  };

    const getCustomerByBusinessPartner = (partnerId, pageNumber, pageSize) => {
      if (pageSize === undefined) {
        pageSize = 10
      }
      let para;
    if(props.admin === true){
      const user = JSON.parse(window.localStorage.getItem("client"));
      para="admin"
    if (user) {
      // token = user.token;
      token = getNewToken();
    } else {
      token = null;
    }
    }else{
      const user = JSON.parse(window.localStorage.getItem("business-client"));
      para="partner"
      if (user) {
        // token = user.token;
        token = getNewPartnerToken();
      } else {
        token = null;
      }
    }
        axios
            .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/${para}/customerpartner/${partnerId}?page=${pageNumber}&pageSize=${pageSize}&search=${search}&sort=${sort}&loginUserId=${usersid}`,{
              headers: {
                'Authorization' : `Bearer ${token}`
              }
          })
            .then((response) => {
              if(para == "admin") {
                if(response.data.message == "Please Login"){
                  localStorage.removeItem('client');
                  navigate('/admin');
                  toast.error("Session Expired Please Login Again")
                }
              } else {
                if(response.data.message == "Please Login"){
                  localStorage.removeItem('business-client');
                  navigate('/business-login');
                  toast.error("Session Expired Please Login Again")
                }
              }
              if(response.data.customers?.length < 1){
                setZero(true);
              }else{
                setZero(false);
                const totalPages = response.data.totalPages;
                setNumberOfPages(totalPages);
                const allcustomers = response.data.customers;
                setCustomerData(allcustomers);
              }
              })
            .catch((error) => console.error(`Error: ${error}`));
    }

    const downloadCustomers = (partnerId) => {
      let para;
    if(props.admin === true){
      const user = JSON.parse(window.localStorage.getItem("client"));
      para="admin"
    if (user) {
      // token = user.token;
      token = getNewToken();
    } else {
      token = null;
    }
    }else{
      const user = JSON.parse(window.localStorage.getItem("business-client"));
      para="partner"
      if (user) {
        // token = user.token;
        token = getNewPartnerToken();
      } else {
        token = null;
    }
    }
      axios
          .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/${para}/pdfcsvdownload/${partnerId}`,{
            headers: {
              'Authorization' : `Bearer ${token}`
            }
        })
          .then((response) => {
            const allCustomerDownload = response.data.customersDownload;
            
            setDownloadCustomerData(allCustomerDownload);
          })
    }

    const pagenumberSearch = (e)=>{
      var pages =parseInt(e) ;
      var page= pages -1;
      if(pages<1){
        setPageNumber(0);
        setShowPage(1);
      }else if(pages>numberOfPages){
        setPageNumber(numberOfPages-1);
        setShowPage(numberOfPages);
      }else if(pages>=1 && pages<=numberOfPages){
        setPageNumber(page);
        setShowPage(pages);
      }
      else{
        setPageNumber(0);
        setShowPage(pages);
      }
      }
  
  
    //for pdf

    const getCustomerspdf = () => {

    
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);
    const headers = [
      ["Name", "Email", "Currency Type", "Available funds", "Contact", "Status"]];

      doc.setFontSize(20)
      doc.setFont("times");
      doc.text(30, 30, `Customers list of ${downloadCustomerData[0]?.business_partner}`)
      let info = []
      downloadCustomerData?.forEach((element) => {
        var name = `${element.first_name} ${element.last_name}`
        var balance = `$ ${Number(element.balance).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
        var status = element.status === true ? element.status = "Active" : element.status = "Inactive";
        var currency = "USD";
        info.push([name, element.email, currency, balance, element.phone, status])
    })
    let content = {
      startY: 50,
      head: headers,
      headStyles: { fillColor: "#fff3ed" },
      styles: { textColor: [10, 10, 10] },
      body: info,
      margin: { top: 10, right: 20, bottom: 0, left: 20 }

    };

    doc.autoTable(content);
    doc.save(`${downloadCustomerData[0]?.business_partner}.pdf`);

    }

     
    const headers = [
      "Name",
      "Email",
      "Currency Type",
      "Available Funds",
      "Contact",
      "Status"
    ];
    let infos  = [];
    
      downloadCustomerData?.forEach((element) => {
        var name = `${element.first_name} ${element.last_name}`
        var balance = `$ ${Number(element.balance).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
        let status = element.status === false ?  "Inactive" :  "Active";
        var currency = "USD";
        infos.push([name, element.email, currency, balance, element.phone, status])
    })

 
const csvReport = {
  data: infos,
  headers: headers,
  filename: `${downloadCustomerData[0]?.business_partner}.csv`
};
    


    //  search
  const handleSearch = (value) => {
    setSearch(value);
    setPageNumber(0);
    setShowPage(1);
  };
      // name
  const sorting = (order) => {
    if (order === "DSC") {
      setSort("dscName");

      setOrder("DSC");
    } else {
      setSort("ascName");

      setOrder("ASC");
    }
  };

  // email
  const emailsorting = (emailorder) => {
    if (emailorder === "DSC") {
      setSort("dscEmail");

      setEmailorder("DSC");
    } else {
      setSort("ascEmail");

      setEmailorder("ASC");
    }
  };

  // partner

  const partnersorting = (partnerorder) => {
    if (partnerorder === "DSC") {
      setSort("dscPartner");

      setPartnerorder("DSC");
    } else {
      setSort("ascPartner");

      setPartnerorder("ASC");
    }
  };

  // partner

  // availablefund
  const fundsorting = (fundorder) => {
    if (fundorder === "DSC") {
      setSort("dscFund");

      setFundorder("DSC");
    } else {
      setSort("ascFund");

      setFundorder("ASC");
    }
  };

  const updatesorting = (updateorder) => {
    if (updateorder === "DSC") {
      setSort("dscUpdate");

      setUpdateorder("DSC");
    } else {
      setSort("ascUpdate");

      setUpdateorder("ASC");
    }
  };

  
  


    function handleStatus(id, status,email,business_partner_id) {
        if (status === false) {
          status = true;
        } else {
          status = false;
        }
        let para;
    if(props.admin === true){
      const user = JSON.parse(window.localStorage.getItem("client"));
      para="admin"
    if (user) {
      // token = user.token;
      token = getNewToken();
    } else {
      token = null;
    }
    }else{
      const user = JSON.parse(window.localStorage.getItem("business-client"));
      para="partner"
      if (user) {
        // token = user.token;
        token = getNewPartnerToken();
      } else {
        token = null;
      }
    }
        
        axios
          .post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/${para}/customer/status`, {
            id,
            status,
            email,
            business_partner_id
            
          }, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          },)
          .then((response) => {
            if(response.data.message === "Not enough funds to activate account"){
            // toast(response.data.message);
            toast.warning(
              <div>
                <p><b>Insufficient funds !</b></p><br/>
                <p>Please contact your business partner</p>
              </div>
            )
          }else if(response.data.message === "Please provide balance in business partner account"){
            toast.warning(response.data.message);
          }else{
            toast.success(response.data.message);
            getCustomerByBusinessPartner(partnerId, pageNumber, rowsPerPage)
          }
          })
          .catch((error) => console.error(`Error: ${error}`));
        }
        
        useEffect(() => {
          setIsLoading(true);
          getCustomerByBusinessPartner(partnerId,pageNumber, rowsPerPage);
          downloadCustomers(partnerId);
          setTimeout(() => {
            
            setIsLoading(false);
          }, 600);
        }, [pageNumber, search, sort]);
        
      let items = [];
      let leftSide = pageNumber - 1;
    
      if (leftSide <= 0) leftSide = 0;
      let rightSide = pageNumber + 2;
    
      if (rightSide > numberOfPages) rightSide = numberOfPages;
      for (let number = leftSide; number < rightSide; number++) {
    
        items.push(
          <li key={number} className={(number === pageNumber ? "active" : "")} onClick={() => { setPageNumber(number);setShowPage(number+1) }}>
            {number + 1}
          </li>,
        );
      }

      const nextPage = () => {
        if (pageNumber < numberOfPages) {
          setPageNumber(pageNumber + 1);
          setShowPage(pageNumber +2);
        }
      }
    
      const prevPage = () => {
        if (pageNumber > 0) {
          setPageNumber(pageNumber - 1);
          setShowPage(pageNumber);
        }
      }

  return (
    <div className="p-3 p-lg-4 p-xl-5">
       {props?.admin ? <div className="d-flex mb-4 align-items-center">
          <Link
          onClick={()=>navigate(-1)}
            to="/admin/businesspartners"
            className="p-0 btn btnOrangeTransparent me-3 fw-semibold"
            variant="white"
          >
            <span className="me-2 fw-semibold">
              <img
                src="/assets/images/back-org.png"
                className="img-fluid"
                alt=""
              />
            </span>
            <span>Back</span>
          </Link>
      <h1 className="font20 text-dark">Customers</h1>
        </div>:
        <div className="d-flex mb-4 align-items-center">
    <h1 className="font20 text-dark">Customers</h1>
      </div>}
      {/* <div className="p-5"> */}
        {/* <h1 className="font20 mb-4 text-dark">Customers</h1> */}
        <Container fluid className="bg-white p-3 rounded shadow">
        <Row className="align-items-center">
          <Container>
            <Row>
              <Col xl={5} xxl={6} lg={4} className="mt-1">
                <div className="textGray font12">

                  <div style={{ maxWidth: "214px" }}>
                    <div className="d-flex align-items-center searchWrapper w-100 ms-0">
                    <Form.Control
                        type="text"
                        className="border-0 searchInput"
                        placeholder="Search"
                        onChange={({ currentTarget: input }) =>
                          handleSearch(input.value)
                        }
                      />
                      <Button
                        variant="primary"
                        title="Click here to search"
                        className="searchBtn d-flex justify-content-center align-items-center"
                      >
                        <img
                          src="/assets/images/search.png"
                          className="img-fluid"
                          alt=""
                        />
                      </Button>
                    </div>
                  </div>

                </div>
              </Col>
              {/* <Col lg={{ span: 3, offset: 1 }} className="mt-1" > */}
              <Col xl={7} xxl={6} lg={8} className="mt-1" >
                <Row className={styles.breakBtn1} >
                <Col lg={4} className="mt-1 ps-3 ps-lg-0"  style={{width:"163.75px"}}>
                {zero ? <Button
                      variant="primary"
                      className="fullRounded px-4 px-lg-3 px-xl-4 py-2 fw-bold font12"
                      style={{width:"153.75px"}}
                      disabled
                    >
                      <span>Download CSV</span>
                    </Button> 
                    :
                     <CSVLink style={{color : "white"}} {...csvReport}>
                  <Button
                      variant="primary"
                      className="fullRounded px-4 px-lg-3 px-xl-4 py-2 fw-bold font12"
                      style={{width:"153.75px"}}
                    >
                      <span>Download CSV</span>
                    </Button>
                    </CSVLink>}
                  </Col>
                  <Col lg={4} className="mt-1 ps-3 ps-lg-0" style={{width:"163.75px"}} >
                    <Button
                      variant="primary"
                      className="fullRounded px-4 px-lg-3 px-xl-4 py-2 fw-bold font12"
                      onClick={getCustomerspdf}
                      style={{width:"153.75px"}}
                      disabled={zero}
                    >
                      <span>Download PDF</span>
                      {/* <CSVLink style={{color : "white"}} data={customerData}> Download CSV</CSVLink> */}
                    </Button>
                    </Col>
                </Row>
              </Col>
            </Row>
          </Container>
            <Col md={12} className="mt-4">
              <div className="table-responsive">
                <table  className="table fontGilroy"
                style={{ verticalAlign: "middle" }}>
                <thead className="bgLightRed font14">
                  <tr>
                    <th className="text-center">
                      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="me-1">Name</span>
                        {order === "ASC" ? (
                          <FaSortDown onClick={() => sorting("DSC")} />
                        ) : (
                          <FaSortUp onClick={() => sorting("ASC")} />
                        )}
                      </div>
                    </th>
                    <th className="text-center">
                      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="me-1"> Email
                          {emailorder === "ASC" ? (
                            <FaSortDown onClick={() => emailsorting("DSC")} />
                          ) : (
                            <FaSortUp onClick={() => emailsorting("ASC")} />
                          )}
                        </span>

                      </div>
                    </th>
                    <th className="text-center">
                      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="me-1"> Partner</span>
                        {partnerorder === "ASC" ? (
                          <FaSortDown onClick={() => partnersorting("DSC")} />
                        ) : (
                          <FaSortUp onClick={() => partnersorting("ASC")} />
                        )}
                      </div>
                    </th>

                    <th className="text-center">
                      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="me-1">Available Funds</span>
                        {fundorder === "ASC" ? (
                          <FaSortDown onClick={() => fundsorting("DSC")} />
                        ) : (
                          <FaSortUp onClick={() => fundsorting("ASC")} />
                        )}
                      </div>
                    </th>

                    <th className="text-center">
                      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="me-1">Updated At</span>
                        {updateorder === "ASC" ? (
                          <FaSortDown onClick={() => updatesorting("DSC")} />
                        ) : (
                          <FaSortUp onClick={() => updatesorting("ASC")} />
                        )}
                      </div>
                    </th>
                    <th className="text-center">
                      <div className="d-flex align-items-center">
                        <span className="me-1"> Status</span>
                      </div>
                    </th>
                    <th className='text-center'></th>                          
                    {/* <th className="text-end">Action</th> */}
                  </tr>
                </thead>
                  <tbody className="font14 fw-medium">
                  {isLoading === true ? (
                    <tr style={{ backgroundColor: "#eee" }}>
                    <td colSpan="8">
                    <Bars
                    height="80"
                    width="100%"
                    margin='auto'
                    color="#FF7020"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    />
                    </td>
                  </tr>
                  ) : 
                  (
                  <> 

                    {zero? <tr >
                      <td colSpan="9" className="text-center">
                      <h1 className="font16 text-dark" >No Customers</h1>
                      </td>
                    </tr>:
                    customerData?.map((cust) => (
                      <tr key={cust._id}>
                      <td className="font14 fw-medium">
                        {cust.first_name} {cust.last_name}
                      </td>
                      <td className="font14 fw-medium">
                        {/* <Link to={`/admin/customers/${cust._id}/edit`}> */}
                          {cust.email} {cust.id}
                        {/* </Link> */}
                      </td>
                      <td className="font14 fw-medium">{cust.business_partner}</td>
                      <td className="font14 fw-medium ps-xxl-5 ps-4">${Number(cust.balance).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td>
                      <td className="font14 fw-medium">{cust.updatedAt.slice(0, 16).replace('T', '      ')}</td>
                      <td className="font14 fw-medium">
                        <Form.Check
                          type="switch"
                          label={cust.status ? "Active" : "Inactive"}
                          className="switchLabel"
                          checked={cust.status}
                          onChange={(e) => {
                            handleStatus(cust._id, cust.status, cust.email, cust.business_partner_id);
                          }}
                        />
                      </td>
                      <td className="font14 fw-medium">
                          <div className="transactionLabel d-inline-flex align-items-center">
                            <div className="me-2">
                              <img
                                src="/assets/images/transcation.png"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                            <div>
                              <Link to={`/admin/transaction/${cust._id}`}  >
                                Transaction
                              </Link>
                            </div>
                          </div>
                        </td>
                    </tr>
                    ))
                        }
                        </>
                    )}
                  </tbody>
                </table>
              </div>
            </Col>
             <Col md={3} className="mt-3 mb-4">
             <label htmlFor="rowsPerPage">Rows Per Page:</label>
              <div className="position-relative rows_per_page">
                <select
                  id="rowsPerPage"
                  
                  value={rowsPerPage}
                  onChange={handleRowsPerPageChange}
                  style={{ maxWidth: '102px' }}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </Col>
            <Col md={6} className="mt-5 mb-4 text-center" >
            {customerData.length > 0 ?
              <ul className="custPagination d-flex justify-content-center list-unstyled font14">


                {pageNumber === 0 ? (
                  ""
                ) : (

                  <li onClick={prevPage}>Previous </li>

                )}


                {items}


                {numberOfPages - 1 === pageNumber ? (
                  ""
                  ) : (
                    <li onClick={nextPage}>Next</li>
                    )}
                  <li className="font14"><input  style={{width:"25px",border:'1px solid #4C00EF',borderRadius:'4px',textAlign:'center'}} value={showPage?showPage:''} type="text" name="number" id="" onChange={(e)=> {pagenumberSearch(e.target.value)}}  ></input> <span style={{color:'#4C00EF'}}>/</span> {numberOfPages}</li>
              </ul> :""
            }
          </Col>
          </Row>
        </Container>
      </div>
  )
}


export default CustomerByBusinessPartner ;