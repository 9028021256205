import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Button, Container, DropdownButton, Dropdown } from 'react-bootstrap';
import { Field, Formik } from 'formik';
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { Bars } from "react-loader-spinner";
import { FaSortDown, FaSortUp } from 'react-icons/fa';
import { getNewToken } from '../config/cookie.config';


const PaymentAndInvoice = () => {

    const { id } = useParams();
    let token;
    let userId;
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
        token = user.token;
        userId = user.user._id
    } else {
        token = null;
        userId = null;
    }
    
    const [credit_amount, setCredit_amount] = useState();
    const [debit_amount, setDebit_amount] = useState();
    const [payment_type, setPayment_type] = useState("");
    const [amountShow, setAmountShow] = useState(false);
    const [availableBalance, setAvailableBalance] = useState('');
    const [availableBalance1, setAvailableBalance1] = useState('');
    const [valueType, setValueType] = useState('');
    const [amount, setAmount] = useState();
    const [paymentData, setPaymentData] = useState([]);
    const [addOrDeductClicked, setAddOrDeductClicked] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isSubmit, setIsSubmit] = useState(false);
    const [buttonClicked, setButtonClicked] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [showPage, setShowPage] = useState(1);
    const [sort, setSort] = useState("");
    const [dateSort, setdateSort] = useState("ASC");
    const [amountSort, setAmountSort] = useState("ASC");
    const [thresholdAmount, setThresholdAmount] = useState('');
    const [isAPiThreshold, setIsAPiThreshold] = useState(false);
    const [err, setErr] = useState(false);
    const [errMessage, setErrMessage] = useState('');

    const handleInputChange = (e) => {
        const { value } = e.target;
        
         const validNumber = /^\d*\.?\d{0,2}$/;

        if (validNumber.test(value)) {
            setThresholdAmount(value);
            setErr(false);
        } else {
            setErr(true);
            setErrMessage('Invalid Threshold format')
        }
    };
    let amounts;

    const formikInitialValue = {
        payment_type,
        credit_amount,
        debit_amount,
    };

    const formSchema = Yup.object().shape({
        payment_type: Yup.string()
            .min(3, "Minimum 3 digits required for payment_type Amount")
            .max(10, "Maximum 10 digits allowed for payment_type Amount"),
    });

    
    // Validation schema
    const validationSchema = Yup.object().shape({
        marginAmount: Yup.string()
            .matches(/^\d+(\.\d{2})?$/, 'Invalid Threshold format')
            .required('Threshold amount is required'),
    });

    const handleamt = (value, valueType) => {
        if (valueType === "Deduct") {
            if (payment_type === "Credit" && parseFloat(availableBalance) >= Number(value)) {
                setButtonClicked(false)
            } else if (payment_type === "Funds" && Number(availableBalance1) <= Number(value)) {
                setButtonClicked(false)
            }
        }
        if (value === "" || value === undefined) {
            setButtonClicked(false)
        }
        let input = value?.replace(/[eE-]/g, '');
        const parts = input?.split('.');
        if (parts?.length > 1) {
            const decimalPart = parts[1]?.slice(0, 2);
            input = parts[0] + (decimalPart?.length > 0 ? '.' + decimalPart : '');
        }
        setAmount(input);
    }


    const dateSorting = (date) => {
        if (date === "DSC") {
            setSort('dscDate')
            setdateSort('DSC')
        } else {
            setSort('ascDate')
            setdateSort('ASC')
        }
    };
    const amountSorting = (amount) => {
        if (amount === "DSC") {
            setSort('dscAmount')
            setAmountSort('DSC')
        } else {
            setSort('ascAmount')
            setAmountSort('ASC')
        }
    }


    const handleSubmitForm = async (data) => {
        setIsSubmit(true);
        if (data.payment_type === "") {
            toast.warn("Please select a payment type");
            setIsSubmit(false);
            return;
        }
      
        if (data.payment_type === "Credit") {
            amounts = amount
            data.credit_amount = amount
           
        }
        if (data.payment_type === "Funds") {
            amounts = amount;
            data.debit_amount = amount;
           
        }

        const payload = {
            amount: amounts,
            business_partner_id: id,
            value_type: valueType,
            ...data
        }
        axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/addfunds`, payload, {
            headers: {
                'Authorization': `Bearer ${getNewToken()}`
            }
        }).then((res) => {
            setButtonClicked(false);
            setValueType("")
            setAmount("")
            setAvailableBalance("")
            setAvailableBalance1("")
            setAddOrDeductClicked(false)
            getPaymentData()
            setTimeout(() => {
                setIsSubmit(false);
            }, 1000);
        }).catch((err) => {
            console.log(err);
            setButtonClicked(false);
            setAddOrDeductClicked(false);
            setAvailableBalance("")
            setAvailableBalance1("")
            setValueType("")
            setAmount("")
            getPaymentData()
            setTimeout(() => {  
                setIsSubmit(false);
            }, 1000);
        })
    };


    const handleStatusChange = async (funds_id, business_partner_id, new_status) => {
        setIsSubmit(true)
        const payload = {
            business_partner_id,
            new_status,
            funds_id,
            loginUserId: userId
        }
        axios
            .post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/funds/status`, payload, {
                headers: {
                    'Authorization': `Bearer ${getNewToken()}`
                }
            })
            .then((res) => {
                if (res?.data?.status === "failed") {
                    toast.warn(res?.data?.message)
                }
                setAvailableBalance("")
                setAvailableBalance1("")
                setTimeout(() => {
                setIsSubmit(false);
                getPaymentData()
                }, 3000);
            })
            .catch((err) => {
                console.log(err);
                setAvailableBalance("")
                setAvailableBalance1("")
                setIsSubmit(false);
                getPaymentData()
            })

    }

    const getPaymentData = (pageNumber) => {
        axios
            .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getfunds/${id}?page=${pageNumber}&sort=${sort}`, {
                headers: {
                    'Authorization': `Bearer ${getNewToken()}`
                }
            })
            .then((res) => {
                setPaymentData(res.data.data)
                const totalPages = res.data.totalPages;
                setNumberOfPages(totalPages);
                setThresholdAmount(res.data.business.funds_reminder)
                if (res.data.business.funds_reminder.length > 0 && res.data.business.funds_reminder !== "0") {
                    setIsAPiThreshold(true);
                } else {
                    setIsAPiThreshold(false);
                }
                if (res.data.business.payment_type === "Credit") {
                    let roundedBal = Number(res.data.business.available_balance)?.toFixed(2);
                    setAvailableBalance(roundedBal);
                }
                if (res.data.business.payment_type === "Funds") {
                    let roundedBal1 = Number(res.data.business.available_balance)?.toFixed(2);
                    setAvailableBalance1(roundedBal1);
                }
                const roundedDebitamt = Number(res.data.business.debit_amount)?.toFixed(2);
                const roundedCreditamt = Number(res.data.business.credit_amount)?.toFixed(2);
                setCredit_amount(roundedCreditamt);
                setDebit_amount(roundedDebitamt);
                setPayment_type(res.data.business.payment_type);
            })
            .catch((err) => {
                console.error(err);
            });
    }

    useEffect(() => {
        getPaymentData(pageNumber)
    }, [pageNumber, sort])

    let items = [];
    let leftSide = pageNumber - 1;

    if (leftSide <= 0) leftSide = 0;
    let rightSide = pageNumber + 2;

    if (rightSide > numberOfPages) rightSide = numberOfPages;
    for (let number = leftSide; number < rightSide; number++) {

        items.push(
            <li key={number} className={(number === pageNumber ? "active" : "")} onClick={() => { setPageNumber(number); setShowPage(number + 1) }}>
                {number + 1}
            </li>,
        );
    }

    const pagenumberSearch = (e) => {
        var pages = parseInt(e);
        var page = pages - 1;
        if (pages < 1) {
            setPageNumber(0);
            setShowPage(1);
        } else if (pages > numberOfPages) {
            setPageNumber(numberOfPages - 1);
            setShowPage(numberOfPages);
        } else if (pages >= 1 && pages <= numberOfPages) {
            setPageNumber(page);
            setShowPage(pages);
        }
        else {
            setPageNumber(0);
            setShowPage(pages);
        }
    }

    const nextPage = () => {
        if (pageNumber < numberOfPages) {
            setPageNumber(pageNumber + 1);
            setShowPage(pageNumber + 2);
        }
    }

    const prevPage = () => {
        if (pageNumber > 0) {
            setPageNumber(pageNumber - 1)
            setShowPage(pageNumber);
        }
    }



    const handleSave = () => {
        validationSchema
            .validate({ marginAmount: thresholdAmount })
            .then(() => {
                setErrMessage('');
                axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/set-reminder/${id}`, { amount: thresholdAmount })
                    .then(response => {
                        toast.success("Threshold set successfully");
                        getPaymentData()
                    })
                    .catch(error => {
                        toast.error("Error saving threshold")
                        console.error('Error saving threshold:', error);
                    });
            })
            .catch((err) => {
                setErrMessage(err.message);
            });
    };

    const handleWithdrawReminder = () => {
        axios.delete(` ${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/delete-reminder/${id}`, {
            headers: {
                'Authorization': `Bearer ${getNewToken()}`
            }
        }).then((response) => {
            if (response.data.status === "success") {
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message);
            }
            getPaymentData()
        }).catch((error) => {
            console.log(`Error : ${error}`);
        })
    };

    


    return (
        <div className="p-3 p-lg-5">
            <div className="d-flex mb-4 align-items-center">
                <Link
                    to="/admin/businesspartners"
                    className="p-0 btn btnOrangeTransparent me-3 fw-semibold"
                    variant="white"
                >
                    <span className="me-2 fw-semibold">
                        <img
                            src="/assets/images/back-org.png"
                            className="img-fluid"
                            alt=""
                        />
                    </span>
                    <span>Back</span>
                </Link>
                <h1 className="font20 text-dark">Payment and Invoice</h1>
            </div>
            <Container fluid className="mt-5 bg-white p-4 rounded shadow">
                {isSubmit ? (

                    <Bars
                        height="80"
                        width="100%"
                        margin="auto"
                        color="#FF7020"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />

                ) :
                    <>
                        <Formik
                            enableReinitialize={true}
                            initialValues={formikInitialValue}
                            validationSchema={formSchema}
                            onSubmit={(values, { setSubmitting }) => {
                                handleSubmitForm(values);
                                setSubmitting(false);
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                            }) => (
                                <Form
                                    className="p-4 rounded containerShadow"
                                    onSubmit={handleSubmit}
                                >
                                    <h2 className="font16 mb-2 text-dark">Add Payment</h2>
                                    <>
                                        <Row className='mt-2 py-2'>
                                            <Col md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label className="font12 text-dark fw-medium">
                                                        Payment Type{' '}
                                                        <span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <Field as='select' className="font12 w-100" name='payment_type' value={(values.payment_type)} onChange={handleChange}
                                                        onBlur={handleBlur} style={{ border: errors.payment_type && touched.payment_type ? '1px solid red' : '1px solid #cfc6c6', height: '31px', borderRadius: '5px' }}
                                                        isValid={touched.payment_type && !errors.payment_type}
                                                        isInvalid={!!errors.payment_type} >
                                                        <option value="" label="Select" />
                                                        <option value="Invoice" label="Invoice" />
                                                        <option value="Credit" label="Credit" />
                                                        <option value="Funds" label="Funds" />
                                                    </Field>
                                                    {errors.payment_type && touched.payment_type ? (
                                                        <div className="text-danger font12">
                                                            {errors.payment_type}
                                                        </div>
                                                    ) : null}
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}></Col>
                                            {values.payment_type === 'Credit' ? (
                                                <Col md={4}>
                                                    <Form.Group className="mb-3" controlId="formBasicText7">
                                                        {values.payment_type ? (
                                                            <Form.Label className="font12 text-dark fw-medium">
                                                                {values.payment_type}{' '}
                                                            </Form.Label>
                                                        ) : (
                                                            <Form.Label className="font12 text-dark fw-medium">
                                                                Credit{' '}
                                                            </Form.Label>
                                                        )}
                                                        <Form.Control
                                                            className="font12"
                                                            name="credit_amount"
                                                            value={
                                                                amountShow
                                                                    ? Number(availableBalance)?.toString()?.replace(
                                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                                        ','
                                                                    )
                                                                    : Number(availableBalance)?.toString()?.replace(
                                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                                        ','
                                                                    )
                                                            }
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            type="text"
                                                            placeholder="Enter credit amount"
                                                            isValid={touched.credit_amount && !errors.credit_amount}
                                                            isInvalid={!!errors.credit_amount}
                                                            disabled
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            ) : (
                                                <>
                                                    {values.payment_type === 'Funds' ? (
                                                        <Col md={4}>
                                                            <Form.Group className="mb-3" controlId="formBasicText7">
                                                                {values.payment_type ? (
                                                                    <Form.Label className="font12 text-dark fw-medium">
                                                                        {values.payment_type}{' '}
                                                                    </Form.Label>
                                                                ) : (
                                                                    <Form.Label className="font12 text-dark fw-medium">
                                                                        Funds{' '}
                                                                    </Form.Label>
                                                                )}
                                                                <Form.Control
                                                                    className="font12"
                                                                    name="debit_amount"
                                                                    disabled
                                                                    value={
                                                                        amountShow
                                                                            ? parseFloat(availableBalance1)
                                                                                .toFixed(2)
                                                                                .toString()
                                                                            : parseFloat(availableBalance1)
                                                                                .toFixed(2)
                                                                                .toString()
                                                                    }
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    type="number"
                                                                    placeholder="Enter funds amount"
                                                                    isValid={touched.debit_amount && !errors.debit_amount}
                                                                    isInvalid={!!errors.debit_amount}
                                                                />
                                                                {errors.debit_amount && touched.debit_amount ? (
                                                                    <div className="text-danger font12">
                                                                        {errors.debit_amount}
                                                                    </div>
                                                                ) : null}
                                                            </Form.Group>
                                                        </Col>
                                                    ) : (
                                                        <Col md={6}></Col>
                                                    )}
                                                </>
                                            )}
                                            <Col md={6}></Col>
                                            {values.payment_type !== "Invoice" && values.payment_type !== "" ?
                                                <Col md={6}>
                                                    <Form.Check
                                                        inline
                                                        label="Add"
                                                        value="Add"
                                                        name="path"
                                                        checked={valueType === 'Add'}
                                                        onChange={(e) => { setValueType(e.target.value); handleamt(amount, e.target.value); }}
                                                        type="radio"
                                                        className="text-dark labelControl"
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="Deduct"
                                                        value="Deduct"
                                                        name="path"
                                                        checked={valueType === 'Deduct'}
                                                        onChange={(e) => { setValueType(e.target.value); handleamt(amount, e.target.value); }}
                                                        type="radio"
                                                        className="text-dark labelControl"
                                                    />
                                                </Col> : null}
                                            <Col md={6}></Col>
                                            {valueType && values.payment_type !== "Invoice" && values.payment_type !== ""?
                                                <Col md={4}>
                                                    <Form.Group className="mb-3" controlId="formBasicText7">
                                                        <Form.Label className="font12 text-dark fw-medium">
                                                            {valueType} {values.payment_type}{' '}
                                                        </Form.Label>
                                                        <Form.Control
                                                            className="font12"
                                                            name="credit_amount"
                                                            value={amount}
                                                            // onChange={handleamt}
                                                            onChange={(e) => { handleamt(e.target.value, valueType); }}
                                                            type="number"
                                                            placeholder="Enter amount"
                                                        />
                                                    </Form.Group>
                                                </Col> : ''}
                                            {/* {valueType ? <Col md={3} className="mt-4">
                                                {values.payment_type === 'Invoice' || values.payment_type === '' ? (
                                                    <div></div>
                                                ) : (
                                                    <Button
                                                        onClick={() => {
                                                            setAmountShow(true);
                                                            setAddOrDeductClicked(true);
                                                            if (amount !== "" && amount !== undefined) {
                                                                setButtonClicked(true);
                                                            }
                                                            if (valueType === 'Add') {
                                                                if (values.payment_type === 'Credit') {
                                                                    if (+amount > 0) {
                                                                        setPayment_type('Credit');
                                                                        let a = +availableBalance + +amount;
                                                                        values.credit_amount = +Number(values.credit_amount) + +amount;
                                                                        setAvailableBalance(a);
                                                                        // setCreditShow(a);
                                                                        setCredit_amount(values.credit_amount);
                                                                    }
                                                                } else if (values.payment_type === 'Funds') {
                                                                    if (+amount > 0) {
                                                                        setPayment_type('Funds');
                                                                        let a = +availableBalance1 + +amount;
                                                                        values.debit_amount = +Number(values.debit_amount) + +amount;
                                                                        setAvailableBalance1(a);
                                                                        // setFundsShow(a);
                                                                        setDebit_amount(values.debit_amount);
                                                                    }
                                                                }
                                                            } else if (valueType === 'Deduct') {
                                                                if (values.payment_type === 'Credit') {
                                                                    if (+amount > 0) {
                                                                        setPayment_type('Credit');
                                                                        let a = +availableBalance - +amount;
                                                                        values.credit_amount = +values.credit_amount - +amount;
                                                                        if (a >= 0.0) {
                                                                            setAvailableBalance(a);
                                                                            // setCreditShow(a);
                                                                            setCredit_amount(values.credit_amount);
                                                                        } else {
                                                                            toast.warning('Invalid balance');
                                                                            return false;
                                                                        }
                                                                    }
                                                                } else if (values.payment_type === 'Funds') {
                                                                    if (+amount > 0) {
                                                                        setPayment_type('Funds');
                                                                        let a = +availableBalance1 - +amount;
                                                                        values.debit_amount = +values.debit_amount - +amount;
                                                                        if (a >= 0.0) {
                                                                            setAvailableBalance1(a);
                                                                            // setFundsShow(a);
                                                                            setDebit_amount(values.debit_amount);
                                                                        } else {
                                                                            toast.warning('Invalid balance');
                                                                            return false;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }}
                                                        style={{ width: '133px', height: '36px' }}
                                                        variant="primary"
                                                        className="px-4 py-2 fullRounded fw-bold shadowPrimary font12"
                                                        disabled={buttonClicked}
                                                    >
                                                        <span>{valueType}</span>
                                                    </Button>
                                                )}
                                            </Col> : ''} */}
                                        </Row>
                                        {valueType && values.payment_type !== "Invoice" && values.payment_type ? (
                                            <Col md={4}>
                                                <Form.Group className="mb-3" controlId="formReferenceId">
                                                    <Form.Label className="font12 text-dark fw-medium">Reference Id</Form.Label>
                                                    <Form.Control
                                                        className="font12"
                                                        name="reference_id"
                                                        value={values.reference_id}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        type="text"
                                                        placeholder="Enter reference ID"
                                                        isValid={touched.reference_id && !errors.reference_id}
                                                        isInvalid={!!errors.reference_id}
                                                    />
                                                    {errors.reference_id && touched.reference_id ? (
                                                        <div className="text-danger font12">
                                                            {errors.reference_id}
                                                        </div>
                                                    ) : null}
                                                </Form.Group>
                                            </Col>
                                        ) : null}
                                    </>
                                    <Row className="mt-4">
                                        <Col>
                                            <Button
                                                type="submit"
                                                variant="primary"
                                                className="px-4 py-2 fullRounded fw-bold shadowPrimary font12"
                                            >
                                                Save Changes
                                            </Button>
                                            {/* {errorMessage && <div className="text-danger">{errorMessage}</div>} */}
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </>}
            </Container>
            
            <Container fluid className="mt-5 bg-white p-4 rounded shadow">
                <div className="p-4 rounded containerShadow">
                    <h2 className="font16 mb-2 mt-2 text-dark">Funds Threshold Reminder</h2>
                    <Row className='g-3'>
                        <Col md={6}>
                            <Form.Group controlId="formBasicText7">
                                <Form.Label className="font12 text-dark fw-medium">Threshold Amount</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Threshold Amount"
                                    value={thresholdAmount}
                                    onChange={handleInputChange}
                                    isInvalid={err}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errMessage}
                                </Form.Control.Feedback>
                            </Form.Group>
                            {isAPiThreshold?
                            <div className="d-flex justify-content-md-end mt-3">
                                <Link className="fullRounded px-4 px-lg-3 px-xl-4 py-2 fw-bold font14 " style={{ minWidth: "140px", marginTop: "-10px" }} onClick={handleWithdrawReminder}>
                                    Withdraw Reminder
                                </Link>
                            </div>:null}
                        </Col>
                        <Col md={6} className="d-flex align-items-end justify-content-md-end mb-4">
                            <Button
                                type="submit"
                                variant="primary"
                                className="px-4 py-2 fullRounded fw-bold shadowPrimary font12"
                                onClick={handleSave}
                            >
                                Set Threshold
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Container>

            <Container fluid className="mt-5 bg-white p-4 rounded shadow">
                <>
                    <h2 className="font16 mb-2 text-dark">Payment List</h2>
                    <Row>
                        <Col md={12} className="mt-4">
                            <div className="table-responsive">
                                <table
                                    className="table couponTable table-borderless fontGilroy"
                                    style={{ verticalAlign: "middle" }}
                                >
                                    <thead className="bgLightRed font14 fw-fw-normal">
                                        <tr className="text-center">
                                            <th className="text-center" >
                                                <div style={{ cursor: 'pointer' }}>
                                                    <span className="me-1">Date
                                                        {/* {dateSort === "ASC" ? <FaSortDown onClick={() => dateSorting('DSC')} /> : <FaSortUp onClick={() => dateSorting('ASC')} />} */}
                                                    </span>
                                                </div>
                                            </th>
                                            <th className="text-center">Reference Number</th>
                                            <th className="text-center">Payment Number</th>
                                            <th className="text-center">Payment Type</th>
                                            {/* <th className="text-center">Value Type</th> */}
                                            <th className="text-center" >
                                                <div style={{ cursor: 'pointer' }}>
                                                    <span className="me-1">Amount
                                                        {/* {amountSort === "ASC" ? <FaSortDown onClick={() => amountSorting('DSC')} /> : <FaSortUp onClick={() => amountSorting('ASC')} />} */}
                                                    </span>
                                                </div>
                                            </th>
                                            <th className="text-center">Status</th>
                                            <th className="text-center">Invoice</th>
                                        </tr>
                                    </thead>
                                  <tbody className="font12 fw-regular text-center">
                                    {isSubmit ? (
                                        <tr style={{ backgroundColor: "#eee" }}>
                                            <td colSpan="9">
                                                <Bars
                                                    height="80"
                                                    width="100%"
                                                    margin="auto"
                                                    color="#FF7020"
                                                    ariaLabel="bars-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                />
                                            </td>
                                        </tr>
                                    ) :
                                            paymentData.map((cust) => (
                                                <tr key={cust._id}>
                                                    <td className="font14 fw-medium">{cust.dateInEst}</td>
                                                    <td className="font14 fw-medium">{cust.reference_id}</td>
                                                    <td className='font14 fw-medium'>
                                                    {cust?.invoice_number}
                                                    </td>
                                                    <td className="font14 fw-medium">{cust.payment_type}</td>
                                                    <td className="font14 fw-medium">
                                                        {cust.value_type === "Deduct" ? (
                                                            <span style={{ color: "red" }}>
                                                                -
                                                            </span>
                                                        ) : (
                                                            <span style={{ color: "green" }}>
                                                                +
                                                            </span>
                                                        )}

                                                        ${Number(cust.amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    </td>
                                                    <td className="font14 fw-medium">
                                                        {/* <Button
                                                          type="submit"
                                                          variant="primary"
                                                          className="px-4 py-2 fullRounded fw-bold shadowPrimary"
                                                          >
                                                        {cust.status === "Pending" && (
                                                            <DropdownButton
                                                                // variant="white"
                                                                className="p-0 tableActionDropDown text-center"
                                                                title={cust.status}
                                                                drop="start"
                                                            >
                                                                <div style={{ overflowY: "auto", maxHeight: "100px" }}>
                                                                    <Dropdown.Item
                                                                        className="font14 fw-medium"
                                                                        onClick={() => handleStatusChange(cust._id, cust.business_partner_id, "Completed")}
                                                                    >
                                                                        Completed
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item
                                                                        className="font14 fw-medium"
                                                                        onClick={() => handleStatusChange(cust._id, cust.business_partner_id, "Cancelled")}
                                                                    >
                                                                        Cancelled
                                                                    </Dropdown.Item>
                                                                </div>
                                                            </DropdownButton>
                                                        )}
                                                        {cust.status !== "Pending" && (
                                                            <span className="font14 fw-medium">{cust.status}</span>
                                                        )}
                                                        </Button> */}
                                                        {cust.status === "Pending" && (
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                                                    {cust.status}
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item onClick={() => handleStatusChange(cust._id, cust.business_partner_id, "Completed")}>
                                                                        Completed
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item onClick={() => handleStatusChange(cust._id, cust.business_partner_id, "Cancelled")}>
                                                                        Cancelled
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        )}
                                                        {cust.status !== "Pending" && (
                                                            <span className="font14 fw-medium">{cust.status}</span>
                                                        )}

                                                    </td>
                                                    <td className="font14 fw-medium">
                                                        {cust.invoice_file && (
                                                            <a href={cust.invoice_file} download target="_blank" rel="noopener noreferrer">
                                                                <i className="fa fa-download"></i>
                                                            </a>
                                                        )}
                                                    </td>

                                                    
                                                </tr>
                                            ))
                                            }
                                        </tbody>
                                </table>

                            </div>
                        </Col>
                        {
                            numberOfPages === 0 ? "" :
                                <Col md={12} className="mt-5 mb-4 text-center">
                                    {
                                        <ul className="custPagination d-flex justify-content-center list-unstyled font14">
                                            {pageNumber === 0 ? (
                                                ""
                                            ) : (
                                                <li onClick={prevPage}>Previous </li>
                                            )}
                                            {items}
                                            {numberOfPages - 1 === pageNumber ? (
                                                ""
                                            ) : (
                                                <li onClick={nextPage}>Next</li>
                                            )}
                                            <li className="font14"><input style={{ width: "25px", border: '1px solid #4C00EF', borderRadius: '4px', textAlign: 'center' }} value={showPage ? showPage : ''} type="text" name="number" id="" onChange={(e) => { pagenumberSearch(e.target.value) }}  ></input> <span style={{ color: '#4C00EF' }}>/</span> {numberOfPages}</li>
                                        </ul>
                                    }
                                </Col>
                        }
                    </Row>
                </>
            </Container>
        </div>
    );
};

export default PaymentAndInvoice;
