import { useRef, useState, useEffect } from "react";
import { Button, Col, Container, Row, Form } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import * as Yup from "yup";
import { Field, Formik } from "formik";
import { Bars } from "react-loader-spinner";
import Select from 'react-select';
import ModalOpen from "./BusinessBrandCategory";
import BusinessBrandCategoryss from './BusinessBrandCategoryss'
import { useDispatch, useSelector } from 'react-redux';
import {BiPlusMedical} from "react-icons/bi";
import { ClearhandleCategories } from "../../Redux/SendCategories/action";
import "../../../src/App.scss"
import { getBusinessContact } from '../../Redux/BusinessContacts/action';
import { getNewPartnerToken } from "../../config/cookie.config";

const BusinessBrands = () => {

  const showCategory = useSelector(state => state.sendCategorie_reducer.showCategory);
  const categoryName = useSelector((state)=>state.sendCategorie_reducer.category);
  const dispatch = useDispatch();
  const inputFileRef = useRef(null);
  const businessPartner = JSON.parse( window.localStorage.getItem('business-client'));
  const id = businessPartner?.user?._id;
  const [partner_id, setPartner_id] = useState(id);
  const [brand_id, setBarnd_id] = useState('');
  const user = JSON.parse( window.localStorage.getItem('business-client'));

  let userId;

  let navigate = useNavigate();
  if (user) {
    userId = user?.user?._id;
  } else {
    userId = null;
    localStorage.removeItem('business-client')
    navigate("/business-login");
  }

 
  const [isLoading, setIsLoading] = useState(true);
  const [internalPercentage,setInternalPercentage] = useState("")
  const [getbrand, setGetBrand] = useState([]);
  const [formValues, setFormValues] = useState([{ brand: "", bonus : "", internalPercentage : "", bonusType : "", bonusDiscount : "", id: partner_id}])
  const [cards, setCards] = useState("");
  const [brandId,setBrandId] =useState("");
  const [brand, setBrand] = useState("");
  const [bonus, setBonus] = useState("");
  const [discount, setDiscount] = useState("");
  const [bonusType,setBonusType] = useState("");
  const [customerData, setCustomerData] = useState([]);
  const [customerData1, setCustomerData1] = useState([]);
  const [disabled,setDisabled] = useState(false);
  const [countryData,setCountryData] = useState([]);
  const [delay,setDelay] = useState(false);
  // const [pageNumber,setPageNumber] = useState(1);
  const [paginate, setpaginate] = useState(20);
  const [length,setLength] = useState();
  const [loadMore,setLoadMore] = useState(false);
  const [deleteDelay,setDeleteDelay] = useState(false);
  const [noData,setNoData] = useState(false);
  const [searching,setSearching] = useState('');
  const [errorPhone,setErrorPhone] = useState();
  const [zero,setZero] = useState(false);
  const [sorts,setSorts] = useState("ASC");
  const [isError, setIsError] = useState(false);

  const [search, setSearch] = useState("")

  const [pageNumber, setPageNumber] = useState(1); 
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [showPage, setShowPage] = useState(1);

  const [pageSize, setPageSize] = useState(10);

  const [promotedCheck,setPromotedCheck] =useState(false);
  const [standoutCheck,setStandoutCheck] =useState(false);
  const [categories,setCategories] = useState([]);
  const [businessCategories, setBusinessCategories] = useState([]);
  const [addedBusinessType, setAddedBusinessType] =  useState("");
  const [businessPartnerType, setBusinessPartnerType] =  useState("");
  
  let dataa ;

  let handleBonusChangeForm = (e, index) => {
    e.target.value = "0";
    
    let newFormValues = [...formValues];
    newFormValues[index][e.target.name] = e.target.value;
  
    if (e.target.name === 'bonusPercentage') {
      newFormValues[index]['bonusDiscount'] = e.target.value;
    }
  
    setFormValues(newFormValues);
  }

  let handleChangeForm = (i, e) => {
      let newFormValues = [...formValues];
      newFormValues[i][e.target.name] = e.target.value;
      setFormValues(newFormValues);  
    }
  
  let addFormFields = () => {
      setFormValues([...formValues, { brand: "", bonus: "",internalPercentage:'', bonusType: "",id: partner_id}])
    }
  
  let removeFormFields = (i) => {
      let newFormValues = [...formValues];
      newFormValues.splice(i, 1);
      setFormValues(newFormValues)
  }
  let handleSubmit = async(event) => {
    setDelay(true);
    setTimeout(()=>{
     setDelay(false);
    },1800)
    var token;
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
      event.preventDefault();
      
     for(let i=0;i<formValues.length;i++){

      if (addedBusinessType === "Discount") {
        let newFormValues = [...formValues];
        newFormValues[i]['bonusType'] = "Discount percentage";
        setFormValues(newFormValues);
      }
      
      if( formValues[i].bonus === "" || formValues[i].bonusType === ""){
        var ip=0; 
        toast.warning("All Field  Required");
          return false;
        }
        else if(formValues[i].bonus < 50){
          toast.warning("Bonus cant be less than 50%")
          return false;
  
      }else if(formValues[i].bonus > 100){
        toast.warning("Maximum Bonus Must Be 100%");
        return false;
      }else if(formValues[i].bonusDiscount > 100){
        toast.warning("Maximum Discount Must Be 100%");
        return false;
      }else{
        ip=100-(+formValues[i].bonus);
        formValues[i].internalPercentage=ip;
      }
      // Ensure bonusDiscount is always set to "0"
    formValues[i].bonusDiscount = "0";
    formValues[i].bonusPercentage = "0";
     }
      
      if(formValues[0].bonus ){      
        setBarnd_id(formValues[0].brand)
        const { data } = await axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/bpbonus`,
          {
            formValues
  
          },{
            headers: {
              'Authorization': `Bearer ${getNewPartnerToken()}`
            }
          },
      );
        toast.success(data.message)  
        getdiscountbrandsstatus();  
      if(data.message === "Brand Added Successfully"){
        // setTimeout(()=>{
          // window.location.reload();
          // },1000)  
          
      }
  }
}


  useEffect(() => {
    if (id) {
      getdiscountbrandsstatus();
      getbrands();
     
    }
}, []);



var token;




  const getdiscountbrandsstatus = (a,check) => {
    // setIsLoading(true)
    const user = JSON.parse( window.localStorage.getItem('business-client'));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    if(dataa || categoryName.length>0 ){
      if(categoryName.length>0){
        dataa="";
      }
      axios
          .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/getbpbrandstatus/${id}?sort=${sorts}&search=${dataa}&category=${categoryName}`,{ 
        headers: {
            'Authorization' : `Bearer ${getNewPartnerToken()}`
          }
      })
        .then((response) => {  
          if(response.data.message == "Please Login"){
            localStorage.removeItem('business-client');
            navigate('/business-login');
            toast.error("Session Expired Please Login Again")
          }  
          setBusinessCategories(response.data.categories);  
         setSearching('')
         if(response.data.brands.length>=1){
          setNoData(false);
         }
         let arr = response.data.brands;
         arr.sort((a,b)=>{
          return (a.brandName-b.brandName)});
         setCustomerData(arr);
        //  if(check !== "check"){
        //    setCustomerData1(arr);
        //  }
         setLength(response.data.brands.length);
        if(a !== true){
          const totalPages = response.data.totalPages;
          setNumberOfPages(totalPages);
        }
      
      })
      .catch((error) => console.error(`Error: ${error}`));
      
      }else{
        axios
          .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/getbpbrandstatus/${id}?sort=${sorts}`,{
          headers: {
            'Authorization' : `Bearer ${getNewPartnerToken()}`
          }
      })
        .then((response) => {  
          setBusinessCategories(response?.data?.categories); 
          setAddedBusinessType(response?.data?.businessTypeOption) 
          setBusinessPartnerType(response?.data?.businessPartnerType) 
          setIsLoading(false)
        //  setSearching('')
         if(response.data.brands.length>=1){
          setNoData(false);
         }
         let arr = response.data.brands;
         arr.sort((a,b)=>{
          return (a.brandName-b.brandName)});
         setCustomerData(arr);
         if(check !== "check"){
          setCustomerData1(arr);
        }
         setLength(response.data.brands.length);
         if(a !== true){
          const totalPages = response.data.totalPages;
          setNumberOfPages(totalPages);
        }
         
      })
      .catch((error) => console.error(`Error: ${error}`));
      }
        };


        const handleCheckboxChange = (index) => {
          const updatedCategories = [...businessCategories];
          updatedCategories[index].checked = !updatedCategories[index].checked;
          setBusinessCategories(updatedCategories);
        };
      
        const handleSave = () => {
          const checkedCategories = businessCategories.filter((item) => item.checked);
          if (checkedCategories.length > 8) {
            getdiscountbrandsstatus();
              return toast.warn("Please select maximum 8 categories");
          }
          axios
            .post(
              `${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/add-categories`,
              {
                id,
                categories: businessCategories,
              }
            )
            .then((response) => {
              toast.success(response.data.message ?? "Categories updated successfully");
            })
            .catch((error) => {
              toast.error("Error updating categories");
              console.error("There was an error updating the categories:", error);
            });
        };


          // pagination


          let items = [];
          let leftSide = pageNumber - 1;
          
          if (leftSide <= 0) leftSide = 1; // Adjusted to start from 1 instead of 0
          let rightSide = pageNumber + 2;
          
          if (rightSide > numberOfPages) rightSide = numberOfPages;
          for (let number = leftSide; number <= rightSide; number++) { // Changed '<' to '<=' to include the last page
            items.push(
              <li
                key={number}
                className={number === pageNumber ? "active" : ""}
                onClick={() => {
                  setPageNumber(number);
                }}
              >
                {number}
              </li>
            );
          }
          const pagenumberSearch = (e) => {
            var pages = parseInt(e);
            var page = pages;
            if (pages < 1) {
              setPageNumber(1);
              setShowPage(1);
            } else if (pages > numberOfPages) {
              setPageNumber(numberOfPages);
              setShowPage(numberOfPages);
            } else if (pages >= 1 && pages <= numberOfPages) {
              setPageNumber(page);
              setShowPage(pages);
            } else {
              setPageNumber(1);
              setShowPage(pages);
            }
          };
        
        
          const nextPage = () => {
            if (pageNumber < numberOfPages) {
              setPageNumber(pageNumber + 1)
            }
          }
        
          const prevPage = () => {
            if (pageNumber > 0) {
              setPageNumber(pageNumber - 1)
            }
          }





  // pagination



  
  function handleStatus(status,bid) {
    var ip;
    const user = JSON.parse( window.localStorage.getItem('business-client'));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    if(status===true){
      if(bonus<50){
        toast.warning("Bonus cant be less than 50%")
        return;
      }else if(bonus>100){
        toast.warning("Bonus Must Be Maximum 100%")
        return;
      }
      else{
        ip=100-(+bonus);
        setInternalPercentage(ip);
      }
      
      axios
      .post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/bp/statusdata/${id}/${bid}`, {  
        status,  
        brandId,
        discount,
        bonus,
        bonusType,
        internalPercentage:ip,
        standoutCheck,
        promotedCheck
      }, {
        headers: {
          'Authorization': `Bearer ${getNewPartnerToken()}`
        }
      },)
      .then((response) => {
       if(response.data.message === "Bonus Must Be Maximum 100%" || response.data.message === "Maximum Bonus Upto Discount Value" || response.data.message === "Bonus Must Be Greater Than 1"){
        toast.success(response.data.message);
        return false;
       }else{
        toast.success(response.data.message);
        setBonus(response.data.brands.bonus);
        setDiscount(response.data.brands.discount)
        setBonusType(response.data.brands.bonusType);
        setBrandId(response.data.brands.couponId);  
        setStandoutCheck(response.data.brands?.stand_out)
        setPromotedCheck(response.data.brands?.promotion) 
        // dispatch(ClearhandleCategories());
        getdiscountbrandsstatus(false);
       }

      })
      .catch((error) => console.error(`Error: ${error}`));
    }
   else if(status===false){
    axios
      .post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/bp/status/${id}/${bid}`, {    
       status,
      }, {
        headers: {
          'Authorization': `Bearer ${getNewPartnerToken()}`
        }
      },)
      .then((response) => {
        // setSearch("")
        setSearching("");
        // const pageLength = Math.ceil(response.data.brands.length/10)
        //   setNumberOfPages(pageLength)
        if(searching.length>0){
          toast.success(response.data.message);
          const searchData = customerData.find(item => item.brandName === response.data.brands.brandName);

          if (searchData) {
            dataa =searchData.brandName;
            setBonus(searchData.bonus);
            setDiscount(response.data.brands.discount)
            setBonusType(searchData.bonusType);
            setInternalPercentage(searchData.internal_rebate);
            setBrandId(searchData.couponId);
            getdiscountbrandsstatus(true,'check');
          }
        }else{
          toast.success(response.data.message);
          setBonus(response.data.brands.bonus);
          setDiscount(response.data.brands.discount)
          setBonusType(response.data.brands.bonusType);
          setInternalPercentage(response.data.brands.internal_rebate);
          setBrandId(response.data.brands.couponId); 
          setStandoutCheck(response.data.brands?.stand_out)
          setPromotedCheck(response.data.brands?.promotion)
          getdiscountbrandsstatus();
        }

      })
      .catch((error) => console.error(`Error: ${error}`));
   }
      
  }


  const [btnColor,setBtnColor] = useState('');
  const [btnText,setBtnText] = useState('');
  const [catergoryOption,setCategoryOption] = useState(true);
  const [clickBrand,setClickBrand] = useState(false);

  useEffect(()=>{
    setBtnColor('#3D05B6');
    setBtnText('white');

  },[]);


useEffect(()=>{
    axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/get-categories`)
    .then((res)=>{
      const unsortedCategories = res.data.brands[0].categories;
      const sortedCategories = unsortedCategories.sort();
      setCategories(sortedCategories);
    }).catch((err)=>{
      console.log(err);
    })
},[]);



  function phoneFormat(input) {
    input = input.replace(/\D/g, "");
    input = input.trim()
    input = input.substring(0, 10);

    var size = input.length;
    if (size === 0) {
      input = input;
    }
     else if (size < 4) {
      input = "(" + input;
    } 
    else if (size < 7) {
      input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6);
    } 
    else if(size <= 11)
    input= "("+input.substring(0, 3)+") "+input.substring(3, 6)+"-" +input.substring(6, 11);
    
    if(input.length === 14){
      setErrorPhone(false);
    }else{
      setErrorPhone(true);
    }
    return input;
  }

  // function formatPhoneExt(ext) {
  //   return ext.replace(/[^0-9+]/, "");
  // }

 

  const handleSearch = (e)=>{
    setSearching(e);
    let arr=[];
    let input = e.toLowerCase();
    if(input.length>0){
      customerData1?.filter((elem)=>{
        if( (elem.brandName.toLowerCase().includes(input))&&input.length>0){
          arr.push(elem);
        }
      })
      if(arr.length===0){
        setNoData(true);
      }else{
        setNoData(false);
      }
      if(arr.length>20){
        setLoadMore(true);
      }else{
        setLoadMore(false);
      }
      setCustomerData(arr);
      const pageLength = Math.ceil(arr.length/10)
      setNumberOfPages(pageLength)
  setPageNumber(1);
  setShowPage(1);
  
    }else{
      const pageLength = Math.ceil(customerData1.length/10);
      setNumberOfPages(pageLength)
      setCustomerData(customerData1);
      setpaginate(20);
      if(customerData1.length>20){
        setNoData(false);
        // setLoadMore(true);
      }
    }
    dispatch(ClearhandleCategories());
  }

 
  
  const deleteBp = (bid) => {
    setDeleteDelay(true);
    setTimeout(()=>{
      setDeleteDelay(false);
    },1500)
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/branddelete/${id}/${bid}`, {
      headers: {
        'Authorization' : `Bearer ${token}`
      }
    })
      .then((response) => {
        // toast(response.data.message);
        getdiscountbrandsstatus();
           })
      .catch((error) => console.error(`Error: ${error}`));
      getdiscountbrandsstatus();
      toast.error("Brand Deleted")
  };


  const handleDiscountChange = (e) => {
    const inputValue = e.target.value.trim();
    if (!isNaN(inputValue) && inputValue >= 0 && inputValue <100 ) {
      setDiscount(inputValue);
    }
  };

  function handleBrandCheckboxChange (couponId, ischeck) {
    const user = JSON.parse(window.localStorage.getItem("business-client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios
      .post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/standout/${id}`,{
        couponId,
        ischeck
      }, {
        headers: {
          'Authorization': `Bearer ${getNewPartnerToken()}`
        }
      },)
      .then((response) => {
        if(response.data?.status === "success") {
          toast.success(response.data.message);
        } else {
          toast.warning(response.data.message);
        }
      })
      .catch((err)=>{
        console.log(`Error :${err}`);
      })
  }


  function handleBrandPromotionCheckboxChange (couponId, ischeck) {
    const user = JSON.parse(window.localStorage.getItem("business-client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios
      .post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/promotion/${id}`,{
        couponId,
        ischeck
      }, {
        headers: {
          'Authorization': `Bearer ${getNewPartnerToken()}`
        }
      },)
      .then((response) => {
        if(response.data?.status === "success") {
          toast.success(response.data.message);
        } else {
          toast.warning(response.data.message);
        }
      })
      .catch((err)=>{
        console.log(`Error :${err}`);
      })
  }


  useEffect(() => {
    const user = JSON.parse(window.localStorage.getItem("business-client"));
    const id = user?.userData?._id;
    const token = user?.token;
  
    dispatch(getBusinessContact(id, token, navigate, toast));
  
  }, [dispatch, navigate, toast]);
  
  
  const getbrands = () => {
    const user = JSON.parse(window.localStorage.getItem("business-client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios
        .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/getallbrands`,
        {headers: {
          'Authorization': `Bearer ${getNewPartnerToken()}`
        }})
        .then((response) => {  
          if(response.data.message == "Please Login"){
            localStorage.removeItem('business-client');
            navigate('/business-login');
            toast.error("Session Expired Please Login Again")
          }            
         setGetBrand(response.data.brands);            
        })
        .catch((error) =>{
          console.error(`Error: ${error}`);
          if (error.response.data.message === "Unauthorized User" ){
            localStorage.removeItem('business-client');
            navigate('/business-login');
            toast.error("Session Expired Please Login Again")
        }
        });
  };


 useEffect(()=>{
  setSearching('');
if(showCategory.length>0){
  const filteredData = customerData1?.filter(customer => showCategory.includes(customer.brandCategory));
setCustomerData(filteredData);
const pageLength = Math.ceil(filteredData.length/10)
      setNumberOfPages(pageLength)
  setPageNumber(1);
  setShowPage(1);
}else{
setCustomerData(customerData1);
const pageLength = Math.ceil(customerData1.length/10)
      setNumberOfPages(pageLength)
  setPageNumber(1);
  setShowPage(1);
}
},[clickBrand])

const [loading,setLoading] = useState(true);
const [primaryColor,setPrimaryColor]= useState('');
const [secondaryColor,setSecondaryColor] = useState('');

// useEffect(()=>{
//   setLoading(true);
//  axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/getcmsbusiness/${id}`)
//  .then((res)=>{
//    if(res.data?.cms?.default_ncentiva === false){ 
//    setBtnColor(res.data?.cms?.btn_color);
//    setPrimaryColor(res.data?.cms?.primary_color);
//    setSecondaryColor(res.data?.cms?.secondary_color);
//  }else{
//      setBtnColor('#3D05B6');
//      setPrimaryColor("#FF7020");
//      setSecondaryColor("#F5C400");
//    }
//    setLoading(false);
//  }).catch((err)=>{
//    console.log(err);
//  })
// },[])




  return isLoading === true ? (
    <div className="d-flex justify-content-center mt-5 pt-5">
      <Bars
  height="80"
  width="80"
  color="#FF7020"
  ariaLabel="bars-loading"
  wrapperStyle={{}}
  visible={true}
/>
    </div>
  ) : (
    <>
      <div className="p-3 p-lg-4 p-xl-5">
      <div className="d-flex align-items-center">
          <h1 className="font20  text-dark">Brands</h1>
        </div>


        <Container fluid className="mt-4">
          <Row  className="bg-white">
            <Col lg={12} md={12}>
              
              {id?<Row >
              {/* <h2 className="font16 mb-2 text-dark">Accessbility</h2> */}
              <Col className="p-4 mt-2 rounded containerShadow">
                <div className="textGray font12 d-flex justify-content-between flex-column flex-sm-row " >

                  <div style={{ maxWidth: "214px" }}>
                    <div className="d-flex align-items-center searchWrapper w-100 ms-0">
                      <Form.Control
                        type="text"
                        value={searching}
                        // value={search}
                        className="border-0 searchInput"
                        placeholder="Search"
                        onChange={({ currentTarget: input }) =>
                          handleSearch(input.value)
                        }
                      />
                      <Button
                        variant="primary"
                        title="Click here to search"
                        className="searchBtn d-flex justify-content-center align-items-center"
                      >
                        <img
                          src="/assets/images/search.png"
                          className="img-fluid"
                          alt=""
                        />
                      </Button>
                    </div>
                  </div>



                  {/* category filter */}

                  <div>

            <div style={{display:"flex",flexDirection:'row'}} className="mt-2 mt-sm-0 ">
  

                  {/* <div className="categories-area">
                       {categories.slice(0, 3).map((hcategory) => 
                            <BusinessBrandCategoryss key={hcategory.toString()} home={"false"} btnColor={btnColor} btnText={btnText} title={hcategory}  catergoryOption={hcategory} setCategoryOption={setCategoryOption} />
                            )}
                    </div> */}




                  <ModalOpen title={<Button variant="primary" style={{borderRadius:'30px', padding:'11px 0px'}} title="Click here to search" className="fullRounded px-4 fw-bold font12 customWidthButton">
                  Filter by Categories
             </Button>
             }
             data={categories}
             num={0}
             home={"false"}
             btnColor={btnColor}
              btnText={btnText} 
              setCategoryOption={setCategoryOption}
              colorCheck={'black'}
              setClickBrand={setClickBrand}
              clickBrand={clickBrand}
              catergoryOption={catergoryOption}
                  />

             </div>
                </div>
                  {/* category filter */}








                </div>
              </Col>
                  <Col lg={12} md={12} Col className="py-3">
                    <h3 className="font16 mb-2 text-dark">Select Banner Categories</h3>
                    <Form className="p-4 rounded containerShadow">
                      {businessCategories
                        .reduce((acc, element, index) => {
                          const rowIndex = Math.floor(index / 6);
                          if (!acc[rowIndex]) {
                            acc[rowIndex] = [];
                          }
                          acc[rowIndex].push(
                            <Col key={index} xxl={2} xl={1.5} lg={4} md={6} sm={6} xs={12}>
                              <Form.Check
                                type="checkbox"
                                checked={element.checked}
                                onChange={() => handleCheckboxChange(index)}
                                label={
                                  <span className="font14 text-dark fw-medium category-label">
                                    {element.name}
                                  </span>
                                }
                                className="custom-checkbox"
                              />
                            </Col>
                          );
                          return acc;
                        }, [])
                        .map((row, rowIndex) => (
                          <Row key={rowIndex} className="mb-1">
                            {row}
                          </Row>
                        ))}
                      <Row className="justify-content-end">
                        <Col xs="auto">
                          <Button variant="primary" className="button add fullRounded px-4 py-2 fw-bold font12 mt-3" onClick={handleSave}>
                            Save Categories
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
              <div >
                   
                   <Col md={12} className="mt-4">
              <div className="table-responsive">
                <table className="table table_brands" style={{ verticalAlign: "middle" }}>
                  <thead className="bgLightRed font14">
                    <tr className="text-center">
                    
                      <th className="py-3">
                        {" "}
                        <div className="d-flex align-items-center justify-content-center ">
                          {" "}
                          <span className="me-1 "> Stand out</span>{" "}
                        </div>{" "}
                      </th>
                      
                      <th className="py-3">
                        {" "}
                        <div className="d-flex align-items-center justify-content-center ">
                          {" "}
                          <span>Promoted</span>{" "}
                        </div>{" "}
                      </th>

                      <th className="py-3">
                        {" "}
                        <div className="d-flex align-items-center justify-content-center ">
                          {" "}
                          <span className="me-1 "> Brand</span>{" "}
                          {/* {updateorder === "ASC" ? (
                            <FaSortDown onClick={() => updatesorting("DSC")} />
                          ) : (
                            <FaSortUp onClick={() => updatesorting("ASC")} />
                          )} */}
                        </div>{" "}
                      </th>

                      <th className="py-3">
                        <div className="d-flex align-items-center justify-content-center" style={{ cursor: 'pointer' }}>
                          <span className="me-1">Category</span>
                          {/* {valueorder === "ASC" ? (
                            <FaSortDown onClick={() => valuesorting("DSC")} />
                          ) : (
                            <FaSortUp onClick={() => valuesorting("ASC")} />
                          )} */}
                        </div>
                      </th>
                      <th className="py-3">
                        <div className="d-flex align-items-center justify-content-center" style={{ cursor: 'pointer' }}>
                        <span className="me-1">{addedBusinessType === "Discount" ?"Discount percentage" : "Bonus percentage"}</span>
                          {/* {bonusDiscount === "ASC" ? (
                          <FaSortDown onClick={() => bonusDiscountSorting("DSC")} />
                        ) : (
                          <FaSortUp onClick={() => bonusDiscountSorting("ASC")} />
                        )} */}
                        </div>
                      </th>
                      <th className="py-3">
                        <div className="d-flex align-items-center justify-content-center" style={{ cursor: 'pointer' }}>
                          <span className="me-1">Business Type</span>
                        </div>
                      </th>
                      <th className="py-3" tyle={{width:'10%'}}>
                        {" "}
                        <div className="d-flex align-items-center justify-content-center " >
                          {" "}
                          <span className="me-1 ">Rebate</span><span>(%)</span>{" "}
                        </div>
                      </th>
                      <th className="py-3" tyle={{width:'10%'}}>
                        {" "}
                        <div className="d-flex align-items-center justify-content-center" style={{width:"56px"}} >
                          {" "}
                          <span className="ms-2 ">Customer</span><span>(%)</span>{" "}
                        </div>
                      </th>
                      <th className="py-2" style={{width:"50px"}}>

                      </th>
                      <th className="py-2">
                        {" "}
                        <div className="d-flex align-items-center justify-content-center ">
                          {" "}
                          <span className="me-1 ">Action</span>{" "}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="font14 fw-medium text-center">
               
                    {isLoading === true ? (
                      <tr style={{ backgroundColor: "#eee" }}>
                        <td colSpan="8">
                        <Bars
                        height="80"
                        width="100%"
                        margin='auto'
                        color="#FF7020"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        />
                        </td>
                      </tr>
                      ) : (
                        customerData
                        ?.sort((a, b) => a.brandName.toLowerCase().localeCompare(b.brandName.toLowerCase()))
                        .slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
              //           .filter((elem) => {
              //           if (Array.isArray(showCategory) && showCategory.length > 0) {
              //           const lowerCaseBrandName = elem.brandCategory.toLowerCase();
              //            const lowerCaseShowCategory = showCategory.map((item) => item.toLowerCase());
              //           return lowerCaseShowCategory.includes(lowerCaseBrandName);
              // }
              //      return true;
              //   })
                .map((elem) => (
                          <tr key={elem._id}>
                            <td className="py-3 font14 fw-medium ">
                              <div className="d-flex justify-content-center">
                                {elem.brandEdit ? 
                                    <Form.Check
                                    style={{ width: '85%' }}
                                    type="checkbox"
                                    name="brand"
                                    // disabled={!elem.brandEdit}
                                    onChange={(e) =>   setStandoutCheck(e.target.checked)} 
                                    />
                                : 
                                  <Form.Check
                                    style={{ width: '85%' }}
                                    type="checkbox"
                                    name="brand"
                                    checked={elem.stand_out}
                                    readOnly
                                    />
                                }
                              </div>
                            </td>
                            <td className="py-3 font14 fw-medium ">
                              <div className="d-flex justify-content-center">
                                {elem.brandEdit ? 
                                    <Form.Check
                                    style={{ width: '85%' }}
                                    type="checkbox"
                                    name="brand"
                                    // disabled={!elem.brandEdit}
                                    onChange={(e) => setPromotedCheck(e.target.checked)} 
                                    />
                                : 
                                  <Form.Check
                                    style={{ width: '85%' }}
                                    type="checkbox"
                                    name="brand"
                                    checked={elem.promotion}
                                    readOnly
                                    />
                                }
                              </div>
                            </td>
                             <td className="py-3 font14 fw-medium ">
                             <div className="d-flex justify-content-center">
{/*                            
                             {elem.brandEdit?  <Form.Select style={{ width:'150px'}} name='brand' value={elem.brandName}  >
                            {
                              getbrand?.map((data)=>{
                                return(
                                  
                                  <option key={data.key} value={data.brandName} >
                                  {data.label}
                                </option>
                                )                                
                              })
                            }
                         </Form.Select>: */}
                         <Form.Control style={{ width:'120px'}} className="font14" name="bonus" value={elem.brandName} disabled/>
                         {/* } */}
                         </div>
                            </td>



                            <td className="py-3 font14 fw-medium ">
                              <div className="d-flex justify-content-center">
                              <Form.Control style={{width:'120px'}} className="font14" name="bonusType" value= {elem.brandCategory} disabled/>
                              </div>

                            </td>
                            <td className="py-3 font14 fw-medium ">
                              <div className="d-flex justify-content-center">
                              {/* {elem.brandEdit? */}
                              {/* <Form.Control style={{width:'180px'}} className="font14" name="bonusType" value= {discount } onChange={handleDiscountChange} />: */}
                              <Form.Control style={{width:'68px'}} className="font14" name="bonusType" value= {`${(elem?.discount)?.toFixed(2)}%`} disabled/>
                              {/* } */}
                              </div>
                            </td>


                            {elem.brandEdit?<td >   
                              <div className="d-flex justify-content-center">   


                        <Form.Select
                          style={{ width: '90px', height: '90%' }}
                          value={addedBusinessType === "Discount" ? "Discount percentage" : bonusType || elem.bonusType}
                          name="bonusType"
                          onChange={e => setBonusType(e.target.value)}
                          className="Dropdown_responsive"
                          disabled={addedBusinessType === "Discount"}
                        >
                          {businessPartnerType === "Marketplace" ? (
                            <>
                              <option value="">Select Business Type</option>
                              <option value="Bonus percentage">Bonus percentage</option>
                              <option value="Bonus flat">Bonus flat</option>
                            </>
                          ) : addedBusinessType === "Discount" ? (
                            <option value="Discount percentage">Discount percentage</option>
                          ) : addedBusinessType === undefined ? (
                            <>
                              <option value="">Select Business Type</option>
                              <option value="Bonus percentage">Bonus percentage</option>
                              <option value="Bonus flat">Bonus flat</option>
                              <option value="Discount percentage">Discount percentage</option>
                            </>
                          ) : (
                            <>
                              <option value="">Select Business Type</option>
                              <option value="Bonus percentage">Bonus percentage</option>
                              <option value="Bonus flat">Bonus flat</option>
                            </>
                          )}
                        </Form.Select>
                         
                        </div>       
                        </td>:
                     <td>
                      <div className="d-flex justify-content-center">
                        <Form.Control style={{width:'85px'}} className="font14" name="bonusType" value={elem.bonusType === "Bonus percentage" ? "Bonus Percentage" : elem.bonusType} disabled/>
                        </div>
                        </td>}
                           
                           
                      
                            <td className="py-3 font14 fw-medium" style={{width:'10%'}}>
                            <div className="d-flex justify-content-center" style={{width:'100%'}}>
                            {elem.brandEdit? 
                            <Form.Control style={{width:'56px'}} className="font14" name="bonus" value={internalPercentage}  type="text" placeholder="Enter Bonus percentage" disabled/>

                            :
                            <Form.Control style={{width:'56px'}} className="font14" name="bonus" value={elem.internalPercentage}  type="text" placeholder="Enter Bonus percentage" disabled/>
                            }
                            </div>
                            </td>




                    <td className="py-3 font14 fw-medium" style={{ width: '10%' }}>
                      <div className="d-flex justify-content-center" style={{ width: '100%' }}>
                        {elem.brandEdit ? (
                          <>
                            <Form.Control
                              style={{ width: '56px', marginRight:"50px"}}
                              className={`font14 ${isError ? 'is-invalid' : ''}`}
                              name="bonus"
                              value={bonus}
                              onChange={e => {
                                const enteredBonus = e.target.value;
                                if (enteredBonus < 100) {
                                  const calculatedInternalPercentage = 100 - enteredBonus;
                                  setBonus(enteredBonus);
                                  setInternalPercentage(calculatedInternalPercentage);
                                  setIsError(false);
                                } else {
                                  setIsError(true);
                                }
                              }}
                              type="text"
                              placeholder="Enter Bonus percentage"
                            />
                            {isError && <div className="invalid-feedback">Bonus value cannot be above 99</div>}
                          </>
                        ) : (
                          <Form.Control
                            style={{ width: '56px',marginRight:"50px" }}
                            className="font14"
                            name="bonus"
                            value={elem.bonus}
                            type="text"
                            placeholder="Enter Bonus percentage"
                            disabled
                          />
                        )}
                        <Form.Control style={{ width: '56px',marginRight:"50px" }} name="id" value={partner_id} type="hidden" placeholder="Enter Percentage" />
                      </div>
                    </td>




                            <td className="py-3 font14 fw-medium ">
                              <div className="d-flex justify-content-center me-3" style={{width:"123px",marginLeft:"-30px"}}>
                              <Button  onClick={() => navigate(`/partner/partners/${id}/${elem.couponId}/coupons`)} type="submit" variant="primary" className="px-4 py-2 fullRounded shadowPrimary font12">Gift Cards ({elem.coupons})</Button>
                              </div>

                            </td>





                            <td>
                            <div className="d-flex justify-content-evenly">

                          <div className="action_button">
                          {elem.brandEdit?<button type="button"  onClick={()=>handleStatus(elem.brandEdit,elem._id)} className="icon mt-1 ms-1" style={{color:'black',border:'none'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-check-square" viewBox="0 0 16 16">
                     <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                     <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
                </svg>
                        </button>
                        :
                        
                        <button type="button" onClick={()=>handleStatus(elem.brandEdit,elem._id)} className="icon mt-1 ms-1" style={{color:'black',border:'none'}}><svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                            <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                          </svg></button>}
                          
                          </div>
                            
{/* edit button */}
<div>


{/* edit button */}

                        {deleteDelay?<button type="button" className="icon mt-1 ms-1" style={{color:'black',border:'none'}} >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                 <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"/>
                  </svg>
                  </button> :<button type="button" className="icon mt-1 ms-1" style={{color:'black',border:'none'}} onClick={() =>  deleteBp(elem.couponId)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
              <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"/>
                 </svg>
                  </button> }


                  </div>

                  </div>
                            </td>
                          </tr>
                        )))}
                     {isLoading ? "" : 
                     <>

                     </>
                     }
                    
                  </tbody>
                </table>
              </div>
            </Col>



            {numberOfPages > 0 ? (
        <Col className="mt-5 mb-4">
          <ul className="custPagination d-flex justify-content-center list-unstyled font14">
            {pageNumber === 1 ? (
              ""
            ) : (
              <li onClick={prevPage}>Previous</li>
            )}

            {items}

            {numberOfPages === pageNumber ? (
              ""
            ) : (
              <li onClick={nextPage}>Next</li>
            )}
            <li className="font14">
              <input
                style={{
                  width: "25px",
                  border: "1px solid #4C00EF",
                  borderRadius: "4px",
                  textAlign: "center"
                }}
                value={showPage ? showPage : ""}
                type="text"
                name="number"
                onChange={(e) => pagenumberSearch(e.target.value)}
              />{" "}
              <span style={{ color: "#4C00EF" }}>/</span> {numberOfPages}
            </li>
          </ul>
        </Col>
      ) : (
        ""
      )}   
            





                  </div>
                         <div className="allbrands-head" style={{display:"flex",alignItems:"center",justifyContent:'center'}}>
                          {zero ?<h2 className="allbrands-head mb-4">No Brands Added</h2>:'' }
                         </div>
               
                {noData ? <h2 className="allbrands-head mb-4">No Brands Found</h2>:''}
                <div style={{display:"flex",alignItems:"center"}}>

            
                </div>
                <Form
              className="p-4 rounded containerShadow"
                onSubmit={handleSubmit}>
              

          {formValues.map((element, index) => (
            <div className="form-inline" key={index}>
              {/* <label>Name</label>
              <input type="text" name="name" value={element.name || ""} onChange={e => handleChange(index, e)} />
              <label>bonus</label>
              <input type="text" name="bonus" value={element.bonus || ""} onChange={e => handleChange(index, e)} /> */}
              <Row className="align-items-center mt-3">
                      <Col sm={6} lg={4} xl={3} >
                        <Form.Group className="mb-3  mb-sm-0 d-block d-xxl-flex  align-items-center group_responsive">
                          <Form.Label className="font13 text-dark fw-medium mt-2 me-2 " >Brand</Form.Label>
                          <Form.Select style={{ width:'85%'}} name='brand' onChange={e => setBrand( handleChangeForm(index, e))} >
                            {
                              getbrand?.map((data,i)=>{
                                return(
                                  <option key={i} value={data.value} >
                                  {data.label}
                                </option>
                                )                                
                              })
                            }

                         </Form.Select>
                         {/* <div style={{width: '300px'}}>
                          <Select  options={getbrand} name={formValues.brand} onChange={(e) => setFormValues.brand(handleChange(index, e))} style={{width:'80%'}}  >

                            </Select>
                          </div> */}
                        </Form.Group>
                      </Col>
                      <Col sm={6} lg={4} xl={2} >
                        <Form.Group className="mb-3  mb-sm-0 d-block d-xxl-flex  align-items-center group_responsive " >
                        <Form.Label   style={{whiteSpace:'nowrap'}} className="font13 text-dark fw-medium mt-2 me-2" >Business Type</Form.Label>
                        <Form.Select
                          style={{ width: '85%' }}
                          value={addedBusinessType === "Discount" ? "Discount percentage" : bonus}
                          name="bonusType"
                          onChange={e => setBonus(handleChangeForm(index, e))}
                          disabled={addedBusinessType === "Discount"}
                        >
                          {businessPartnerType === "Marketplace" ? (
                            <>
                              <option value="">Select Business Type</option>
                              <option value="Bonus percentage">Bonus percentage</option>
                              <option value="Bonus flat">Bonus flat</option>
                            </>
                          ) : addedBusinessType === "Discount" ? (
                            <option value="Discount percentage">Discount percentage</option>
                          ) : addedBusinessType === undefined ? (
                            <>
                              <option value="">Select Business Type</option>
                              <option value="Bonus percentage">Bonus percentage</option>
                              <option value="Bonus flat">Bonus flat</option>
                              <option value="Discount percentage">Discount percentage</option>
                            </>
                          ) : (
                            <>
                              <option value="">Select Business Type</option>
                              <option value="Bonus percentage">Bonus percentage</option>
                              <option value="Bonus flat">Bonus flat</option>
                            </>
                          )}
                        </Form.Select>
                         
                        </Form.Group>
                      </Col>
                      <Col sm={6} lg={4} xl={2} >
                        <Form.Group className="mb-3  mb-sm-0 d-block d-xxl-flex  align-items-center group_responsive " >
                        <Form.Label   style={{whiteSpace:'nowrap'}} className="font13 text-dark fw-medium mt-2 me-2" >Rebate (%)</Form.Label>
                        <Form.Control style={{width:'80%'}} className="font14" name="internalPercentage" value={element.internalPercentage || ""} onChange={e => handleChangeForm(index, e)} type="number" placeholder="Enter Percentage" disabled/>
                        </Form.Group>
                      </Col>
                      <Col sm={6} lg={4} xl={2}  >
                        <Form.Group className="mb-3  mb-sm-0 d-block d-xxl-flex  align-items-center group_responsive" >
                          <Form.Label style={{ whiteSpace: 'nowrap' }} className="font13 text-dark fw-medium mt-2 me-2" >Bonus (%)</Form.Label>
                          <Form.Control
                            style={{ width: '80%' }}
                            className="font14"
                            name="bonusPercentage"
                            value="0.00"
                            disabled
                            type="number"
                            step="0.01"
                            placeholder="Enter Bonus"
                            onChange={e => handleBonusChangeForm(e, index)}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={6} lg={4} xl={3} >
                        <Form.Group className="mb-3  mb-sm-0 d-block d-xxl-flex  align-items-center group_responsive" >
                          <div>
                          <Form.Label className="font13 text-dark fw-medium mt-2 me-2"style={{display:"flex"}} >Customer <span style={{ display:"inline-block",marginLeft:"2px"}}> (%)</span></Form.Label>
                        </div>
                        <div className="d-flex  align-items-center">
                          <Form.Control style={{width:'100%'}} className="font14" name="bonus" value={element.bonus || ""} onChange={e => handleChangeForm(index, e)} type="number" placeholder="Enter Percentage" />
                          <Form.Control style={{width:'100%'}} className="font14" name="id" value={partner_id} onChange={e => handleChangeForm(index, e)} type="hidden" placeholder="Enter Percentage" />

{index ?
                            <button type="button" className="icon mt-1 ms-1" style={{color:'black',border:'none'}}><svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                            <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                          </svg></button>
                          :
                          null

}

                         {
                index ? 
                  <button type="button" className="icon mt-1 ms-1" style={{color:'black',border:'none'}} onClick={() => removeFormFields(index)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
  <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"/>
</svg>
                  </button> 
                : null
              }
              </div>
                        </Form.Group>
                      </Col>
                  

                    </Row> 
            </div>
          ))}
          <div className="button-section">
          {delay?<Button variant="primary" className="button add fullRounded px-4 py-2 fw-bold font12 mt-3 me-2" >Save</Button>
              :<Button variant="primary" className="button add fullRounded px-4 py-2 fw-bold font12 mt-3 me-2" type="submit" >Save</Button>}
              <Button variant="primary" className="button submit  fullRounded px-4 py-2 fw-bold font12 mt-3" onClick={() => addFormFields()} type="button">
              <span className="me-2">
                  {/* <img
                    src="/assets/images/plus.svg"
                    className="img-fluid"
                    alt=""
                  /> */}
                   <BiPlusMedical />
                </span>
                <span>Add More</span></Button>
          </div>
      </Form>

              </Row>:''}
            </Col>
          </Row>
        </Container>
       
      </div>
    </>
  );
};

export default BusinessBrands;