import React, { useCallback, useRef } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useEffect, useState } from 'react';
import axios from "axios";
import { toast } from 'react-toastify';
import { useNavigate, Link } from 'react-router-dom';
import "../styles/page/Login.scss";
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { setCookie } from "../config/cookie.config";
const Verify = () => {
    const location = useLocation();
    const isSms = new URLSearchParams(location.search).get('isSms');
    useEffect(() => {
        document.title = "ncentiva || Verify"
    }, [])
    const navigate = useNavigate();
    const user = JSON.parse(window.localStorage.getItem('user'));
    let email;
    let password;
    if (user) {
        const decryptedString = CryptoJS.AES.decrypt(user.data.securedLogin, process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);
        const decryptedObj = JSON.parse(decryptedString);
        if (decryptedObj) {
            email = decryptedObj.email;
            password = decryptedObj.password
        } else {
            email = null;
            password = null;
            navigate('/admin')
        }
    } else {
        navigate('/admin')
    }
    const [otp, setOtp] = useState("");
    const [stillSendingOtp, setStillSendingOtp] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [apicall, setApicall] = useState(false);
    const inputRef = useRef(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [resendTimer, setResendTimer] = useState(60);
    const [canResend, setCanResend] = useState(true);
    const [otpError, setOtpError] = useState("");
    useEffect(() => {
        const timer = setTimeout(() => {
            if (inputRef.current) {
                 inputRef.current.focus();
            }
        }, 300);
        return () => clearTimeout(timer);
    }, []);
    useEffect(() => {
        redirectToLogin();
    }, []);
    const handleOtpChange = useCallback((e) => {
        let trimValue = e.target.value.replace(/\D/g, '');
        setOtp(trimValue);
        setOtpError(trimValue.length !== 6 ? "OTP must be 6 digits" : "");
    }, []);
    const redirectToLogin = () => {
        const userCheck = JSON.parse(window.localStorage.getItem('user'))
        if (userCheck === null) {
            navigate('/admin')
        }
    }
    useEffect(() => {
        const getCookie = (name) => {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
            return null;
        };

        const calculateRemainingTime = () => {
            const timestamp = getCookie('otpTiming');
            if (timestamp) {
                const otpTimestamp = parseInt(timestamp, 10);
                const currentTime = Date.now();
                const elapsed = (currentTime - otpTimestamp) / 1000; // in seconds
                const remaining = Math.max(0, 120 - Math.floor(elapsed)); // 120 seconds = 2 minutes
                setResendTimer(remaining);
                setCanResend(remaining <= 0);
            }
        };

        calculateRemainingTime();

        const timer = setInterval(() => {
            setResendTimer((prevTimer) => {
                if (prevTimer <= 1) {
                    clearInterval(timer);
                    setCanResend(true);
                    return 30; // Reset timer to 30 seconds
                }
                return prevTimer - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, [resendTimer]);


    const credentials = {
        email : email,
        password: password,
        otppath: isSms,
        timestamp: Date.now()
      };
      const jsonString = JSON.stringify(credentials);
    const securedLogin = CryptoJS.AES.encrypt(jsonString, process.env.REACT_APP_SECRET_KEY).toString();


    const otpSend = async (e) => {
        e.preventDefault();
        if (!stillSendingOtp && canResend) {
            try {
                setStillSendingOtp(true);
                const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/user/otppage/`, {
                    securedLogin
                });
                if (data.status === "success") {
                    const dataToSave = {
                        "status": data?.status,
                        "data": data?.data
                    };
                    window.localStorage.setItem('user', JSON.stringify(dataToSave));
                    toast.success("OTP sent successfully!");
                    setStillSendingOtp(false);
                   
                } else {
                    if (data.message === "Please wait before requesting another OTP.") {
                        setStillSendingOtp(false);
                        setCanResend(false);
    
                        // Set the cookie with current timestamp
                        document.cookie = `otpTiming=${Date.now()}; max-age=${60 * 2}; Secure=true; SameSite=Strict`;
                        setResendTimer(300)
                    }
                    toast.warning(data.message);
                    setTimeout(() => setStillSendingOtp(false), 3500);
                }
            } catch (err) {
                console.log(`Error : ${err}`);
                toast.error(err.response.data);
            }
        }
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        setApicall(true);
        if (isSubmitting) return;
        setIsSubmitting(true);
        if (otp.length !== 6) {
            setOtpError("OTP must be 6 digits");
            setApicall(false);
            setIsSubmitting(false);
            return;
        }
        if (!otp) {
            setTimeout(() => {
                setApicall(false);
                setDisabled(false);
            }, 500);
            toast.warning("Enter Your passcode")
            setIsSubmitting(false);
        } else {
            setOtpError("");
            setDisabled(true);

            try {
                const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/user/login/verify`, {
                    securedLogin,
                    otp
                });
                setTimeout(() => {
                    setApicall(false);
                    setDisabled(false);
                }, 500);
                if (data.status === "success") {
                    toast.success(data.message);
                    const { token, ...dataWithoutToken } = data;
                    localStorage.removeItem('admin_email');

                    window.localStorage.setItem("client", JSON.stringify(dataWithoutToken));

                    const [header, payload, signature] = token.split('.');

                    const encodedHeader = btoa(header);

                    window.localStorage.setItem("CAACAO-012", encodedHeader);

                    // Store in cookie
                    setCookie('OAC-01', btoa(payload), 7); // Expires in 7 days
                    setCookie('AAC-02', btoa(signature), 7); // Expires in 7 days
                    
                    if (data.user.new === true) {

                        navigate(`/admin/setPassword/${data.user._id}`)
                    } else {

                        window.localStorage.setItem("client", JSON.stringify(dataWithoutToken));
                        if (data.user.new === false) {
                            navigate('/admin/dashboard')
                        }

                    }
                } else if (data.status === "failed") {
                    toast.error(data.message)
                }
            } catch (err) {
                if (err?.response?.status === 403) {
                    toast.error(err?.response?.data?.message);
                } else {
                    toast.error(err?.response?.data);
                }
                console.log(`Error : ${err}`);
                setTimeout(() => {
                    setApicall(false);
                    setDisabled(false);
                }, 500);
            } finally {
                setIsSubmitting(false);
            }
        }
    }

    function checkWhitespace(event) {
        // Get the pasted data as plain text
        let data = event.clipboardData.getData("text/plain");
        
        // Remove all non-numeric characters (including spaces) from the pasted data
        let cleanData = data.replace(/\D/g, '');
    
        // Check if the cleaned data is empty
        if (cleanData.length === 0) {
            // Prevent paste if there's no valid numeric data
            event.preventDefault();
        } else {
            // Prevent the default paste action and set the cleaned data
            event.preventDefault();
            setOtp(cleanData);
    
            // Show error if the OTP length is not exactly 6
            setOtpError(cleanData.length !== 6 ? "OTP must be 6 digits" : "");
        }
    }
    


    return (
        <>
            <Container fluid className="d-flex flex-column justify-content-center align-items-center" style={{ height: '100vh', backgroundColor: '#FFF8F3' }}>
                <Container
                    className="bg-white loginContainer p-0 rounded"
                    style={{ maxWidth: "1065px" }}
                >
                    <Row className="align-items-center g-0">
                        <Col style={{ height: "100%" }} md={6} className="loginLeft order-2 order-md-1">
                            <div className="">
                                <h4 className="text-dark">Enter OTP Code</h4>
                                <h6 className="text-dark" style={{ width: '100%', fontSize: '15px' }}>Your code has been sent to your OTP preferred delivery method</h6>

                                <Form className="form mt-3" onSubmit={handleSubmit}>
                                    <Form.Group className="mb-3">
                                        <Form.Control
                                            type="text"
                                            // value={otp}
                                            ref={inputRef}
                                            onChange={handleOtpChange}
                                            placeholder="Enter your Otp for Verification"
                                            // onPaste={(e) => checkWhitespace(e)} 
                                            autoComplete="off"
                                               inputMode="numeric"
                                        />
                                        {otpError && <div className="text-danger mt-2">{otpError}</div>}
                                        <p className="font14 mt-2 text-dark">
                                            OTP not received?{" "}
                                            {canResend ? (
                                                <>
                                                    <span
                                                        className="fw-semibold resend-link"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={otpSend}
                                                    >
                                                        Click here
                                                    </span>
                                                    <span className="font14 mt-2 text-dark"> to send it again.</span>
                                                </>
                                            ) : (
                                                <span className="fw-semibold text-danger resend-timer">
                                                    Resend in {resendTimer}s
                                                </span>
                                            )}
                                        </p>

                                      </Form.Group>
                                    <Form.Group className="mb-3">
                                        {disabled ? (
                                            <Button
                                                variant="primary"
                                                className="w-100 fullRounded font12 fw-bold py-3 shadowPrimary"
                                                disabled
                                            >
                                                {apicall ? 'Please wait ....' : 'Verify'}
                                            </Button>
                                        ) : (
                                            <Button
                                                type="submit"
                                                variant="primary"
                                                className="w-100 fullRounded font12 fw-bold py-3 shadowPrimary"

                                            >
                                                {apicall ? 'Please wait ....' : 'Verify'}
                                            </Button>
                                        )}
                                    </Form.Group>
                                </Form>
                            </div>
                        </Col>
                        <Col
                            md={6}
                            className="loginRight d-flex justify-content-center flex-column align-items-center order-1 order-md-1"
                            style={{ height: "100%" }}
                        >
                            <Link to="/admin" >
                                <img
                                    src="/assets/images/logo.png"
                                    className="imgLogo img-fluid"
                                    alt=""
                                    style={{ width: '200px', height: '100px', objectFit: 'scale-down' }}
                                /></Link>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    );
};
export default Verify;