import React, { useCallback, useRef } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useEffect, useState } from 'react';
import axios from "axios";
import { toast } from 'react-toastify';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import "../../styles/page/Login.scss";
import 'react-toastify/dist/ReactToastify.css';
import CryptoJS from 'crypto-js';
import { setCookie } from "../../config/cookie.config";


const BusinessVerify = () => {
    useEffect(() => {
        document.title = "ncentiva || Business Verify"
    }, [])
    const location = useLocation();
    const isSms = new URLSearchParams(location.search).get('isSms');
    useEffect(() => {
        document.title = "ncentiva || Verify"
    }, [])
    const navigate = useNavigate();
    const user = JSON.parse(window.localStorage.getItem('business'));

    const decryptedString = CryptoJS.AES.decrypt(
        user.securedLogin,
        process.env.REACT_APP_SECRET_KEY
    ).toString(CryptoJS.enc.Utf8);
    const decryptedObj = JSON.parse(decryptedString);

    var email;
    var password;
    if (user) {
        email = decryptedObj.email;
        password = decryptedObj.password;
    } else {
        email = null;
        password = null;
        navigate('/business-login')
    }
    const [otp, setOtp] = useState("");
    const [otppath, setOtpPath] = useState("");
    const [otpSent, setOtpSent] = useState(false);
    const [stillSendingOtp, setStillSendingOtp] = useState(false);
    const [apicall, setApicall] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [resendTimer, setResendTimer] = useState(60);
    const [canResend, setCanResend] = useState(true);
    const [otpError, setOtpError] = useState("");

    useEffect(() => {
        redirectToLogin();
    }, []);
    const redirectToLogin = () => {
        const userCheck = JSON.parse(window.localStorage.getItem('business'))
        if (userCheck === null) {
            navigate('/business-login')
        }
    }

    useEffect(() => {
        const getCookie = (name) => {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
            return null;
        };

        const calculateRemainingTime = () => {
            const timestamp = getCookie('businessOtpTiming');
            if (timestamp) {
                const otpTimestamp = parseInt(timestamp, 10);
                const currentTime = Date.now();
                const elapsed = (currentTime - otpTimestamp) / 1000; // in seconds
                const remaining = Math.max(0, 120 - Math.floor(elapsed)); // 120 seconds = 2 minutes
                setResendTimer(remaining);
                setCanResend(remaining <= 0);
            }
        };

        calculateRemainingTime();

        const timer = setInterval(() => {
            setResendTimer((prevTimer) => {
                if (prevTimer <= 1) {
                    clearInterval(timer);
                    setCanResend(true);
                    return 30; // Reset timer to 30 seconds
                }
                return prevTimer - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, [resendTimer]);

    const credentials = {
        email : email,
        password: password,
        otppath: isSms,
        timestamp: Date.now()
      };
      const jsonString = JSON.stringify(credentials);
    const securedLogin = CryptoJS.AES.encrypt(jsonString, process.env.REACT_APP_SECRET_KEY).toString();


    const otpSend = async (e) => {
        e.preventDefault();
        console.table({ email, password, otppath });
        if (!stillSendingOtp) {
            try {
                setStillSendingOtp(true);
                const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/business-otppage/`, {
                    securedLogin
                });
                if (data.status === "success") {
                    window.localStorage.setItem("business", JSON.stringify(data?.data));
                    toast.success("OTP sent successfully!");
                    setStillSendingOtp(false);
                  
                }
                else {
                    if (data.message === "Please wait before requesting another OTP."){
                        setOtpSent(true)
                        setStillSendingOtp(false);
                        setCanResend(false);
    
                        // Set the cookie with current timestamp
                        document.cookie = `businessOtpTiming=${Date.now()}; max-age=${60 * 2}; Secure=true; SameSite=Strict`;
                        setResendTimer(300)
                    }
                    toast.warning(data.message);

                    setTimeout(() => setStillSendingOtp(false), 3500);

                }
            } catch (err) {
                console.log(`Error : ${err}`);
                toast.error(err.response.data);
            }
        }

    }
    const inputRef = useRef(null);
    useEffect(() => {
        const timer = setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }, 300);
        return () => clearTimeout(timer);
    }, []);
    const handleOtpChange = useCallback((e) => {
        let trimValue = e.target.value.replace(/\D/g, '');
        setOtp(trimValue);
        setOtpError(trimValue.length !== 6 ? "OTP must be 6 digits" : "");
    }, []);
    const handleSubmit = async (e) => {
        setApicall(true)
        e.preventDefault();
        if (isSubmitting) return;
        setIsSubmitting(true);
        console.table({ email, password, otp });
        if ( otp.length !== 6) {
            setOtpError("OTP must be 6 digits");
            setApicall(false);
            setIsSubmitting(false);
            return;
        }
        if (!otp) {
            toast.warning("Enter Your passcode")
            setIsSubmitting(false);
            setTimeout(() => {
                setApicall(false);
                setDisabled(false);
            }, 500);
        } else {
            setOtpError("");
            setDisabled(true);
            const credentials = {
                email: email,
                password: password,
                otp: otp,
            };
            const jsonString = JSON.stringify(credentials);
            const securedLogin = CryptoJS.AES.encrypt(jsonString, process.env.REACT_APP_SECRET_KEY).toString();

            try {
                const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/business-login/verify`, {
                    securedLogin
                });
                setTimeout(() => {
                    setApicall(false);
                    setDisabled(false);
                }, 500);
                if (data.status === "success") {

                    // Remove the token from the data object before saving it
                    const { token, ...dataWithoutToken } = data;
                    // Save the modified data (without the token) in localStorage
                    window.localStorage.setItem("business-client", JSON.stringify(dataWithoutToken));
                    toast.success(data.message);

                    const [header, payload, signature] = data.token.split('.');

                    const encodedHeader = btoa(header);

                    window.localStorage.setItem("business_CAACAO-012", encodedHeader);

                    // Store in cookie
                    setCookie('business_OAC-01', btoa(payload), 7); // Expires in 7 days
                    setCookie('business_AAC-02', btoa(signature), 7); // Expires in 7 days
                    

                    navigate('/partner/dashboard');
                } else if (data.status === "failed") {
                    toast.error(data.message)
                }
            } catch (err) {
                if (err?.response?.status === 403) {
                    toast.error(err?.response?.data?.message);
                } else {
                    toast.error(err?.response?.data);
                }
                setTimeout(() => {
                    setApicall(false);
                    setDisabled(false);
                }, 500);
            } finally {
                setIsSubmitting(false);
            }
        }
    }

    function checkWhitespace(event) {
        // Get the pasted data as plain text
        let data = event.clipboardData.getData("text/plain");
        
        // Remove all non-numeric characters (including spaces) from the pasted data
        let cleanData = data.replace(/\D/g, '');
    
        // Check if the cleaned data is empty
        if (cleanData.length === 0) {
            // Prevent paste if there's no valid numeric data
            event.preventDefault();
        } else {
            // Prevent the default paste action and set the cleaned data
            event.preventDefault();
            setOtp(cleanData);
    
            // Show error if the OTP length is not exactly 6
            setOtpError(cleanData.length !== 6 ? "OTP must be exactly 6 digits" : "");
        }
    }
    

    return (
        <>
            <Container fluid className="d-flex flex-column justify-content-center align-items-center" style={{ height: '100vh', backgroundColor: '#FFF8F3' }}>
                <Container
                    className="bg-white loginContainer p-0 rounded"
                    style={{ maxWidth: "1065px" }}
                >
                    <Row className="align-items-center g-0">
                        <Col style={{ height: "100%" }} md={6} className="loginLeft order-2 order-md-1">
                            <div className="">
                                <h4 className="text-dark">Enter OTP Code</h4>
                                <h6 className="text-dark verifyPageText">Your code has been sent to your OTP preferred delivery method</h6>
                                <Form className="form mt-3" onSubmit={handleSubmit}>
                                    <Form.Group className="mb-3">
                                        <Form.Control
                                            type="text"
                                            // value={otp}
                                            ref={inputRef}
                                            onChange={handleOtpChange}
                                            placeholder="Enter your Otp for Verification"
                                            // onPaste={(e) => checkWhitespace(e)}
                                        />
                                         {otpError && <div className="text-danger mt-2">{otpError}</div>}
                                        {/* <p className="font14 mt-2 text-dark">OTP not received? <span className="fw-semibold" style={{ cursor: 'pointer' }} onClick={otpSend}>Click here</span> to  send it again.</p> */}
                                        <p className="font14 mt-2 text-dark">
                                            OTP not received?{" "}
                                            {canResend ? (
                                                <>
                                                    <span
                                                        className="fw-semibold resend-link"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={otpSend}
                                                    >
                                                        Click here
                                                    </span>
                                                    <span className="font14 mt-2 text-dark"> to send it again.</span>
                                                </>
                                            ) : (
                                                <span className="fw-semibold text-danger resend-timer">
                                                    Resend in {resendTimer}s
                                                </span>
                                            )}
                                        </p>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        {disabled ? (
                                            <Button
                                                variant="primary"
                                                className="w-100 fullRounded font12 fw-bold py-3 shadowPrimary"
                                                disabled
                                            >
                                                {apicall ? 'Please wait ....' : 'Verify'}
                                            </Button>
                                        ) : (
                                            <Button
                                                type="submit"
                                                variant="primary"
                                                className="w-100 fullRounded font12 fw-bold py-3 shadowPrimary"

                                            >
                                                {apicall ? 'Please wait ....' : 'Verify'}
                                            </Button>
                                        )}
                                    </Form.Group>
                                </Form>
                            </div>
                        </Col>
                        <Col
                            md={6}
                            className="loginRight d-flex justify-content-center flex-column align-items-center order-1 order-md-1"
                            style={{ height: "100%" }}
                        >
                            <Link to="/" >
                                <img
                                    src="/assets/images/logo.png"
                                    className="imgLogo img-fluid"
                                    alt=""
                                    style={{ width: '200px', height: '100px', marginLeft: '18%', objectFit: 'scale-down' }}
                                /></Link>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    );
};

export default BusinessVerify
