import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FaMinus, FaPlus } from 'react-icons/fa';
import ViewCardTab from './ViewCardTab';
import axios from "axios";
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Form } from "react-bootstrap";
import PaymentConfirmationModal from "./PaymentConfirmationModal";
import { useDispatch, useSelector } from 'react-redux';
import { getcart } from '../../Redux/CartType/actions';
import { BsInfoCircle } from "react-icons/bs";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getNewCustomerToken } from '../../config/cookie.config';
function ViewCardModal(props) {
    const cmsData = useSelector((state) => state.cmsReducer);

    // const defaultNcentiva = cmsData?.data?.cms?.default_ncentiva;
    const defaultNcentiva = localStorage.getItem("defaultNcentivaCms");
   
  // let [count, setCount] = useState(Number(props.data.minValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
  // const [cost, setCost] = useState(Number(props.data.minCost).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));

  // const bonusFee = props.data.bonusFee;
  // const discount = props.data.discount;
  // const bonus = discount *(bonusFee/100);
  // const [b, setB] = useState(Number(bonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
  // function incrementCount() {
  //   setCount(count+1);
  // }
  // function decrementCount() {
  //   if(count >= 1){
  //     setCount(count-1);
  //   }
  // }
  const customer = JSON.parse(window.localStorage.getItem('customer'));
  const dispatch = useDispatch();
//   const contactList = JSON.parse(localStorage.getItem('listItem')) ?? [];
  const customerId =customer?.customer?._id
  const cartData = useSelector((state)=>state.cartReducer);
  let lists = JSON.parse(localStorage.getItem('listItem')) ?? [];
  if (lists) {
    lists = lists.filter((item) => item?.customer_id === customerId);
  }
  const contactList = cartData?.data?.cart ?? [];

  let token;
  if (customer) {
    token = customer.token;
  } else {
    token = null;
  }
  var id;
  var bonus = 0;
  var minValue = 0;
  var minCost = 0;
  var bonusFee = 0;
  var max_discount = 0;
  var max_bonus_amount = 0;
  var max_cost = 0;
  var costValue = 0;
  var rbalance = 0;
  var balance = 0;
  var maxValue = 0;
  var rebate = 0;
  const [bonuss, setBonuss] = useState(0);
  const [bonusCashModal, setBonusCashModal] = useState(0);
  if (customer) {
      balance = customer.customer.balance;
  }
  contactList.length !== 0 ? contactList.findLast((item) => id = item.id) : id = 0
  const data = props.data
  if (data.minValue > 0) {
      minValue = data.minValue
      minCost = data.minCost;
      maxValue = data.maxValue;
  }
  if (data.faceValue > 0) {
      minValue = data.faceValue
      minCost = data.faceValue;
  }


  const [count, setCount] = useState(Number(minValue).toFixed(2));
  const [cminvalue] = useState(minValue);
  const [cmaxvalue] = useState(maxValue);
  const [cost, setCost] = useState(minCost);
  const [rFund, setrFund] = useState(balance);
  const [disabled,setDisabled] = useState(false);
  const [fixedValue,setFixedValue] = useState();
  const [fixedGiftId,setFixedGiftId] = useState();
  const [fixedDiscount,setFixedDiscount] = useState();
  const [showaddmodal,setShowAddModal] = useState(false);
//   const [isFixed, setIsFixed] = useState(false);
const [fixedProcessing,setFixedProcessing] = useState();
const [fixedBonus, setFixedBonus] = useState();
let propsWalletBalance = props.wallet_balance;
let localwallet =Number(0);
  for (let i = 0; i < contactList?.length; i++) {
      localwallet = localwallet + parseFloat(contactList[i].ncentiva_wallet)
  }

  const handleClose = () => {
    props.onHide();
  };
     const openModal = () =>{
    setShowAddModal(true)
  }
  const closeModal = () =>{
    setShowAddModal(false)
  }

//  const modalOnchange = (e) => {
//     setPaymentOption(e.target.value)
// }
const {ws}= props
useEffect(() => {
    if (ws) {
      ws.onmessage = (event) => {
        console.log('Received message from server:', event.data);
        const message = JSON.parse(event.data);
        console.log('message', message.action);
        if (message.action === 'addItemToCart') {
          dispatch(getcart(token));
        }
      };
    }
    return () => {
      
    };
  }, [ws]); 

useEffect(()=>{
    if(data.fixed.length>0){
        setFixedValue(Number(data.fixed[0].faceValue));
        setFixedDiscount(Number(data.fixed[0].discount));
        setFixedGiftId(Number(data.fixed[0].giftcart_id));
        setBonuss(Number(data?.bonus));
    }
   },[props.show]);
  if (data.bonusFee > 0) {
      bonusFee = data.bonusFee;
  } else {
      if (data.discount > 0) {
          bonus = data.discount;
      }
  }

  const discount = data.discount;
  if (discount) {
      if (data.bonusFee > 0) {
          bonus = discount * (bonusFee / 100);
      }

  } else {

  }

  const [b] = useState(Number(bonus).toFixed(2));
  var cust_id;
  if (customer) {
      cust_id = customer.customer._id;
  }


  useEffect(()=>{
    if(data.fixed.length>0){
        data.fixed.sort((a,b)=>{
            return a.faceValue - b.faceValue
        })
        setFixedValue(Number(data.fixed[0].faceValue));
        // setfixedDis(Number(data.fixed[0].faceValue) + (Number(data.fixed[0].faceValue) * ((Number(data.fixed[0].discount)/100))));
        const addBonusOnFix = ((Number(data.fixed[0].discount) * Number(data.bonusFee /100)))/100;
        setFixedBonus(addBonusOnFix);
        // setBonuss(data.bonus)
        // if(data.bonusFee){
        //     setBonusDis(Number(data.fixed[0].discount) *Number(data.bonusFee /100))
        // } else {
        //     setBonusDis(Number(data.fixed[0].discount))
        // }
        setFixedDiscount(Number(data.fixed[0].discount));
        setFixedGiftId(Number(data.fixed[0].giftcart_id));
        setFixedProcessing(data.fixed[0].processing);
    }
   },[])
   

  const handleConfirmModal = (selectedPaymentOption) => {
    
    if ( props.isFixed === true || props.data?.fixed?.length > 0 ) {
        // setTimeout(() => {
            addFixedCart(selectedPaymentOption)
        // }, 2000);
    } else {
        // setTimeout(() => {
            addCart(selectedPaymentOption)
        // }, 2000);
    }
};
  const getcustomer = (cust_id) => {
      // axios
      //     .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getcustomer/${cust_id}`)
      //     .then((response) => {
      if (contactList) {
          // var sbalance = response.data.customer.balance;
          var sbalance = props.rbalance_redux;
          let sum = 0;
          let bonus = 0;
          let costValue = 0;
          for (var i = 0; i < contactList.length; i++) {
              sum += parseFloat(contactList[i].cost);
              bonus += parseFloat(contactList[i].bonus);
              costValue += parseFloat(contactList[i].costValue);
          }
          rbalance = parseFloat(sbalance) - parseFloat(sum);
          setrFund(rbalance);

      } else {
          // setrFund(response.data.customer.balance);
          setrFund(props.rbalance_redux);
      }
  };




  useEffect(() => {
      if (cust_id) {
          getcustomer(cust_id, token);
          // getcustomer(cust_id);
      }
      if (count === 0) {
          toast.warning(`insufficient balance`);
          return false;
      }
      if (count > data.maxValue) {
          const gmax_discount = parseInt(data.maxValue) * (data.discount / 100);
          const gmax_bonus_amount = gmax_discount * (data.bonusFee / 100);
          const gmax_cost = parseInt(data.maxValue) - parseFloat(gmax_bonus_amount);
          setCount(Number(gmax_cost).toFixed(2));
          setCost(Number(data.maxValue).toFixed(2));
      }
      if (count == data.maxValue) {
          const max_bonus_amount = parseInt(data.discount) * (data.bonusFee / 100);
          const max_count = parseFloat(data.maxValue) - parseFloat(max_bonus_amount);
          const max_cost = parseFloat(max_count) + parseFloat(max_bonus_amount);
          setCount(Number(max_count).toFixed(2));
          setCost(Number(max_cost).toFixed(2));
      }
      else {
        
        if (data.discount > 0) {
            const max_discount = parseFloat(count) * (data.bonus / 100);
            const max_bonus = parseFloat(max_discount) *(data.bonusFee / 100);
                const fixed_discount = parseFloat(data.maxValue) / (1 + (data.bonus / 100));
                const max_cost = parseFloat(count) + parseFloat(max_discount);
               
                if(max_cost >= data.maxValue){
                    setCount(Number(fixed_discount).toFixed(2));
                    setCost(Number(data.maxValue).toFixed(2));
                }else{

                    setCost(Number(max_cost).toFixed(2));
                }
               
            
        } else {
            if(count > data.maxValue){
                setCost(Number(data.maxValue).toFixed(2));
                setCount(Number(data.maxValue).toFixed(2))
            }else{
                setCost(Number(count).toFixed(2));
            }
           
        }
      }

  }, [count, b, cost, cust_id, rFund]);
  
  useEffect(() => {
    if(!cust_id){
        setCost(Number(data.minValue).toFixed(2));
    }
  },[count, b, cost, cust_id, rFund])

  function addCart(selectedPaymentOption) {
    props.setDisableBtn(true);
    setDisabled(true);
    setTimeout(()=>{
        setDisabled(false);
        props.setDisableBtn(false);
    },500);
    let walletUsedBalance = Number(0);
    let walletBalance = Number(0);
    
    for (var i = 0; i < contactList.length; i++) {
        costValue += parseFloat(contactList[i].costValue);
        walletBalance += parseFloat(contactList[i].ncentiva_wallet);
        if (contactList[i].wallet === true) {
            walletUsedBalance += parseFloat(contactList[i].cost);
        }
    }
    
    const floatCount = parseFloat(count).toFixed(2);
    const floatrFund = parseFloat(rFund).toFixed(2);
    let floatWallet = Number(0);
    let walletBalanceDeduct;
    walletBalanceDeduct = parseFloat(0);
             
   let cal = parseFloat(localwallet).toFixed(2) - parseFloat(floatCount).toFixed(2);
   let p = parseFloat(localwallet);
   p = Math.abs(p);
   let usedBalance = Number(0);
   let customerBal = parseFloat(balance);
   for ( let i = 0; i < contactList?.length; i++ ) {
       usedBalance += parseFloat(contactList[i].cost);
       floatWallet += parseFloat(contactList[i].ncentiva_wallet)
    }
    floatWallet = floatWallet + parseFloat(props.wallet_balance)
    
    floatWallet = (parseFloat(floatWallet) - parseFloat(walletUsedBalance)).toFixed(2)
    
    if (selectedPaymentOption === "wallet") {
        if (parseFloat(floatWallet)  < count) {
            // toast.warning(`Insufficient Balance `);
            // return false;
        }
        if (count < cminvalue) {
            toast.warning(`Please  Add Minimum Value of Card`);
            return false;
        }
        if (count > cmaxvalue) {
            toast.warning(`Please  Add Maximum Value of Card`);
            return false;
        }
        var item = {
            id: id + 1,
            customer_id: customerId,
            name: data.productname,
            img_path: data.img_path,
            user_id: data.user_id,
            cost: Number(count).toFixed(2),
            bonus: "0.00",
            costValue: Number(count).toFixed(2),
            discount:Number(data.discount).toFixed(2),
            brand_id: data.brand_id,
            processing: data.processing,
            ncentiva_wallet: 0,
            wallet: true,
        }

        axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/add-on-hold-cart`, {
            headers: {
                'Authorization': `Bearer ${getNewCustomerToken()}`
            },
            id: id + 1,
            customer_id: customerId,
            name: data.productname,
            brandCategory: data.brandCategory,
            img_path: data.img_path,
            user_id: data.user_id,
            cost: Number(count).toFixed(2),
            bonus: "0.00",
            costValue: Number(count).toFixed(2),
            discount:Number(data.discount).toFixed(2),
            brand_id: data.brand_id,
            processing: data.processing,
            ncentiva_wallet: 0,
            wallet: true,
        })
        .then((response) => {
            if (response.data.status === "success") {
                toast.success(response.data.message)
                 // Access ws variable here and send WebSocket message
                 if (ws) {
                    ws.send(JSON.stringify({ action: 'addItemToCart' }));
                } else {
                    console.error('WebSocket connection is not established');
                }
                dispatch(getcart(token));
            } else {
                toast.error(response.data.message)
            } 
        })
        .catch((err) => {
            toast.error("Failed to add data in cart");
            console.log(`Error ${err}`);
        });
        if(cost != "NaN"){
            
            // contactList.push(item)
        }
        // localStorage.setItem('listItem', JSON.stringify(contactList))
        setTimeout(() => {
            // window.location.reload();
            
        },500)
        
        // toast.success(`${data.productname} is added on cart `);
        // dispatch(getcart(token));
        handleClose();
        return false;
      
    }
    if (usedBalance === balance) {
        if (parseFloat(floatWallet) < parseFloat(floatCount)) {
            // toast.warning(`Insufficient Balance `);
            // return false;
        }
    }
    if(balance == 0.00) {
        if (parseFloat(floatWallet)== 0.00) {
            // toast.warning(`Insufficient Balance `);
            // return false; 
        }
    }
    if (usedBalance >= balance) {
        if (parseFloat(count) < parseFloat(cminvalue)) {
            toast.warning(`Please  Add Minimum Value of Card`);
            return false;
        }
        if (floatWallet < "0.00") {
            // toast.warning(`Insufficient Balance `);
            // return false; 
        }
        if ( floatWallet > 0.00 ) {
            if ( parseFloat(floatCount) > floatWallet ) {
                // toast.warning(`Insufficient Balance `);
                // return false; 
            } else {
                // toast.success(`${data.productname} is added on cart`);
                
            }
        }

    }
    if (count === 0) {
        // toast.warning(`Insufficient Balance `);
        // return false;
    }
    // if( usedBalance < balance ) {
    //     if (parseFloat(count) > parseFloat(rFund)) {
    //         toast.warning(`Insufficient Blance `);
    //         return false
    //     }
    // }
    var c = 0;
    if (data.faceValue > 0) {
        c = parseFloat(data.faceValue);
        max_cost = parseFloat(data.faceValue);
    } else {
        if (count < cminvalue) {
            toast.warning(`Please  Add Minimum Value of Card`);
            return false;
        }
        if (count > cmaxvalue) {
            toast.warning(`Please  Add Maximum Value of Card`);
            return false;
        }
        c = parseFloat(count);
        if (data.discount > 0) {
            max_discount = c * (data.discount / 100);
            if (data.bonusFee > 0) {
                max_bonus_amount = max_discount * (data.bonusFee / 100);
            }
     
      
            max_cost = parseFloat(c) + parseFloat(max_bonus_amount);
            rebate = max_cost * (data.discount / 100) * (data.bonusFee / 100);
        } else {
            max_cost = parseFloat(c);
        }
    }

    var item = {
        id: id + 1,
        customer_id: customerId,
        name: data.productname,
        img_path: data.img_path,
        user_id: data.user_id,
        cost: Number(c).toFixed(2),
        bonus: Number(max_bonus_amount).toFixed(2),
        costValue: Number(max_cost).toFixed(2),
        discount:Number(data.discount).toFixed(2),
        brand_id: data.brand_id,
        processing: data.processing,
        ncentiva_wallet: 0,
        wallet: false,
    }

    axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/add-on-hold-cart`, {
        headers: {
            'Authorization': `Bearer ${getNewCustomerToken()}`
        },
        id: id + 1,
        customer_id: customerId,
        name: data.productname,
        brandCategory: data.brandCategory,
        img_path: data.img_path,
        user_id: data.user_id,
        cost: Number(c).toFixed(2),
        bonus: Number(max_bonus_amount).toFixed(2),
        costValue: Number(max_cost).toFixed(2),
        discount:Number(data.discount).toFixed(2),
        brand_id: data.brand_id,
        processing: data.processing,
        ncentiva_wallet: 0,
        wallet: false,
    })
    .then((response) => {
        if (response.data.status === "success") {
            toast.success(response.data.message)
               // Access ws variable here and send WebSocket message
            if (ws) {
                ws.send(JSON.stringify({ action: 'addItemToCart' }));
            } else {
                console.error('WebSocket connection is not established');
            }
            dispatch(getcart(token));
        } else {
            toast.error(response.data.message)
        }
    })
    .catch((err) => {
        toast.error("Failed to add data in cart");
        console.log(`Error ${err}`);
    });
// if(usedBalance > balance) {
// toast.warning(`Insufficient Balance `);
// return false;
// }
    // if (usedBalance >= balance) {
    //     var item = {
    //         id: id + 1,
    //         name: data.productname,
    //         img_path: data.img_path,
    //         user_id: data.user_id,
    //         cost: Number(c).toFixed(2),
    //         bonus: "0.00",
    //         costValue: Number(c).toFixed(2),
    //         discount:Number(data.discount).toFixed(2),
    //         brand_id: data.brand_id,
    //         processing: data.processing,
    //         ncentiva_wallet: 0,
    //         wallet: true,
    //     }
    //     if(cost != "NaN"){

    //         // contactList.push(item)
    //     }
    //     localStorage.setItem('listItem', JSON.stringify(contactList))
    //     setTimeout(() => {
    //         // window.location.reload();
    //     },2500)
    //     return false;
    // }
    if(cost != "NaN"){

        // contactList.push(item)
    }
    if(rFund > 0.0 ){
        // localStorage.setItem('listItem', JSON.stringify(contactList))
    }
    if(p > 0.0 ){
        // localStorage.setItem('listItem', JSON.stringify(contactList))
        const walletUpadte = (p - parseFloat(floatCount)).toFixed(2);
    }

    if(cal >=0){
        // localStorage.setItem('listItem', JSON.stringify(contactList))

        if(cal<0.00){
    //         toast.warning(`Insufficient Balance `);
    // return false;
        }
    }

    var item = {
        id: id + 1,
        customer_id: customerId,
        name: data.productname,
        img_path: data.img_path,
        user_id: data.user_id,
        cost: Number(c).toFixed(2),
        bonus: Number(max_bonus_amount).toFixed(2),
        costValue: Number(max_cost).toFixed(2),
        discount:Number(data.discount).toFixed(2),
        brand_id: data.brand_id,
        processing: data.processing,
        ncentiva_wallet: 0,
        wallet: false,
    }

    if (contactList && cost != "NaN" && rFund > 0) {
        // toast.success(`${data.productname} is added on cart `);
        // dispatch(getcart(token));
        handleClose();
        setTimeout(() => {
            // window.location.reload();
        },500)
    }
}

  function incrementCount() {
    if(count == "") {
        setCount(1.00)
     } else {
        if(count < data.minValue) {
            setCount(Number(count) + 1)
        } else {
      if (count < data.maxValue) {
          setCount(Number(parseFloat(count) + 1).toFixed(2));
          if (data.bonusType == "percentage") {
              const c = parseFloat(count) + 1;
              if (data.discount > 0) {
                  const max_discount = c * (data.discount / 100);
                  const max_bonus_amount = max_discount * (data.bonusFee / 100);
                  const max_cost = parseFloat(c) + parseFloat(max_bonus_amount);
                  setCost(Number(max_cost).toFixed(2));
              } else {
                  setCost(Number(c).toFixed(2));
              }

          }
      }}
    }

  }
  function decrementCount() {
      if (count > data.minValue) {
          setCount(Number(parseFloat(count) - 1).toFixed(2));
          if (data.bonusType == "percentage") {
              const c = parseFloat(count) - 1;
              if (data.discount > 0) {
                  const max_discount = c * (data.discount / 100);
                  const max_bonus_amount = max_discount * (data.bonusFee / 100);
                  const max_cost = parseFloat(c) + parseFloat(max_bonus_amount);
                  setCost(Number(max_cost).toFixed(2));
              } else {
                  setCost(Number(c).toFixed(2));
              }

          }
      }
  }


 function addFixedCart (selectedPaymentOption){
        props.setDisableBtn(true);
        setDisabled(true);
        setTimeout(()=>{
            setDisabled(false);
            props.setDisableBtn(false);
        },500);
        
        let usedBalance = Number(0);
        let walletUsedBalance = Number(0);
        let walletBalance = Number(0);
        let customerBal = parseFloat(balance);
        for ( let i = 0; i < contactList?.length; i++ ) {
            usedBalance += parseFloat(contactList[i].cost);
            walletBalance += parseFloat(contactList[i].ncentiva_wallet);
            if ( contactList[i].wallet === true ) {
                walletUsedBalance += parseFloat(contactList[i].cost);
            }
        }

        walletBalance = parseFloat(walletBalance) + parseFloat(props.wallet_balance)

        walletBalance = (parseFloat(walletBalance) - parseFloat(walletUsedBalance)).toFixed(2)
        
        if (selectedPaymentOption === "wallet") {
            let costValue=0;
            const a = parseFloat(props.rbalance_redux)
            if(contactList){
                for(let i=0;i<contactList.length;i++){
                    costValue += parseFloat(contactList[i].cost);
                }
            }
            let left_balance = ((+a)-costValue);
            var item = {
                id: id + 1,
                customer_id: customerId,
                name: data.productname,
                img_path: data.img_path,
               user_id: fixedGiftId,
                cost: (+fixedValue).toFixed(2),
                bonus: Number(max_bonus_amount).toFixed(2),
                costValue: (+fixedValue).toFixed(2),
                discount:fixedDiscount,
                brand_id: data.brand_id,
                processing: fixedProcessing,
                ncentiva_wallet: 0,
                wallet: true,
            }
            axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/add-on-hold-cart`, {
                headers: {
                    'Authorization': `Bearer ${getNewCustomerToken()}`
                },
                id: id + 1,
                customer_id: customerId,
                name: data.productname,
                brandCategory: data.brandCategory,
                img_path: data.img_path,
                user_id: fixedGiftId,
                cost: (+fixedValue).toFixed(2),
                bonus: Number(max_bonus_amount).toFixed(2),
                costValue: (+fixedValue).toFixed(2),
                discount:fixedDiscount,
                brand_id: data.brand_id,
                processing: fixedProcessing,
                ncentiva_wallet: 0,
                wallet: true,
            })
            .then((response) => {
                if (response.data.status === "success") {
                    toast.success(response.data.message)
                    if (ws) {
                        ws.send(JSON.stringify({ action: 'addItemToCart' }));
                    } else {
                        console.error('WebSocket connection is not established');
                    }
                    dispatch(getcart(token));
                } else {
                    toast.error(response.data.message)
                }
            })
            .catch((err) => {
                toast.error("Failed to add data in cart");
                console.log(`Error ${err}`);
            });
            const wallet = localwallet
                if (walletBalance < parseFloat(fixedValue)) {
                    // toast.warning("Insufficient Balance ")
                    // return false;
                }else{
                    // contactList.push(item)
                    // localStorage.setItem('listItem', JSON.stringify(contactList))
                    const walletUpadte = Number(wallet) - Number(fixedValue);
                    window.localStorage.setItem('ncentiva_wallet', walletUpadte)
                    // toast.success(`${data.productname} is added on cart `);
                    // dispatch(getcart(token));
                    handleClose();
                    setTimeout(() => {
                        // window.location.reload();
                    },500)
                    return false;
                }
 
        }
        
        // if( usedBalance >= balance) {
           
        // }
        let costValue=0;
        const a = parseFloat(props.rbalance_redux)
        if(contactList){
            for(let i=0;i<contactList.length;i++){
                costValue += parseFloat(contactList[i].cost);
            }
        }
        let left_balance = ((+a)-costValue);
        var item = {
            id: id + 1,
            customer_id: customerId,
            name: data.productname,
            img_path: data.img_path,
            user_id: fixedGiftId,
            cost: (+fixedValue).toFixed(2),
            bonus: Number(max_bonus_amount).toFixed(2),
            costValue: (+fixedValue).toFixed(2),
            discount:fixedDiscount,
            brand_id: data.brand_id,
            processing: fixedProcessing,
            ncentiva_wallet: Number(fixedBonus) * Number(fixedValue),
            wallet: false,
        }
        axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/add-on-hold-cart`, {
            headers: {
                'Authorization': `Bearer ${getNewCustomerToken()}`
            },
            id: id + 1,
            customer_id: customerId,
            name: data.productname,
            brandCategory: data.brandCategory,
            img_path: data.img_path,
            user_id: fixedGiftId,
            cost: (+fixedValue).toFixed(2),
            bonus: Number(max_bonus_amount).toFixed(2),
            costValue: (+fixedValue).toFixed(2),
            discount:fixedDiscount,
            brand_id: data.brand_id,
            processing: fixedProcessing,
            ncentiva_wallet: Number(fixedBonus) * Number(fixedValue),
            wallet: false,
        })
        .then((response) => {
            if (response.data.status === "success") {
                toast.success(response.data.message)
                if (ws) {
                    ws.send(JSON.stringify({ action: 'addItemToCart' }));
                } else {
                    console.error('WebSocket connection is not established');
                }
                dispatch(getcart(token));
            } else {
                toast.error(response.data.message)
            } 
        })
        .catch((err) => {
            toast.error("Failed to add data in cart");
            console.log(`Error ${err}`);
        });
        // if(parseInt(left_balance)>costValue){
            if(parseInt(left_balance) < parseInt(fixedValue)){
                // toast.warning("Insufficient Balance ");
                // return false;
            }else{
                // contactList.push(item)
                // localStorage.setItem('listItem', JSON.stringify(contactList))
                // toast.success(`${data.productname} is added on cart `);
                // dispatch(getcart(token));
                handleClose();
                setTimeout(() => {
                    // window.location.reload();
                },500)
            }
    }
    function addVariable(remain) {
        props.setDisableBtn(true);
        setDisabled(true);
        setTimeout(() => {
            setDisabled(false);
            props.setDisableBtn(false);
        }, 500);
        let walletUsedBalance = Number(0);
        let walletBalance = Number(0);

        for (var i = 0; i < contactList.length; i++) {
            costValue += parseFloat(contactList[i].costValue);
            walletBalance += parseFloat(contactList[i].ncentiva_wallet);
            if (contactList[i].wallet === true) {
                walletUsedBalance += parseFloat(contactList[i].cost);
            }
        }

        const floatCount = parseFloat(count).toFixed(2);
        const floatrFund = parseFloat(rFund).toFixed(2);
        let floatWallet = Number(0);
        let walletBalanceDeduct;
        walletBalanceDeduct = parseFloat(0);

        let cal = parseFloat(localwallet).toFixed(2) - parseFloat(floatCount).toFixed(2);
        let p = parseFloat(localwallet);
        p = Math.abs(p);
        let usedBalance = Number(0);
        let customerBal = parseFloat(balance);
        for (let i = 0; i < contactList?.length; i++) {
            usedBalance += parseFloat(contactList[i].cost);
            floatWallet += parseFloat(contactList[i].ncentiva_wallet)
        }
        floatWallet = floatWallet + parseFloat(props.wallet_balance)

        floatWallet = (parseFloat(floatWallet) - parseFloat(walletUsedBalance)).toFixed(2)

        if (remain === "true") {
            if (parseFloat(floatWallet)  < count) {
                // toast.warning(`Insufficient Balance `);
                // return false;
            }
            if (count < cminvalue) {
                toast.warning(`Please  Add Minimum Value of Card`);
                return false;
            }
            if (count > cmaxvalue) {
                toast.warning(`Please  Add Maximum Value of Card`);
                return false;
            }
            var item = {
                id: id + 1,
                customer_id: customerId,
                name: data.productname,
                img_path: data.img_path,
                user_id: data.user_id,
                cost: Number(count).toFixed(2),
                bonus: "0.00",
                costValue: Number(count).toFixed(2),
                discount:Number(data.discount).toFixed(2),
                brand_id: data.brand_id,
                processing: data.processing,
                ncentiva_wallet: 0,
                wallet: true,
            }
            axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/add-on-hold-cart`, {
                headers: {
                    'Authorization': `Bearer ${getNewCustomerToken()}`
                },
                id: id + 1,
                customer_id: customerId,
                name: data.productname,
                brandCategory: data.brandCategory,
                img_path: data.img_path,
                user_id: data.user_id,
                cost: Number(count).toFixed(2),
                bonus: "0.00",
                costValue: Number(count).toFixed(2),
                discount:Number(data.discount).toFixed(2),
                brand_id: data.brand_id,
                processing: data.processing,
                ncentiva_wallet: 0,
                wallet: true,
            })
            .then((response) => {
                if (response.data.status === "success") {
                    toast.success(response.data.message)
                    if (ws) {
                        ws.send(JSON.stringify({ action: 'addItemToCart' }));
                    } else {
                        console.error('WebSocket connection is not established');
                    }
                    dispatch(getcart(token));
                } else {
                    toast.error(response.data.message)
                } 
            })
            .catch((err) => {
                toast.error("Failed to add data in cart");
                console.log(`Error ${err}`);
            });
            if(cost != "NaN"){
 
                // contactList.push(item)
            }
            // localStorage.setItem('listItem', JSON.stringify(contactList))
            setTimeout(() => {
                // window.location.reload();
            },500)
            
            // toast.success(`${data.productname} is added on cart `);
            // dispatch(getcart(token));
            handleClose();
            return false;
 
        }

        if (usedBalance === balance) {
            if (parseFloat(floatWallet) < parseFloat(floatCount)) {
                // toast.warning(`Insufficient Balance `);
                // return false;
            }
        }
        if (balance == 0.00) {
            if (parseFloat(floatWallet) == 0.00) {
                // toast.warning(`Insufficient Balance `);
                // return false;
            }
        }
        if (usedBalance >= balance) {
            if (parseFloat(count) < parseFloat(cminvalue)) {
                toast.warning(`Please  Add Minimum Value of Card`);
                return false;
            }
            if (floatWallet < "0.00") {
                // toast.warning(`Insufficient Balance `);
                // return false;
            }
            if (floatWallet > 0.00) {
                if (parseFloat(floatCount) > floatWallet) {
                    // toast.warning(`Insufficient Balance `);
                    // return false;
                } else {
                    // toast.success(`${data.productname} is added on cart`);

                }
            }

        }
        if (count === 0) {
            // toast.warning(`Insufficient Balance `);
            // return false;
        }
        if (usedBalance < balance) {
            if (parseFloat(count) > parseFloat(rFund)) {
                // toast.warning(`Insufficient Blance `);
                setTimeout(() => {
                    setDisabled(false);
                    props.setDisableBtn(false);
                }, 500);
                // return false
            }
        }
        var c = 0;
        if (data.faceValue > 0) {
            c = parseFloat(data.faceValue);
            max_cost = parseFloat(data.faceValue);
        } else {
            if (count < cminvalue) {
                toast.warning(`Please  Add Minimum Value of Card`);
                return false;
            }
            if (count > cmaxvalue) {
                toast.warning(`Please  Add Maximum Value of Card`);
                return false;
            }
            c = parseFloat(count);
            if (data.discount > 0) {
                max_discount = c * (data.discount / 100);
                if (data.bonusFee > 0) {
                    max_bonus_amount = max_discount * (data.bonusFee / 100);
                }


                max_cost = parseFloat(c) + parseFloat(max_bonus_amount);
                rebate = max_cost * (data.discount / 100) * (data.bonusFee / 100);
            } else {
                max_cost = parseFloat(c);
            }
        }

        var item = {
            id: id + 1,
            customer_id: customerId,
            name: data.productname,
            img_path: data.img_path,
            user_id: data.user_id,
            cost: Number(c).toFixed(2),
            bonus: Number(max_bonus_amount).toFixed(2),
            costValue: Number(max_cost).toFixed(2),
            discount: Number(data.discount).toFixed(2),
            brand_id: data.brand_id,
            processing: data.processing,
            ncentiva_wallet: 0,
            wallet: false,
        }
        axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/add-on-hold-cart`, {
            headers: {
                'Authorization': `Bearer ${getNewCustomerToken()}`
            },
            id: id + 1,
            customer_id: customerId,
            name: data.productname,
            brandCategory: data.brandCategory,
            img_path: data.img_path,
            user_id: data.user_id,
            cost: Number(c).toFixed(2),
            bonus: Number(max_bonus_amount).toFixed(2),
            costValue: Number(max_cost).toFixed(2),
            discount: Number(data.discount).toFixed(2),
            brand_id: data.brand_id,
            processing: data.processing,
            ncentiva_wallet: 0,
            wallet: false,
        })
        .then((response) => {
            if (response.data.status === "success") {
                toast.success(response.data.message)
                if (ws) {
                    ws.send(JSON.stringify({ action: 'addItemToCart' }));
                } else {
                    console.error('WebSocket connection is not established');
                }
                dispatch(getcart(token));
            } else {
                toast.error(response.data.message)
            }
        })
        .catch((err) => {
            toast.error("Failed to add data in cart");
            console.log(`Error ${err}`);
        });
        if (usedBalance > balance) {
            // toast.warning(`Insufficient Balance `);
            // return false;
        }

        if (cost != "NaN") {

            // contactList.push(item)
        }
        if (rFund > 0.0) {
            // localStorage.setItem('listItem', JSON.stringify(contactList))
        }
        if (p > 0.0) {
            // localStorage.setItem('listItem', JSON.stringify(contactList))
            const walletUpadte = (p - parseFloat(floatCount)).toFixed(2);
        }

        if (cal >= 0) {
            // localStorage.setItem('listItem', JSON.stringify(contactList))

            if (cal < 0.00) {
                // toast.warning(`Insufficient Balance `);
                // return false;
            }
        }

        var item = {
            id: id + 1,
            customer_id: customerId,
            name: data.productname,
            img_path: data.img_path,
            user_id: data.user_id,
            cost: Number(c).toFixed(2),
            bonus: Number(max_bonus_amount).toFixed(2),
            costValue: Number(max_cost).toFixed(2),
            discount: Number(data.discount).toFixed(2),
            brand_id: data.brand_id,
            processing: data.processing,
            ncentiva_wallet: 0,
            wallet: false,
        }

        if (contactList && cost != "NaN" && rFund > 0) {
            // toast.success(`${data.productname} is added on cart `);
            // dispatch(getcart(token));
            handleClose();
            setTimeout(() => {
                // window.location.reload();
            }, 500)
        }
    }


    function addFix(remain) {
        props.setDisableBtn(true);
        setDisabled(true);
        setTimeout(() => {
            setDisabled(false);
            props.setDisableBtn(false);
        }, 500);

        let usedBalance = Number(0);
        let walletUsedBalance = Number(0);
        let walletBalance = Number(0);
        let customerBal = parseFloat(balance);
        for (let i = 0; i < contactList?.length; i++) {
            usedBalance += parseFloat(contactList[i].cost);
            walletBalance += parseFloat(contactList[i].ncentiva_wallet);
            if (contactList[i].wallet === true) {
                walletUsedBalance += parseFloat(contactList[i].cost);
            }
        }

        walletBalance = parseFloat(walletBalance) + parseFloat(props.wallet_balance)

        walletBalance = (parseFloat(walletBalance) - parseFloat(walletUsedBalance)).toFixed(2)
        if (remain === "true") {
            let costValue = 0;
            const a = parseFloat(props.rbalance_redux)
            if (contactList) {
                for (let i = 0; i < contactList.length; i++) {
                    costValue += parseFloat(contactList[i].cost);
                }
            }
            let left_balance = ((+a) - costValue);
            var item = {
                id: id + 1,
                customer_id: customerId,
                name: data.productname,
                img_path: data.img_path,
                user_id: fixedGiftId,
                cost: (+fixedValue).toFixed(2),
                bonus: Number(max_bonus_amount).toFixed(2),
                costValue: (+fixedValue).toFixed(2),
                discount: fixedDiscount,
                brand_id: data.brand_id,
                processing: fixedProcessing,
                ncentiva_wallet: 0,
                wallet: true,
            }
            axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/add-on-hold-cart`, {
                headers: {
                    'Authorization': `Bearer ${getNewCustomerToken()}`
                },
                id: id + 1,
                customer_id: customerId,
                name: data.productname,
                brandCategory: data.brandCategory,
                img_path: data.img_path,
                user_id: fixedGiftId,
                cost: (+fixedValue).toFixed(2),
                bonus: Number(max_bonus_amount).toFixed(2),
                costValue: (+fixedValue).toFixed(2),
                discount: fixedDiscount,
                brand_id: data.brand_id,
                processing: fixedProcessing,
                ncentiva_wallet: 0,
                wallet: true,
            })
            .then((response) => {
                if (response.data.status === "success") {
                    toast.success(response.data.message)
                    if (ws) {
                        ws.send(JSON.stringify({ action: 'addItemToCart' }));
                    } else {
                        console.error('WebSocket connection is not established');
                    }
                    dispatch(getcart(token));
                } else {
                    toast.error(response.data.message)
                }
            })
            .catch((err) => {
                toast.error("Failed to add data in cart");
                console.log(`Error ${err}`);
            });
            const wallet = localwallet
            if (walletBalance < parseFloat(fixedValue)) {
                // toast.warning("Insufficient Balance ")
                // return false;
            } else {
                // contactList.push(item)
                // localStorage.setItem('listItem', JSON.stringify(contactList))
                const walletUpadte = Number(wallet) - Number(fixedValue);
                window.localStorage.setItem('ncentiva_wallet', walletUpadte)
                // toast.success(`${data.productname} is added on cart `);
                // dispatch(getcart(token));
                handleClose();
                setTimeout(() => {
                    // window.location.reload();
                }, 500)
                return false;
            }

        }
        let costValue = 0;
        const a = parseFloat(props.rbalance_redux)
        if (contactList) {
            for (let i = 0; i < contactList.length; i++) {
                costValue += parseFloat(contactList[i].cost);
            }
        }
        let left_balance = ((+a) - costValue);
        var item = {
            id: id + 1,
            customer_id: customerId,
            name: data.productname,
            img_path: data.img_path,
            user_id: fixedGiftId,
            cost: (+fixedValue).toFixed(2),
            bonus: Number(max_bonus_amount).toFixed(2),
            costValue: (+fixedValue).toFixed(2),
            discount: fixedDiscount,
            brand_id: data.brand_id,
            processing: fixedProcessing,
            ncentiva_wallet: Number(fixedBonus) * Number(fixedValue),
            wallet: false,
        }
        axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/add-on-hold-cart`, {
            headers: {
                'Authorization': `Bearer ${getNewCustomerToken()}`
            },
            id: id + 1,
            customer_id: customerId,
            name: data.productname,
            brandCategory: data.brandCategory,
            img_path: data.img_path,
            user_id: fixedGiftId,
            cost: (+fixedValue).toFixed(2),
            bonus: Number(max_bonus_amount).toFixed(2),
            costValue: (+fixedValue).toFixed(2),
            discount: fixedDiscount,
            brand_id: data.brand_id,
            processing: fixedProcessing,
            ncentiva_wallet: Number(fixedBonus) * Number(fixedValue),
            wallet: false,
        })
        .then((response) => {
            if (response.data.status === "success") {
                toast.success(response.data.message)
                if (ws) {
                    ws.send(JSON.stringify({ action: 'addItemToCart' }));
                } else {
                    console.error('WebSocket connection is not established');
                }
                dispatch(getcart(token));
            } else {
                toast.error(response.data.message)
            }
        })
        .catch((err) => {
            toast.error("Failed to add data in cart");
            console.log(`Error ${err}`);
        });
        // if(parseInt(left_balance)>costValue){
        if (parseInt(left_balance) < parseInt(fixedValue)) {
            // toast.warning("Insufficient Balance ")
        } else {
            // contactList.push(item)
            // localStorage.setItem('listItem', JSON.stringify(contactList))
            // toast.success(`${data.productname} is added on cart `);
            // dispatch(getcart(token));
            handleClose();
            setTimeout(() => {
                // window.location.reload();
            }, 500)
        }
    }

      const optionGiftcard = {
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "32px",
    // textAlign: "center",
    color: "#171F46",
    width: "100%",
    maxWidth: '200px'
    // paddingLeft: "25px"
}
    if (cost === "NaN" && cust_id) {
        setCost(Number(data.minValue).toFixed(2));
    }

    let wUsedbalance = Number(0);
    for (let i = 0; i < contactList?.length; i++) {
        if (contactList[i].wallet == true) {
            wUsedbalance += parseFloat(contactList[i].cost);
        }
    }
    wUsedbalance = parseFloat(props?.wallet_balance) - parseFloat(wUsedbalance);

    wUsedbalance.toFixed(2);
    useEffect(()=>{
        if(bonuss && fixedValue && cust_id){
            setBonusCashModal(((fixedValue * bonuss)/100).toFixed(2));
          }
          else{
            setBonusCashModal(((fixedValue * fixedDiscount)/100).toFixed(2));
          }
      },[openModal])
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="viewcard-modal"
      >
        <Modal.Header closeButton className='border-0'></Modal.Header>
        <Modal.Body className="pt-0 px-lg-5 pb-5">
          <div className="viewcard-product">
            <div className="listing-logo">
                <img src={props.imagepath} alt="" className="img-fluid"/>
            </div>
            <div className="viewcard-dtl">
                <h1>{props.name}</h1>
            </div>
          </div>
          <div className="add-viewgift d-flex flex-wrap align-items-center justify-content-center flex-wrap my-4">
          {props?.data?.fixed?.length>0 ? <div className="inputwithunit mb-2 w-100">
                                       
          <Form.Select style={optionGiftcard} className='mx-auto'  onChange={(e)=>{const selectedValue = JSON.parse(e.target.value);setFixedValue(selectedValue.faceValue);setFixedDiscount(selectedValue.discount);setFixedGiftId(selectedValue.giftcart_id)}}>
                           {
                               props?.data?.fixed?.map((data)=>{
                                   return(
                                       
                                    <option key={data.giftcart_id} value={JSON.stringify(data)}  className="">$     {(+data.faceValue)?.toFixed(2)}</option>        
                                  
                               )                                
                             })
                          
                           }
                        </Form.Select>
                                   </div>
                                   :
          <>
          <div className='d-flex w-100 justify-content-center align-items-center'>
          <button
          type="button"
          className="btn listing-input-btn btn-minus me-1"
          onClick={decrementCount}
          >
            <FaMinus/>
        </button>
        <div className="inputwithunit mb-2">
            <span>$</span>
            <input
            type="number"
            className="form-control"
            value={count}
            onChange={(e)=>setCount( e.target.value )}
            />
        </div>
        <button
        type="button"
        className="btn listing-input-btn btn-plus ms-1"
        onClick={incrementCount}
        >
            <FaPlus/>
        </button>
          </div>
        </>
        }
            {/* <button type="button" className="btn btn-main btn-add py-0">Add</button>
             */}
             {disabled || props.disableBtn  ? 
            customer &&  <div className="d-flex justify-content-center align-items-center w-100">
                <button type="button" className="btn btn-main btn-add py-0 px-4 px-sm-5 mx-0" 
            //   style={{ backgroundColor: `${props?.btnColor}80`, color: props?.btnText, border: `1px solid transparent` }}
              style={defaultNcentiva === "true"
                ? { backgroundColor: `${props?.btnColor}80`, color: props?.btnText, border: `1px solid transparent`, width: props?.data?.fixed?.length>0 ? "200px" : "150px"  }
                : defaultNcentiva === "false" ? { backgroundColor: `${props?.btnColor}`, color: props?.btnText, border: `1px solid ${props?.btnColor}`, width: props?.data?.fixed?.length>0 ? "200px" : "150px"   } : null
              }>Add
              </button>
             </div>
              :       
                                        <>
                                          {customer ? 
                                          <>
                                          {props?.data?.fixed?.length>0 ? 
                                          
                                          <Link to=""
                                          onClick={() => {
                                            if(Number(rFund) < Number(fixedValue)) {
                                            }
                                            if (parseFloat(rFund) < parseFloat(fixedValue) && parseFloat(wUsedbalance) > parseFloat(fixedValue)) {
                                                addFix("true");
                                            } else if (parseFloat(rFund) > parseFloat(fixedValue) && parseFloat(wUsedbalance) < parseFloat(fixedValue)) {
                                                addFix();
                                            } else if (parseFloat(rFund) >= parseFloat(fixedValue) && parseFloat(wUsedbalance) >= parseFloat(fixedValue)) {
                                              openModal();
                                            } else {
                                                addFix();
                                            }
                                          }} replace type="button" className="btn btn-main btn-add py-0 px-4 px-sm-5 mx-0"  
                                          style={defaultNcentiva === "true"
                                            ? { backgroundColor: `${props?.btnColor}80`, color: props?.btnText, border: `1px solid transparent`, width: "200px" }
                                            : defaultNcentiva === "false" ? { backgroundColor: `${props?.btnColor}`, color: props?.btnText, border: `1px solid ${props?.btnColor}`, width: "200px" } : null
                                          }
                                        //   style={{ backgroundColor: `${props?.btnColor}80`, color: props?.btnText, border: `1px solid transparent` }}
                                          >
                                      Add
                                  </Link>
                                        :
                                        customer &&    <div className='w-100 d-flex justify-content-center' >
                                            <Link to=""
                                          onClick={() => {
                                            if(Number(rFund) < Number(count)) {
                                            }
                                            if (parseFloat(rFund) < parseFloat(count) && parseFloat(wUsedbalance) >= parseFloat(count)) {
                                                addVariable("true");
                                            } else if (parseFloat(rFund) > parseFloat(count) && parseFloat(wUsedbalance) < parseFloat(count)) {
                                                addVariable();
                                            } else if (parseFloat(cartData?.data?.remaingBalance) >= parseFloat(count) && parseFloat(cartData?.data?.remaingWalletBalance) >= parseFloat(count)) {
                                              openModal();
                                            } else{
                                                addVariable()
                                            }
                                          }} replace type="button" className="btn btn-main btn-add py-0 px-4 px-sm-5 mx-0" 
                                          style={defaultNcentiva === "true"
                                            ? { backgroundColor: `${props?.btnColor}80`, color: props?.btnText, border: `1px solid transparent`, width: "150px" }
                                            : defaultNcentiva === "false" ? { backgroundColor: `${props?.btnColor}`, color: props?.btnText, border: `1px solid ${props?.btnColor}`, width: "150px" } : null
                                          }
                                        //    style={{ backgroundColor: `${props?.btnColor}80`, color: props?.btnText, border: `1px solid transparent` }}
                                           >
                                        Add
                                    </Link>
                                          </div>
                                            }
                                            </>
                                     :
                                     customer && <div className='d-flex w-100 justify-content-center'>
                                       <button
                                        //  disabled
                                        type="button" className="btn btn-main py-0 px-4 px-sm-5 mx-0"
                                        style={defaultNcentiva === "true"
                                            ? { backgroundColor: `${props?.btnColor}80`, color: props?.btnText, border: `1px solid transparent`, cursor: "not-allowed", width: "200px" }
                                            : defaultNcentiva === "false" ? { backgroundColor: `${props?.btnColor}`, color: props?.btnText, border: `1px solid ${props?.btnColor}`, cursor: "not-allowed", width: "200px" } : null
                                        }
                                    >
                                        Add
                                    </button>
                                    </div>
                                       }
                                        </>
                                    
                                    }
                                    {
                                     (showaddmodal && <PaymentConfirmationModal paymentOption={props.paymentOption} onChangeFunction={props.onChangeFunction} onConfirm={handleConfirmModal} reaminingBonus={cartData.data.remaingWalletBalance}  handleClose={closeModal} value={props?.data?.fixed?.length > 0 ? fixedValue : count}   btnColor={props.btnColor} btnText={props.btnText} rFund={cartData.data.remaingBalance} isFixed={props.isFixed} handleOpen={openModal}  />)
                                    }
                                    {props.data?.fixed.length>0 ? 
                                    <>{
                                    fixedValue ? <p className="text-main mt-4 w-100 text-center">You get 
                                    <span className="fs-3 fw-bold">
                                        {` $${Number(fixedValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                                    </span> { cust_id===undefined ?  
                                    // <span> {` (+${Number(props.data.discount)
                                    //     .toFixed(2)
                                    //     .toString()
                                    //     .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}12%)`}
                                    // </span> 
                                    <span style={{color: "#F5C400"}}> {` (+$${bonusCashModal
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Bonus Cash)`} 
                                    </span>
                                    : 
                                    <span style={{color: "#F5C400"}}> {` (+$${bonusCashModal
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Bonus Cash)`} 
                                    </span>}</p>
                                    :<p className="text-main mt-4 w-100 text-center">You get <span className="fs-3 fw-bold">{` $${Number(data.fixed[0].faceValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</span></p>
                                   }</>:
            <p className="text-main mt-4">You get <span className="fs-3 fw-bold"> ${cost}</span> (+{b}%)</p>}
          </div>
          <ViewCardTab description={props.description} redemptionInstructions={props.redemptionInstructions} termsAndConditions={props.termsAndConditions} availableDenominations={props.data.fixed} defaultNcentiva={defaultNcentiva} btnColor={props.btnColor} btnText={props.btnText} cmsData={props.cmsData} />
        </Modal.Body>
      </Modal>
    );
  }
  const ViewCard = props => {
    // const [modalShow, setModalShow] = React.useState(false);
    const brand_id = props.brand_id
    const coupon = props.coupondescription
    const [name, setName] = useState(props.data.productname ?? props.data.brand_name);
    const [description, setDescription] = useState(props.data.description);
    const [redemptionInstructions, setRedemptionInstructions] = useState(props.data.redemptionInstructions);
    const [termsAndConditions, setTermsAndConditions] = useState(props.data.termsAndConditions);
    const [imagepath, setImagePath] = useState(props.data.img_path);

    const modalClose = () =>{
        props?.modalShowDC ? props.setModalShowDC(false) : props?.giftImageModalShow ? props?.setGiftImageModalShow(false) :props.setModalShow(false);
    }
    const modalData = props.data
    // for mobile devices tooltip is hidden
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        const checkIsMobile = () => {
          setIsMobile(window.innerWidth <= 992);
        };
    
        checkIsMobile();
        window.addEventListener('resize', checkIsMobile);
    
        return () => {
          window.removeEventListener('resize', checkIsMobile);
        };
    }, []);
  return (
    <>
      {props.carousel !== "carousel" && !isMobile ? (
        <OverlayTrigger
          placement="left"
          overlay={<Tooltip id="info-tooltip">Info</Tooltip>}
        >
          <div
            variant=""
            onClick={() => props.setModalShow(true)}
            className="text-start ps-0 fs-5"
            style={{ cursor: "pointer" }}
          >
            <BsInfoCircle className="infoCircle" />
          </div>
        </OverlayTrigger>
      ) : (
        <div
          onClick={() => props.setModalShow(true)}
          className="text-start ps-0 fs-5"
          style={{ cursor: "pointer" }}
        >
          <BsInfoCircle className="infoCircle" />
        </div>
      )}
      <ViewCardModal
        name={name}
        imagepath={imagepath}
        description={description}
        redemptionInstructions={redemptionInstructions}
        termsAndConditions={termsAndConditions}
        data={modalData}
        rbalance_redux={props.rbalance_redux} disableBtn={props.disableBtn} setDisableBtn={props.setDisableBtn}
        primaryColor={props.primaryColor} secondaryColor={props.secondaryColor} btnColor={props.btnColor} btnText={props.btnText}
        defaultNcentiva= {props} 
        show={props.modalShow || props.modalShowDC || props.giftImageModalShow}
        remainingBalance={props.walletBalace}
        onConfirm={props.handleConfirm}
        rFund={props.rfund}
        dataType={props.dataType}
        isFixed={props.isfixedValue}
        onChangeFunction={props.onChange}
        paymentOption={props.payment}
        wallet_balance={props.wallet_balance}
        onHide={modalClose}
        ws = {props.ws}
        cmsData={props.cmsData}
      />
    </>
  )
}
export default ViewCard