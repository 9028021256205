import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, useNavigate, useParams } from "react-router-dom";
import jsPDF from "jspdf";
import axios from "axios";
import { useEffect } from "react";
import logo from "../../assets/images/logo.png";
import "jspdf-autotable";
// import "../styles/transaction.css"
import { FaSortUp, FaSortDown } from "react-icons/fa";
import { Bars } from 'react-loader-spinner';
import {
  Button,
  Col,
  Container,
  Row,
  Form,
  DropdownButton,
  Dropdown,
  Modal
} from "react-bootstrap";
import { toast } from "react-toastify";
import { DateRangePicker} from 'react-date-range'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Select from "react-select";
import "../../styles/monetray.scss"
import { useDispatch, useSelector } from 'react-redux';
import { getBusinessContact } from '../../Redux/BusinessContacts/action';
import { getNewPartnerToken } from "../../config/cookie.config";
const BusinessMonetary = () => {
  let navigate = useNavigate();
  var token;
  var userrole;
  var usersid;
  const user = JSON.parse(window.localStorage.getItem("business-client"));
  let id;
  const businessPartner = JSON.parse( window.localStorage.getItem('business-client'));
  id=businessPartner?.user?._id;
//   const params = useParams();

  const [search, setSearch] = useState("");
  const [role] = useState(userrole);
  const [userid] = useState(usersid);
  const [customerData, setCustomerData] = useState([]);

  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [order, setOrder] = useState("ASC");
  const [emailorder, setEmailorder] = useState("ASC");
  const [valueorder, setValueorder] = useState("ASC");
  const [updateorder, setUpdateorder] = useState("ASC");
  const [noData,setNoData] = useState(false);
  const [sort, setSort] = useState("");
  const [sendMail, setSendMail] = useState([]);
  const [totalFund, setTotalFund] = useState([]);
  const [usedFund, setUsedFund] = useState([]);
  const [allproducts, setAllProducts] = useState([]);
  const [startDate,setStartDate]= useState('');
  const [endDate,setEndDate]= useState('');
  const [show, setShow] = useState(false);
  const [showPage,setShowPage] = useState(1);
  const [monetaryData,setMonetaryData] = useState();
  const [custFilter, setCustFilter] = useState("");
  const [apiResponse, setApiResponse] = useState(false);

 
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "var(--darkblue)" : "var(--color)",
      fontSize: 14,
      padding: 10,
      marginBottom: 0,
      backgroundColor: state.isSelected ? "rgba(215, 223, 233, 0.34);" : "#fff",
    }),

    control: () => ({
      // none of react-select's styles are passed to <Control />
      width: "auto",
      display: "flex",
      border: "1px solid #ced4da",
      borderRadius: ".25rem",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const color = "var(--color)";
      const fontSize = "14px";
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition, color, fontSize };
    },
  };


  const [getCustomer, setCustomer] = useState([]);

  
  // name
  const sorting = (order) => {
    if (order === "DSC") {
      setSort("dscName");

      setOrder("DSC");

    } else {
      setSort("ascName");

      setOrder("ASC");

    }
  };

  const emailsorting = (emailorder) => {
    if (emailorder === "DSC") {
      setSort("dscEmail");

      setEmailorder("DSC");
    } else {
      setSort("ascEmail");

      setEmailorder("ASC");
    }
  };

  const valuesorting = (valueorder) => {
    if (valueorder === "DSC") {
      setSort("dscName");

      setValueorder("DSC");
    } else {
      setSort("ascName");

      setValueorder("ASC");
    }
  };

  const updatesorting = (updateorder) => {
    if (updateorder === "DSC") {
      setSort("dscCreate");

      setUpdateorder("DSC");
    } else {
      setSort("ascCreate");

      setUpdateorder("ASC");
    }
  };


  const formatDate = (date) => {
    // Convert the date to EST time zone
    const estDate = new Date(date);
    const estOptions = {
      timeZone: "America/New_York",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
    };
  
    // Format the date as per the EST time zone, without seconds
    return estDate.toLocaleString("en-US", estOptions).replace(",", "");
  };

  const pages = new Array(numberOfPages).fill(null).map((v, i) => i);
  const getTransactionData = (pageNumber,start,end) => {
    setIsLoading(true)
    const user = JSON.parse(window.localStorage.getItem("business-client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/monetarytransaction/${user?.user?.business_partner_id}?page=${pageNumber}&search=${search}&sort=${sort}&startDate=${start}&endDate=${end}&custFilter=${custFilter}`, {
      headers: {
        'Authorization': `Bearer ${getNewPartnerToken()}`
      }
    })
      .then((response) => {
        if(response.data.message == "Please Login"){
          localStorage.removeItem('business-client');
          navigate('/business-login');
          toast.error("Session Expired Please Login Again")
        }    
          setApiResponse(true);
          setIsLoading(false);
        setCustomer(response.data.customerFilter);
        if(response.data.getFund === "NaN" || "0"){
          setTotalFund(0.00);
        }else{
          setTotalFund(response.data.getFund);
        }
        if(response.data.usedFund === "NaN" || "0"){
          setUsedFund(0.00);
        }else{
          setUsedFund(response.data.usedFund);
        }
        setMonetaryData(response.data);
        const alltransaction = response.data.monetary;
        const totalPages = response.data.totalPages;
        setCustomerData(response?.data?.monetary);
        setNumberOfPages(totalPages);
        if(alltransaction?.length===0){
          setNoData(true);
        }
    
        setIsLoading(false);
      })
      .catch((error) =>{
        console.log(error)
        if (error.response.data.message === "Unauthorized User" ){
          localStorage.removeItem('business-client');
          navigate('/business-login');
          toast.error("Session Expired Please Login Again")
        }
      })
  };
  //for PDF


  const handleChange = (selectedOption) => {
    if (selectedOption.value === "All Customers") {
      setCustFilter("");
      // getTransactionData(pageNumber,startDate,endDate,custFilter);
       setIsLoading(false);
    } else {
      // setSearch(selectedOption.value);
      const promise = new Promise((resolve, reject) => {
        // Perform your asynchronous operation here
        // For example, fetch data from an API
        setCustFilter(selectedOption.value);
      });
      promise.then(data => {
        getTransactionData(pageNumber,startDate,endDate,custFilter);
        setIsLoading(false);
      }).catch(error => {
        console.error(error);
        setIsLoading(false);
      });
    }
  };




  let items = [];
  let leftSide = pageNumber - 1;

  if (leftSide <= 0) leftSide = 0;
  let rightSide = pageNumber + 2;

  if (rightSide > numberOfPages) rightSide = numberOfPages;
  for (let number = leftSide; number < rightSide; number++) {

    items.push(
      <li key={number} className={(number === pageNumber ? "active" : "")} onClick={() => { setPageNumber(number) }}>
        {number + 1}
      </li>,
    );
  }

  const pagenumberSearch = (e)=>{
    var pages =parseInt(e) ;
    var page= pages -1;
    if(pages<1){
      setPageNumber(0);
      setShowPage(1);
    }else if(pages>numberOfPages){
      setPageNumber(numberOfPages-1);
      setShowPage(numberOfPages);
    }else if(pages>=1 && pages<=numberOfPages){
      setPageNumber(page);
      setShowPage(pages);
    }
    else{
      setPageNumber(0);
      setShowPage(pages);
    }
    }


    const nextPage = () => {
      if (pageNumber < numberOfPages) {
        setPageNumber(pageNumber + 1);
        setShowPage(pageNumber+2);
      }
    }
  
    const prevPage = () => {
      if (pageNumber > 0) {
        setPageNumber(pageNumber - 1);
        setShowPage(pageNumber);
      }
    }

  const handleSearch = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
    setPageNumber(0);
  };

  useEffect(() => {
    setIsLoading(true);
    getTransactionData(pageNumber,startDate,endDate,custFilter);
    // redirectToLogin();
    setIsLoading(false);
  }, [pageNumber, search, sort,custFilter]);


  const dispatch = useDispatch();
  
  useEffect(() => {
    const user = JSON.parse(window.localStorage.getItem("business-client"));
    const id = user?.userData?._id;
    const token = user?.token;
  
    dispatch(getBusinessContact(id, token, navigate, toast));
  
  }, [dispatch, navigate, toast]);



  const userID = localStorage.getItem('business-client');
  const users = JSON.parse(userID);
  const userIds = users?.user?._id;


  const handleSelect = (date) => {
    let filtered = allproducts.filter((product) => {
      let productDate = new Date(product["createdAt"]);
      return(
        productDate >= date.selection.startDate &&
        productDate <= date.selection.endDate
        );
      })
      setStartDate(date.selection.startDate);
      setEndDate(date.selection.endDate);
      setCustomerData(filtered);
      getTransactionData(pageNumber,date?.selection?.startDate,date?.selection?.endDate);
    }

    
    const selectionRange = {
      startDate: startDate,
      endDate: endDate,
      key: 'selection',
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);   
    const [btnColor,setBtnColor] = useState('');
    const [loading,setLoading] = useState(true);
    const [primaryColor,setPrimaryColor]= useState('');
    const [secondaryColor,setSecondaryColor] = useState('');
  
    // useEffect(()=>{
    //   setLoading(true);
    //  axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/getcmsbusiness/${id}`)
    //  .then((res)=>{
    //    if(res.data?.cms?.default_ncentiva === false){ 
    //    setBtnColor(res.data?.cms?.btn_color);
    //    setPrimaryColor(res.data?.cms?.primary_color);
    //    setSecondaryColor(res.data?.cms?.secondary_color);
    //  }else{
    //      setBtnColor('#3D05B6');
    //      setPrimaryColor("#FF7020");
    //      setSecondaryColor("#F5C400");
    //    }
    //    setLoading(false);
    //  }).catch((err)=>{
    //    console.log(err);
    //  })
    // },[])



  return (
    <>
      <div className="p-3 p-lg-5">
      <div className="d-flex mb-4 align-items-center">
    <h1 className="font20 text-dark">Monetary Transactions </h1>
      </div>
        <Container fluid className="bg-white p-4 rounded shadow">
          <Row className="row-break1 ">
            <Col lg={4}>
              <div className="search-btn">
                <div className="d-flex align-items-center searchWrapper w-100 ms-0">
                  <Form.Control
                    type="text"
                    className="border-0 searchInput"
                    placeholder="Search"
                    onChange={handleSearch}
                  />
                  <Button
                    variant="primary"
                    title="Click here to search"
                    className="searchBtn d-flex justify-content-center align-items-center"
                  >
                    <img
                      src="/assets/images/search.png"
                      className="img-fluid"
                      alt=""
                    />
                  </Button>
                </div>
              </div>
            </Col>
            <Col lg={4}>
            <div className="custom-select select-item mt-1 mt-lg-0">
                      <Select
                        styles={customStyles}
                        onChange={handleChange}
                        options={getCustomer}
                        defaultValue={{
                          value: "Filter by:All",
                          label: "Filter by:All",
                        }}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </div>
            </Col>
            <Col lg={4}>
              <div className="date-range-modal ">
                <Button variant="primary"
                  type="button"
                  className="fullRounded fw-bold font14 dateRangeBtn mt-2 mt-lg-0"
                  onClick={handleShow}>
                  <span>Search With Date</span>
                </Button>
                  <Modal
                    show={show}
                    onHide={handleClose}
                    size="lg"
                    centered
                    >
                  <Modal.Header closeButton>
                  <Modal.Title>Date Range</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                  <DateRangePicker ranges={[selectionRange]} onChange={handleSelect} />
                  </Modal.Body>
                  </Modal>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center">
          <Container>
            <Row>
              <Col xl={7} xxl={8} lg={7} className="mt-1" >
                <div className="text-dark font12">
                    <div className="d-flex align-items-center w-100 ms-0"> {
                      apiResponse ? 
                      <h3 className="font16 text-dark">Total Funds Transferred : ${Number(monetaryData?.getFund)?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h3>
                      : 
                      ""
                    }
                    </div>
                    <div className="d-flex align-items-center w-100 ms-0">
                      {
                        apiResponse ? 
                      <h3 className="font16 text-dark">Total Funds Used : ${Number(monetaryData?.usedcost)?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h3>
                      : ""
                      }
                    </div>
                </div>
              </Col>
              <Col xl={5} xxl={4} lg={5} className="mt-1" >
              <div className="text-dark font12">
                    <div className="d-flex align-items-center w-100 ms-0">
                      {apiResponse ?   
                      <h3 className="font16 text-dark">Total Bonus Generated: ${Number(monetaryData?.totalBonus)?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h3>
                      : ""}
                    </div>
                    <div className="d-flex align-items-center w-100 ms-0">
                      {
                        apiResponse ? 
                        <h3 className="font16 text-dark">Total Business Partner Rebate : ${Number(monetaryData?.rebate)?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h3>
                      : ""}
                    </div>
                    {/* <TransModal/> */}
                </div>
              </Col>

            </Row>
          </Container>
            <Col md={12} className="mt-4 table_custom_grey_scroll ">
              <div className="table-responsive">
                <table className="table " style={{ verticalAlign: "middle" }}>
                  <thead className="bgLightRed font14">
                    <tr className="text-center">
                    
                      <th className="py-3">
                        {" "}
                        <div className="d-flex align-items-center justify-content-center ">
                          {" "}
                          <span className="me-1 "> Date</span>{" "}
                          {updateorder === "ASC" ? (
                            <FaSortDown onClick={() => updatesorting("DSC")} />
                          ) : (
                            <FaSortUp onClick={() => updatesorting("ASC")} />
                          )}
                        </div>{" "}
                      </th>

                      <th className="py-3">
                        <div className="d-flex align-items-center justify-content-center" style={{ cursor: 'pointer' }}>
                          <span className="me-1">Customer Name</span>
                          {valueorder === "ASC" ? (
                            <FaSortDown onClick={() => valuesorting("DSC")} />
                          ) : (
                            <FaSortUp onClick={() => valuesorting("ASC")} />
                          )}
                        </div>
                      </th>
                      <th className="py-3">
                        <div className="d-flex align-items-center justify-content-center" style={{ cursor: 'pointer' }}>
                          <span className="me-1">Transaction Type</span>
                        </div>
                      </th>
                      <th className="py-3">
                        {" "}
                        <div className="d-flex align-items-center justify-content-center ">
                          {" "}
                          <span className="me-1 ">Value</span>{" "}
                        </div>
                      </th>
                      <th className="py-3">
                        {" "}
                        <div className="d-flex align-items-center justify-content-center ">
                          {" "}
                          <span className="me-1 ">Action</span>{" "}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="font14 fw-medium text-center">
               
                    {apiResponse === false ? (
                      <tr style={{ backgroundColor: "#eee" }}>
                        <td colSpan="8">
                        <Bars
                        height="80"
                        width="100%"
                        margin='auto'
                        color="#FF7020"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        />
                        </td>
                      </tr>
                      ) : (
                        customerData?.map((cust) => (
                          <tr
                            key={cust._id}
                          >
                             <td className="py-3 font14 fw-medium ">
                              {formatDate(cust.createdAt).slice(0, 16).replace("T", "      ")}
                              {/* {cust.transaction_id} */}
                            </td>
                           
                            <td className="py-3 font14 fw-medium ">
                            {/* ${Number(cust.fund).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} */}
                            {cust.customer_name}
                            </td>
                            {/* <td>${Number(cust.bonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td> */}
                           
                       
                            <td className="py-3 font14 fw-medium">
                              {cust.transaction_type}
                              {/* {" "}
                              {cust.updatedAt.slice(0, 16).replace("T", "      ")} */}
                            </td>
                            <td className="py-3 font14 fw-medium ">
                            {/* {cust.transaction_type === "Funds Updated" ? ( */}
                                {cust.deduct ? (
                                  <span style={{ color: "red" }}>
                                    -
                                  </span>
                                ) : (
                                  <span style={{ color: "green" }}>
                                    +
                                  </span>
                                )}
                              {/* ) : ""} */}

                            ${Number(cust.amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </td>
                            <td>
                              {role === "Admin" ? (
                                <DropdownButton
                                  variant="white"
                                  className="p-0  tableActionDropDown text-center"
                                  title={
                                    <div className="actionBtn">
                                      <span></span>
                                      <span></span>
                                      <span></span>
                                    </div>
                                  }
                                  drop="start"
                                >
                                  {/* <Dropdown.Item className='font12 fw-medium' >View</Dropdown.Item> */}
                                  <Dropdown.Item
                                    className="font14 fw-medium"
                                    // onClick={(e) => pdfDownlod(cust._id)}
                                  >
                                    Details
                                  </Dropdown.Item>
                                </DropdownButton>
                              ) : (
                                <DropdownButton
                                  variant="white"
                                  className="p-0  tableActionDropDown text-center"
                                  title={
                                    <div className="actionBtn">
                                      <span></span>
                                      <span></span>
                                      <span></span>
                                    </div>
                                  }
                                  drop="start"
                                >
                                  <Dropdown.Item
                                    className="font14 fw-medium"
                                    // onClick={(e) => pdfDownlod(cust._id)}
                                  >
                                    Details
                                  </Dropdown.Item>
                                </DropdownButton>
                              )}
                            </td>
                          </tr>
                        )))}
                     {isLoading ? "" : 
                     <>
                     {customerData?.length  === 0 && apiResponse? <tr >
                        <td colSpan="8">
                        <h1 className="font16 text-dark">No Transactions</h1>
                        </td>
                      </tr>:""}
                     </>
                     }
                    
                  </tbody>
                </table>
              </div>
            </Col>

            {/* {startDate? '':<> */}

            {
            numberOfPages === 0 ? "" :
              <Col className="mt-5 mb-4">
              {
                <ul className="custPagination d-flex justify-content-center list-unstyled font14">
                  {pageNumber === 0 ? (
                    ""
                  ) : (

                    <li onClick={prevPage}>Previous </li>

                  )}


                  {items}


                  {numberOfPages - 1 === pageNumber ? (
                    ""
                    ) : (
                      <li onClick={nextPage}>Next</li>
                      )}
                      <li className="font14"><input  style={{width:"25px",border:'1px solid #4C00EF',borderRadius:'4px',textAlign:'center'}} value={showPage?showPage:''} type="text" name="number" id="" onChange={(e)=> {
                            pagenumberSearch(e.target.value)
                        }}  ></input> <span style={{color:'#4C00EF'}}>/</span> {numberOfPages}</li>
                </ul>
              }
            </Col>
            }
            {/* </>
} */}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default BusinessMonetary;
