import React, { useState, useEffect } from "react";
import { Link, useNavigate , useParams } from "react-router-dom";
import { Button, Col, Container, Row, Form, DropdownButton, Dropdown, } from "react-bootstrap";
import { default as ReactSelect } from "react-select";
import { toast } from 'react-toastify';
import Select from 'react-select';
import axios from "axios";
// import { colourOptions } from "../components/data";
import { Bars } from "react-loader-spinner";
import { getNewToken } from "../config/cookie.config";


const BussinessBonus = () => {
const { id } = useParams();
  const user = JSON.parse(window.localStorage.getItem('client'));
  const userrole = user.user.role;
  const [role, setRoles] = useState(userrole);
  const [bonus_type, setBonusType] = useState("");
  const [bonus, setBonus] = useState("");
  const [getbrand, setGetBrand] = useState([]);
  const [selectBrand, setSelectBrand] = useState([]);
  const [customerData, setCustomerData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  //const [flat, setFlat] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setIsLoading(true);

    getbrands();
    getdiscountbrands();

    setIsLoading(false);

  }, []);
  const getbrands = () => {

    axios
        .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getallbrands`)
        .then((response) => {        
        
         setGetBrand(response.data.brands);

        })
        .catch((error) => console.error(`Error: ${error}`));
  };
  const getdiscountbrands = () => {

    axios
        .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getdiscountbrands`)
        .then((response) => {     
         setCustomerData(response.data.brands);
      })
      .catch((error) => console.error(`Error: ${error}`));
  };
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectBrand.length > 0) {
      if (bonus_type === "flat" && selectBrand[0].value === "All Brands") {
        toast.warning("Please Select Specific Brand");
        return false;
      }
    }

    if (bonus.length === 0) {
      toast.warning("Please Add A Discount");
      return false;
    }
    if (selectBrand.length === 0) {
      toast.warning("Please Select Brand");
      return false;
    }
    const user = JSON.parse(window.localStorage.getItem("client"));
    var token;
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
      try {
           const { data } = await axios.post(
               `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/bpbonus`,
               {
                   bonus,
                   bonus_type,
                   selectBrand,
                   id
                 
               }, {
                headers: {
                  'Authorization': `Bearer ${getNewToken()}`
                }
              },
           );
          
           if (data.status === "success") {
              toast.success(data.message);
              getdiscountbrands();
              setBonusType("");
              setBonus("");
              setSelectBrand("");
           }
           else{
            toast.success(data.message);
            getdiscountbrands();
           }
       } catch (err) {
          console.log(`Error : ${err}`);
           err.response.data.errors.map((error) => {
           });
       }
  };
  return (
    <>
      <div className="p-3 p-lg-4 p-xl-5">
        <h1 className="font20 mb-4 text-dark">Bonus Configuration BP</h1>
        <Container fluid className="bg-white p-4 rounded shadow">
          <Form className="bg-white p-2 rounded containerShadow" onSubmit={handleSubmit}>
            <Row className="align-items-center">
              <Col md={12} className="mt-4">

                <Form.Check inline label="Bonus percentage" value="percentage" name="path" onChange={(e) => setBonusType(e.target.value)} type="radio" className="text-dark labelControl" />
                <Form.Check inline label="Flat" value="flat" name="path" onChange={(e) => setBonusType(e.target.value)} type="radio" className="text-dark labelControl" />

                {bonus_type === "Bonus percentage" ?
                  <>
                    <Row className="align-items-center mt-3">
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="font13 text-dark fw-medium">Bonus percentage <span className="text-danger">*</span></Form.Label>
                          <Form.Control className="font15" value={bonus} onChange={(e) => setBonus(e.target.value)} type="text" placeholder="Enter Bonus percentage" />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="font13 text-dark fw-medium">Select Brands <span className="text-danger">*</span></Form.Label>
                          <Select isMulti options={getbrand} onChange={setSelectBrand} />
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                  :
                  ''
                }
                {bonus_type === "flat" ?
                  <>
                    <Row className="align-items-center mt-3">
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="font13 text-dark fw-medium">Flat <span className="text-danger">*</span></Form.Label>
                          <Form.Control className="font15" value={bonus} onChange={(e) => setBonus(e.target.value)} type="text" placeholder="Enter Flat Amount" />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="font13 text-dark fw-medium">Select Brands <span className="text-danger">*</span></Form.Label>
                          <Select isMulti options={getbrand} onChange={setSelectBrand} />
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                  :
                  ''
                }

              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <Button type="submit" variant="primary" className="px-4 py-2 fullRounded shadowPrimary font12">Add Bonus</Button>
              </Col>
            </Row>
          </Form>
          <Row>
            <Col md={12}>
              <div className="table-responsive">
                <table
                  className="table couponTable table-borderless fontGilroy"
                  style={{ verticalAlign: "middle" }}
                >
                  <thead className="bgLightRed font14">
                    <tr className="text-center">
                      <th className="text-start">
                        Brand
                      </th>
                      <th>Brand Name</th>
                      <th>Business Type</th>
                      <th>Bonus</th>
                      <th>Gift Cards</th>
                    </tr>
                  </thead>
                  <tbody className="font12 fw-regular text-center">
                    {
                      isLoading === true ? (
                        <tr style={{ backgroundColor: "#eee" }}  >
                          <td colSpan='8'>
                          <Bars
                      height="80"
                      width="80"
                      color="#FF7020"
                      ariaLabel="bars-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}/>
                            </td>
                        </tr>
                      ) : (


                        customerData.map((cust) => (
                          <tr key={cust._id}>
                            <td className="font14 fw-medium text-start">
                              <img
                                src={cust.img_path}
                                alt="img"
                                width="100px"
                              />
                            </td>
                            <td className="font14 fw-medium">

                              {cust.brandname}

                            </td>
                            <td className="font14 fw-medium">

                              {cust.bonusType}
                            </td>
                            <td className="font14 fw-medium">

                              {cust.bonusType == 'percentage' ? Number(cust.bonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '%' : Number(cust.bonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '%'

                              }

                            </td>
                            <td className="font14 fw-medium">
                              <Link
                                to={`/admin/${cust.coupon_id}/coupons`}
                              >
                                <div>{cust.coupons} Gift Cards</div>
                              </Link>
                            </td>
                          </tr>
                        ))

                      )
                    }

                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>

  );
};

export default BussinessBonus;
