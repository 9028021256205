import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, useNavigate, useParams } from "react-router-dom";
import jsPDF from "jspdf";
import axios from "axios";
import { useEffect } from "react";
import logo from "../../assets/images/logo.png";
import "jspdf-autotable";
import "../../styles/transaction.css"
import { FaSortUp, FaSortDown } from "react-icons/fa";
import { Bars } from 'react-loader-spinner';
import {
  Button,
  Col,
  Container,
  Row,
  Form,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { getNewPartnerToken } from "../../config/cookie.config";
const BusinessTransactionId = () => {
  let navigate = useNavigate();
  var token;
  var userrole;
  var usersid;
  const user = JSON.parse(window.localStorage.getItem("business-client"));
  if (user) {
    userrole = user.user.role;
    usersid = user.user._id
  } else {
    userrole = null;
    usersid = null;
    localStorage.removeItem('business-client')
    navigate("/business-login");
  }


  const { id } = useParams();
  const [search, setSearch] = useState("");
  const [role] = useState(userrole);
  const [userid] = useState(usersid);
  const [customerData, setCustomerData] = useState([]);
  const [transactionData, setTransactionData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [order, setOrder] = useState("ASC");
  const [emailorder, setEmailorder] = useState("ASC");
  const [valueorder, setValueorder] = useState("ASC");
  const [updateorder, setUpdateorder] = useState("ASC");
  const [noData,setNoData] = useState(false);
  const [sort, setSort] = useState("");
  const [sendMail, setSendMail] = useState([]);
  const [showPage,setShowPage] = useState(1);
  


  // name
  const sorting = (order) => {
    if (order === "DSC") {
      setSort("dscName");

      setOrder("DSC");

    } else {
      setSort("ascName");

      setOrder("ASC");

    }
  };

  const emailsorting = (emailorder) => {
    if (emailorder === "DSC") {
      setSort("dscEmail");

      setEmailorder("DSC");
    } else {
      setSort("ascEmail");

      setEmailorder("ASC");
    }
  };

  const valuesorting = (valueorder) => {
    if (valueorder === "DSC") {
      setSort("dscValue");

      setValueorder("DSC");
    } else {
      setSort("ascValue");

      setValueorder("ASC");
    }
  };

  const updatesorting = (updateorder) => {
    if (updateorder === "DSC") {
      setSort("dscUpdate");

      setUpdateorder("DSC");
    } else {
      setSort("ascUpdate");

      setUpdateorder("ASC");
    }
  };

  const pages = new Array(numberOfPages).fill(null).map((v, i) => i);
  const getTransactionData = (pageNumber) => {
    setIsLoading(true)
    const user = JSON.parse(window.localStorage.getItem("business-client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/getalltransactionbycustomer/${id}?page=${pageNumber}&search=${search}&sort=${sort}`, {
      headers: {
        'Authorization': `Bearer ${getNewPartnerToken()}`
      }
    })
      .then((response) => {
        if(response.data.message == "Please Login"){
          localStorage.removeItem('business-client');
          navigate('/business-login');
          toast.error("Session Expired Please Login Again")
        }    
        const alltransaction = response.data.transaction;
        const totalPages = response.data.totalPages;
        setCustomerData(alltransaction);
        setNumberOfPages(totalPages);
        if(alltransaction.length===0){
          setNoData(true);
        }
    
        setIsLoading(false);
      })
      .catch((error) =>
        console.log(error)
      )
  };

  //for PDF

  const pdfDownlod = (id) => {
    const user = JSON.parse(window.localStorage.getItem("business-client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios
      .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/gettransactiondetails/${id}`, {
        headers: {
          'Authorization': `Bearer ${getNewPartnerToken()}`
        }
      })
      .then((response) => {
        if(response.data.message == "Please Login"){
          localStorage.removeItem('business-client');
          navigate('/business-login');
          toast.error("Session Expired Please Login Again")
        }    
        const getTransactionDataByID = response.data.transaction
        setTransactionData(response.data.transaction);
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
    
        const doc = new jsPDF(orientation, unit, size);
        // var paragraph = "\t\tncentiva";
        var paragraphSecond = "6860 Dallas Pkwy";
        var paragraphThird = "Suite 200";
        var paragraphFourth = "Plano, TX 75024,";
        var paragraphFifth = "United States.";
        
              // var lMargin = 158;
              // var rMargin = 10;
              // var pdfInMM = 510;
    
              var lMargin2 = 450;
              var rMargin2 = 5;
              var pdfInMM2 = 690;

              var lMargin3 = 450;
              var rMargin3 = 5;
              var pdfInMM3 = 690;

              var lMargin4 = 450;
              var rMargin4 = 5;
              var pdfInMM4 = 690;
              
              var lMargin5 = 450;
              var rMargin5 = 5;
              var pdfInMM5 = 690;
    
              // doc.setFont("bold");
              // doc.setFontSize(26);
              // var lines = doc.splitTextToSize(paragraph, (pdfInMM - lMargin - rMargin));
              // doc.text(lMargin, 40, lines);
    
              // doc.setFont("normal")
              doc.setFontSize(12)
              // doc.setFont('bold')
              // doc.addFont('bold');
              // doc.getFontList('Roboto-Regular', 'bold');
              doc.setFont('Roboto-Regular', 'bold');
              var lines2 = doc.splitTextToSize(paragraphSecond, (pdfInMM2 - lMargin2 - rMargin2));
              doc.text(lMargin2, 100, lines2);
              doc.setFontSize(12)
              var lines3 = doc.splitTextToSize(paragraphThird, (pdfInMM3 - lMargin3 - rMargin3));
              doc.text(lMargin3, 115, lines3);
              doc.setFontSize(12)
              var lines4 = doc.splitTextToSize(paragraphFourth, (pdfInMM4 - lMargin4 - rMargin4));
              doc.text(lMargin4, 130, lines4);
              doc.setFontSize(12)
              var lines5 = doc.splitTextToSize(paragraphFifth, (pdfInMM5 - lMargin5 - rMargin5));
              doc.text(lMargin5, 145, lines5);
    
              const logos = { logo };
              doc.addImage(logo, "png", 30, 40, 150, 30);
    
              doc.setFontSize(18);
              doc.text(30, 200, "RECIPIENT:");
              doc.setFontSize(12);
              doc.text(30, 220, `${response.data.transaction.customer_first_name} ${response.data.transaction.customer_last_name}`);
              doc.setFontSize(10);
              doc.text(30, 235, `${response.data.transaction.customer_email}`);
    
              var total = `$ ${Number(response.data.transaction.costValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
              var datass = [
                { name: "Process", amount: `${response.data.transaction?.status}` },
                { name: "Transaction Date", amount: `${response.data.transaction.createdAt.slice(0, 10)}` },
                { name: "Transaction Time", amount: `${response.data.transaction.createdAt.slice(11, 16)}` }
              ];
              
              var body = [...datass?.map(el => [el.name, el.amount]),
                        [{
                          content: `Total                                     ${total}`, colSpan: 2,
                          styles: { fillColor: "#fff3ed" }
                        }]]
              
              doc.autoTable({
                startY: 160,
                headStyles: { fillColor: "#fff3ed" },
                head: [['Transaction ID', `${response.data.transaction.transaction_id}`]],
                styles: { textColor: [10, 10, 10] },
                body: body,
                margin: { right: 30, left: 320 }
                  });
    
        doc.setFontSize(15);
        const headers = [
          ["Product Name", "Cost", "Bonus", "Cost Value", "Purchase Order"]];
    
          // doc.setFontSize(20)
          // doc.setFont("times");
          // doc.text(30, 30, `Customers List By ${response.data.transaction.customer_email} Business Partner`)
          let info = []
            response.data.transaction?.giftcards?.map((element) => {
              var costs = `$ ${Number(element.cost).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
              var bonuss = `$ ${Number(element.bonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
              var costvalues = `$ ${Number(element.costValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
              info.push([element.productName || element.productname, costs, bonuss, costvalues, element.purchaseOrderName])
          })
          
        let content = {
          startY: 350,
          head: headers,
          headStyles: { fillColor: "#fff3ed" },
          styles: { textColor: [10, 10, 10] },
          body: info,
          margin: { top: 10, right: 30, bottom: 0, left: 30 }
    
        };
    
              doc.setFontSize(16)
              doc.text(340, 600, "Subtotal")
              doc.text(420, 600, `$ ${Number(response.data.transaction.fund).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`);
              doc.line(335, 605, 525, 605);
    
              doc.text(340, 620, "Bonus")
              doc.text(420, 620, `$ ${Number(response.data.transaction.bonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`);
              doc.line(335, 625, 525, 625);
    
              doc.text(340, 640, "Amount")
              doc.text(420, 640, `$ ${Number(response.data.transaction.costValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`);
              doc.line(335, 645, 525, 645);
    
        doc.autoTable(content);
        doc.save(`${response.data.transaction.customer_first_name}.pdf`);
      })
      .catch((error) => {
        console.log(error)
      })
     

  }


  //for mail send
  const transactionMail = (id) => {
    setIsLoading(true);
    axios
        .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/sendmailtransaction/${id}`)
        .then((response) => {
          const allData = response.data;
          setSendMail(allData);
          setIsLoading(false);
          toast.success(response.data.message);
        })
        .catch((error)=>{
          console.log(error);
        })
  }


  let items = [];
  let leftSide = pageNumber - 1;

  if (leftSide <= 0) leftSide = 0;
  let rightSide = pageNumber + 2;

  if (rightSide > numberOfPages) rightSide = numberOfPages;
  for (let number = leftSide; number < rightSide; number++) {

    items.push(
      <li key={number} className={(number === pageNumber ? "active" : "")} onClick={() => { setPageNumber(number); setShowPage(number+1) }}>
        {number + 1}
      </li>,
    );
  }


  const pagenumberSearch = (e)=>{
    var pages =parseInt(e) ;
    var page= pages -1;
    if(pages<1){
      setPageNumber(0);
      setShowPage(1);
    }else if(pages>numberOfPages){
      setPageNumber(numberOfPages-1);
      setShowPage(numberOfPages);
    }else if(pages>=1 && pages<=numberOfPages){
      setPageNumber(page);
      setShowPage(pages);
    }
    else{
      setPageNumber(0);
      setShowPage(pages);
    }
    }


  const nextPage = () => {
    if (pageNumber < numberOfPages) {
      setPageNumber(pageNumber + 1);
      setShowPage(pageNumber+2);
    }
  }

  const prevPage = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1);
      setShowPage(pageNumber);
    }
  }

  const handleSearch = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
    setPageNumber(0);
    setShowPage(1);
  };

  useEffect(() => {
    setIsLoading(true);
    getTransactionData(pageNumber);
    setIsLoading(false);
  }, [pageNumber, search, sort]);






  return (
    <>
      <div className="p-3 p-lg-5">
      <div className="d-flex mb-4 align-items-center">
          <Link
          onClick={()=>navigate(-1)}
            to="/partner/customers"
            className="p-0 btn btnOrangeTransparent me-3 fw-semibold"
            variant="white"
          >
            <span className="me-2 fw-semibold">
              <img
                src="/assets/images/back-org.png"
                className="img-fluid"
                alt=""
              />
            </span>
            <span>Back</span>
          </Link>
      <h1 className="font20 text-dark">Transaction Details</h1>
        </div>
        <Container fluid className="bg-white p-4 rounded shadow">
          <Row>
            <Col md={4}>
              <div style={{ maxWidth: "214px" }}>
                <div className="d-flex align-items-center searchWrapper w-100 ms-0">
                  <Form.Control
                    type="text"
                    className="border-0 searchInput"
                    placeholder="Search"
                    onChange={handleSearch}
                  />
                  <Button
                    variant="primary"
                    title="Click here to search"
                    className="searchBtn d-flex justify-content-center align-items-center"
                  >
                    <img
                      src="/assets/images/search.png"
                      className="img-fluid"
                      alt=""
                    />
                  </Button>
                </div>
              </div>
            </Col>
            <Col md={4}></Col>
            <Col md={4}></Col>
          </Row>
          <Row className="align-items-center">
            <Col md={12} className="mt-4">
              <div className="table-responsive">
                <table className="table " style={{ verticalAlign: "middle" }}>
                  <thead className="bgLightRed font14">
                    <tr className="text-center">
                    
                      <th className="py-3">
                        {" "}
                        <div className="d-flex align-items-center justify-content-center ">
                          {" "}
                          <span className="me-1 "> Transaction ID</span>{" "}
                        </div>{" "}
                      </th>

                      <th className="py-3">
                        <div className="d-flex align-items-center justify-content-center" style={{ cursor: 'pointer' }}>
                          <span className="me-1">Funds</span>
                          {valueorder === "ASC" ? (
                            <FaSortDown onClick={() => valuesorting("DSC")} />
                          ) : (
                            <FaSortUp onClick={() => valuesorting("ASC")} />
                          )}
                        </div>
                      </th>
                      <th className="py-3">
                        <div className="d-flex align-items-center justify-content-center" style={{ cursor: 'pointer' }}>
                          <span className="me-1">Bonus / Discount</span>
                        </div>
                      </th>
                      
                      <th className="py-3">
                        <div className="d-flex align-items-center justify-content-center">
                          <span className="me-1">Date</span>
                        </div>
                      </th>
                      <th className="py-3">
                        {" "}
                        <div className="d-flex align-items-center justify-content-center ">
                          {" "}
                          <span className="me-1 "> Giftcards</span>{" "}
                        </div>
                      </th>
                      <th className="py-3">Status</th>
                      <th className="py-3">Action</th>
                    </tr>
                  </thead>
                  <tbody className="font14 fw-medium text-center">
               
                    {isLoading === true ? (
                      <tr style={{ backgroundColor: "#eee" }}>
                        <td colSpan="8">
                        <Bars
                        height="80"
                        width="100%"
                        margin='auto'
                        color="#FF7020"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        />
                        </td>
                      </tr>
                      ) : (
                        customerData?.map((cust) => (
                          <tr
                            key={cust._id}
                          >
                             <td className="py-3 font14 fw-medium ">
                              {cust.transaction_id}
                            </td>
                           
                            <td className="py-3 font14 fw-medium ">
                            ${Number(cust.fund).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </td>
                            <td>${Number(cust.bonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td>
                           
                       
                            <td className="py-3 font14 fw-medium">
                              {" "}
                              {cust.updatedAt.slice(0, 16).replace("T", "      ")}
                            </td>
                            <td className="py-3 font14 fw-medium ">
                              <Link to={`/partner/transaction/${cust._id}/giftcards`}>
                              {cust.giftcards.length} Gift Cards
                              </Link>
                            </td>
                            <td className="py-3  font14 fw-medium">
                              {cust?.status}
                            </td>
                            <td>
                              {role === "Admin" ? (
                                <DropdownButton
                                  variant="white"
                                  className="p-0  tableActionDropDown text-center"
                                  title={
                                    <div className="actionBtn">
                                      <span></span>
                                      <span></span>
                                      <span></span>
                                    </div>
                                  }
                                  drop="start"
                                >
                                  {cust?.status === "Success" ? 
                                  <>
                                    <Dropdown.Item
                                    className="font14 fw-medium"
                                    onClick={(e) => pdfDownlod(cust._id)}
                                  >
                                    Download PDF
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                    className="font14 fw-medium"
                                    onClick={(e) => transactionMail(cust._id)}
                                  >
                                    Send Email
                                   </Dropdown.Item>
                                  </>
                                  :
                                  <>
                                  </>
                                  }
                                <Dropdown.Item
                                  className="font14 fw-medium"
                                onClick={()=>navigate(`/partner/transaction/${cust._id}/giftcards`)}
                                >
                                  Details
                                
                                </Dropdown.Item>
                                </DropdownButton>
                              ) : (
                                <DropdownButton
                                  variant="white"
                                  className="p-0  tableActionDropDown text-center"
                                  title={
                                    <div className="actionBtn">
                                      <span></span>
                                      <span></span>
                                      <span></span>
                                    </div>
                                  }
                                  drop="start"
                                >
                                  {cust?.status === "Success" ? 
                                  <>
                                    <Dropdown.Item
                                    className="font14 fw-medium"
                                    onClick={(e) => pdfDownlod(cust._id)}
                                  >
                                    Download PDF
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                    className="font14 fw-medium"
                                    onClick={(e) => transactionMail(cust._id)}
                                  >
                                    Send Email
                                   </Dropdown.Item>
                                  </>
                                  :
                                  <>
                                  </>
                                  }
                                <Dropdown.Item
                                  className="font14 fw-medium"
                                onClick={()=>navigate(`/partner/transaction/${cust._id}/giftcards`)}
                                >
                                  Details
                                
                                </Dropdown.Item>
                                </DropdownButton>
                              )}
                            </td>
                          </tr>
                        )))}
                     {isLoading || isLoading? "" : 
                     <>
                     {noData ? <tr >
                        <td colSpan="8">
                        <h1 className="font16 text-dark">No Transactions</h1>
                        </td>
                      </tr>:""}
                     </>
                     }
                    
                  </tbody>
                </table>
              </div>
            </Col>

            {  numberOfPages === 0 ? "" :
             <Col className="mt-5 mb-4">
              {
                <ul className="custPagination d-flex justify-content-center list-unstyled font14">
                  {pageNumber === 0 ? (
                    ""
                  ) : (

                    <li onClick={prevPage}>Previous </li>

                  )}

                  
                  {items}

                  {numberOfPages - 1 === pageNumber ? (
                    ""
                  ) : (
                    <li onClick={nextPage}>Next</li>
                  )}
                    <li className="font14"><input  style={{width:"25px",border:'1px solid #4C00EF',borderRadius:'4px',textAlign:'center'}} value={showPage?showPage:''} type="text" name="number" id="" onChange={(e)=> {pagenumberSearch(e.target.value)}}  ></input> <span style={{color:'#4C00EF'}}>/</span> {numberOfPages}</li>
                </ul>
              }
            </Col>}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default BusinessTransactionId;
