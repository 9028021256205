import React, { useState } from 'react'
import Footer from '../components/Footer';
import NavbarComponent from './../components/Navbar';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { getNewToken } from '../config/cookie.config';

const TransactionsDetails = () => {

  var token;

  const userrole = user.user.role;
  const [role, setRoles] = useState(userrole);
  const [customerData, setCustomerData] = useState([]);
  const [transactionData, setTransactionData] = useState([]);
  let navigate = useNavigate();
  // const {id} = useParams
  const user = JSON.parse(window.localStorage.getItem("client"));
  if (user) {
    token = user.token;
  } else {
    token = null;
  }


  axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getalltransaction`, {
    headers: {
      'Authorization': `Bearer ${getNewToken()}`
    }
  })
    .then((response) => {
      if(response.data.message == "Please Login"){
        localStorage.removeItem('client');
        navigate('/admin');
        toast.error("Session Expired Please Login Again")
      }  
      const alltransaction = response.data.transaction
      setTransactionData(alltransaction);
    }).catch((error) => console.log(`Error : ${error}`))

  return (
    <div>
      <NavbarComponent />
      <div className="p-3 p-lg-5">
        <h1 className="font20 mb-4 text-dark">Transactions</h1>
        <Container fluid className="bg-white p-4 rounded shadow">
          <Row className="align-items-center">
            <Col md={12} className="mt-4">
              <div className="table-responsive">
                <table className="table " style={{ verticalAlign: "middle" }}>
                  <thead className="font14">
                    <tr className="text-center">
                      {/* <th className="text-start py-3">Name</th>
                      <th className="py-3">Email</th> */}
                      <th className="py-3">Order Id</th>
                      <th className="py-3">Total Face Value</th>
                      {/* <th className="py-3">Total Bonus</th> */}
                      <th className="py-3">Status</th>
                      {/* <th className="py-3">Action</th> */}
                    </tr>
                  </thead>
                  <tbody className="font14 fw-medium text-center">
                    {transactionData.map((cust) => (
                      <tr key={cust._id} className={cust.id === 123 ? 'bgLightRed' : ''}>
                        {/* <td className="font14 text-start py-3">{cust.customer_name}</td>
                        <td className="py-3">
                          {cust.customer_email}
                        </td> */}
                        <td className="py-3">{cust.purchaseOrderName}</td>
                        <td className="py-3">{cust.faceValue}</td>
                        {/* <td className="py-3">{cust.bonus}</td> */}
                        <td className="py-3">{cust.status}</td>
                        {/* <td className="py-3">
                          { role === "admin" ?
                             <DropdownButton
                             variant="white"
                             className="p-0  tableActionDropDown "
                             title={
                               <div className="actionBtn">
                                 <span></span>
                                 <span></span>
                                 <span></span>
                               </div>
                             }
                             drop="start"
                           >
                             <Dropdown.Item className="font12 fw-medium">
                               Edit
                             </Dropdown.Item>
                             <Dropdown.Item className="font12 fw-medium">
                               Delete
                             </Dropdown.Item>
                           </DropdownButton>
                           :
                           <DropdownButton
                           variant="white"
                           className="p-0  tableActionDropDown "
                           title={
                             <div className="actionBtn">
                               <span></span>
                               <span></span>
                               <span></span>
                             </div>
                           }
                           drop="start"
                         >
                           <Dropdown.Item className="font12 fw-medium">
                             Edit
                           </Dropdown.Item>
                         </DropdownButton>
                          }
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  )
}
export default TransactionsDetails