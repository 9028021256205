import React, { useRef } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useEffect, useState } from 'react';
import axios from "axios";
import { toast } from 'react-toastify';
import { useNavigate, Link } from 'react-router-dom';
import "../styles/page/Login.scss";
import 'react-toastify/dist/ReactToastify.css';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import CryptoJS from 'crypto-js';

const Login = () => {
  const nameDomain = (window.location.host.split('.'));

  useEffect(() => {
    document.title = "ncentiva || Customer Login"
  }, [])

  const [validated] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [image, setImage] = useState("");
  const [btnColor, setBtnColor] = useState('');
  const [btnText, setBtnText] = useState('');
  const [loading, setLoading] = useState(true);
  const [passwordType, setPasswordType] = useState("password");
  const [stillSendingOtp, setStillSendingOtp] = useState(false)
  const [dropdwon, setDropdown] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [defaultPartner, setDefaultPartner] = useState(null);
  const [noCustomer, setNoCustomer] = useState(false);
  const [partnerId, setPartnerId] = useState("");
  const [isStatus, setIsStatus] = useState(false);
  const [isActive, setIsActive] = useState();
  const [newPassword, setNewPassword] = useState("");
  const [newConfirmPassword, setNewConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [isMulti, setIsMulti] = useState(false);
  const [isEmail, setIsEmail] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const submitLockRef = useRef(false);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }
  const navigate = useNavigate();
  var token;
  useEffect(() => {
    redirectToDashboard();
  },);

  const redirectToDashboard = () => {
    const user = JSON.parse(window.localStorage.getItem('customer'));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }

    if (token !== null) {
      navigate('/')
    }
  }
  const [partners, setPartners] = useState([]);
  const [partnersLoaded, setPartnersLoaded] = useState(false);
  const [cmsData, setCmsData] = useState(false)


  useEffect(() => {
    setLoading(true);
    if (nameDomain[0] !== "dev" || "staging" || "payouts" || "uat") {
      axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getcms/${nameDomain[0]}`)
        .then((res) => {
          if (res.data?.cms?.default_ncentiva === false) {
            setCmsData(false)
            setBtnColor(res.data?.cms?.btn_color);
            setBtnText(res.data?.cms?.btn_text);
            if (res.data?.cms?.brand_logo) {
              setImage(res.data?.cms?.brand_logo)
            }
          } else {
            setCmsData(true)

            setImage("https://dev.ncentiva.com/assets/images/logo.png")
            setBtnColor('#3D05B6');
            setBtnText('white');
          }
          setLoading(false);
        }).catch((err) => {
          console.log(err);
        })
    } else {
      setLoading(false);
    }
  }, []);
  
let defaultCms_local=localStorage.getItem("defaultNcentivaCms")
if(defaultCms_local){
  localStorage.removeItem("defaultNcentivaCms")
}

  const handleSubmit = async (e) => {

    e.preventDefault();
    e.stopPropagation();

    if (submitLockRef.current) return;
    submitLockRef.current = true;
    setIsSubmitting(true);
    // const waitForPartnersUpdate = (resolve, reject) => {
    //   if (partnersLoaded) {
    //     resolve();

    //   } else {
    //     setTimeout(() => waitForPartnersUpdate(resolve, reject), 100);
    //     setTimeout(() => setStillSendingOtp(false), 100)
    //   }
    // };

    // new Promise(waitForPartnersUpdate).then(async () => {
    //   setStillSendingOtp(true);

    //   if (isStatus === true && isActive === false) {
    //     if (!password && !newPassword) {
    //       toast.warning("All Fields Are Required");
    //       setTimeout(() => setStillSendingOtp(false), 400);
    //       return;
    //     }
    //     if (!newPassword) {
    //       toast.warning("Please Enter Your New Password");
    //       setTimeout(() => setStillSendingOtp(false), 400);
    //       return;
    //     }
    //     if (!newConfirmPassword) {
    //       toast.warning("Please Enter Your Confirm Password");
    //       setTimeout(() => setStillSendingOtp(false), 400);
    //       return;
    //     }
    //     if (newPassword !== newConfirmPassword) {
    //       toast.warning("Password And Confirm Password Doesn't Match");
    //       setTimeout(() => setStillSendingOtp(false), 400);
    //       return;
    //     }
    //     if (newPassword.search(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/) < 0) {
    //       toast.warning(
    //         <div>
    //           <p><b>Password must be</b></p>
    //           <br />
    //           <ul>
    //             <li>Use at least 8 characters</li>
    //             <li>Use upper and lower case characters</li>
    //             <li>Use 1 or more numbers</li>
    //             <li>Use 1 or more special characters</li>
    //           </ul>
    //         </div>
    //       );
    //       setTimeout(() => setStillSendingOtp(false), 400);
    //       return;
    //     }
    //   }

    //   console.table({ email, password });

    //   // if (!partnersLoaded) {
    //   //   toast.warning("Please wait until Business-partners are loaded.");
    //   //   setTimeout(() => setStillSendingOtp(false), 400);
    //   //   return;
    //   // }

    //   if (partners?.length > 1 && noCustomer === false && email !== "") {
    //     setTimeout(() => setStillSendingOtp(false), 2000);
    //     toast.warning("Please Select Business Partner");
    //     return;
    //   }

    //   if (noCustomer) {
    //     setTimeout(() => setStillSendingOtp(false), 2000);
    //     toast.error("Please Login with your registered email");
    //     return;
    //   }

    //   try {
    //     if (dropdwon.trim() === "" && partners.length > 1) {
    //       setTimeout(() => setStillSendingOtp(false), 2000);
    //       toast.warning("Please Select Business Partner");
    //       return;
    //     }

    //     const credentials = {
    //       email: email,
    //       password: password,
    //       business_partner_id: dropdwon,
    //       type: 'customer',
    //       newPassword: newPassword,
    //       newConfirmPassword: newConfirmPassword,
    //     };
    //     const jsonString = JSON.stringify(credentials);
    //     const securedLogin = CryptoJS.AES.encrypt(jsonString, process.env.REACT_APP_SECRET_KEY).toString();

    //     const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/otp`, { securedLogin });

    //     if (data.message === 'All Fields are Required') {
    //       toast.warning(data.message);
    //     } else if (data.message === 'Email Required') {
    //       toast.warning(data.message);
    //     } else if (data.message === 'Password Required') {
    //       toast.warning(data.message);
    //     } else if (data.message === 'Email or Password is not Valid') {
    //       toast.warning(data.message);
    //     } else if (data.status === "failed") {
    //       toast.error(data.message);
    //     } else if (data.message === 'This Account is Deactivated') {
    //       toast.warning(data.message);
    //     }

    //     if (data.status === "success") {
    //       setNewPassword("");
    //       setNewConfirmPassword("");
    //       window.localStorage.setItem("customer_data", JSON.stringify(data));
    //       localStorage.removeItem('customer_email');
    //       navigate('/customer/verify');
    //       setStillSendingOtp(false);
    //     } else if (data.status === "failed") {
    //       setTimeout(() => setStillSendingOtp(false), 2000);
    //     }
    //   } catch (err) {
    //     console.log(`Error: ${err}`);
    //     toast.error(err);
    //   }
    // });
     if (!email.trim()) {
    toast.warning("Email is required");
    setTimeout(()=>{
      submitLockRef.current=false
    }, 2000)
    return;
  }
  if (!isValidEmail(email)) {
    toast.error("Invalid email format");
    setTimeout(()=>{
      submitLockRef.current=false
    }, 2000)
    return;
  }
  if (!password.trim()) {
    toast.warning("Password is required");
    setTimeout(()=>{
      submitLockRef.current=false
    }, 2000)
    return;
  }
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/customer-partners`, {
        email: email
      });

      const status = response.data;
      const customers = response?.data?.partners;



      if (status.status === 'Success') {
        // setPartners(partner);
        setPartnersLoaded(true);
        // setDefaultPartner(partners[0]);

        setIsMulti(true);

      }
      if(response.data?.status === 'failed') {
          toast.warning(response.data?.message);
      }

      if (customers.length > 1 && status !== "failed" && email !== "" && dropdwon.trim() === "") {
        toast.warning("Please Select Business Partner");
        setTimeout(()=>{
          submitLockRef.current=false
        }, 2000)
        return;
      } else {

        if (isStatus === true && isActive === false) {
          if (!password && !newPassword) {
            toast.warning("All Fields Are Required");
            setTimeout(()=>{
              submitLockRef.current=false
            }, 2000)
            setTimeout(() => setStillSendingOtp(false), 400);
            return;
          }
          if (!newPassword) {
            toast.warning("Please Enter Your New Password");
            setTimeout(()=>{
              submitLockRef.current=false
            }, 2000)
            setTimeout(() => setStillSendingOtp(false), 400);
            return;
          }
          if (!newConfirmPassword) {
            toast.warning("Please Enter Your Confirm Password");
            setTimeout(()=>{
              submitLockRef.current=false
            }, 2000)
            setTimeout(() => setStillSendingOtp(false), 400);
            return;
          }
          if (newPassword !== newConfirmPassword) {
            toast.warning("Password And Confirm Password Doesn't Match");
            setTimeout(()=>{
              submitLockRef.current=false
            }, 2000)
            setTimeout(() => setStillSendingOtp(false), 400);
            return;
          }
          if (newPassword.search(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/) < 0) {
            toast.warning(
              <div>
                <p><b>Password must be</b></p>
                <br />
                <ul>
                  <li>Use at least 8 characters</li>
                  <li>Use upper and lower case characters</li>
                  <li>Use 1 or more numbers</li>
                  <li>Use 1 or more special characters</li>
                </ul>
              </div>
            );
            setTimeout(()=>{
              submitLockRef.current=false
            }, 2000)
            setTimeout(() => setStillSendingOtp(false), 400);
            return;
          }
        }

        // console.table({ email, password });

        // if (!partnersLoaded) {
        //   toast.warning("Please wait until Business-partners are loaded.");
        //   setTimeout(() => setStillSendingOtp(false), 400);
        //   return;
        // }

        // (partners?.length > 1 && noCustomer === false && email !== "") && (
        //   setTimeout(() => setStillSendingOtp(false), 2000),
        //   toast.warning("Please Select Business Partner"),
        //   true // indicate an early return
        // ) && (() => { return })();

        if (noCustomer) {
          setTimeout(() => setStillSendingOtp(false), 2000);
          toast.error("Please Login with your registered email");
          setTimeout(()=>{
            submitLockRef.current=false
          }, 2000)
          return;
        }

        try {
          if (dropdwon.trim() === "" && partners.length > 1) {
            setTimeout(() => setStillSendingOtp(false), 2000);
            toast.warning("Please Select Business Partner");
            setTimeout(()=>{
              submitLockRef.current=false
            }, 2000)
            return;
          }

          const credentials = {
            email: email,
            password: password,
            business_partner_id: dropdwon,
            type: 'customer',
            newPassword: newPassword,
            newConfirmPassword: newConfirmPassword,
            timestamp: Date.now()
          };
          const jsonString = JSON.stringify(credentials);
          const securedLogin = CryptoJS.AES.encrypt(jsonString, process.env.REACT_APP_SECRET_KEY).toString();

          const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/otp`, { securedLogin });

          if (data.message === 'All Fields are Required') {
            toast.warning(data.message);
          } else if (data.message === 'Email Required') {
            toast.warning(data.message);
          } else if (data.message === 'Password Required') {
            toast.warning(data.message);
          } else if (data.message === 'Email or Password is not Valid') {
            toast.warning(data.message);
          } else if (data.status === "failed") {
            toast.error(data.message);
          } else if (data.message === 'This Account is Deactivated') {
            toast.warning(data.message);
          }

          if (data.status === "success") {
            setNewPassword("");
            setNewConfirmPassword("");
            window.localStorage.setItem("customer_data", JSON.stringify(data));
            localStorage.removeItem('customer_email');
            navigate('/customer/verify');
            setStillSendingOtp(false);
          } else if (data.status === "failed") {
            setTimeout(() => setStillSendingOtp(false), 2000);
          }
        } catch (err) {
          console.log(`Error: ${err}`);
          toast.error(err?.response?.data?.message || 'An error occurred');
        }
        finally {
          setIsSubmitting(false);
          setTimeout(()=>{
            submitLockRef.current=false
          }, 2000)
        }
      }


    } catch (error) {
      console.error('Error:', error);
      setIsStatus(false);
      setIsMulti(false);
      setPartnerId("")
      setTimeout(()=>{
        submitLockRef.current=false
      }, 2000)
    }





  }

  const handleBusinessPartner = async (selectedOption) => {
    const dropdownvalue = selectedOption.target.value
    if (dropdownvalue === "") {
      setDropdown("");
      setPartnerId("")
      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/customer-status-check`, {
          email: email,
          partner_id: ""
        });
        setIsStatus(response.data.data);
        setIsActive(response.data.activity);
      } catch (error) {
        console.error('Error:', error);
      }

    } else {
      setDropdown(dropdownvalue);
      setPartnerId(selectedOption.target.value)
      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/customer-status-check`, {
          email: email,
          partner_id: selectedOption.target.value
        });
        setIsStatus(response.data.data);
        setIsActive(response.data.activity);
      } catch (error) {
        console.error('Error:', error);
      }
    }

  }

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleKeyUp = async (event) => {

    event.preventDefault();
    if (event.key === 'Enter') {
      setTimeout(()=>{
        submitLockRef.current=false
      }, 2000)
    }
    if (!email) {
      setIsEmail(false);
      setPartners([]);
      setPartnerId("")
    } else {
      setIsEmail(true)
    }
    if (email && isValidEmail(email)) {
      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/customer-partners`, {
          email: email
        });

        const status = response.data;
        const partner = response?.data?.partners;



        if (status.status === 'Success') {
          setPartners(partner);
          setPartnersLoaded(true);
          setDefaultPartner(partners[0]);

          setIsMulti(true);

        } else {
          if (response.data.message === "Customer Doesn't Exist") {
            setIsStatus(false);
            setIsMulti(false);
            setPartnerId("")
            setPartners([]);
          }
          console.error('Error fetching partners:', response.data.message);
          setIsStatus(false);
          setIsMulti(false);
          setPartnerId("")
        }

        if (status === "failed") {
          setNoCustomer(true);
          setIsStatus(false);
          setIsMulti(false);
          setPartnerId("")
        } else {
          setNoCustomer(false);
        }
      } catch (error) {
        console.error('Error:', error);
        setIsStatus(false);
        setIsMulti(false);
        setPartnerId("")
      }
      if (password && password?.length > 7) {
        try {
          const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/customer-status-check`, {
            email: email,
            partner_id: partnerId
          });
          setIsStatus(response.data.data);
          setIsActive(response.data.activity);
        } catch (error) {
          console.error('Error:', error);
        }
      }
    }
  };

  const checkStatus = async (event) => {
    event.preventDefault();
    if (email && isValidEmail(email)) {
      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/customer-status-check`, {
          email: email,
          partner_id: partnerId
        });
        setIsStatus(response.data.data);
        setIsActive(response.data.activity);
      } catch (error) {
        console.error('Error:', error);
      }
    } 
  }

  function checkWhitespace(event) {
    event.preventDefault();
    var data = event.clipboardData.getData("text/plain");
    var trimmedData = data.trim();
    
    if (trimmedData.length > 0) {
      document.execCommand('insertText', false, trimmedData);
    }
  }


  function keyDown(e) {
    var e = window.event || e;
    var key = e.keyCode;
    //space pressed
    if (key == 32) { //space
      e.preventDefault();
    }

  }
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent default form submission
      if (!submitLockRef.current) {
        handleSubmit(e);
      }
    }
  };
  const location = window.location;
  // const urlLink = `${process.env.REACT_APP_URL}`
  const urlLink = window.location.origin

  return (
    <>
      {loading ? '' :
        <>

          <Container
            fluid
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ height: "100vh", backgroundColor: "#fff8f3" }}
          >
            <Container
              className="bg-white loginContainer p-0 rounded"
              style={{ maxWidth: "1065px" }}
            >
              <div className="d-flex align-items-center justify-content-center">
                <Link
                  to={urlLink}
                // onClick={()=>navigate(-1)}
                >

                  <img
                    src={image}
                    className="imgLogo img-fluid mt-4 img_hover"
                    alt=""
                    style={{
                      width: '200px',
                      height: '100px',
                      objectFit: 'contain'
                    }}
                  />
                </Link>
              </div>
              <Row className="align-items-center g-0">
                <Col
                  md="6"
                  style={{ height: "100%", borderRight: "1px solid #ccc" }}
                  className="loginLeft j_login_left"
                >
                  <div className="">
                    <h1 className="loginH1Title text-dark mb-3">Login</h1>

                    <Form className="form mt-5" noValidate validated={validated} onSubmit={handleSubmit} onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="text-dark">Email</Form.Label>
                        <Form.Control
                          type="email"
                          required
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Enter Email"
                          onInput={handleKeyUp}
                          onKeyUp={handleKeyUp}
                          onKeyPress={(e) => keyDown(e)}
                          onPaste={(e) => checkWhitespace(e)}
                          value={email}
                        />
                      </Form.Group>
                      {partners?.length > 1 ? (
                        <Form.Group className="mb-3" controlId="formBasicPartner">
                          <Form.Label className="text-dark">Select Your Account</Form.Label>
                          <div
                            className="position-relative"
                            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', }}
                            onClick={toggleDropdown}
                          >
                            <Form.Control
                              as="select"
                              onChange={handleBusinessPartner}
                              style={{ padding: "14px 28px", cursor: 'pointer' }}

                            >
                              <option value="">Select Your Account</option>
                              {partners.map((partner, index) => (
                                <option key={index} value={partner.value}>
                                  {partner.label}
                                </option>
                              ))}
                            </Form.Control>
                            <span
                              className="position-absolute"
                              style={{
                                right: '12px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                zIndex: 1,
                                width: '24px',
                                height: '24px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                              onClick={(e) => {
                                toggleDropdown();
                              }}
                            >
                              {isDropdownOpen ? <MdOutlineKeyboardArrowUp /> : <MdKeyboardArrowDown />}
                            </span>
                          </div>

                        </Form.Group>) : ""}

                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label className=" text-dark">Password</Form.Label>
                        <div className="position-relative">

                          <Form.Control type={passwordType} onChange={(e) => {
                            let trimmedValue = e.target.value;
                            trimmedValue = trimmedValue.replace(/\s/g, '')
                            setPassword(trimmedValue)
                          }} value={password} name="password" onKeyUp={checkStatus} placeholder="Enter Password"
                            onPaste={(e) => checkWhitespace(e)}
                          />
                          <button
                            type="button"
                            className="bg-transparent border-0 position-absolute eyBtn"
                            onClick={togglePassword}
                          >
                            {passwordType === "password" ? <FaEyeSlash /> : <FaEye />}

                          </button>
                        </div>

                      </Form.Group>
                      {/* {isStatus && isActive === true && isMulti === true? ( */}
                      {isStatus && isMulti === true && isActive !== true && isEmail && password.length > 0 ? (
                        <>
                          <Form.Group className="mb-3">
                            <Form.Label className=" text-dark">New Password</Form.Label>
                            <div className="position-relative">
                              <Form.Control
                                type={showConfirmPassword ? 'text' : 'password'}
                                onChange={(e) => {
                                  let trimValue = e.target.value;
                                  trimValue = trimValue.replace(/\s/g, '')
                                  setNewPassword(trimValue)
                                }}
                                placeholder="Enter New Password"
                                onPaste={(e) => checkWhitespace(e)}
                                value={newPassword}
                              />
                              {newPassword.length > 0 && <button type="button" className="bg-transparent border-0 position-absolute eyBtn" onClick={() => { setShowConfirmPassword(!showConfirmPassword) }} >
                                {!showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                              </button>}
                            </div>

                          </Form.Group>


                          <Form.Group className="mb-3">
                            <Form.Label className=" text-dark"> Confirm New Password</Form.Label>
                            <div className="position-relative">
                              <Form.Control
                                type={showConfirmNewPassword ? 'text' : 'password'}
                                onChange={(e) => {
                                  let trimmedValue = e.target.value;
                                  trimmedValue = trimmedValue.replace(/\s/g, '')
                                  setNewConfirmPassword(trimmedValue)
                                }}
                                placeholder="Enter Confirm New Password"
                                onPaste={(e) => checkWhitespace(e)}
                                value={newConfirmPassword}
                              />
                              {newConfirmPassword.length > 0 && <button type="button" className="bg-transparent border-0 position-absolute eyBtn" onClick={() => { setShowConfirmNewPassword(!showConfirmNewPassword) }} >
                                {!showConfirmNewPassword ? <FaEyeSlash /> : <FaEye />}
                              </button>}
                            </div>

                          </Form.Group></>) : ""}

                      <Form.Group className="mb-3">
                        <Link
                          className="text-end font14 text-primary fw-medium"
                          to={`/customer-send-password-reset-email`}
                        >
                          Forgot Password ?
                        </Link>
                      </Form.Group>

                      <Form.Group className="mb-3">


                        {
                          stillSendingOtp && isSubmitting?
                            <Button
                              type="submit"
                              variant="primary"
                              // style={{ backgroundColor: `${btnColor}80`, color: btnText, border: `1px solid transparent` }}
                              style={cmsData
                                ? { backgroundColor: `${btnColor}80`, color: btnText, border: `1px solid transparent` }
                                : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }
                              }
                              className="w-100 fullRounded font12 fw-bold py-3 shadowPrimary"
                              disabled>Please wait ....</Button> :
                            <>{btnColor ?
                              <Button
                                type="submit"
                                variant="primary"
                                // style={{ backgroundColor: `${btnColor}80`, color: btnText, border: `1px solid transparent ` }}
                                style={cmsData
                                  ? { backgroundColor: `${btnColor}80`, color: btnText, border: `1px solid transparent` }
                                  : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }
                                }
                                className="w-100 fullRounded font12 fw-bold py-3 shadowPrimary button_hover"
                              >Login</Button>
                              : ''}
                            </>
                        }
                      </Form.Group>
                    </Form>
                  </div>
                </Col>
                <Col
                  md="6"
                  className="loginRight j_login_right  align-items-center"
                  style={{ height: "100%" }}
                >
                  <div>
                    <p className="customer-login-verification-text text-dark">
                      <span className="verification-title" style={{ fontWeight: '500' }}>One time password verification.</span> <br /><br />
                      We will send an OTP code to your email.<br />
                      Please use it before it expires within 5 minutes.<br />


                    </p>
                  </div>
                </Col>

              </Row>
            </Container>
          </Container>
        </>}
    </>
  );
};

export default Login;
