import React, { useCallback, useMemo, useRef } from "react";
import { Container } from "react-bootstrap";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import styles from "./customize.module.css";
import { Col, Modal, Button } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { Form } from "react-bootstrap";
import bannerImage from "../assets/images/banner.png";
import Resizer from "react-image-file-resizer";
import ReactFileReader from "react-file-reader";
import { FaCloudUploadAlt } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import FooterICon from "./../components/FooterICon";
import JoditEditor from "jodit-react";
import ModalColor from "../components/elements/ModalColor";
import ModalColor1 from "../components/elements/ModalColor1";
import ModalColor2 from "../components/elements/ModalColor2";
import ModalColor3 from "../components/elements/ModalColor3";
import ModalColor4 from "../components/elements/ModalColor4";
// import 'pdfjs-dist/build/pdf';

import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { pdfjs } from "react-pdf";
import okIMG from "../assets/images/ok.png";

import "pdfjs-dist/build/pdf.worker.min";
import { getNewPartnerToken, getNewToken } from "../config/cookie.config";
// import ReactCrop from 'react-image-crop';
// import 'react-image-crop/dist/ReactCrop.css';\

const Customize = (props) => {
  const editor = useRef(null);
  const editor1 = useRef(null);
  const editor2 = useRef(null);
  const editor3 = useRef(null);
  const editor4 = useRef(null);
  const editor5 = useRef(null);
  const editor6 = useRef(null);
  const editor7 = useRef(null);
  const editor8 = useRef(null);
  const editor9 = useRef(null);
  const editor10 = useRef(null);
  const editor11 = useRef(null);
  const editor12 = useRef(null);
  // const editor13 = useRef(null)
  const editor13 = useRef(null);
  const editor14 = useRef(null);
  const editor15 = useRef(null);
  const editor16 = useRef(null);
  const editor17 = useRef(null);

  const editor18 = useRef(null);
  const editor19 = useRef(null);
  const editor20 = useRef(null);
  const editor21 = useRef(null);
  const editor22 = useRef(null);
  const click = useRef(0);
  const params = useParams();
  const businessPartner = JSON.parse(
    window.localStorage.getItem("business-client")
  );
  let id;
  if (props.admin === true) {
    id = params.id;
  } else {
    id = businessPartner?.user?._id;
  }

  const [part1, setPart1] = useState(false);
  const [part2, setPart2] = useState(false);
  const [part3, setPart3] = useState(false);

  const [home, setHome] = useState(false);
  const [brand, setBrand] = useState(false);
  const [primaryColor, setPrimaryColor] = useState("#FF7020");
  const [buttonColor, setButtonColor] = useState("white");
  const [buttonColor1, setButtonColor1] = useState("#3D05B6");
  const [secondaryColor, setSecondaryColor] = useState("#F5C400");
  const [colorBrand, setColorBrand] = useState("");
  const [brandColor, setBrandColor] = useColor("hex", "#121212");
  const [color, setColor] = useColor("hex", "#121212");
  const [color1, setColor1] = useColor("hex", "#121212");
  const [color2, setColor2] = useColor("hex", "#121212");
  const [colorSecondary, setColorSecondary] = useColor("hex", "#121212");
  const [filename, setFilename] = useState("");
  const [logoname, setLogoname] = useState("");
  const [footerlogoname, setFooterLogoname] = useState("");
  const [footerlogoname1, setFooterLogoname1] = useState("");
  const [footerlogoname2, setFooterLogoname2] = useState("");
  const [filename1, setFilename1] = useState("");
  const [filesize, setFilesize] = useState("");
  const [logosize, setLogosize] = useState("");
  const [footerlogosize, setFooterLogosize] = useState("");
  const [footerlogosize1, setFooterLogosize1] = useState("");
  const [footerlogosize2, setFooterLogosize2] = useState("");
  const [filesize1, setFilesize1] = useState("");
  const [image, setImage] = useState("");
  const [footerimage, setFooterImage] = useState("");
  const [footerimage1, setFooterImage1] = useState("");
  const [footerimage2, setFooterImage2] = useState("");
  const [logo, setLogo] = useState("");
  const [logotrue, setLogoTrue] = useState(true);
  const [image1, setImage1] = useState("");
  const [email, setEmail] = useState(false);
  const [emailOrder, setEmailOrder] = useState(false);
  const [refundOrder, setRefundOrder] = useState(false);
  const [balanceUpadte, setBalanceUpadte] = useState(false);
  const [balanceReduceUpadte, setBalanceReduceUpadte] = useState(false);
  const [pdfHandle, setPdfHandle] = useState(false);
  const [colorCheck, setColorCheck] = useState(false);
  const [BrandColorCheck, setBrandColorCheck] = useState(false);
  const [colorCheck1, setColorCheck1] = useState(false);
  const [colorCheckSecondary, setColorCheckSecondary] = useState(false);
  const [selectPrimaryColor, setSelectPrimaryColor] = useState(true);
  const [ncentivaSettings, setncentivaSettings] = useState(false);
  const [selectSecondaryColor, setSelectSecondaryColor] = useState(false);
  const [homeBannerColor, setHomeBannerColor] = useState(false);
  const [bannerColor, setBannerColor] = useState(false);
  const [videoPreview, setVideoPreview] = useState("");
  const [videoPreview1, setVideoPreview1] = useState("");
  const [messageTopicEmail, setMessageTopicEmail] = useState(
    "Great news, you just earned a bonus value of $ ${bonus} with the gift cards you selected.. A summary of your order appears below. You will receive a separate email that includes your gift card codes and instructions for redemption."
  );
  const [welcomeEmail, setWelcomeEmail] = useState(
    "Redeem your funds from a selection of prepaid VISA and Mastercard products, alongside 300+ of the best consumer digital card brands. The best part is you can earn a bonus value of up to 10% additional funds based on the brand(s) you select."
  );
  const [brand_name, setBrand_name] = useState("");
  const [refundEmail, setRefundEmail] = useState(
    "Opps! One or more of the gift cards you ordered could not be delivered at this time. We have processed a refund for the full value of the gift card(s) to your account wallet."
  );
  const [welcomeEmailHead, setWelcomeEmailHead] = useState(
    "Congratulations! ${customer_name}, you just received ${addBalance} from ${brand_name}."
  );
  const [refundEmailHead, setRefundEmailHead] = useState(
    "${brand_name} - failed transaction."
  );
  const [welcomeEmailSubject, setWelcomeEmailSubject] = useState(
    "${brand_name} - sent you funds - Time to select your gift cards!"
  );
  const [refundEmailSubject, setRefundEmailSubject] = useState(
    "${customer_name}, your gift card order transaction has failed"
  );
  const [messageEmailHead, setMessageEmailHead] = useState(
    "${customer_name}, your gift card order is confirmed."
  );
  const [messageEmailSubject, setMessageEmailSubject] = useState(
    "${brand_name} - gift card order confirmation"
  );
  const [homeBannerImage, setHomeBannerImage] = useState("");
  const [BannerImage, setBannerImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [welcomeEmail1, setWelcomeEmail1] = useState(
    "To get started click the “Go to Ncentiva” link below using the credentials provided to choose your gift card(s)."
  );
  const [refundEmail1, setRefundEmail1] = useState(
    "Apologies for any inconvenience this may have caused,$ ${value} is now immediately available for additional gift card purchases. Questions or concerns, please reach out to ${business_name} via (insert email)."
  );
  const [welcomeEmail2, setWelcomeEmail2] = useState(
    "Thank you for using ${brand_name} as your Ncentiva account."
  );
  const [refundEmail2, setRefundEmail2] = useState(
    "Thank you for using ${brand_name} as your Ncentiva account."
  );
  const [messageTopicEmail1, setMessageTopicEmail1] = useState(
    "You will receive a secondary email soon with your gift card(s), so be on the lookout.*"
  );
  const [balanceEmailSubject, setBalanceEmailSubject] = useState(
    "${brand_name} - sent you additional funds."
  );
  const [balanceEmailHead, setBalanceEmailHead] = useState(
    "Great News! ${customer_name}, you just received additional funds from ${brand_name} to shop for gift cards."
  );
  const [balanceEmail1, setBalanceEmail1] = useState(
    "You’ve just received $${addBalance} from ${business_name}, increasing your balance in your wallet to $${balance}."
  );
  const [balanceEmail2, setBalanceEmail2] = useState(
    ' A friendly reminder that you can redeem your funds from a selection of <span style="fontWeight:700px">prepaid VISA and Mastercard</span>  products, alongside <span>300+</span> of the best consumer digital gift card brands. The best part is you can earn a bonus value of up to <span>10% additional funds</span> based on the brand(s) you select.'
  );
  const [balanceEmail3, setBalanceEmail3] = useState(
    "Please click the “Go to Ncentiva” link below to access your account and shop for gift cards."
  );

  const [balanceReduceEmailSubject, setBalanceReduceEmailSubject] = useState(
    "${brand_name} - funds modified."
  );
  const [balanceReduceEmailHead, setBalanceReduceEmailHead] = useState(
    "${customer_name}, your funds have been modified for your account to shop for gift cards."
  );
  const [balanceReduceEmail1, setBalanceReduceEmail1] = useState(
    "${business_name} would like to to inform you that incorrect funds were applied to your account wallet. The incorrect amount of $ ${remove_balance} has been modified and the correct amount of $ ${balance} is now reflected in your account wallet."
  );
  const [balanceReduceEmail2, setBalanceReduceEmail2] = useState(
    "${business_name} apologies for any inconvenience this may have caused. For questions or concerns, please contact ${business_name} via email at (Insert email)."
  );
  const [balanceReduceEmail3, setBalanceReduceEmail3] = useState(
    "Please click the “Go to Ncentiva” link below to access your account and shop for gift cards."
  );

  const [image2, setImage2] = useState("");
  const [image3, setImage3] = useState("");
  const [image4, setImage4] = useState("");
  const [image5, setImage5] = useState("");
  const [image6, setImage6] = useState("");
  const [logoemail, setLogoemail] = useState("");
  const [logoname1, setLogoname1] = useState("");
  const [logoname2, setLogoname2] = useState("");
  const [logoname3, setLogoname3] = useState("");
  const [logoname4, setLogoname4] = useState("");
  const [logoname5, setLogoname5] = useState("");
  const [fileNameEmail, setFilenameEmail] = useState("");
  const [fileNameEmail1, setFilenameEmail1] = useState("");
  const [fileNameEmail2, setFilenameEmail2] = useState("");
  const [fileNameEmail3, setFilenameEmail3] = useState("");
  const [fileNameEmail4, setFilenameEmail4] = useState("");
  const [logo1, setLogo1] = useState("");
  const [logo2, setLogo2] = useState("");
  const [logo3, setLogo3] = useState("");
  const [logo4, setLogo4] = useState("");
  const [logo5, setLogo5] = useState("");
  const [footerlogoname3, setFooterLogoname3] = useState("");
  const [footerlogoname4, setFooterLogoname4] = useState("");
  const [footerimage3, setFooterImage3] = useState("");
  const [footerimage4, setFooterImage4] = useState("");
  const [colorCheck2, setColorCheck2] = useState(false);
  const [colorCheck3, setColorCheck3] = useState(false);
  const [emailHeaderColor, setEmailHeaderColor] = useState(false);
  const [emailHeaderColor1, setEmailHeaderColor1] = useState(false);
  const [emailHeaderColor2, setEmailHeaderColor2] = useState(false);
  const [emailHeaderColor3, setEmailHeaderColor3] = useState(false);
  const [emailHeaderColor4, setEmailHeaderColor4] = useState(false);
  const [url, setUrl] = useState([]);
  const [burl, setBurl] = useState([]);
  const [error, setError] = useState(false);
  const [defaultCmsUrl, setDefaultCmsUrl] = useState(false);

  const [homeBannerColorCombination, setHomeBannerColorCombination] =
    useState();
  const [homeBannerColorCombination1, setHomeBannerColorCombination1] =
    useState();
  const [BannerColorCombination, setBannerColorCombination] = useState();
  const [BannerColorCombination1, setBannerColorCombination1] = useState();
  const [welcomeEmailColorCombination, setWelcomeEmailColorCombination] =
    useState();
  const [welcomeEmailColorCombination1, setWelcomeEmailColorCombination1] =
    useState();
  const [orderEmailColorCombination, setOrderEmailColorCombination] =
    useState();
  const [orderEmailColorCombination1, setOrderEmailColorCombination1] =
    useState();
  const [refundEmailColorCombination, setRefundEmailColorCombination] =
    useState();
  const [refundEmailColorCombination1, setRefundEmailColorCombination1] =
    useState();
  const [balanceEmailColorCombination, setBalanceEmailColorCombination] =
    useState();
  const [balanceEmailColorCombination1, setBalanceEmailColorCombination1] =
    useState();
  const [
    balanceReduceEmailColorCombination,
    setBalanceReduceEmailColorCombination,
  ] = useState();
  const [
    balanceReduceEmailColorCombination1,
    setBalanceReduceEmailColorCombination1,
  ] = useState();

  const [welcomeIconText, setWelcomeIconText] = useState(false);
  const [orderIconText, setOrderIconText] = useState(false);
  const [refundIconText, setRefundIconText] = useState(false);
  const [balanceIconText, setBalanceIconText] = useState(false);
  const [balanceReduceIconText, setBalanceReduceIconText] = useState(false);

  // pdf handle

  const pdfInputRef = useRef(null);

  const [pdfName, setPdfName] = useState("");
  const [imageName, setImageName] = useState("");
  const [pdfPreview, setPdfPreview] = useState("");
  const [balanceReducePdfText, setBalanceReducePdfText] = useState(false);

  const [pdfFile, setPdfFile] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfPreviewApi, setPdfPreviewApi] = useState(false);
  // pdf handle

  // handle pdf image
  const [pdfimage, setPdfImage] = useState("");
  const [pdfimageName, setPdfImageName] = useState("");
  const [pdfimagePreview, setPdfimagePreview] = useState("");

  // handle pdf image

  // const [welcomeEmailSubjectError,setWelcomeEmailSubjectError] = useState(false);

  let navigate = useNavigate();

  const handleHome = () => {
    setHome(!home);
  };

  const handlePart1 = () => {
    setPart1(!part1);
  };
  const handlePart2 = () => {
    setPart2(!part2);
  };
  const handlePart3 = () => {
    setPart3(!part3);
  };

  const handleBrand = () => {
    setBrand(!brand);
  };

  const handleEmail = () => {
    setEmail(!email);
  };
  const handleEmailOrder = () => {
    setEmailOrder(!emailOrder);
  };
  const handleRefundOrder = () => {
    setRefundOrder(!refundOrder);
  };
  const handleBalanceUpadted = () => {
    setBalanceUpadte(!balanceUpadte);
  };
  const handleBalanceReduceUpadted = () => {
    setBalanceReduceUpadte(!balanceReduceUpadte);
  };
  const handlepdfed = () => {
    setPdfHandle(!pdfHandle);
  };

  const resetInfo = () => {
    setPdfName("");
    setPdfFile("");
    setImageName("");
    setPdfPreview("");
    setPdfimagePreview("");
    if (pdfFile?.length > 0) {
      setPdfPreviewApi(false);
    } else {
      setPdfPreviewApi(true);
    }

    // setPdfPreview();
    setBalanceReducePdfText(false);
  };
  const handleColor = () => {
    setColorCheck(!colorCheck);
  };
  const handleColor1 = () => {
    setColorCheck1(!colorCheck1);
  };
  const handleColor2 = () => {
    setColorCheck2(!colorCheck2);
  };
  const handleColor3 = () => {
    setColorCheck3(!colorCheck3);
  };
  const handlencentivaSettings = () => {
    setncentivaSettings(!ncentivaSettings);
  };

  const handlePost = async (section) => {
    if (ncentivaSettings === false) {
       if(logo === "" && brand_name.length<3){
         toast.warning("Please upload business logo");
         setError(true);
         setLogoTrue(false);
         return;
        }else if(logo === ""){
          toast.warning("Please upload business logo");
          setLogoTrue(false);
          return;
        }else{
          setLogoTrue(true);
        }
      if(brand_name.length<3 || brand_name.length>63){
        toast.warning("Business Name Required");
        setError(true);
        return;
      }

      // if(homeBannerColor === false && homeBannerImage === ""){
      //   toast.warning("Please provide a Home page banner");
      //   return;
      // }
      // if(bannerColor === false && BannerImage == ""){
      //   toast.warning("Please provide a Brand page banner");
      //   return;
      // }

      // if(welcomeEmailSubject === "<p><br></p>" || welcomeEmailHead === "" || welcomeEmail === "" || welcomeEmail1 === "" || welcomeEmail2 === ""){
      //   toast("Please provide a valid welcome email");
      //   return;
      // }
      if (welcomeEmailSubject.length < 18) {
        toast.warning(
          "Welcome mail subject must contain atleast 10 characters"
        );
        return;
      }
      if (welcomeEmailHead.length < 14) {
        toast.warning("Welcome mail heading must contain atleast 6 characters");
        return;
      }
      if (welcomeEmail.length < 18) {
        toast.warning("Welcome mail body1 must contain atleast 10 characters");
        return;
      }
      if (welcomeEmail1.length < 18) {
        toast.warning("Welcome mail body2 must contain atleast 10 characters");
        return;
      }
      if (welcomeEmail2.length < 18) {
        toast.warning("Welcome mail body3 must contain atleast 10 characters");
        return;
      }
      if (messageEmailSubject.length < 18) {
        toast.warning("Order mail subject must contain atleast 10 characters");
        return;
      }
      if (messageEmailHead.length < 14) {
        toast.warning("Order mail heading must contain atleast 6 characters");
        return;
      }
      if (messageTopicEmail < 18) {
        toast.warning("Order mail body1 must contain atleast 10 characters");
        return;
      }
      if (messageTopicEmail1 < 18) {
        toast.warning("Order mail body2 must contain atleast 10 characters");
        return;
      }
    }
    setIsLoading(true);
    setError(false);
    let footer_logos = [
      {
        logo_one: footerimage,
        logo_two: footerimage1,
        logo_three: footerimage2,
      },
    ];

    try {
      if (bannerColor === true) {
        setImage1("");
        setFilename1("");
        setBannerImage("");
      }
      if (homeBannerColor === true) {
        setHomeBannerImage("");
      }
      if (emailHeaderColor1 === true) {
        setFilenameEmail1("");
        setImage3("");
      }
      if (emailHeaderColor === true) {
        setFilenameEmail("");
        setImage2("");
      }
      let token;
      let para;
      let userId;
      if (props.admin === true) {
        const user = JSON.parse(window.localStorage.getItem("client"));
        para = "admin";
        if (user) {
          // token = user.token;
          token = getNewToken();
          userId = user.user._id;
        } else {
          token = null;
          userId = null;
        }
      } else {
        const user = JSON.parse(window.localStorage.getItem("business-client"));
        para = "partner";
        if (user) {
          // token = user.token;
          token = getNewPartnerToken();
          userId = user.user._id;
        } else {
          token = null;
          userId = null;
        }
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/${para}/cms/create`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          section: section,
          default_ncentiva: ncentivaSettings,
          brand_logo: logo,
          brand_name: brand_name,
          primary_color: primaryColor,
          secondary_color: secondaryColor,
          home_image: homeBannerImage,
          home_image_color: homeBannerColor,
          banner_image_color: bannerColor,
          footer_logos: footer_logos,
          banner_image: BannerImage,
          welcome_mail_subject: welcomeEmailSubject,
          welcome_mail_heading: welcomeEmailHead,
          welcome_mail_body: welcomeEmail,
          order_mail_subject: messageEmailSubject,
          order_mail_heading: messageEmailHead,
          order_mail_body: messageTopicEmail,
          refund_mail_subject: refundEmailSubject,
          refund_mail_heading: refundEmailHead,
          refund_mail_body: refundEmail,
          partner_id: id,
          order_mail_header_color: emailHeaderColor1,
          refund_mail_header_color: emailHeaderColor2,
          welcome_mail_header_color: emailHeaderColor,
          welcome_mail_body_two: welcomeEmail1,
          welcome_mail_body_three: welcomeEmail2,
          welcome_mail_email_icon: logo1,
          // welcome_mail_color : buttonColor,
          welcome_mail_header_image: image2,
          welcome_mail_footer: footerimage3,
          order_mail_body_two: messageTopicEmail1,
          refund_mail_body_two: refundEmail1,
          // refund_mail_body_three : refundEmail2,
          order_mail_email_icon: logo2,
          refund_mail_email_icon: logo3,
          btn_color: buttonColor1,
          btn_text: buttonColor,
          // order_mail_color : buttonColor1,
          order_mail_header_image: image3,
          refund_mail_header_image: image4,
          order_mail_footer: footerimage4,
          homeBannerColorCombination: homeBannerColorCombination,
          homeBannerColorCombination1: homeBannerColorCombination1,
          bannerColorCombination: BannerColorCombination,
          bannerColorCombination1: BannerColorCombination1,
          welcomeEmailColorCombination: welcomeEmailColorCombination,
          welcomeEmailColorCombination1: welcomeEmailColorCombination1,
          orderEmailColorCombination: orderEmailColorCombination,
          orderEmailColorCombination1: orderEmailColorCombination1,
          refundEmailColorCombination: refundEmailColorCombination,
          refundEmailColorCombination1: refundEmailColorCombination1,
          balance_mail_header_color: emailHeaderColor3,
          balance_mail_subject: balanceEmailSubject,
          balance_mail_heading: balanceEmailHead,
          balance_mail_body: balanceEmail1,
          balance_mail_body_two: balanceEmail2,
          balance_mail_body_three: balanceEmail3,
          balance_mail_email_icon: logo4,
          balance_mail_header_image: image5,
          balanceEmailColorCombination: balanceEmailColorCombination,
          balanceEmailColorCombination1: balanceEmailColorCombination1,

          balance_reduce_mail_header_color: emailHeaderColor4,
          balance_reduce_mail_subject: balanceReduceEmailSubject,
          balance_reduce_mail_heading: balanceReduceEmailHead,
          balance_reduce_mail_body: balanceReduceEmail1,
          balance_reduce_mail_body_two: balanceReduceEmail2,
          balance_reduce_mail_body_three: balanceReduceEmail3,
          balance_reduce_mail_email_icon: logo5,
          balance_reduce_mail_header_image: image6,
          balanceReduceEmailColorCombination:
            balanceReduceEmailColorCombination,
          balanceReduceEmailColorCombination1:
            balanceReduceEmailColorCombination1,
          pdfPreview: pdfPreview,
          pdfimagePreview: pdfimagePreview,
          loginUserId: userId,
        }
      );
      setIsLoading(false);
      toast.success(data.message);

      if (ncentivaSettings == false) {
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    let token;
    let para;
    if (props.admin === true) {
      const user = JSON.parse(window.localStorage.getItem("client"));
      para = "admin";
      if (user) {
        // token = user.token;
        token = getNewToken();
      } else {
        token = null;
      }
    } else {
      const user = JSON.parse(window.localStorage.getItem("business-client"));
      para = "partner";
      if (user) {
        // token = user.token;
        token = getNewPartnerToken();
      } else {
        token = null;
      }
    }
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/${para}/getcmss/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (para == "admin") {
          if (res.data.message == "Please Login") {
            localStorage.removeItem("client");
            navigate("/admin");
            toast.error("Session Expired Please Login Again");
          }
        } else {
          if (res.data.message == "Please Login") {
            localStorage.removeItem("business-client");
            navigate("/business-login");
            toast.error("Session Expired Please Login Again");
          }
        }
        setIsLoading(false);
        setUrl(res.data.url);
        setBurl(res.data.bs_url);
        setDefaultCmsUrl(res.data.default_cms_url);

        // if(res.data?.cms?.default_ncentiva === true){
        //   var cmsimage = res.data?.cms;
        //   setPdfimagePreview(cmsimage.pdfimagePreview);
        //   setPdfPreview('');
        // }else{

        // setPdfimagePreview(cms.pdfimagePreview);
        //  setPdfPreview('');
        // }

        let cms;
        if (res.data?.cms?.default_ncentiva == true) {
          setncentivaSettings(true);
          cms = res.data.cmsOld;
        } else {
          cms = res?.data?.cms;
        }

        if (res.data?.cms?.default_ncentiva == true) {
          var cmsimage = res.data?.cms;
          setPdfimagePreview(cmsimage.pdfimagePreview);
          setPdfPreview("");
        }

        if (cms.welcome_mail_email_icon) {
          setWelcomeIconText(true);
        }
        if (cms.order_mail_email_icon) {
          setOrderIconText(true);
        }
        if (cms.refund_mail_email_icon) {
          setRefundIconText(true);
        }
        if (cms.balance_mail_email_icon) {
          setBalanceIconText(true);
        }
        if (cms.balance_reduce_mail_email_icon) {
          setBalanceReduceIconText(true);
        }

        const footer = cms.footer_logos;
        // if(cms.brand_name){
        //   setWelcomeEmail1(` At <span style="color: #FF7020;">${cms.brand_name}</span>, our goal is to deliver extra value. You can redeem your funds for hundreds of different gift cards. The best part is that, depending on which gift card(s) you choose, you can earn an average of 10% bonus value over and above the funds received from ${cms.brand_name}.`);
        //   setWelcomeEmail2(`Thank you for using ${cms.brand_name} as your Ncentiva account.`)
        //   setWelcomeEmailSubject(`Welcome to ${cms.brand_name} Ncentiva account.`);
        //   setWelcomeEmailHead("Hello ${first_name},"+" "+`Welcome to ${cms.brand_name}.`);
        //   setMessageEmailSubject(`${cms.brand_name} - Your `+" ${customer_business_partner}"+ ` Gift Card Order`)

        // }

        if (cms.balance) {
          setWelcomeEmail(
            `You’ve just received $ ${Number(cms.balance)} from ${
              cms.brand_name
            } allowing you to select from a variety of digital gift cards from popular brands you know and maybe some new ones you’ll discover.`
          );
        }

        if (cms) {
          setWelcomeEmail(cms.welcome_mail_body);
          setWelcomeEmail1(cms.welcome_mail_body_two);
          setWelcomeEmail2(cms.welcome_mail_body_three);
          setWelcomeEmailSubject(cms.welcome_mail_subject);
          setWelcomeEmailHead(cms.welcome_mail_heading);
          setRefundEmail(cms.refund_mail_body);
          setRefundEmail1(cms.refund_mail_body_two);
          // setRefundEmail2(cms.refund_mail_body_three);
          setRefundEmailSubject(cms.refund_mail_subject);
          setRefundEmailHead(cms.refund_mail_heading);
          setBalanceEmail1(cms.balance_mail_body);
          setBalanceEmail2(cms.balance_mail_body_two);
          setBalanceEmail3(cms.balance_mail_body_three);
          setBalanceEmailSubject(cms.balance_mail_subject);
          setBalanceEmailHead(cms.balance_mail_heading);

          setBalanceReduceEmail1(cms.balance_reduce_mail_body);
          setBalanceReduceEmail2(cms.balance_reduce_mail_body_two);
          setBalanceReduceEmail3(cms.balance_reduce_mail_body_three);
          setBalanceReduceEmailSubject(cms.balance_reduce_mail_subject);
          setBalanceReduceEmailHead(cms.balance_reduce_mail_heading);

          setMessageEmailSubject(cms.order_mail_subject);
          setMessageEmailHead(cms.order_mail_heading);
          setMessageTopicEmail(cms.order_mail_body);
          setMessageTopicEmail1(cms.order_mail_body_two);
          setBrand_name(cms.brand_name);
          setPrimaryColor(cms.primary_color);
          setSecondaryColor(cms.secondary_color);
          setLogo(cms.brand_logo);
          setLogoTrue(true);
          setLogoname("BrandLogo.png");
          setHomeBannerColor(cms.home_image_color);
          setImage(cms.home_image);
          setHomeBannerImage(cms.home_image);
          setBannerColor(cms.banner_image_color);
          setBannerImage(cms.banner_image);
          setButtonColor1(cms.btn_color);
          setButtonColor(cms.btn_text);
          setImage1(cms.banner_image);
          setMessageTopicEmail1(cms.order_mail_body_two);
          setHomeBannerColorCombination(cms.homeBannerColorCombination);
          setHomeBannerColorCombination1(cms.homeBannerColorCombination1);
          setBannerColorCombination(cms.bannerColorCombination);
          setBannerColorCombination1(cms.bannerColorCombination1);
          setWelcomeEmailColorCombination(cms.welcomeEmailColorCombination);
          setWelcomeEmailColorCombination1(cms.welcomeEmailColorCombination1);
          setOrderEmailColorCombination(cms.orderEmailColorCombination);
          setOrderEmailColorCombination1(cms.orderEmailColorCombination1);
          setRefundEmailColorCombination(cms.refundEmailColorCombination);
          setRefundEmailColorCombination1(cms.refundEmailColorCombination1);
          setBalanceEmailColorCombination(cms.balanceEmailColorCombination);
          setBalanceEmailColorCombination1(cms.balanceEmailColorCombination1);
          setBalanceReduceEmailColorCombination(
            cms.balanceReduceEmailColorCombination
          );
          setBalanceReduceEmailColorCombination1(
            cms.balanceReduceEmailColorCombination1
          );

          if (cms.pdfPreview) {
            setPdfPreview(cms.pdfPreview);
            setPdfPreviewApi(true);
            setPdfFile("");
            setPdfimagePreview("");
          } else if (cms.pdfimagePreview) {
            if (res.data?.cms?.default_ncentiva === true) {
              var cmsimage = res.data?.cms;
              setPdfimagePreview(cmsimage.pdfimagePreview);
              setPdfPreview("");
            } else {
              setPdfimagePreview(cms.pdfimagePreview);
              setPdfPreview("");
            }
          }
          //  setButtonColor1(cms.order_mail_color);
          //  setButtonColor(cms.welcome_mail_color);
          if (cms.order_mail_header_color === true) {
            setEmailHeaderColor1(true);
          }
          if (cms.refund_mail_header_color === true) {
            setEmailHeaderColor2(true);
          }
          if (cms.balance_reduce_mail_header_color === true) {
            setEmailHeaderColor4(true);
          }
          if (cms.balance_mail_header_color === true) {
            setEmailHeaderColor3(true);
          }
          if (cms.welcome_mail_header_color === true) {
            setEmailHeaderColor(true);
          }
          let imgFoo = footer[0].logo_one;
          let data = imgFoo.split(".");
          if (data[data.length - 1] === "undefined") {
            setFooterImage("");
            setFooterLogoname("");
            setLogoname1("");
            setLogoname2("");
            setLogoname3("");
            setLogoname4("");
            setLogoname5("");
          } else if (data == "") {
            setFooterImage("");
            setFooterLogoname("");
            setLogoname1("");
            setLogoname2("");
            setLogoname3("");
            setLogoname4("");
            setLogoname5("");
          } else {
            setFooterImage(footer[0].logo_one);
            setFooterLogoname("FooterLogo1.png");
            setLogoname1("WelcomeEmail.png");
            setLogoname2("OrderEmail.png");
            setLogoname3("RefundEmail.png");
            setLogoname4("BalanceUpdateEmail.png");
            setLogoname5("BalanceReduceEmail.png");
          }

          if (cms.welcome_mail_email_icon) {
            setLogoname1("WelcomeEmail.png");
          }
          if (cms.order_mail_email_icon) {
            setLogoname2("OrderEmail.png");
          }
          if (cms.refund_mail_email_icon) {
            setLogoname3("RefundEmail.png");
          }
          if (cms.balance_mail_email_icon) {
            setLogoname4("BalanceUpdateEmail.png");
          }
          if (cms.balance_reduce_mail_email_icon) {
            setLogoname5("BalanceReduceEmail.png");
          }
          let imgFoo1 = footer[0].logo_two;
          let data1 = imgFoo1.split(".");
          if (data1[data1.length - 1] === "undefined") {
            setFooterImage1("");
            setFooterLogoname1("");
          } else if (data1 == "") {
            setFooterImage1("");
            setFooterLogoname1("");
          } else {
            setFooterImage1(footer[0].logo_two);
            setFooterLogoname1("FooterLogo2.png");
          }
          let imgFoo2 = footer[0].logo_three;
          let data2 = imgFoo2.split(".");
          if (data2[data2.length - 1] === "undefined") {
            setFooterImage2("");
            setFooterLogoname2("");
          } else if (data2 == "") {
            setFooterImage2("");
            setFooterLogoname2("");
          } else {
            setFooterImage2(footer[0].logo_three);
            setFooterLogoname2("FooterLogo3.png");
          }

          let check = cms.welcome_mail_header_image;
          check = check.split(".");
          if (check[check.length - 1] === "undefined") {
            setImage2("");
          } else {
            setImage2(cms.welcome_mail_header_image);
          }
          let check1 = cms.order_mail_header_image;
          check1 = check1.split(".");
          if (check1[check1.length - 1] === "undefined") {
            setImage3("");
          } else {
            setImage3(cms.order_mail_header_image);
          }
          if (check1[check1.length - 1] === "undefined") {
            setImage4("");
          } else {
            setImage4(cms.refund_mail_header_image);
          }
          if (check1[check1.length - 1] === "undefined") {
            setImage5("");
          } else {
            setImage5(cms.balance_mail_header_image);
          }
          if (check1[check1.length - 1] === "undefined") {
            setImage6("");
          } else {
            setImage6(cms.balance_reduce_mail_header_image);
          }
          let check2 = cms.welcome_mail_email_icon;
          check2 = check2.split(".");
          if (check2[check2.length - 1] === "undefined") {
            setLogo1("");
          } else {
            setLogo1(cms.welcome_mail_email_icon);
          }
          let check3 = cms.order_mail_email_icon;
          check3 = check3.split(".");
          if (check3[check3.length - 1] === "undefined") {
            setLogo2("");
          } else {
            setLogo2(cms.order_mail_email_icon);
          }
          if (check3[check3.length - 1] === "undefined") {
            setLogo3("");
          } else {
            setLogo3(cms.refund_mail_email_icon);
          }
          if (check3[check3.length - 1] === "undefined") {
            setLogo4("");
          } else {
            setLogo4(cms.balance_mail_email_icon);
          }
          if (check3[check3.length - 1] === "undefined") {
            setLogo5("");
          } else {
            setLogo5(cms.balance_reduce_mail_email_icon);
          }

          // setLogo2(cms.order_mail_email_icon );
          let check4 = cms.welcome_mail_footer;
          check4 = check4.split(".");
          if (check4[check4.length - 1] === "undefined") {
            setFooterImage3("");
          } else {
            setFooterImage3(cms.welcome_mail_footer);
          }
          let check5 = cms.welcome_mail_footer;
          check5 = check5.split(".");
          if (check5[check5.length - 1] === "undefined") {
            setFooterImage4("");
          } else {
            setFooterImage4(cms.order_mail_footer);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        if (para === "partner") {
          if (err?.response?.data?.message === "Unauthorized User") {
            localStorage.removeItem("business-client");
            navigate("/business-login");
            toast.error("Session Expired Please Login Again");
          }
        }
      });
  }, []);

  const handleFiless = (files) => {
    // setFilesize(files[0].size);

    if (files[0].size <= 5 * 1024 * 1024) {
      let file = files[0];
      const reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
      }
      const reader1 = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          const maxWidth = 1338; // maximum width in pixels
          const maxHeight = 350; // maximum height in pixels
          // if (img.width > maxWidth || img.height > maxHeight) {
          //   toast.warning(`Image dimensions must be less than ${maxWidth} x ${maxHeight}px`);
          // } else
          if (img.src) {
            setEmailHeaderColor(false);
            setFilenameEmail(files[0].name);
            setImage2(img.src);
            return;
          }
        };
      };
      reader1.readAsDataURL(file);
    } else {
      toast.warning("File size exceeds 5MB limit.");
    }
  };
  const handleFiless1 = (files) => {
    // setFilesize(files[0].size);
    if (files[0].size <= 5 * 1024 * 1024) {
      let file = files[0];
      const reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
      }
      const reader1 = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          const maxWidth = 1338; // maximum width in pixels
          const maxHeight = 350; // maximum height in pixels
          // if (img.width > maxWidth || img.height > maxHeight) {
          //   toast.warning(`Image dimensions must be less than ${maxWidth} x ${maxHeight}px`);
          // } else
          if (img.src) {
            setEmailHeaderColor1(false);
            setFilenameEmail1(files[0].name);
            setImage3(img.src);
            return;
          }
        };
      };
      reader1.readAsDataURL(file);
    } else {
      toast.warning("File size exceeds 5MB limit.");
    }
  };

  const handleFiless2 = (files) => {
    // setFilesize(files[0].size);
    if (files[0].size <= 5 * 1024 * 1024) {
      let file = files[0];
      const reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
      }
      const reader1 = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          const maxWidth = 1338; // maximum width in pixels
          const maxHeight = 350; // maximum height in pixels
          // if (img.width > maxWidth || img.height > maxHeight) {
          //   toast.warning(`Image dimensions must be less than ${maxWidth} x ${maxHeight}px`);
          // } else
          if (img.src) {
            setEmailHeaderColor2(false);
            setFilenameEmail2(files[0].name);
            setImage4(img.src);
            return;
          }
        };
      };
      reader1.readAsDataURL(file);
    } else {
      toast.warning("File size exceeds 5MB limit.");
    }
  };

  const handleFiless3 = (files) => {
    // setFilesize(files[0].size);
    if (files[0].size <= 5 * 1024 * 1024) {
      let file = files[0];
      const reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
      }
      const reader1 = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          const maxWidth = 1338; // maximum width in pixels
          const maxHeight = 350; // maximum height in pixels
          // if (img.width > maxWidth || img.height > maxHeight) {
          //   toast.warning(`Image dimensions must be less than ${maxWidth} x ${maxHeight}px`);
          // } else
          if (img.src) {
            setEmailHeaderColor3(false);
            setFilenameEmail3(files[0].name);
            setImage5(img.src);
            return;
          }
        };
      };
      reader1.readAsDataURL(file);
    } else {
      toast.warning("File size exceeds 5MB limit.");
    }
  };

  const handleFiless4 = (files) => {
    // setFilesize(files[0].size);
    if (files[0].size <= 5 * 1024 * 1024) {
      let file = files[0];
      const reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
      }
      const reader1 = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          const maxWidth = 1338; // maximum width in pixels
          const maxHeight = 350; // maximum height in pixels
          // if (img.width > maxWidth || img.height > maxHeight) {
          //   toast.warning(`Image dimensions must be less than ${maxWidth} x ${maxHeight}px`);
          // } else
          if (img.src) {
            setEmailHeaderColor4(false);
            setFilenameEmail4(files[0].name);
            setImage6(img.src);
            return;
          }
        };
      };
      reader1.readAsDataURL(file);
    } else {
      toast.warning("File size exceeds 5MB limit.");
    }
  };

  const onDrop = useCallback((files) => {
    // Do something with the files
    if (files[0].size <= 5 * 1024 * 1024) {
      setLogosize(files[0].size);
      let file = files[0];

      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          const maxWidth = 200; // maximum width in pixels
          const maxHeight = 50; // maximum height in pixels
          // if (img.width > maxWidth || img.height > maxHeight) {
          //   toast.warning(`Image dimensions must be less than ${maxWidth} x ${maxHeight}px`);
          // } else
          if (files[0].name) {
            setLogoname(files[0].name);
            setLogo(img.src);
            setLogoTrue(true);
            return;
          }
        };
      };
      reader.readAsDataURL(file);
    } else {
      toast.warning("File size exceeds 5MB limit.");
    }
  }, []);
  const handleFiles = (files) => {
    setHomeBannerColor(false);
    setFilesize(files[0].size);

    if (files[0].size <= 5 * 1024 * 1024) {
      let file = files[0];
      const reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
      }
      reader.onload = (readerEvent) => {
        if (file.type.includes("video")) {
          setVideoPreview(readerEvent.target.result);
          setHomeBannerImage(readerEvent.target.result);
          setFilename(files[0].name);
          return;
        }
      };
      const reader1 = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          const maxWidth = 1338; // maximum width in pixels
          const maxHeight = 180; // maximum height in pixels
          // if (img.width > maxWidth || img.height > maxHeight) {
          //   toast.warning(`Image dimensions must be less than ${maxWidth} x ${maxHeight}px`);
          // } else
          if (img.src) {
            setFilename(files[0].name);
            setImage(img.src);
            setVideoPreview("");
            setHomeBannerImage(img.src);
            return;
          }
        };
      };
      reader1.readAsDataURL(file);
    } else {
      toast.warning("File size exceeds 5MB limit.");
    }
  };
  const handleFooterLogo = (files) => {
    if (files[0].size <= 5 * 1024 * 1024) {
      setFooterLogosize(files[0].size);
      let file = files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          const maxWidth = 80; // maximum width in pixels
          const maxHeight = 50; // maximum height in pixels
          // if (img.width > maxWidth || img.height > maxHeight) {
          //   toast.warning(`Image dimensions must be less than ${maxWidth} x ${maxHeight}px`);
          // } else
          if (img.src) {
            setFooterLogoname(files[0].name);
            setFooterImage(img.src);
            return;
          }
        };
      };
      reader.readAsDataURL(file);
    } else {
      toast.warning("File size exceeds 5MB limit.");
    }
  };

  const handleFooterLogo3 = (files) => {
    // setFooterLogosize(files[0].size);
    if (files[0].size <= 5 * 1024 * 1024) {
      let file = files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          const maxWidth = 150; // maximum width in pixels
          const maxHeight = 50; // maximum height in pixels
          // if (img.width > maxWidth || img.height > maxHeight) {
          //   toast.warning(`Image dimensions must be less than ${maxWidth} x ${maxHeight}px`);
          // } else
          if (img.src) {
            setFooterLogoname3(files[0].name);
            setFooterImage3(img.src);
            return;
          }
        };
      };
      reader.readAsDataURL(file);
    } else {
      toast.warning("File size exceeds 5MB limit.");
    }
  };

  const handleFooterLogo4 = (files) => {
    // setFooterLogosize(files[0].size);

    if (files[0].size <= 5 * 1024 * 1024) {
      let file = files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          const maxWidth = 150; // maximum width in pixels
          const maxHeight = 50; // maximum height in pixels
          // if (img.width > maxWidth || img.height > maxHeight) {
          //   toast.warning(`Image dimensions must be less than ${maxWidth} x ${maxHeight}px`);
          // } else
          if (img.src) {
            setFooterLogoname4(files[0].name);
            setFooterImage4(img.src);
            return;
          }
        };
      };
      reader.readAsDataURL(file);
    } else {
      toast.warning("File size exceeds 5MB limit.");
    }
  };

  const handleEmailIcon = (files) => {
    // setFooterLogosize(files[0].size);

    if (files[0].size <= 5 * 1024 * 1024) {
      let file = files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          const maxWidth = 80; // maximum width in pixels
          const maxHeight = 50; // maximum height in pixels
          // if (img.width > maxWidth || img.height > maxHeight) {
          //   toast.warning(`Image dimensions must be less than ${maxWidth} x ${maxHeight}px`);
          // } else
          if (img.src) {
            setLogoname1(files[0].name);
            setLogo1(img.src);
            setWelcomeIconText(true);
            return;
          }
        };
      };
      reader.readAsDataURL(file);
    } else {
      toast.warning("File size exceeds 5MB limit.");
    }
  };
  const handleEmailIcon2 = (files) => {
    // setFooterLogosize(files[0].size);

    if (files[0].size <= 5 * 1024 * 1024) {
      let file = files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          const maxWidth = 80; // maximum width in pixels
          const maxHeight = 50; // maximum height in pixels
          // if (img.width > maxWidth || img.height > maxHeight) {
          //   toast.warning(`Image dimensions must be less than ${maxWidth} x ${maxHeight}px`);
          // } else
          if (img.src) {
            setLogoname2(files[0].name);
            setLogo2(img.src);
            setOrderIconText(true);
            return;
          }
        };
      };
      reader.readAsDataURL(file);
    } else {
      toast.warning("File size exceeds 5MB limit.");
    }
  };

  const handleEmailIcon3 = (files) => {
    if (files[0].size <= 5 * 1024 * 1024) {
      let file = files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          const maxWidth = 80; // maximum width in pixels
          const maxHeight = 50; // maximum height in pixels
          // if (img.width > maxWidth || img.height > maxHeight) {
          //   toast.warning(`Image dimensions must be less than ${maxWidth} x ${maxHeight}px`);
          // } else
          if (img.src) {
            setLogoname3(files[0].name);
            setLogo3(img.src);
            setRefundIconText(true);
            return;
          }
        };
      };
      reader.readAsDataURL(file);
    } else {
      toast.warning("File size exceeds 5MB limit.");
    }
  };

  const handleEmailIcon4 = (files) => {
    if (files[0].size <= 5 * 1024 * 1024) {
      let file = files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          const maxWidth = 80; // maximum width in pixels
          const maxHeight = 50; // maximum height in pixels
          // if (img.width > maxWidth || img.height > maxHeight) {
          //   toast.warning(`Image dimensions must be less than ${maxWidth} x ${maxHeight}px`);
          // } else
          if (img.src) {
            setLogoname4(files[0].name);
            setLogo4(img.src);
            setBalanceIconText(true);
            return;
          }
        };
      };
      reader.readAsDataURL(file);
    } else {
      toast.warning("File size exceeds 5MB limit.");
    }
  };

  const handleEmailIcon5 = (files) => {
    if (files[0].size <= 5 * 1024 * 1024) {
      let file = files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          const maxWidth = 80; // maximum width in pixels
          const maxHeight = 50; // maximum height in pixels
          // if (img.width > maxWidth || img.height > maxHeight) {
          //   toast.warning(`Image dimensions must be less than ${maxWidth} x ${maxHeight}px`);
          // } else
          if (img.src) {
            setLogoname5(files[0].name);
            setLogo5(img.src);
            setBalanceReduceIconText(true);
            return;
          }
        };
      };
      reader.readAsDataURL(file);
    } else {
      toast.warning("File size exceeds 5MB limit.");
    }
  };

  const handleFooterLogo1 = (files) => {
    if (files[0].size <= 5 * 1024 * 1024) {
      setFooterLogosize1(files[0].size);
      let file = files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          const maxWidth = 80; // maximum width in pixels
          const maxHeight = 50; // maximum height in pixels
          // if (img.width > maxWidth || img.height > maxHeight) {
          //   toast.warning(`Image dimensions must be less than ${maxWidth} x ${maxHeight}px`);
          // } else
          if (img.src) {
            setFooterImage1(img.src);
            setFooterLogoname1(files[0].name);
            return;
          }
        };
      };
      reader.readAsDataURL(file);
    } else {
      toast.warning("File size exceeds 5MB limit.");
    }
  };
  const handleFooterLogo2 = (files) => {
    if (files[0].size <= 5 * 1024 * 1024) {
      setFooterLogosize2(files[0].size);
      let file = files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          const maxWidth = 80; // maximum width in pixels
          const maxHeight = 50; // maximum height in pixels
          // if (img.width > maxWidth || img.height > maxHeight) {
          //   toast.warning(`Image dimensions must be less than ${maxWidth} x ${maxHeight}px`);
          // } else
          if (img.src) {
            setFooterLogoname2(files[0].name);
            setFooterImage2(img.src);
            return;
          }
        };
      };
      reader.readAsDataURL(file);
    } else {
      toast.warning("File size exceeds 5MB limit.");
    }
  };

  const handleselectPrimaryColor = () => {
    setSelectPrimaryColor(!selectPrimaryColor);
  };

  const handleBrandColorStatus = () => {
    setBrandColorCheck(!BrandColorCheck);
  };
  const handleselectSecondaryColor = () => {
    setSelectSecondaryColor(!selectSecondaryColor);
  };
  const handleFiles1 = (files) => {
    setBannerColor(false);
    setFilesize1(files[0].size);
    if (files[0].size <= 5 * 1024 * 1024) {
      let file = files[0];
      const reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
      }
      reader.onload = (readerEvent) => {
        if (file.type.includes("video")) {
          setFilename1(files[0].name);
          setVideoPreview1(readerEvent.target.result);
          setBannerImage(readerEvent.target.result);
          return;
        }
      };
      const reader1 = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          const maxWidth = 1903; // maximum width in pixels
          const maxHeight = 105; // maximum height in pixels
          // if (img.width > maxWidth || img.height > maxHeight) {
          //   toast.warning(`Image dimensions must be less than ${maxWidth} x ${maxHeight}px`);
          // } else
          if (img.src) {
            setFilename1(files[0].name);
            setImage1(img.src);
            setVideoPreview1("");
            setBannerImage(img.src);
            return;
          }
        };
      };
      reader1.readAsDataURL(file);
    } else {
      toast.warning("File size exceeds 5MB limit.");
    }
  };
  const handlePrimaryColor = () => {
    setPrimaryColor(color.hex);
    setColorCheck(!colorCheck);
  };
  const handlePrimaryColor1 = () => {
    setButtonColor(color1.hex);
    setColorCheck2(!colorCheck2);
  };
  const handlePrimaryColor2 = () => {
    setButtonColor1(color2.hex);
    setColorCheck3(!colorCheck3);
  };

  const handleSecondaryColor = () => {
    setSecondaryColor(colorSecondary.hex);
    setColorCheck1(!colorCheck1);
  };

  const handleHomeBannerColor = () => {
    setHomeBannerColor(!homeBannerColor);
    if (homeBannerColor === true) {
      setImage("");
      setFilename("");
      setHomeBannerImage("");
    }
  };
  const HandleHeaderImageColour = () => {
    setEmailHeaderColor(!emailHeaderColor);
    setFilenameEmail("");
    setImage2("");
  };
  const HandleHeaderImageColour1 = () => {
    setEmailHeaderColor1(!emailHeaderColor1);
    setFilenameEmail1("");
    setImage3("");
  };
  const handleBannerColor = () => {
    setBannerColor(!bannerColor);
    if (bannerColor === true) {
      setImage1("");
      setFilename1("");
      setBannerImage("");
    }
  };

  const handleMessage = (e) => {
    setMessageTopicEmail(e.target.value);
  };
  const handleWelcome = (e) => {
    setWelcomeEmail(e.target.value);
  };

  const handleMessage1 = (e) => {
    setMessageTopicEmail1(e.target.value);
  };
  const handleWelcome1 = (e) => {
    setWelcomeEmail1(e.target.value);
  };
  const handleWelcome2 = (e) => {
    setWelcomeEmail2(e.target.value);
  };

  const borderStyle = {
    border: "1px solid red",
  };

  const randomValue = Math.random();

  const config = useMemo(
    () => ({
      readonly: false,
    }),
    []
  );
  useEffect(() => {
    const path = window.location.pathname.split("/");

    if (path[1] !== "admin") {
      const user = JSON.parse(window.localStorage.getItem("business-client"));
      let token;
      if (user) {
        token = user.token;
      } else {
        token = null;
      }
      const id = user?.userData?._id;
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/getbusinesscontact/${id}`,
          {
            headers: {
              Authorization: `Bearer ${getNewPartnerToken()}`,
            },
          }
        )
        .then((response) => {
          if (
            response.data.businessContactId.status === false ||
            response.data.businessContactId.soft_delete === true
          ) {
            localStorage.removeItem("business-client");
            navigate("/business-login");
            toast.warn("Your Account Is Deactivated or Deleted");
          }
        })
        .catch((error) => {
          console.log(`Error: ${error}`);
        });
    }
  }, []);

  // modal variables
  const welcomeEmailModal = (
    <div dangerouslySetInnerHTML={{ __html: welcomeEmail }} />
  );
  const welcomeEmailHeadModal = (
    <div dangerouslySetInnerHTML={{ __html: welcomeEmailHead }} />
  );
  const welcomeEmail1Modal = (
    <div dangerouslySetInnerHTML={{ __html: welcomeEmail1 }} />
  );
  const welcomeEmail2Modal = (
    <div dangerouslySetInnerHTML={{ __html: welcomeEmail2 }} />
  );
  const messageEmailHeadModal = (
    <div dangerouslySetInnerHTML={{ __html: messageEmailHead }} />
  );
  const messageTopicEmailModal = (
    <div dangerouslySetInnerHTML={{ __html: messageTopicEmail }} />
  );
  const messageTopicEmail1Modal = (
    <div dangerouslySetInnerHTML={{ __html: messageTopicEmail1 }} />
  );

  const refundEmailSubjectModal = (
    <div dangerouslySetInnerHTML={{ __html: refundEmailSubject }} />
  );
  const refundEmailModal = (
    <div dangerouslySetInnerHTML={{ __html: refundEmail }} />
  );
  const refundEmail1Modal = (
    <div dangerouslySetInnerHTML={{ __html: refundEmail1 }} />
  );

  const balanceEmailHeadModal = (
    <div dangerouslySetInnerHTML={{ __html: balanceEmailHead }} />
  );
  const balanceEmail1Modal = (
    <div dangerouslySetInnerHTML={{ __html: balanceEmail1 }} />
  );
  const balanceEmail3Modal = (
    <div dangerouslySetInnerHTML={{ __html: balanceEmail3 }} />
  );
  const balanceEmail2Modal = (
    <div dangerouslySetInnerHTML={{ __html: balanceEmail2 }} />
  );

  const balanceReduceEmailHeadModal = (
    <div dangerouslySetInnerHTML={{ __html: balanceReduceEmailHead }} />
  );
  const balanceReduceEmail1Modal = (
    <div dangerouslySetInnerHTML={{ __html: balanceReduceEmail1 }} />
  );
  const balanceReduceEmail2Modal = (
    <div dangerouslySetInnerHTML={{ __html: balanceReduceEmail2 }} />
  );
  const balanceReduceEmail3Modal = (
    <div dangerouslySetInnerHTML={{ __html: balanceReduceEmail3 }} />
  );

  // modal variables
  const handlePdf = (files) => {
    if (files[0]?.size <= 5 * 1024 * 1024) {
      const file = files[0];
      const reader = new FileReader();
      pdfjs.GlobalWorkerOptions.workerSrc = null;
      if (file?.type === "application/pdf") {
        setPdfFile(file);
        setPageNumber(1);

        const fileReader = new FileReader();

        fileReader.onload = async (event) => {
          const arrayBuffer = event.target.result;
          const data = new Uint8Array(arrayBuffer);

          try {
            pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/build/pdf.worker.min.js`;
            const pdf = await pdfjs.getDocument({ data }).promise;
            const totalPages = pdf.numPages;
            if (totalPages == 1) {
              const images = [];
              for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
                const pdfPage = await pdf.getPage(pageNumber);
                const viewport = pdfPage.getViewport({ scale: 10 });
                const canvas = document.createElement("canvas");
                const context = canvas.getContext("2d");
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                const renderContext = {
                  canvasContext: context,
                  viewport: viewport,
                };
                await pdfPage.render(renderContext).promise;
                const base64Image = canvas.toDataURL("image/png").split(",")[1];
                images.push(base64Image);
                setPdfFile(base64Image);
              }

              images.forEach((base64Image, index) => {});
              setPdfName(file?.name);
              setPdfPreview(event.target.result);
              setBalanceReducePdfText(true);
              setPdfimagePreview(images[0]);
              setImageName("");
            } else {
              toast.warning("Upload One page pdf only");
              return false;
            }
          } catch (error) {
            console.error("Error converting PDF to base64:", error);
          }
        };
        fileReader.readAsArrayBuffer(file);
      } else if (file.type.startsWith("image/")) {
        setPdfImage(file);
        reader.onload = (e) => {
          const imagePreviewSrc = e.target.result;
          setPdfImageName(file?.name);
          setImageName(file?.name);
          setPdfimagePreview(imagePreviewSrc);
          setPdfPreview("");
          setPdfFile("");
          setPdfName("");
        };
        reader.readAsDataURL(file);
      } else {
        toast.warning(
          "Unsupported file type. Please upload a PDF or image file."
        );
        setPdfPreview("");
        setPdfFile("");
        setPdfName("");
        setPdfImageName("");
        setPdfimagePreview("");
        setImageName();
      }
    } else if (files?.length == 0) {
    } else {
      toast.warning("File size exceeds 5MB limit.");
    }
  };

  const [show, setShow] = useState(false);
  const [showWelcomeMail, setshowWelcomeMail] = useState(false);
  const [showOrderMail, setshowOrderMail] = useState(false);
  const [showRefundMail, setshowRefundMail] = useState(false);
  const [showAddfundMail, setshowAddfundMail] = useState(false);
  const [showReducefundMail, setshowReducefundMail] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // welcome mail mail
  const handleCloseShowWelcomeMail = () => setshowWelcomeMail(false);
  const handleShowWelcomeMail = () => {
    setshowWelcomeMail(true);
  };
  // welcome mail mail

  // order mail
  const handleCloseShowOrderMail = () => setshowOrderMail(false);
  const handleShowOrderMail = () => {
    setshowOrderMail(true);
  };
  // order mail

  // refund mail
  const handleCloseShowrefundMail = () => setshowRefundMail(false);
  const handleShowrefundMail = () => {
    setshowRefundMail(true);
  };
  // refund mail

  // add fund mail
  const handleCloseAddFundMail = () => setshowAddfundMail(false);
  const handleShowAddFundMail = () => {
    setshowAddfundMail(true);
  };
  // add fund mail

  // remove fund mail
  const handleCloseRemoveFundMail = () => setshowReducefundMail(false);
  const handleShowRemoveFundMail = () => {
    setshowReducefundMail(true);
  };
  // remove fund mail

  const handlePreview = () => {};

  // const getPdfNameFromUrl = (url) => {
  //   const urlParts = url?.split('/');
  //   const pdfFileName = urlParts[urlParts.length - 1].split('?')[0];
  //   return pdfFileName;
  // };
  // const pdfNameApi = getPdfNameFromUrl(pdfPreview);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return (
    <div className={styles.mainCont}>
      {isLoading ? (
        <div className={styles.loaderContainer}>
          <Bars
            height="80"
            width="100%"
            margin="auto"
            color="#FF7020"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <div className="p-3 p-lg-4 p-xl-4">
          <div className="d-flex align-items-center mb-4">
            {props?.admin ? (
              <Link
                to="/admin/businesspartners"
                className="p-0 btn btnOrangeTransparent me-3 fw-semibold"
                variant="white"
              >
                <span className="me-2 fw-semibold">
                  <img
                    src="/assets/images/back-org.png"
                    className="img-fluid"
                    alt=""
                  />
                </span>
                <span>Back</span>
              </Link>
            ) : (
              ""
            )}
            <h1 className="font20 text-dark">Branding/Emails</h1>
          </div>

          {ncentivaSettings == true ? <h1> </h1> : <h1> </h1>}

          <Row>
            <Col className="mt-1 d-lg-flex">
              <Form.Group
                className="mb-xl-3 mb-sm-1 d-flex"
                controlId="formBasicCheckbox"
              >
                {ncentivaSettings ? (
                  <Form.Check
                    type="checkbox"
                    checked="checked"
                    onClick={handlencentivaSettings}
                    className="me-1"
                  />
                ) : (
                  <Form.Check
                    type="checkbox"
                    checked={ncentivaSettings}
                    onChange={handlencentivaSettings}
                    className="me-1"
                  />
                )}
                <Form.Label className="font20 text-dark ">
                  Go As Default{" "}
                  <span className={styles.ncentivaColor}>Ncentiva</span>{" "}
                  Settings{" "}
                </Form.Label>
              </Form.Group>

              {ncentivaSettings == false ? (
                // <div style={{display:"flex",width:"100%"}}>
                <Col xl={9} xxl={8} className="mt-xs-2 mt-lg-0 ms-lg-2">
                  {url ? (
                    <Col xl={9} xxl={8} className="mt-1">
                      <div className="d-md-flex">
                        <h1 className="font16 text-dark me-1">CMS URL:</h1>
                        <a
                          href={url}
                          target="_blank"
                          className="text-truncate d-block"
                        >
                          {url}
                        </a>
                        <a
                          target={"_blank"}
                          href={url}
                          style={{ marginTop: "-3px", marginLeft: "4px" }}
                        >
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="currentColor"
                            className="bi bi-box-arrow-up-right"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
                            />
                            <path
                              fillRule="evenodd"
                              d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
                            />
                          </svg>
                        </a>
                        {/* <a className="font20 fw-medium text-dark">{url}</a> */}
                        <Button
                          variant="light"
                          className={styles.cpybutton}
                          onClick={() => {
                            navigator.clipboard.writeText(url);
                            toast.success("Copied to clipboard.");
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            className="bi bi-files"
                            viewBox="0 0 16 16"
                          >
                            <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z" />
                          </svg>
                        </Button>
                      </div>
                    </Col>
                  ) : (
                    ""
                  )}

                  {url ? (
                    <Col xl={9} xxl={10} className="mt-3">
                      <div className="d-md-flex align-items-center">
                        <h1 className="font16 text-dark me-1">Customer URL:</h1>
                        <a
                          href={`${burl}/giftcardss`}
                          target="_blank"
                          style={{ whiteSpace: "nowrap" }}
                          className="text-truncate d-block"
                        >
                          {burl}
                        </a>
                        <a
                          target={"_blank"}
                          href={`${burl}/giftcardss`}
                          style={{ marginTop: "-3px", marginLeft: "4px" }}
                        >
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="currentColor"
                            className="bi bi-box-arrow-up-right"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
                            />
                            <path
                              fillRule="evenodd"
                              d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
                            />
                          </svg>
                        </a>
                        {/* <a className="font20 fw-medium text-dark">{url}</a> */}
                        <Button
                          className="me-2 ms-1"
                          variant="light"
                          onClick={() => {
                            navigator.clipboard.writeText(`${burl}/giftcardss`);
                            toast.success("Copied to clipboard.");
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            className="bi bi-files"
                            viewBox="0 0 16 16"
                          >
                            <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z" />
                          </svg>
                        </Button>
                      </div>
                    </Col>
                  ) : (
                    ""
                  )}
                </Col>
              ) : (
                // </div>

                <Col xl={9} xxl={8} className="mt-1 p-2">
                  {ncentivaSettings === true ? (
                    <Col xl={9} xxl={8} className="mt-1">
                      <div className="d-md-flex">
                        {defaultCmsUrl ? (
                          <>
                            <h1 className="font16 text-dark me-1">CMS URL:</h1>
                            <a href={defaultCmsUrl} target="_blank">
                              {defaultCmsUrl}
                            </a>
                            <a htarget={"_blank"} href={defaultCmsUrl}></a>

                            <a
                              target={"_blank"}
                              href={defaultCmsUrl}
                              style={{ marginTop: "-3px", marginLeft: "4px" }}
                            >
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="currentColor"
                                className="bi bi-box-arrow-up-right"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
                                />
                                <path
                                  fillRule="evenodd"
                                  d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
                                />
                              </svg>
                            </a>
                          </>
                        ) : (
                          <>
                            <h1 className="font16 text-dark me-1">CMS URL:</h1>
                            <a href={url} target="_blank">
                              {url}
                            </a>
                            <a htarget={"_blank"} href={url}></a>

                            <a
                              target={"_blank"}
                              href={url}
                              style={{ marginTop: "-3px", marginLeft: "4px" }}
                            >
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="currentColor"
                                className="bi bi-box-arrow-up-right"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
                                />
                                <path
                                  fillRule="evenodd"
                                  d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
                                />
                              </svg>
                            </a>
                          </>
                        )}

                        {/* <a target={'_blank'} href='https://dev.ncentiva.com/' style={{marginTop:'-3px',marginLeft:'4px'}}> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"/>
  <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"/>
</svg></a> */}
                        {/* <a className="font20 fw-medium text-dark">{url}</a> */}
                        <Button
                          className="me-2 ms-1"
                          variant="light"
                          style={{ marginTop: "-8px" }}
                          onClick={() => {
                            navigator.clipboard.writeText(defaultCmsUrl);
                            toast.success("Copied to clipboard.");
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            className="bi bi-files"
                            viewBox="0 0 16 16"
                          >
                            <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z" />
                          </svg>
                        </Button>
                      </div>
                    </Col>
                  ) : (
                    ""
                  )}

                  {ncentivaSettings === true ? (
                    <Col xl={9} xxl={8} className="mt-3">
                      <div className="d-md-flex">
                        <h1 className="font16 text-dark me-1">Customer URL:</h1>
                        <a
                          href={`${burl}/giftcardss`}
                          style={{ whiteSpace: "nowrap" }}
                          target="_blank"
                        >
                          {burl}
                        </a>
                        <a
                          target={"_blank"}
                          href={`${burl}/giftcardss`}
                          style={{ marginTop: "-3px", marginLeft: "4px" }}
                        >
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="currentColor"
                            className="bi bi-box-arrow-up-right"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
                            />
                            <path
                              fillRule="evenodd"
                              d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
                            />
                          </svg>
                        </a>
                        {/* <a className="font20 fw-medium text-dark">{url}</a> */}
                        <Button
                          className="me-2 ms-1"
                          variant="light"
                          style={{ marginTop: "-8px" }}
                          onClick={() => {
                            navigator.clipboard.writeText(`${burl}/giftcardss`);
                            toast.success("Copied to clipboard.");
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            className="bi bi-files"
                            viewBox="0 0 16 16"
                          >
                            <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z" />
                          </svg>
                        </Button>
                      </div>
                    </Col>
                  ) : (
                    ""
                  )}
                </Col>
              )}
            </Col>
          </Row>

          <Row>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12} className="mt-1 p-2">
              {ncentivaSettings === true ? (
                <Col xl={12} xxl={12} className="mt-3">
                  <div className="mt-5 mb-4">
                    <Container
                      fluid
                      className="mt-3 px-4 py-2 rounded shadow"
                      style={{ backgroundColor: "#FF7020" }}
                      onClick={handlepdfed}
                    >
                      <div className={styles.homepagehead}>
                        <h1 className="font20 text-light ">Advertisement</h1>
                        {balanceReduceUpadte ? (
                          <svg
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="40"
                              height="40"
                              rx="8"
                              transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 40 40)"
                              fill="white"
                            />
                            <path
                              d="M20 27L20 13"
                              stroke="#4C00EF"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M27 20L20 13L13 20"
                              stroke="#4C00EF"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        ) : (
                          <svg
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="40"
                              height="40"
                              rx="8"
                              transform="matrix(1.19249e-08 1 1 -1.19249e-08 0 0)"
                              fill="white"
                            />
                            <path
                              d="M20 13L20 27"
                              stroke="#4C00EF"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M13 20L20 27L27 20"
                              stroke="#4C00EF"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        )}
                      </div>
                    </Container>
                    {pdfHandle ? (
                      <>
                        <div className="d-flex mt-3">
                          <div className={styles.dot}></div>
                          <h1 className="font20 mb-3 text-dark ">
                            Upload Advertisement
                          </h1>
                        </div>

                        <Container
                          fluid
                          className="mt-2 mb-3"
                          style={{
                            backgroundColor: "white",
                            boxShadow: "0px 8px 16px 0px grey",
                            borderRadius: "5px",
                          }}
                        >
                          <Row>
                            <Form className="bg-white p-4 rounded containerShadow">
                              <Row>
                                <>
                                  <h1 className="font16 text-dark fw-medium mb-2">
                                    Upload Advertisement
                                  </h1>
                                  <div className={styles.container}>
                                    {/* className={styles.headInput} */}
                                    <Container
                                      fluid
                                      className=" rounded shadow position-relative"
                                    >
                                      {/* style={{ backgroundImage: `url(${pdfimagePreview})`, backgroundSize: '100% 100%',backgroundRepeat:'no-repeat',width:'100%', height:'100%'}} */}
                                      <div
                                        className="d-flex align-items-center justify-content-center flex-column"
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                        }}
                                      >
                                        <label
                                          htmlFor="file"
                                          className={styles.formContainer}
                                        >
                                          <Button
                                            className="  fileReader_style font14 me-2 fw-semibold fs-6 d-flex align-items-center justify-content-center"
                                            style={{
                                              background: "white",
                                              border: "none",
                                              width: "100%",
                                              height: "100%",
                                              padding: "0",
                                            }}
                                          >
                                            <ReactFileReader
                                              handleFiles={handlePdf}
                                              fileTypes={[
                                                ".pdf",
                                                ".png",
                                                ".jpg",
                                                ".jpeg",
                                                ".gif",
                                              ]}
                                              style={{ position: "absolute" }}
                                            ></ReactFileReader>
                                            <div className="d-flex align-items-center justify-content-center flex-column">
                                              <img
                                                src="/assets/images/upload.svg"
                                                alt="i"
                                              />
                                              <span className="ms-2">
                                                Upload Advertisement
                                              </span>
                                            </div>
                                          </Button>
                                        </label>
                                        {pdfName && (
                                          <div className={styles.fileSelected}>
                                            <p className="mb-0 text-danger font16 bottom-0 w-100 text-center">
                                              {pdfName}{" "}
                                              <span className="arrow">
                                                <img src={okIMG} alt="" />
                                              </span>
                                            </p>
                                          </div>
                                        )}
                                        {pdfName?.length == 0 &&
                                          pdfPreviewApi &&
                                          pdfFile == "" && (
                                            <div
                                              className=""
                                              style={{
                                                fontWeight: "500",
                                                fontSize: "18px",
                                              }}
                                            >
                                              {pdfPreview.length === 0 ? (
                                                ""
                                              ) : (
                                                <p className=" mb-0 text-danger font16 bottom-0 w-100 text-center">
                                                  Promotional.pdf
                                                </p>
                                              )}
                                            </div>
                                          )}
                                        {imageName && (
                                          <div className={styles.fileSelected}>
                                            <p className="mb-0 text-danger font16 bottom-0 w-100 text-center">
                                              {imageName}{" "}
                                              <span className="arrow">
                                                <img src={okIMG} alt="" />
                                              </span>
                                            </p>
                                          </div>
                                        )}

                                        {imageName?.length == 0 ? (
                                          pdfName?.length > 0 ||
                                          pdfimagePreview?.length === 0 ? (
                                            ""
                                          ) : (
                                            <p className="mb-0 text-danger font16 bottom-0 w-100 text-center">
                                              Promotional.png
                                            </p>
                                          )
                                        ) : (
                                          ""
                                        )}

                                        {/* <ModalColor homeBannerColor={emailHeaderColor} setHomeBannerColor={setEmailHeaderColor} setImage={setImage} setFilename={setFilenameEmail} primaryColor={primaryColor} secondaryColor={secondaryColor} setHomeBannerColorCombination={setBalanceReduceEmailColorCombination} setHomeBannerColorCombination1={setBalanceReduceEmailColorCombination1} homeBannerColorCombination={balanceReduceEmailColorCombination} homeBannerColorCombination1={balanceReduceEmailColorCombination1} /> */}
                                      </div>
                                    </Container>
                                  </div>
                                  <div className="d-flex justify-content-end align-items-center py-1">
                                    <Button
                                      type="button"
                                      className="btn btn-secondary"
                                      style={{ marginRight: "10px" }}
                                      onClick={resetInfo}
                                    >
                                      Clear
                                    </Button>
                                    <div className="py-1">
                                      {pdfPreview.length > 0 ||
                                      pdfimagePreview.length > 0 ? (
                                        <Button onClick={handleShow}>
                                          Preview
                                        </Button>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>

                                  {isLoading ? (
                                    <Button
                                      variant="primary"
                                      className="fullRounded px-4 px-lg-3 px-xl-4 py-2 fw-bold font12"
                                      style={{ width: "133.75px" }}
                                    >
                                      <span>Proceeding...</span>
                                    </Button>
                                  ) : (
                                    <Button
                                      variant="primary"
                                      onClick={() => {
                                        handlePost();
                                      }}
                                      className="fullRounded px-4 px-lg-3 px-xl-4 py-2 fw-bold font12 my-2"
                                      style={{ width: "133.75px" }}
                                    >
                                      <span>Save</span>
                                    </Button>
                                  )}
                                </>
                              </Row>
                            </Form>
                          </Row>

                          <div>
                            <div>
                              <Modal show={show} centered>
                                {/* <Modal.Header closeButton>

                              </Modal.Header> */}
                                <Modal.Body
                                  style={{
                                    backgroundColor: primaryColor,
                                    border: "2px solid",
                                    margin: "0",
                                    padding: "3px",
                                    borderRadius: "8px",
                                  }}
                                >
                                  {pdfFile?.length > 0 ? (
                                    <div
                                      style={{ width: "100%" }}
                                      className="scroll-modal d-flex align-items-center justify-content-center"
                                    >
                                      <img
                                        className="pdf-viewer-container img-thumbnail position_relative"
                                        src={`data:image/png;base64,${pdfFile}`}
                                        alt="pdfimage"
                                        //  style={{ width: '100%', height: '900px', zoom: '100%', overflowX: 'hidden' }}
                                      />
                                      <div className="button_closed">
                                        <p
                                          style={{
                                            backgroundColor: primaryColor,
                                            border: `2px dotted ${primaryColor}`,
                                          }}
                                          className="  button_style"
                                          onClick={handleClose}
                                        >
                                          X
                                        </p>
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      style={{ width: "100%" }}
                                      className="scroll-modal d-flex align-items-center justify-content-center"
                                    >
                                      <img
                                        className="pdf-viewer-container img-thumbnail position_relative"
                                        src={pdfimagePreview}
                                        alt="pdfimage"
                                        // style={{ width: '100%', height: '900px', zoom: '100%', overflowX: 'hidden' }}
                                      />

                                      <div className="button_closed">
                                        <p
                                          style={{
                                            backgroundColor: primaryColor,
                                            border: `2px dotted ${primaryColor}`,
                                          }}
                                          className="  button_style"
                                          onClick={handleClose}
                                        >
                                          X
                                        </p>
                                      </div>
                                    </div>
                                  )}

                                  <style>
                                    {`
              .scroll-modal::-webkit-scrollbar {
                width: 6px;
                height: 4px;
              }
              .scroll-modal::-webkit-scrollbar-thumb {
                background-color: ${secondaryColor};
                border-radius: 6px;
                
              }
              .scroll-modal::-webkit-scrollbar-track {
                background-color: transparent;
              }
            `}
                                  </style>

                                  <div>
                                    {error && <p>Error: {error.message}</p>}
                                  </div>
                                </Modal.Body>
                              </Modal>
                            </div>
                          </div>
                        </Container>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
              ) : (
                ""
              )}
            </Col>
          </Row>

          {ncentivaSettings ? (
            <>
              {isLoading ? (
                <Button
                  variant="primary"
                  className="fullRounded px-4 px-lg-3 px-xl-4 py-2 fw-bold font12"
                  style={{ width: "133.75px" }}
                >
                  <span>Proceeding...</span>
                </Button>
              ) : (
                <Button
                  variant="primary"
                  onClick={() => {
                    handlePost();
                  }}
                  className="fullRounded px-4 px-lg-3 px-xl-4 py-2 fw-bold font12 my-2"
                  style={{ width: "133.75px" }}
                >
                  <span>Save</span>
                </Button>
              )}
            </>
          ) : (
            <>
              <div className="d-flex">
                {/* <div className={styles.dot}></div> */}
                <h1 className="font20 mb-4 text-dark ">White Label</h1>
              </div>

              <Container
                fluid
                className="mt-3 px-4 py-2 rounded shadow"
                style={{ backgroundColor: "#FF7020" }}
                onClick={handlePart1}
              >
                <div className={styles.homepagehead}>
                  <h1 className="font20 text-light ">Branding</h1>
                  {part1 ? (
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="40"
                        height="40"
                        rx="8"
                        transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 40 40)"
                        fill="white"
                      />
                      <path
                        d="M20 27L20 13"
                        stroke="#4C00EF"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M27 20L20 13L13 20"
                        stroke="#4C00EF"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="40"
                        height="40"
                        rx="8"
                        transform="matrix(1.19249e-08 1 1 -1.19249e-08 0 0)"
                        fill="white"
                      />
                      <path
                        d="M20 13L20 27"
                        stroke="#4C00EF"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13 20L20 27L27 20"
                        stroke="#4C00EF"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </div>
              </Container>

              {part1 ? (
                <>
                  <div className="mb-5">
                    {/* <Row>
      <Col md={6} ps={0} > */}
                    <div className="d-flex py-2">
                      <div className={styles.dot}></div>
                      <h1 className="font20 mb-4 text-dark ">
                        Set Business details
                      </h1>
                    </div>
                    <Container fluid className="bg-white p-4 rounded shadow">
                      <Row>
                        <Col xl={6}>
                          <h1 className="font16 mb-2 text-dark ">
                            Business Logo<span className="text-danger">*</span>{" "}
                          </h1>
                          <div
                            {...getRootProps()}
                            style={{ cursor: "pointer" }}
                          >
                            <input {...getInputProps()} />
                            {
                              isDragActive ? (
                                <p>Drop the files here ...</p>
                              ) : //   <p>Drag 'n' drop some files here, or click to select files</p>
                              logotrue == true ? (
                                <div className={styles.uploader}>
                                  {logo ? (
                                    <div className={styles.showlogo}>
                                      <img
                                        className={styles.logoimg}
                                        src={logo}
                                        alt=""
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <svg
                                    width="71"
                                    height="75"
                                    className="mt-3 mt-md-0"
                                    viewBox="0 0 71 75"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <circle
                                      cx="32.5"
                                      cy="32.5"
                                      r="32.5"
                                      fill="#568EFE"
                                    />
                                    <path
                                      d="M42.3333 22.6667V41.3333H23.6667V22.6667H42.3333ZM42.3333 20H23.6667C22.2 20 21 21.2 21 22.6667V41.3333C21 42.8 22.2 44 23.6667 44H42.3333C43.8 44 45 42.8 45 41.3333V22.6667C45 21.2 43.8 20 42.3333 20ZM35.8533 31.8133L31.8533 36.9733L29 33.52L25 38.6667H41L35.8533 31.8133Z"
                                      fill="#F3F7FF"
                                    />
                                    <circle
                                      cx="56"
                                      cy="60"
                                      r="15"
                                      fill="#FDFEFE"
                                    />
                                    <path
                                      d="M55.9993 63.6671C55.7396 63.6671 55.5221 63.5791 55.3467 63.4031C55.1707 63.2277 55.0827 63.0101 55.0827 62.7504V56.1962L53.3639 57.915C53.1806 58.0983 52.9667 58.19 52.7223 58.19C52.4778 58.19 52.2563 58.0907 52.0577 57.8921C51.8743 57.7087 51.7867 57.4909 51.7946 57.2385C51.8019 56.9867 51.8896 56.7768 52.0577 56.6087L55.3577 53.3087C55.4493 53.2171 55.5487 53.152 55.6556 53.1135C55.7625 53.0756 55.8771 53.0566 55.9993 53.0566C56.1216 53.0566 56.2362 53.0756 56.3431 53.1135C56.45 53.152 56.5493 53.2171 56.641 53.3087L59.941 56.6087C60.1243 56.7921 60.212 57.0096 60.2041 57.2614C60.1968 57.5138 60.1091 57.724 59.941 57.8921C59.7577 58.0754 59.5401 58.1707 59.2883 58.1781C59.036 58.186 58.8181 58.0983 58.6348 57.915L56.916 56.1962V62.7504C56.916 63.0101 56.8283 63.2277 56.6529 63.4031C56.4769 63.5791 56.2591 63.6671 55.9993 63.6671ZM50.4993 67.3337C49.9952 67.3337 49.5637 67.1544 49.205 66.7956C48.8457 66.4363 48.666 66.0046 48.666 65.5004V63.6671C48.666 63.4073 48.7537 63.1895 48.9291 63.0135C49.1051 62.8381 49.323 62.7504 49.5827 62.7504C49.8424 62.7504 50.0603 62.8381 50.2363 63.0135C50.4117 63.1895 50.4993 63.4073 50.4993 63.6671V65.5004H61.4993V63.6671C61.4993 63.4073 61.5873 63.1895 61.7633 63.0135C61.9387 62.8381 62.1563 62.7504 62.416 62.7504C62.6757 62.7504 62.8933 62.8381 63.0687 63.0135C63.2447 63.1895 63.3327 63.4073 63.3327 63.6671V65.5004C63.3327 66.0046 63.1533 66.4363 62.7946 66.7956C62.4353 67.1544 62.0035 67.3337 61.4993 67.3337H50.4993Z"
                                      fill="#568EFE"
                                    />
                                  </svg>

                                  <div className={styles.divBorder}>
                                    <h3 className="font20 mt-2 ms-1 text-dark">
                                      Drop your logo here or{" "}
                                      <span className={styles.spanColor}>
                                        Select a file
                                      </span>
                                    </h3>
                                    <p>Supports: JPG, PNG, SVG, JPEG </p>
                                  </div>
                                </div>
                              ) : (
                                <div className={styles.empty}>
                                  {logo ? (
                                    <div className={styles.showlogo}>
                                      <img
                                        className={styles.logoimg}
                                        src={logo}
                                        alt=""
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <svg
                                    width="71"
                                    height="75"
                                    viewBox="0 0 71 75"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <circle
                                      cx="32.5"
                                      cy="32.5"
                                      r="32.5"
                                      fill="#568EFE"
                                    />
                                    <path
                                      d="M42.3333 22.6667V41.3333H23.6667V22.6667H42.3333ZM42.3333 20H23.6667C22.2 20 21 21.2 21 22.6667V41.3333C21 42.8 22.2 44 23.6667 44H42.3333C43.8 44 45 42.8 45 41.3333V22.6667C45 21.2 43.8 20 42.3333 20ZM35.8533 31.8133L31.8533 36.9733L29 33.52L25 38.6667H41L35.8533 31.8133Z"
                                      fill="#F3F7FF"
                                    />
                                    <circle
                                      cx="56"
                                      cy="60"
                                      r="15"
                                      fill="#FDFEFE"
                                    />
                                    <path
                                      d="M55.9993 63.6671C55.7396 63.6671 55.5221 63.5791 55.3467 63.4031C55.1707 63.2277 55.0827 63.0101 55.0827 62.7504V56.1962L53.3639 57.915C53.1806 58.0983 52.9667 58.19 52.7223 58.19C52.4778 58.19 52.2563 58.0907 52.0577 57.8921C51.8743 57.7087 51.7867 57.4909 51.7946 57.2385C51.8019 56.9867 51.8896 56.7768 52.0577 56.6087L55.3577 53.3087C55.4493 53.2171 55.5487 53.152 55.6556 53.1135C55.7625 53.0756 55.8771 53.0566 55.9993 53.0566C56.1216 53.0566 56.2362 53.0756 56.3431 53.1135C56.45 53.152 56.5493 53.2171 56.641 53.3087L59.941 56.6087C60.1243 56.7921 60.212 57.0096 60.2041 57.2614C60.1968 57.5138 60.1091 57.724 59.941 57.8921C59.7577 58.0754 59.5401 58.1707 59.2883 58.1781C59.036 58.186 58.8181 58.0983 58.6348 57.915L56.916 56.1962V62.7504C56.916 63.0101 56.8283 63.2277 56.6529 63.4031C56.4769 63.5791 56.2591 63.6671 55.9993 63.6671ZM50.4993 67.3337C49.9952 67.3337 49.5637 67.1544 49.205 66.7956C48.8457 66.4363 48.666 66.0046 48.666 65.5004V63.6671C48.666 63.4073 48.7537 63.1895 48.9291 63.0135C49.1051 62.8381 49.323 62.7504 49.5827 62.7504C49.8424 62.7504 50.0603 62.8381 50.2363 63.0135C50.4117 63.1895 50.4993 63.4073 50.4993 63.6671V65.5004H61.4993V63.6671C61.4993 63.4073 61.5873 63.1895 61.7633 63.0135C61.9387 62.8381 62.1563 62.7504 62.416 62.7504C62.6757 62.7504 62.8933 62.8381 63.0687 63.0135C63.2447 63.1895 63.3327 63.4073 63.3327 63.6671V65.5004C63.3327 66.0046 63.1533 66.4363 62.7946 66.7956C62.4353 67.1544 62.0035 67.3337 61.4993 67.3337H50.4993Z"
                                      fill="#568EFE"
                                    />
                                  </svg>

                                  <div className={styles.divBorder}>
                                    <h3 className="font20 mt-2 ms-1 text-dark">
                                      Drop your logo here or{" "}
                                      <span className={styles.spanColor}>
                                        Select a file
                                      </span>
                                    </h3>
                                    <p>Supports: JPG, PNG, SVG, JPEG </p>
                                  </div>
                                </div>
                              )
                              //
                            }
                          </div>
                          {logoname && logo && (
                            <p className="mb-1 text-danger font16 bottom-0 w-100 text-center">
                              {logoname}{" "}
                              <span
                                className="text-dark "
                                onClick={() => {
                                  setLogoname("");
                                  setLogo("");
                                  setLogoTrue(false);
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                x
                              </span>
                            </p>
                          )}
                        </Col>
                        <Col xl={6}>
                          <Form.Group className="mb-1">
                            <Form.Label>
                              <h1 className="font16 mt-2 text-dark">
                                Business Name{" "}
                                <span className="text-danger">*</span>
                              </h1>

                              <span className="fw-normal text-secondary"></span>
                            </Form.Label>
                            <Col>
                              <Form.Control
                                style={error ? borderStyle : null}
                                value={brand_name}
                                onChange={(e) => {
                                  // let c=0;

                                  let a = e.target.value.trimStart();
                                  const regex = /^[^.@,\s][a-zA-Z0-9@.,\s]*$/;
                                  if (
                                    (/^[a-zA-Z0-9\s]+\.?$/.test(a) &&
                                      /^[^_]*$/.test(a)) ||
                                    a === ""
                                  ) {
                                    setBrand_name(a);
                                  }
                                  if (a.length < 3 || a.length > 64) {
                                    setError(true);
                                  } else {
                                    setError(false);
                                  }
                                }}
                                className="font14"
                                name="brand_name"
                                type="text"
                              />
                              {error ? (
                                <div className="text-danger font12">
                                  Minimum 3 and Maximum 64 characters are
                                  allowed
                                </div>
                              ) : (
                                ""
                              )}
                            </Col>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Container>

                    <div className="d-flex">
                      <div className={`mt-4 ${styles.dot}`}></div>
                      <div className="d-flex">
                        <h1 className="font20 mb-3 text-dark mt-3">
                          Set Colors
                        </h1>
                      </div>
                    </div>
                    <Container fluid className="bg-white p-4 rounded shadow">
                      <Row className="d-flex">
                        <Col lg={6} className="mt-2">
                          <div className="d-flex">
                            <h1 className="font16 text-dark mb-1">
                              Select Primary Color
                            </h1>
                            <div
                              className="ms-1 mb-1"
                              style={{
                                marginTop: "-5px",
                                height: "30px",
                                width: "30px",
                                borderRadius: "50%",
                                backgroundColor: primaryColor,
                              }}
                            ></div>
                          </div>
                          <div className="d-flex mt-3">
                            <div
                              onClick={() => setPrimaryColor("#FF7020")}
                              className={styles.dott}
                            ></div>
                            <div
                              onClick={() => setPrimaryColor("#F5C400")}
                              className={styles.dott1}
                            ></div>
                            <div
                              onClick={() => setPrimaryColor("#7e7803")}
                              className={styles.dott2}
                            ></div>
                            <div
                              onClick={() => setPrimaryColor("#3D05B6")}
                              className={styles.dott3}
                            ></div>
                            <div
                              style={{ color: "black" }}
                              onClick={handleColor}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="35"
                                height="35"
                                fill="currentColor"
                                className="bi bi-plus-circle-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                              </svg>
                            </div>
                          </div>
                          {colorCheck ? (
                            <>
                              <ColorPicker
                                width={256}
                                height={200}
                                color={color}
                                onChange={setColor}
                                hideHSV
                                dark
                              />
                              <Button
                                onClick={handlePrimaryColor}
                                variant="primary"
                                className="fullRounded px-4 px-lg-3 px-xl-4 py-2 mt-2 fw-bold font12"
                                style={{ width: "133.75px" }}
                              >
                                <span>Save</span>
                              </Button>
                            </>
                          ) : (
                            ""
                          )}
                          {/* </Col>


              <Col lg={6} className='mt-5 '> */}
                          <div className="d-flex mt-5">
                            <h1 className="font16 text-dark mb-1">
                              Select Secondary Color
                            </h1>
                            <div
                              className="ms-1 mb-1"
                              style={{
                                marginTop: "-5px",
                                height: "30px",
                                width: "30px",
                                borderRadius: "50%",
                                backgroundColor: secondaryColor,
                              }}
                            />
                          </div>
                          <div className="d-flex mt-3">
                            <div
                              onClick={() => setSecondaryColor("#FF7020")}
                              className={styles.dott}
                            ></div>
                            <div
                              onClick={() => setSecondaryColor("#F5C400")}
                              className={styles.dott1}
                            ></div>
                            <div
                              onClick={() => setSecondaryColor("#7e7803")}
                              className={styles.dott2}
                            ></div>
                            <div
                              onClick={() => setSecondaryColor("#3D05B6")}
                              className={styles.dott3}
                            ></div>
                            <div
                              style={{ color: "black" }}
                              onClick={handleColor1}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="35"
                                height="35"
                                fill="currentColor"
                                className="bi bi-plus-circle-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                              </svg>
                            </div>
                          </div>
                          {colorCheck1 ? (
                            <>
                              <ColorPicker
                                width={256}
                                height={200}
                                color={colorSecondary}
                                onChange={setColorSecondary}
                                hideHSV
                                dark
                              />
                              <Button
                                onClick={handleSecondaryColor}
                                variant="primary"
                                className="fullRounded px-4 px-lg-3 px-xl-4 py-2 mt-2 fw-bold font12"
                                style={{ width: "133.75px" }}
                              >
                                <span>Save</span>
                              </Button>
                            </>
                          ) : (
                            ""
                          )}
                        </Col>

                        <Col lg={6} className="mt-5 mt-lg-2">
                          <div className="d-flex">
                            <h1 className="font16 text-dark mb-1">
                              Select Button Color
                            </h1>
                            <Button
                              className="ms-1 mb-1 fullRounded px-md-4 px-lg-3 px-xl-4 py-2 fw-bold font12"
                              style={{
                                marginTop: "-5px",
                                width: "100px",
                                backgroundColor: buttonColor1,
                                border: `1px solid ${buttonColor1}`,
                                color: buttonColor,
                              }}
                            >
                              Button
                            </Button>
                          </div>
                          <div className="d-flex mt-3">
                            <div
                              onClick={() => setButtonColor1("#FF7020")}
                              className={styles.dott}
                            ></div>
                            <div
                              onClick={() => setButtonColor1("#F5C400")}
                              className={styles.dott1}
                            ></div>
                            <div
                              onClick={() => setButtonColor1("#7e7803")}
                              className={styles.dott2}
                            ></div>
                            <div
                              onClick={() => setButtonColor1("#3D05B6")}
                              className={styles.dott3}
                            ></div>
                            <div
                              style={{ color: "black" }}
                              onClick={handleColor3}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="35"
                                height="35"
                                fill="currentColor"
                                className="bi bi-plus-circle-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                              </svg>
                            </div>
                          </div>
                          {colorCheck3 ? (
                            <>
                              <ColorPicker
                                width={256}
                                height={200}
                                color={color2}
                                onChange={setColor2}
                                hideHSV
                                dark
                              />
                              <Button
                                onClick={handlePrimaryColor2}
                                variant="primary"
                                className="fullRounded px-4 px-lg-3 px-xl-4 py-2 mt-2 fw-bold font12"
                                style={{ width: "133.75px" }}
                              >
                                <span>Save</span>
                              </Button>
                            </>
                          ) : (
                            ""
                          )}
                          {/* </Col>


              <Col lg={6} className='mt-5 '> */}
                          <div className="d-flex mt-5">
                            <h1 className="font16 text-dark mb-1">
                              Select Button Text Color
                            </h1>
                            {/* <div className='ms-1 mb-1' style={{marginTop:'-5px',height:'30px',width:'30px',borderRadius:'50%',backgroundColor:buttonColor}}/> */}
                            <Button
                              className="ms-1 mb-1 fullRounded px-md-4 px-lg-3 px-xl-4 py-2 fw-bold font12"
                              style={{
                                marginTop: "-5px",
                                width: "100px",
                                backgroundColor: buttonColor1,
                                border: `1px solid ${buttonColor1}`,
                                color: buttonColor,
                              }}
                            >
                              Button
                            </Button>
                          </div>
                          <div className="d-flex mt-3">
                            <div
                              onClick={() => setButtonColor("#FF7020")}
                              className={styles.dott}
                            ></div>
                            <div
                              onClick={() => setButtonColor("#F5C400")}
                              className={styles.dott1}
                            ></div>
                            <div
                              onClick={() => setButtonColor("#7e7803")}
                              className={styles.dott2}
                            ></div>
                            <div
                              onClick={() => setButtonColor("#3D05B6")}
                              className={styles.dott3}
                            ></div>
                            <div
                              style={{ color: "black" }}
                              onClick={handleColor2}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="35"
                                height="35"
                                fill="currentColor"
                                className="bi bi-plus-circle-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                              </svg>
                            </div>
                          </div>
                          {colorCheck2 ? (
                            <>
                              <ColorPicker
                                width={256}
                                height={200}
                                color={color1}
                                onChange={setColor1}
                                hideHSV
                                dark
                              />
                              <Button
                                onClick={handlePrimaryColor1}
                                variant="primary"
                                className="fullRounded px-4 px-lg-3 px-xl-4 py-2 mt-2 fw-bold font12"
                                style={{ width: "133.75px" }}
                              >
                                <span>Save</span>
                              </Button>
                            </>
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                    </Container>
                  </div>
                  {/* {homeBannerColor ? (
                    <div className="mb-5">
                      <Container
                        fluid
                        className="mt-3 px-4 py-2 rounded shadow"
                        style={{ backgroundColor: "#fff" }}
                        onClick={handleHome}
                      >
                        <div className={styles.homepagehead}>
                          <h1 className="font20 " style={{ color: "black" }}>
                            Home Page
                          </h1>
                          {home ? (
                            <svg
                              width="40"
                              height="40"
                              viewBox="0 0 40 40"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="40"
                                height="40"
                                rx="8"
                                transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 40 40)"
                                fill="white"
                              />
                              <path
                                d="M20 27L20 13"
                                stroke="#4C00EF"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M27 20L20 13L13 20"
                                stroke="#4C00EF"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          ) : (
                            <svg
                              width="40"
                              height="40"
                              viewBox="0 0 40 40"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="40"
                                height="40"
                                rx="8"
                                transform="matrix(1.19249e-08 1 1 -1.19249e-08 0 0)"
                                fill="white"
                              />
                              <path
                                d="M20 13L20 27"
                                stroke="#4C00EF"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M13 20L20 27L27 20"
                                stroke="#4C00EF"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}
                        </div>
                      </Container>
                      {home ? (
                        <>
                          <div className="d-flex mt-3">
                            <div className={styles.dot}></div>
                            <h1 className="font20 mb-3 text-dark ">Banner </h1>
                          </div>

                          <Container
                            fluid
                            className="p-4 rounded shadow position-relative"
                          >
                            <div
                              style={{
                                backgroundImage: `linear-gradient(93.59deg,${homeBannerColorCombination},${homeBannerColorCombination1})`,
                                width: "100%",
                                height: "350px",
                              }}
                              className={styles.headDiv}
                            ></div>

                            <div className={styles.headInput}>
                              <Button
                                variant="primary"
                                style={{ width: "158px" }}
                                className="fullRounded px-4 py-2 font14 me-2 fw-semibold fs-6"
                              >
                                <ReactFileReader
                                  handleFiles={handleFiles}
                                  fileTypes={".png,.jpg,.mp4"}
                                >
                                  {" "}
                                  <div className="d-flex align-items-center justify-content-center">
                                    <FaCloudUploadAlt />{" "}
                                    <span className="ms-2">Upload File</span>
                                  </div>{" "}
                                </ReactFileReader>
                              </Button>

                              <ModalColor
                                homeBannerColor={homeBannerColor}
                                setHomeBannerColor={setHomeBannerColor}
                                setImage={setImage}
                                setFilename={setFilename}
                                setHomeBannerImage={setHomeBannerImage}
                                primaryColor={primaryColor}
                                secondaryColor={secondaryColor}
                                setHomeBannerColorCombination={
                                  setHomeBannerColorCombination
                                }
                                setHomeBannerColorCombination1={
                                  setHomeBannerColorCombination1
                                }
                                homeBannerColorCombination={
                                  homeBannerColorCombination
                                }
                                homeBannerColorCombination1={
                                  homeBannerColorCombination1
                                }
                              />
                            </div>
                          </Container>

                          <div className="d-flex mt-3">
                            <div className={styles.dot}></div>
                            <h1 className="font20 mb-3 text-dark ">Footer </h1>
                          </div>

                          <Container
                            fluid
                            className="bg-white p-4 rounded shadow"
                          >
                            <div className="flex-wrap d-flex align-items-center justify-content-between">
                              <h1 className="font16 text-dark me-2 mb-2">
                                Upload Footer Logos
                              </h1>
                              <div className="d-flex footer_img_cms">
                                <Button className="me-2" variant="light">
                                 
                                  <img
                                    src="/assets/images/cyber_img.png"
                                    alt="i"
                                    style={{
                                      boxShadow:
                                        "inset 0 0 0 2000px rgb(250, 245, 245)",
                                    }}
                                  />
                                 
                                </Button>
                               
                                <div>
                                  <Button className="me-2" variant="light">
                                    <ReactFileReader
                                      handleFiles={handleFooterLogo1}
                                      fileTypes={".png,.jpg"}
                                    >
                                      {footerimage1 ? (
                                        <div className={styles.footerlogo}>
                                          <img src={footerimage1} alt="" />
                                        </div>
                                      ) : (
                                        <FooterICon />
                                      )}
                                    </ReactFileReader>
                                  </Button>
                                  {footerlogoname1 && (
                                    <p className="mb-0 text-danger font12 bottom-0 w-100 text-center">
                                      {footerlogoname1}{" "}
                                      <span
                                        className="text-dark "
                                        onClick={() => {
                                          setFooterLogoname1("");
                                          setFooterImage1("");
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        x
                                      </span>
                                    </p>
                                  )}
                                </div>
                                <div>
                                  <Button className="me-2" variant="light">
                                    <ReactFileReader
                                      handleFiles={handleFooterLogo2}
                                      fileTypes={".png,.jpg"}
                                    >
                                      {footerimage2 ? (
                                        <div className={styles.footerlogo}>
                                          <img src={footerimage2} alt="" />
                                        </div>
                                      ) : (
                                        <FooterICon />
                                      )}
                                    </ReactFileReader>
                                  </Button>
                                  {footerlogoname2 && (
                                    <p className="mb-0 text-danger font12 bottom-0 w-100 text-center">
                                      {footerlogoname2}{" "}
                                      <span
                                        className="text-dark "
                                        onClick={() => {
                                          setFooterLogoname2("");
                                          setFooterImage2("");
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        x
                                      </span>
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Container>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <div className="mb-5">
                      <Container
                        fluid
                        className="mt-3 px-4 py-2 rounded shadow"
                        style={{ backgroundColor: "#fff" }}
                        onClick={handleHome}
                      >
                        <div className={styles.homepagehead}>
                          <h1 className="font20 " style={{ color: "black" }}>
                            Home Page
                          </h1>
                          {home ? (
                            <svg
                              width="40"
                              height="40"
                              viewBox="0 0 40 40"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="40"
                                height="40"
                                rx="8"
                                transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 40 40)"
                                fill="white"
                              />
                              <path
                                d="M20 27L20 13"
                                stroke="#4C00EF"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M27 20L20 13L13 20"
                                stroke="#4C00EF"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          ) : (
                            <svg
                              width="40"
                              height="40"
                              viewBox="0 0 40 40"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="40"
                                height="40"
                                rx="8"
                                transform="matrix(1.19249e-08 1 1 -1.19249e-08 0 0)"
                                fill="white"
                              />
                              <path
                                d="M20 13L20 27"
                                stroke="#4C00EF"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M13 20L20 27L27 20"
                                stroke="#4C00EF"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}
                        </div>
                      </Container>
                      {home ? (
                        <>
                          <div className="d-flex mt-3">
                            <div className={styles.dot}></div>
                            <h1 className="font20 mb-3 text-dark ">Banner </h1>
                          </div>

                          <Container
                            fluid
                            className="bg-white p-4 rounded shadow position-relative"
                          >
                            {videoPreview ? (
                              <video
                                className={styles.video}
                                controls
                                src={videoPreview}
                                width={"100%"}
                                height={"350px"}
                              ></video>
                            ) : (
                              <>
                                {image ? (
                                  <img
                                    className={styles.headimage}
                                    src={image}
                                    alt=""
                                    width={"100%"}
                                    height={"350px"}
                                  />
                                ) : (
                                  <img
                                    src={`${bannerImage}`}
                                    alt=""
                                    width={"100%"}
                                    height={"350px"}
                                  />
                                )}
                              </>
                            )}
                            <div className={styles.headInput}>
                              <Button
                                variant="primary"
                                style={{ width: "158px" }}
                                className="fullRounded px-4 py-2 font14 me-2 fw-semibold fs-6"
                              >
                                <ReactFileReader
                                  handleFiles={handleFiles}
                                  fileTypes={".png,.jpg,.mp4"}
                                >
                                  {" "}
                                  <div className="d-flex align-items-center justify-content-center">
                                    <FaCloudUploadAlt />{" "}
                                    <span className="ms-2">Upload File</span>
                                  </div>{" "}
                                </ReactFileReader>
                              </Button>
                              {filename && (
                                <p className="mb-0 text-danger font16 bottom-0 w-100 text-center">
                                  {filename}{" "}
                                  <span
                                    className="text-dark "
                                    onClick={() => {
                                      setFilename("");
                                      setImage("");
                                      setVideoPreview("");
                                      setHomeBannerImage("");
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    x
                                  </span>
                                </p>
                              )}

                              <ModalColor
                                homeBannerColor={homeBannerColor}
                                setHomeBannerColor={setHomeBannerColor}
                                setImage={setImage}
                                setFilename={setFilename}
                                setHomeBannerImage={setHomeBannerImage}
                                primaryColor={primaryColor}
                                secondaryColor={secondaryColor}
                                setHomeBannerColorCombination={
                                  setHomeBannerColorCombination
                                }
                                setHomeBannerColorCombination1={
                                  setHomeBannerColorCombination1
                                }
                                homeBannerColorCombination={
                                  homeBannerColorCombination
                                }
                                homeBannerColorCombination1={
                                  homeBannerColorCombination1
                                }
                              />
                            </div>
                          </Container>

                          <div className="d-flex mt-3">
                            <div className={styles.dot}></div>
                            <h1 className="font20 mb-3 text-dark ">Footer </h1>
                          </div>

                          <Container
                            fluid
                            className="bg-white p-4 rounded shadow"
                          >
                            <div className={styles.homepagehead}>
                              <h1 className="font16 text-dark ">
                                Upload Footer Logos
                              </h1>
                              <div className="d-flex">
                                <Button className="me-2" variant="light">
                                 
                                  <img
                                    src="/assets/images/cyber_img.png"
                                    alt="i"
                                    style={{
                                      width: "50px",
                                      height: "54px",
                                      boxShadow:
                                        "inset 0 0 0 2000px rgb(250, 245, 245)",
                                    }}
                                  />
                                
                                </Button>
                        
                                <div>
                                  <Button className="me-2" variant="light">
                                    <ReactFileReader
                                      handleFiles={handleFooterLogo1}
                                      fileTypes={".png,.jpg"}
                                    >
                                      {footerimage1 ? (
                                        <div className={styles.footerlogo}>
                                          <img src={footerimage1} alt="" />
                                        </div>
                                      ) : (
                                        <FooterICon />
                                      )}
                                    </ReactFileReader>
                                  </Button>
                                  {footerlogoname1 && (
                                    <p className="mb-0 text-danger font12 bottom-0 w-100 text-center">
                                      {footerlogoname1}{" "}
                                      <span
                                        className="text-dark "
                                        onClick={() => {
                                          setFooterLogoname1("");
                                          setFooterImage1("");
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        x
                                      </span>
                                    </p>
                                  )}
                                </div>
                                <div>
                                  <Button className="me-2" variant="light">
                                    <ReactFileReader
                                      handleFiles={handleFooterLogo2}
                                      fileTypes={".png,.jpg"}
                                    >
                                      {footerimage2 ? (
                                        <div className={styles.footerlogo}>
                                          <img src={footerimage2} alt="" />
                                        </div>
                                      ) : (
                                        <FooterICon />
                                      )}
                                    </ReactFileReader>
                                  </Button>
                                  {footerlogoname2 && (
                                    <p className="mb-0 text-danger font12 bottom-0 w-100 text-center">
                                      {footerlogoname2}{" "}
                                      <span
                                        className="text-dark "
                                        onClick={() => {
                                          setFooterLogoname2("");
                                          setFooterImage2("");
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        x
                                      </span>
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Container>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  )} */}

                  {bannerColor ? (
                    <div className="mb-5">
                      <Container
                        fluid
                        className="mt-3 px-4 py-2 rounded shadow"
                        style={{ backgroundColor: "#fff" }}
                        onClick={handleBrand}
                      >
                        <div className={styles.homepagehead}>
                          <h1 className="font20 " style={{ color: "black" }}>
                            Brand Page
                          </h1>
                          {brand ? (
                            <svg
                              width="40"
                              height="40"
                              viewBox="0 0 40 40"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="40"
                                height="40"
                                rx="8"
                                transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 40 40)"
                                fill="white"
                              />
                              <path
                                d="M20 27L20 13"
                                stroke="#4C00EF"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M27 20L20 13L13 20"
                                stroke="#4C00EF"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          ) : (
                            <svg
                              width="40"
                              height="40"
                              viewBox="0 0 40 40"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="40"
                                height="40"
                                rx="8"
                                transform="matrix(1.19249e-08 1 1 -1.19249e-08 0 0)"
                                fill="white"
                              />
                              <path
                                d="M20 13L20 27"
                                stroke="#4C00EF"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M13 20L20 27L27 20"
                                stroke="#4C00EF"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}
                        </div>
                      </Container>

                      {brand ? (
                        <>
                          <div className="d-flex mt-3">
                            <div className={styles.dot}></div>
                            <h1 className="font20 mb-3 text-dark ">
                              Brand Page - Banner{" "}
                            </h1>
                          </div>

                          <Container
                            fluid
                            className="bg-white p-4 rounded shadow position-relative"
                          >
                            <div
                              style={{
                                backgroundImage: `linear-gradient(93.59deg,${BannerColorCombination},${BannerColorCombination1})`,
                                width: "100%",
                                height: "350px",
                              }}
                              className={styles.headDiv}
                            ></div>

                            <div className={styles.headInput}>
                              <Button
                                variant="primary"
                                style={{ width: "158px" }}
                                className="fullRounded px-4 py-2 font14 me-2 fw-semibold fs-6"
                              >
                                <ReactFileReader
                                  handleFiles={handleFiles1}
                                  fileTypes={".png,.jpg,.mp4"}
                                >
                                  {" "}
                                  <div className="d-flex align-items-center justify-content-center">
                                    <FaCloudUploadAlt />{" "}
                                    <span className="ms-2">Upload File</span>
                                  </div>{" "}
                                </ReactFileReader>
                              </Button>

                              {/* <Button
         onClick={handleBannerColor}
     variant="primary"
     className="fullRounded px-4 px-lg-3 font-14 px-xl-4 py-2 mt-2 fw-semibold">
        <span>Solid Color</span>
    </Button> */}
                              <ModalColor
                                homeBannerColor={bannerColor}
                                setHomeBannerColor={setBannerColor}
                                setImage={setImage1}
                                setFilename={setFilename1}
                                setHomeBannerImage={setBannerImage}
                                primaryColor={primaryColor}
                                secondaryColor={secondaryColor}
                                setHomeBannerColorCombination={
                                  setBannerColorCombination
                                }
                                setHomeBannerColorCombination1={
                                  setBannerColorCombination1
                                }
                                homeBannerColorCombination={
                                  BannerColorCombination
                                }
                                homeBannerColorCombination1={
                                  BannerColorCombination1
                                }
                              />
                            </div>
                          </Container>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <div className="mb-5">
                      <Container
                        fluid
                        className="mt-3 px-4 py-2 rounded shadow"
                        style={{ backgroundColor: "#fff" }}
                        onClick={handleBrand}
                      >
                        <div className={styles.homepagehead}>
                          <h1 className="font20" style={{ color: "black" }}>
                            Brand Page
                          </h1>
                          {brand ? (
                            <svg
                              width="40"
                              height="40"
                              viewBox="0 0 40 40"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="40"
                                height="40"
                                rx="8"
                                transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 40 40)"
                                fill="white"
                              />
                              <path
                                d="M20 27L20 13"
                                stroke="#4C00EF"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M27 20L20 13L13 20"
                                stroke="#4C00EF"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          ) : (
                            <svg
                              width="40"
                              height="40"
                              viewBox="0 0 40 40"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="40"
                                height="40"
                                rx="8"
                                transform="matrix(1.19249e-08 1 1 -1.19249e-08 0 0)"
                                fill="white"
                              />
                              <path
                                d="M20 13L20 27"
                                stroke="#4C00EF"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M13 20L20 27L27 20"
                                stroke="#4C00EF"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}
                        </div>
                      </Container>

                      {brand ? (
                        <>
                          <div className="d-flex mt-3">
                            <div className={styles.dot}></div>
                            <h1 className="font20 mb-3 text-dark ">
                              Brand Page - Banner{" "}
                            </h1>
                          </div>

                          <Container
                            fluid
                            className="bg-white p-4 rounded shadow position-relative"
                          >
                            {videoPreview1 ? (
                              <video
                                className={styles.video}
                                controls
                                src={videoPreview1}
                                width={"100%"}
                                height={"350px"}
                              ></video>
                            ) : (
                              <>
                                {image1 ? (
                                  <img
                                    className={styles.headimage}
                                    src={image1}
                                    alt=""
                                    width={"100%"}
                                    height={"350px"}
                                  />
                                ) : (
                                  <img
                                    src={bannerImage}
                                    alt=""
                                    width={"100%"}
                                    height={"350px"}
                                  />
                                )}
                              </>
                            )}
                            <div className={styles.headInput}>
                              <Button
                                variant="primary"
                                style={{ width: "158px" }}
                                className="fullRounded px-4 py-2 font14 me-2 fw-semibold fs-6"
                              >
                                <ReactFileReader
                                  handleFiles={handleFiles1}
                                  fileTypes={".png,.jpg,.mp4"}
                                >
                                  {" "}
                                  <div className="d-flex align-items-center justify-content-center">
                                    <FaCloudUploadAlt />{" "}
                                    <span className="ms-2">Upload File</span>
                                  </div>{" "}
                                </ReactFileReader>
                              </Button>
                              {filename1 && (
                                <p className="mb-0 text-danger font16 bottom-0 w-100 text-center">
                                  {filename1}{" "}
                                  <span
                                    className="text-dark "
                                    onClick={() => {
                                      setFilename1("");
                                      setImage1("");
                                      setVideoPreview1("");
                                      setBannerImage("");
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    x
                                  </span>
                                </p>
                              )}
                              {/* <Button
         onClick={handleBannerColor}
     variant="primary"
     className="fullRounded px-4 px-lg-3 font-14 px-xl-4 py-2 mt-2 fw-semibold">
        <span>Solid Color</span>
    </Button> */}
                              <ModalColor
                                homeBannerColor={bannerColor}
                                setHomeBannerColor={setBannerColor}
                                setImage={setImage1}
                                setFilename={setFilename1}
                                setHomeBannerImage={setBannerImage}
                                primaryColor={primaryColor}
                                secondaryColor={secondaryColor}
                                setHomeBannerColorCombination={
                                  setBannerColorCombination
                                }
                                setHomeBannerColorCombination1={
                                  setBannerColorCombination1
                                }
                                homeBannerColorCombination={
                                  BannerColorCombination
                                }
                                homeBannerColorCombination1={
                                  BannerColorCombination1
                                }
                              />
                            </div>
                          </Container>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  )}

                  {isLoading ? (
                    <Button
                      variant="primary"
                      className="fullRounded px-4 px-lg-3 px-xl-4 py-2 fw-bold font12"
                      style={{ width: "133.75px" }}
                    >
                      <span>Proceeding...</span>
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      onClick={() => {
                        handlePost("branding");
                      }}
                      className="fullRounded px-4 px-lg-3 px-xl-4 py-2 fw-bold font12 my-2"
                      style={{ width: "133.75px" }}
                    >
                      <span>Save</span>
                    </Button>
                  )}
                </>
              ) : (
                ""
              )}

              <Container
                fluid
                className="mt-3 px-4 py-2 rounded shadow"
                style={{ backgroundColor: "#FF7020" }}
                onClick={handlePart2}
              >
                <div className={styles.homepagehead}>
                  <h1 className="font20 text-light ">Mailing</h1>
                  {part2 ? (
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="40"
                        height="40"
                        rx="8"
                        transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 40 40)"
                        fill="white"
                      />
                      <path
                        d="M20 27L20 13"
                        stroke="#4C00EF"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M27 20L20 13L13 20"
                        stroke="#4C00EF"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="40"
                        height="40"
                        rx="8"
                        transform="matrix(1.19249e-08 1 1 -1.19249e-08 0 0)"
                        fill="white"
                      />
                      <path
                        d="M20 13L20 27"
                        stroke="#4C00EF"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13 20L20 27L27 20"
                        stroke="#4C00EF"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </div>
              </Container>
              {part2 ? (
                <>
                  <div className="mb-5">
                    <Container
                      fluid
                      className="mt-3 px-4 py-2 rounded shadow"
                      style={{ backgroundColor: "#fff" }}
                      onClick={handleEmail}
                    >
                      <div className={styles.homepagehead}>
                        <h1 className="font20  " style={{ color: "black" }}>
                          Welcome Email
                        </h1>
                        {email ? (
                          <svg
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="40"
                              height="40"
                              rx="8"
                              transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 40 40)"
                              fill="white"
                            />
                            <path
                              d="M20 27L20 13"
                              stroke="#4C00EF"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M27 20L20 13L13 20"
                              stroke="#4C00EF"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        ) : (
                          <svg
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="40"
                              height="40"
                              rx="8"
                              transform="matrix(1.19249e-08 1 1 -1.19249e-08 0 0)"
                              fill="white"
                            />
                            <path
                              d="M20 13L20 27"
                              stroke="#4C00EF"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M13 20L20 27L27 20"
                              stroke="#4C00EF"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        )}
                      </div>
                    </Container>

                    {email ? (
                      <>
                        <div className="d-flex mt-3">
                          <div className={styles.dot}></div>
                          <h1 className="font20 mb-3 text-dark ">
                            Message Topic
                          </h1>
                        </div>
                        <Container fluid className="mt-2">
                          <Row>
                            <Form className="bg-white p-4 rounded containerShadow">
                              <Row>
                                <Col md={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="font16 text-dark fw-medium">
                                      Email Subject Heading{" "}
                                      <span className="fw-normal text-secondary"></span>
                                    </Form.Label>
                                    {/* <div style={{border:welcomeEmailSubject === "<p><br></p>" ?"1px solid red":''}}> */}
                                    <JoditEditor
                                      ref={editor}
                                      value={welcomeEmailSubject}
                                      onBlur={(e) => setWelcomeEmailSubject(e)}
                                    />
                                    {/* </div> */}
                                  </Form.Group>
                                </Col>
                                <Col md={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="font16 text-dark fw-medium">
                                      Mail Heading{" "}
                                      <span className="fw-normal text-secondary"></span>
                                    </Form.Label>
                                    {/* <div style={{border:welcomeEmailHead?"":'1px solid red'}}> */}
                                    <JoditEditor
                                      ref={editor1}
                                      value={welcomeEmailHead}
                                      onBlur={(e) => setWelcomeEmailHead(e)}
                                    />
                                    {/* </div> */}
                                  </Form.Group>
                                </Col>
                                <Col md={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="font16 text-dark fw-medium">
                                      Mail Body 1{" "}
                                      <span className="fw-normal text-secondary"></span>
                                    </Form.Label>

                                    {/* <DefaultEditor value={welcomeEmail} onChange={handleWelcome}/> */}
                                    {/* <div style={{border : welcomeEmail ? '' : '1px solid red'}}> */}
                                    <JoditEditor
                                      ref={editor2}
                                      value={welcomeEmail}
                                      onBlur={(e) => setWelcomeEmail(e)}
                                    />
                                    {/* </div> */}
                                  </Form.Group>
                                </Col>

                                <Col md={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="font16 text-dark fw-medium">
                                      Mail Body 2{" "}
                                      <span className="fw-normal text-secondary"></span>
                                    </Form.Label>

                                    {/* <DefaultEditor value={welcomeEmail1} onChange={handleWelcome1}/> */}
                                    {/* <div style={{border : welcomeEmail ? '' : '1px solid red'}}> */}

                                    <JoditEditor
                                      ref={editor3}
                                      value={welcomeEmail1}
                                      onBlur={(e) => setWelcomeEmail1(e)}
                                    />
                                    {/* </div> */}
                                  </Form.Group>
                                </Col>
                                <Col md={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="font16 text-dark fw-medium">
                                      Mail Body 3{" "}
                                      <span className="fw-normal text-secondary"></span>
                                    </Form.Label>

                                    {/* <DefaultEditor value={welcomeEmail2} onChange={handleWelcome2}/> */}
                                    {/* <div style={{border:welcomeEmail2?'':'1px solid red'}}> */}
                                    <JoditEditor
                                      ref={editor4}
                                      value={welcomeEmail2}
                                      onBlur={(e) => setWelcomeEmail2(e)}
                                    />
                                    {/* </div> */}
                                  </Form.Group>
                                </Col>
                                {/*working here */}
                                <>
                                  <div className="d-flex">
                                    <div className="flex-fill">
                                      <h1 className="font16 text-dark mb-1">
                                        Upload Email Icon
                                      </h1>
                                      <div className="d-flex">
                                        <div>
                                          <Button
                                            className="me-2"
                                            variant="light"
                                          >
                                            <ReactFileReader
                                              handleFiles={handleEmailIcon}
                                              fileTypes={".png,.jpg"}
                                            >
                                              {logo1 ? (
                                                <div
                                                  className={styles.emaillogos}
                                                >
                                                  <img
                                                    src={logo1}
                                                    alt="Upload Email Icon"
                                                  />
                                                </div>
                                              ) : (
                                                <FooterICon />
                                              )}
                                            </ReactFileReader>
                                          </Button>
                                          {welcomeIconText && logoname1 && (
                                            <p className="mb-0 text-danger font14 bottom-0 w-100 text-center">
                                              {logoname1}{" "}
                                              <span
                                                className="text-dark"
                                                onClick={() => {
                                                  setLogoname1("");
                                                  setLogo1("");
                                                }}
                                                style={{ cursor: "pointer" }}
                                              >
                                                x
                                              </span>
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <h1 className="font16 text-dark fw-medium mb-2">
                                    Email Header Image{" "}
                                  </h1>
                                  <Container
                                    fluid
                                    className="p-4 rounded shadow position-relative"
                                  >
                                    {emailHeaderColor ? (
                                      <div
                                        style={{
                                          backgroundImage: `linear-gradient(93.59deg,${welcomeEmailColorCombination},${welcomeEmailColorCombination1})`,
                                          width: "100%",
                                          height: "250px",
                                        }}
                                        className={styles.headDiv}
                                      ></div>
                                    ) : (
                                      <>
                                        {image2 ? (
                                          <img
                                            className={styles.headimage}
                                            src={image2}
                                            alt=""
                                            width={"100%"}
                                            height={"250px"}
                                          />
                                        ) : (
                                          <img
                                            className={styles.headimage}
                                            src={bannerImage}
                                            alt=""
                                            width={"100%"}
                                            height={"250px"}
                                          />
                                        )}
                                      </>
                                    )}
                                    <div className={styles.headInput}>
                                      <Button
                                        variant="primary"
                                        style={{ width: "158px" }}
                                        className="fullRounded px-4 py-2 font14 me-2 fw-semibold fs-6"
                                      >
                                        <ReactFileReader
                                          handleFiles={handleFiless}
                                          fileTypes={".png,.jpg"}
                                        >
                                          {" "}
                                          <div className="d-flex align-items-center justify-content-center">
                                            <FaCloudUploadAlt />{" "}
                                            <span className="ms-2">
                                              Upload File
                                            </span>
                                          </div>{" "}
                                        </ReactFileReader>
                                      </Button>
                                      {fileNameEmail && (
                                        <p className="mb-0 text-danger font16 bottom-0 w-100 text-center">
                                          {fileNameEmail}{" "}
                                          <span
                                            className="text-dark "
                                            onClick={() => {
                                              setFilenameEmail("");
                                              setImage2("");
                                            }}
                                            style={{ cursor: "pointer" }}
                                          >
                                            x
                                          </span>
                                        </p>
                                      )}
                                      {/* <Button
         onClick={HandleHeaderImageColour}
         variant="primary"
         className="fullRounded px-4 px-lg-3 font-14 px-xl-4 py-2 mt-2 fw-semibold">
        <span>Solid Color</span>
    </Button> */}
                                      <ModalColor1
                                        homeBannerColor={emailHeaderColor}
                                        setHomeBannerColor={setEmailHeaderColor}
                                        setImage={setImage2}
                                        setFilename={setFilenameEmail}
                                        primaryColor={primaryColor}
                                        secondaryColor={secondaryColor}
                                        setHomeBannerColorCombination={
                                          setWelcomeEmailColorCombination
                                        }
                                        setHomeBannerColorCombination1={
                                          setWelcomeEmailColorCombination1
                                        }
                                        homeBannerColorCombination={
                                          welcomeEmailColorCombination
                                        }
                                        homeBannerColorCombination1={
                                          welcomeEmailColorCombination1
                                        }
                                      />
                                    </div>
                                  </Container>

                                  {/* <div className='d-flex mt-3'>
        <h1 className="font16 text-dark fw-medium mb-2">Email Footer Image<span className="font16 mb-3 text-danger ">(150 X 50)px</span></h1>
        </div>   
        <Container fluid className="bg-white p-4 rounded shadow">
      <div className={styles.homepagehead}>
         <div className='d-flex'>
          <div>
         <Button className='me-2' variant="light">
         <ReactFileReader handleFiles={handleFooterLogo3} fileTypes={".png,.jpg"}>
         {footerimage3? <div className={styles.footerlogo}>
          <img src={footerimage3} alt="Upload Footer Logos" />
         </div>:
         <FooterICon/>}
         </ReactFileReader>
         </Button>
         {footerlogoname3 && <p className="mb-0 text-danger font12 bottom-0 w-100 text-center">{footerlogoname3} <span className="text-dark " onClick={() =>{setFooterLogoname3('');setFooterImage3('')}} style={{ cursor: 'pointer' }} >x</span></p>}
          </div>

         </div>
         </div>
   </Container> */}
                                </>

                                <div className="d-flex justify-content-end mt-3 px-2">
                                  <Button onClick={handleShowWelcomeMail}>
                                    Preview
                                  </Button>
                                </div>
                              </Row>
                            </Form>
                          </Row>
                        </Container>
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="mb-5">
                    <Container
                      fluid
                      className="mt-3 px-4 py-2 rounded shadow"
                      style={{ backgroundColor: "#fff" }}
                      onClick={handleEmailOrder}
                    >
                      <div className={styles.homepagehead}>
                        <h1 className="font20 " style={{ color: "black" }}>
                          Order Email
                        </h1>
                        {emailOrder ? (
                          <svg
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="40"
                              height="40"
                              rx="8"
                              transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 40 40)"
                              fill="white"
                            />
                            <path
                              d="M20 27L20 13"
                              stroke="#4C00EF"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M27 20L20 13L13 20"
                              stroke="#4C00EF"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        ) : (
                          <svg
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="40"
                              height="40"
                              rx="8"
                              transform="matrix(1.19249e-08 1 1 -1.19249e-08 0 0)"
                              fill="white"
                            />
                            <path
                              d="M20 13L20 27"
                              stroke="#4C00EF"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M13 20L20 27L27 20"
                              stroke="#4C00EF"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        )}
                      </div>
                    </Container>
                    {emailOrder ? (
                      <>
                        <div className="d-flex mt-3">
                          <div className={styles.dot}></div>
                          <h1 className="font20 mb-3 text-dark ">
                            Message Topic
                          </h1>
                        </div>

                        {/* </div> */}
                        <Container fluid className="mt-2">
                          <Row>
                            <Form className="bg-white p-4 rounded containerShadow">
                              <Row>
                                <Col md={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="font16 text-dark fw-medium">
                                      Email Subject Heading{" "}
                                      <span className="fw-normal text-secondary"></span>
                                    </Form.Label>

                                    <JoditEditor
                                      ref={editor5}
                                      value={messageEmailSubject}
                                      onBlur={(e) => setMessageEmailSubject(e)}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="font16 text-dark fw-medium">
                                      Mail Heading{" "}
                                      <span className="fw-normal text-secondary"></span>
                                    </Form.Label>

                                    <JoditEditor
                                      ref={editor6}
                                      value={messageEmailHead}
                                      onBlur={(e) => setMessageEmailHead(e)}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="font16 text-dark fw-medium">
                                      Mail Body 1{" "}
                                      <span className="fw-normal text-secondary"></span>
                                    </Form.Label>

                                    {/* <DefaultEditor name="messageTopicEmail" value={messageTopicEmail} onChange={handleMessage}/> */}
                                    <JoditEditor
                                      ref={editor7}
                                      value={messageTopicEmail}
                                      onBlur={(e) => setMessageTopicEmail(e)}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="font16 text-dark fw-medium">
                                      Mail Body 2{" "}
                                      <span className="fw-normal text-secondary"></span>
                                    </Form.Label>

                                    {/* <DefaultEditor name="messageTopicEmail1" value={messageTopicEmail1} onChange={handleMessage1}/>  */}
                                    <JoditEditor
                                      ref={editor8}
                                      value={messageTopicEmail1}
                                      onBlur={(e) => setMessageTopicEmail1(e)}
                                    />
                                  </Form.Group>
                                </Col>
                                {/*working here */}
                                <>
                                  <div className="d-flex">
                                    <div className="flex-fill">
                                      <h1 className="font16 text-dark mb-1">
                                        Upload Email Icon{" "}
                                      </h1>
                                      <div className="d-flex">
                                        <div>
                                          <Button
                                            className="me-2"
                                            variant="light"
                                          >
                                            <ReactFileReader
                                              handleFiles={handleEmailIcon2}
                                              fileTypes={".png,.jpg"}
                                            >
                                              {logo2 ? (
                                                <div
                                                  className={styles.emaillogos}
                                                >
                                                  {/* <img src='https://res.cloudinary.com/klizo-solutions/image/upload/v1662394148/ncentiva/Email-templates/Check_2_-_16pxtick_sk9c93.png' alt="" /> */}
                                                  <img src={logo2} alt="" />
                                                </div>
                                              ) : (
                                                <FooterICon />
                                              )}
                                            </ReactFileReader>
                                          </Button>
                                          {orderIconText && logoname2 && (
                                            <p className="mb-0 text-danger font14 bottom-0 w-100 text-center">
                                              {logoname2}{" "}
                                              <span
                                                className="text-dark"
                                                onClick={() => {
                                                  setLogoname2("");
                                                  setLogo2("");
                                                }}
                                                style={{ cursor: "pointer" }}
                                              >
                                                x
                                              </span>
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <h1 className="font16 text-dark fw-medium mb-2">
                                    Email Header Image
                                  </h1>
                                  <Container
                                    fluid
                                    className="p-4 rounded shadow position-relative"
                                  >
                                    {emailHeaderColor1 ? (
                                      <div
                                        style={{
                                          backgroundImage: `linear-gradient(93.59deg,${orderEmailColorCombination},${orderEmailColorCombination1})`,
                                          width: "100%",
                                          height: "250px",
                                        }}
                                        className={styles.headDiv}
                                      ></div>
                                    ) : (
                                      <>
                                        {image3 ? (
                                          <img
                                            className={styles.headimage}
                                            src={image3}
                                            alt=""
                                            width={"100%"}
                                            height={"250px"}
                                          />
                                        ) : (
                                          <img
                                            className={styles.headimage}
                                            src={bannerImage}
                                            alt=""
                                            width={"100%"}
                                            height={"250px"}
                                          />
                                        )}
                                      </>
                                    )}

                                    <div className={styles.headInput}>
                                      <Button
                                        variant="primary"
                                        style={{ width: "158px" }}
                                        className="fullRounded px-4 py-2 font14 me-2 fw-semibold fs-6"
                                      >
                                        <ReactFileReader
                                          handleFiles={handleFiless1}
                                          fileTypes={".png,.jpg"}
                                        >
                                          {" "}
                                          <div className="d-flex align-items-center justify-content-center">
                                            <FaCloudUploadAlt />
                                            <span className="ms-2">
                                              Upload File
                                            </span>
                                          </div>{" "}
                                        </ReactFileReader>
                                      </Button>
                                      {fileNameEmail1 && (
                                        <p className="mb-0 text-danger font16 bottom-0 w-100 text-center">
                                          {fileNameEmail1}{" "}
                                          <span
                                            className="text-dark "
                                            onClick={() => {
                                              setFilenameEmail1("");
                                              setImage3("");
                                            }}
                                            style={{ cursor: "pointer" }}
                                          >
                                            x
                                          </span>
                                        </p>
                                      )}
                                      {/* <Button
         onClick={HandleHeaderImageColour1}
     variant="primary"
     className="fullRounded px-4 px-lg-3 font-14 px-xl-4 py-2 mt-2 fw-semibold">
        <span>Solid Color</span>
    </Button> */}
                                      <ModalColor1
                                        homeBannerColor={emailHeaderColor1}
                                        setHomeBannerColor={
                                          setEmailHeaderColor1
                                        }
                                        setImage={setImage3}
                                        setFilename={setFilenameEmail1}
                                        primaryColor={primaryColor}
                                        secondaryColor={secondaryColor}
                                        setHomeBannerColorCombination={
                                          setOrderEmailColorCombination
                                        }
                                        setHomeBannerColorCombination1={
                                          setOrderEmailColorCombination1
                                        }
                                        homeBannerColorCombination={
                                          orderEmailColorCombination
                                        }
                                        homeBannerColorCombination1={
                                          orderEmailColorCombination1
                                        }
                                      />
                                    </div>
                                  </Container>
                                  {/* 
     <div className='d-flex mt-3'>
        <h1 className="font16 text-dark fw-medium mb-2">Email Footer Image<span className="font16 mb-3 text-danger ">(150 X 50)px</span></h1>
        </div>   
        <Container fluid className="bg-white p-4 rounded shadow">
      <div className={styles.homepagehead}>
         <div className='d-flex'>
          <div>
         <Button className='me-2' variant="light">
         <ReactFileReader handleFiles={handleFooterLogo4} fileTypes={".png,.jpg"}>
         {footerimage4? <div className={styles.footerlogo}>
          <img src={footerimage4} alt="" />
         </div>:
         <FooterICon/>}
         </ReactFileReader>
         </Button>
         {footerlogoname4 && <p className="mb-0 text-danger font12 bottom-0 w-100 text-center">{footerlogoname4} <span className="text-dark " onClick={() =>{setFooterLogoname4('');setFooterImage4('')}} style={{ cursor: 'pointer' }} >x</span></p>}
          </div>

         </div>
         </div>
   </Container> */}
                                </>
                              </Row>
                            </Form>

                            <div className="d-flex justify-content-end mt-3 px-2">
                              <Button onClick={handleShowOrderMail}>
                                Preview
                              </Button>
                            </div>
                          </Row>
                        </Container>
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="mb-5">
                    <Container
                      fluid
                      className="mt-3 px-4 py-2 rounded shadow"
                      style={{ backgroundColor: "#fff" }}
                      onClick={handleRefundOrder}
                    >
                      <div className={styles.homepagehead}>
                        <h1 className="font20 " style={{ color: "black" }}>
                          Refund Email
                        </h1>
                        {refundOrder ? (
                          <svg
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="40"
                              height="40"
                              rx="8"
                              transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 40 40)"
                              fill="white"
                            />
                            <path
                              d="M20 27L20 13"
                              stroke="#4C00EF"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M27 20L20 13L13 20"
                              stroke="#4C00EF"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        ) : (
                          <svg
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="40"
                              height="40"
                              rx="8"
                              transform="matrix(1.19249e-08 1 1 -1.19249e-08 0 0)"
                              fill="white"
                            />
                            <path
                              d="M20 13L20 27"
                              stroke="#4C00EF"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M13 20L20 27L27 20"
                              stroke="#4C00EF"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        )}
                      </div>
                    </Container>
                    {refundOrder ? (
                      <>
                        <div className="d-flex mt-3">
                          <div className={styles.dot}></div>
                          <h1 className="font20 mb-3 text-dark ">
                            Message Topic
                          </h1>
                        </div>
                        <Container fluid className="mt-2">
                          <Row>
                            <Form className="bg-white p-4 rounded containerShadow">
                              <Row>
                                <Col md={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="font16 text-dark fw-medium">
                                      Email Subject Heading{" "}
                                      <span className="fw-normal text-secondary"></span>
                                    </Form.Label>

                                    <JoditEditor
                                      ref={editor9}
                                      value={refundEmailHead}
                                      onBlur={(e) => setRefundEmailHead(e)}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="font16 text-dark fw-medium">
                                      Mail Heading{" "}
                                      <span className="fw-normal text-secondary"></span>
                                    </Form.Label>

                                    <JoditEditor
                                      ref={editor10}
                                      value={refundEmailSubject}
                                      onBlur={(e) => setRefundEmailSubject(e)}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="font16 text-dark fw-medium">
                                      Mail Body 1{" "}
                                      <span className="fw-normal text-secondary"></span>
                                    </Form.Label>

                                    <JoditEditor
                                      ref={editor11}
                                      value={refundEmail}
                                      onBlur={(e) => setRefundEmail(e)}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="font16 text-dark fw-medium">
                                      Mail Body 2{" "}
                                      <span className="fw-normal text-secondary"></span>
                                    </Form.Label>

                                    <JoditEditor
                                      ref={editor12}
                                      value={refundEmail1}
                                      onBlur={(e) => setRefundEmail1(e)}
                                    />
                                  </Form.Group>
                                </Col>
                                {/*working here */}
                                <>
                                  <div className="d-flex">
                                    <div className="flex-fill">
                                      <h1 className="font16 text-dark mb-1">
                                        Upload Email Icon{" "}
                                      </h1>
                                      <div className="d-flex">
                                        <div>
                                          <Button
                                            className="me-2"
                                            variant="light"
                                          >
                                            <ReactFileReader
                                              handleFiles={handleEmailIcon3}
                                              fileTypes={".png,.jpg"}
                                            >
                                              {logo3 ? (
                                                <div
                                                  className={styles.emaillogos}
                                                >
                                                  {/* <img src='https://res.cloudinary.com/klizo-solutions/image/upload/v1662394148/ncentiva/Email-templates/Check_2_-_16pxtick_sk9c93.png' alt="" /> */}
                                                  <img src={logo3} alt="" />
                                                </div>
                                              ) : (
                                                <FooterICon />
                                              )}
                                            </ReactFileReader>
                                          </Button>
                                          {refundIconText && logoname3 && (
                                            <p className="mb-0 text-danger font14 bottom-0 w-100 text-center">
                                              {logoname3}{" "}
                                              <span
                                                className="text-dark"
                                                onClick={() => {
                                                  setLogoname3("");
                                                  setLogo3("");
                                                }}
                                                style={{ cursor: "pointer" }}
                                              >
                                                x
                                              </span>
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <h1 className="font16 text-dark fw-medium mb-2">
                                    Email Header Image
                                  </h1>
                                  <Container
                                    fluid
                                    className="p-4 rounded shadow position-relative"
                                  >
                                    {emailHeaderColor2 ? (
                                      <div
                                        style={{
                                          backgroundImage: `linear-gradient(93.59deg,${refundEmailColorCombination},${refundEmailColorCombination1})`,
                                          width: "100%",
                                          height: "250px",
                                        }}
                                        className={styles.headDiv}
                                      ></div>
                                    ) : (
                                      <>
                                        {image4 ? (
                                          <img
                                            className={styles.headimage}
                                            src={image4}
                                            alt=""
                                            width={"100%"}
                                            height={"250px"}
                                          />
                                        ) : (
                                          <img
                                            className={styles.headimage}
                                            src={bannerImage}
                                            alt=""
                                            width={"100%"}
                                            height={"250px"}
                                          />
                                        )}
                                      </>
                                    )}

                                    <div className={styles.headInput}>
                                      <Button
                                        variant="primary"
                                        style={{ width: "158px" }}
                                        className="fullRounded px-4 py-2 font14 me-2 fw-semibold fs-6"
                                      >
                                        <ReactFileReader
                                          handleFiles={handleFiless2}
                                          fileTypes={".png,.jpg"}
                                        >
                                          {" "}
                                          <div className="d-flex align-items-center justify-content-center">
                                            <FaCloudUploadAlt />
                                            <span className="ms-2">
                                              Upload File
                                            </span>
                                          </div>{" "}
                                        </ReactFileReader>
                                      </Button>
                                      {fileNameEmail2 && (
                                        <p className="mb-0 text-danger font16 bottom-0 w-100 text-center">
                                          {fileNameEmail2}{" "}
                                          <span
                                            className="text-dark "
                                            onClick={() => {
                                              setFilenameEmail2("");
                                              setImage4("");
                                            }}
                                            style={{ cursor: "pointer" }}
                                          >
                                            x
                                          </span>
                                        </p>
                                      )}
                                      <ModalColor2
                                        homeBannerColor={emailHeaderColor2}
                                        setHomeBannerColor={
                                          setEmailHeaderColor2
                                        }
                                        setImage={setImage4}
                                        setFilename={setFilenameEmail2}
                                        primaryColor={primaryColor}
                                        secondaryColor={secondaryColor}
                                        setHomeBannerColorCombination={
                                          setRefundEmailColorCombination
                                        }
                                        setHomeBannerColorCombination1={
                                          setRefundEmailColorCombination1
                                        }
                                        homeBannerColorCombination={
                                          refundEmailColorCombination
                                        }
                                        homeBannerColorCombination1={
                                          refundEmailColorCombination1
                                        }
                                      />
                                    </div>
                                  </Container>
                                </>
                              </Row>
                            </Form>

                            <div className="d-flex justify-content-end mt-3 px-2">
                              <Button onClick={handleShowrefundMail}>
                                Preview
                              </Button>
                            </div>
                          </Row>
                        </Container>
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                  {/* Balance Update Notication Email */}

                  <div className="mb-5">
                    <Container
                      fluid
                      className="mt-3 px-4 py-2 rounded shadow"
                      style={{ backgroundColor: "#fff" }}
                      onClick={handleBalanceUpadted}
                    >
                      <div className={styles.homepagehead}>
                        <h1 className="font20 " style={{ color: "black" }}>
                          Balance Add Notification Email
                        </h1>
                        {balanceUpadte ? (
                          <svg
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="40"
                              height="40"
                              rx="8"
                              transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 40 40)"
                              fill="white"
                            />
                            <path
                              d="M20 27L20 13"
                              stroke="#4C00EF"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M27 20L20 13L13 20"
                              stroke="#4C00EF"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        ) : (
                          <svg
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="40"
                              height="40"
                              rx="8"
                              transform="matrix(1.19249e-08 1 1 -1.19249e-08 0 0)"
                              fill="white"
                            />
                            <path
                              d="M20 13L20 27"
                              stroke="#4C00EF"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M13 20L20 27L27 20"
                              stroke="#4C00EF"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        )}
                      </div>
                    </Container>
                    {balanceUpadte ? (
                      <>
                        <div className="d-flex mt-3">
                          <div className={styles.dot}></div>
                          <h1 className="font20 mb-3 text-dark ">
                            Message Topic
                          </h1>
                        </div>
                        <Container fluid className="mt-2">
                          <Row>
                            <Form className="bg-white p-4 rounded containerShadow">
                              <Row>
                                <Col md={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="font16 text-dark fw-medium">
                                      Email Subject Heading{" "}
                                      <span className="fw-normal text-secondary"></span>
                                    </Form.Label>

                                    <JoditEditor
                                      ref={editor13}
                                      value={balanceEmailSubject}
                                      onBlur={(e) => setBalanceEmailSubject(e)}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="font16 text-dark fw-medium">
                                      Mail Heading{" "}
                                      <span className="fw-normal text-secondary"></span>
                                    </Form.Label>

                                    <JoditEditor
                                      ref={editor14}
                                      value={balanceEmailHead}
                                      onBlur={(e) => setBalanceEmailHead(e)}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="font16 text-dark fw-medium">
                                      Mail Body 1{" "}
                                      <span className="fw-normal text-secondary"></span>
                                    </Form.Label>

                                    <JoditEditor
                                      ref={editor15}
                                      value={balanceEmail1}
                                      onBlur={(e) => setBalanceEmail1(e)}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="font16 text-dark fw-medium">
                                      Mail Body 2{" "}
                                      <span className="fw-normal text-secondary"></span>
                                    </Form.Label>

                                    <JoditEditor
                                      ref={editor16}
                                      value={balanceEmail2}
                                      onBlur={(e) => setBalanceEmail2(e)}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="font16 text-dark fw-medium">
                                      Mail Body 3{" "}
                                      <span className="fw-normal text-secondary"></span>
                                    </Form.Label>

                                    <JoditEditor
                                      ref={editor17}
                                      value={balanceEmail3}
                                      onBlur={(e) => setBalanceEmail3(e)}
                                    />
                                  </Form.Group>
                                </Col>
                                {/*working here */}
                                <>
                                  <div className="d-flex">
                                    <div className="flex-fill">
                                      <h1 className="font16 text-dark mb-1">
                                        Upload Email Icon{" "}
                                      </h1>
                                      <div className="d-flex">
                                        <div>
                                          <Button
                                            className="me-2"
                                            variant="light"
                                          >
                                            <ReactFileReader
                                              handleFiles={handleEmailIcon4}
                                              fileTypes={".png,.jpg"}
                                            >
                                              {logo4 ? (
                                                <div
                                                  className={styles.emaillogos}
                                                >
                                                  {/* <img src='https://res.cloudinary.com/klizo-solutions/image/upload/v1662394148/ncentiva/Email-templates/Check_2_-_16pxtick_sk9c93.png' alt="" /> */}
                                                  <img src={logo4} alt="" />
                                                </div>
                                              ) : (
                                                <FooterICon />
                                              )}
                                            </ReactFileReader>
                                          </Button>
                                          {balanceIconText && logoname4 && (
                                            <p className="mb-0 text-danger font14 bottom-0 w-100 text-center">
                                              {logoname4}{" "}
                                              <span
                                                className="text-dark"
                                                onClick={() => {
                                                  setLogoname4("");
                                                  setLogo4("");
                                                }}
                                                style={{ cursor: "pointer" }}
                                              >
                                                x
                                              </span>
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <h1 className="font16 text-dark fw-medium mb-2">
                                    Email Header Image
                                  </h1>
                                  <Container
                                    fluid
                                    className="p-4 rounded shadow position-relative"
                                  >
                                    {emailHeaderColor3 ? (
                                      <div
                                        style={{
                                          backgroundImage: `linear-gradient(93.59deg,${balanceEmailColorCombination},${balanceEmailColorCombination1})`,
                                          width: "100%",
                                          height: "250px",
                                        }}
                                        className={styles.headDiv}
                                      ></div>
                                    ) : (
                                      <>
                                        {image5 ? (
                                          <img
                                            className={styles.headimage}
                                            src={image5}
                                            alt=""
                                            width={"100%"}
                                            height={"250px"}
                                          />
                                        ) : (
                                          <img
                                            className={styles.headimage}
                                            src={bannerImage}
                                            alt=""
                                            width={"100%"}
                                            height={"250px"}
                                          />
                                        )}
                                      </>
                                    )}

                                    <div className={styles.headInput}>
                                      <Button
                                        variant="primary"
                                        style={{ width: "158px" }}
                                        className="fullRounded px-4 py-2 font14 me-2 fw-semibold fs-6"
                                      >
                                        <ReactFileReader
                                          handleFiles={handleFiless3}
                                          fileTypes={".png,.jpg"}
                                        >
                                          {" "}
                                          <div className="d-flex align-items-center justify-content-center">
                                            <FaCloudUploadAlt />
                                            <span className="ms-2">
                                              Upload File
                                            </span>
                                          </div>{" "}
                                        </ReactFileReader>
                                      </Button>
                                      {fileNameEmail3 && (
                                        <p className="mb-0 text-danger font16 bottom-0 w-100 text-center">
                                          {fileNameEmail3}{" "}
                                          <span
                                            className="text-dark "
                                            onClick={() => {
                                              setFilenameEmail3("");
                                              setImage5("");
                                            }}
                                            style={{ cursor: "pointer" }}
                                          >
                                            x
                                          </span>
                                        </p>
                                      )}
                                      <ModalColor3
                                        homeBannerColor={emailHeaderColor3}
                                        setHomeBannerColor={
                                          setEmailHeaderColor3
                                        }
                                        setImage={setImage5}
                                        setFilename={setFilenameEmail3}
                                        primaryColor={primaryColor}
                                        secondaryColor={secondaryColor}
                                        setHomeBannerColorCombination={
                                          setBalanceEmailColorCombination
                                        }
                                        setHomeBannerColorCombination1={
                                          setBalanceEmailColorCombination1
                                        }
                                        homeBannerColorCombination={
                                          balanceEmailColorCombination
                                        }
                                        homeBannerColorCombination1={
                                          balanceEmailColorCombination1
                                        }
                                      />
                                    </div>
                                  </Container>
                                </>
                              </Row>
                            </Form>
                          </Row>
                          <div className="d-flex justify-content-end mt-3 px-2">
                            <Button onClick={handleShowAddFundMail}>
                              Preview
                            </Button>
                          </div>
                        </Container>
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="mb-5">
                    <Container
                      fluid
                      className="mt-3 px-4 py-2 rounded shadow"
                      style={{ backgroundColor: "#fff" }}
                      onClick={handleBalanceReduceUpadted}
                    >
                      <div className={styles.homepagehead}>
                        <h1 className="font20" style={{ color: "black" }}>
                          Balance Reduce Notification Email
                        </h1>
                        {balanceReduceUpadte ? (
                          <svg
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="40"
                              height="40"
                              rx="8"
                              transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 40 40)"
                              fill="white"
                            />
                            <path
                              d="M20 27L20 13"
                              stroke="#4C00EF"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M27 20L20 13L13 20"
                              stroke="#4C00EF"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        ) : (
                          <svg
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="40"
                              height="40"
                              rx="8"
                              transform="matrix(1.19249e-08 1 1 -1.19249e-08 0 0)"
                              fill="white"
                            />
                            <path
                              d="M20 13L20 27"
                              stroke="#4C00EF"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M13 20L20 27L27 20"
                              stroke="#4C00EF"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        )}
                      </div>
                    </Container>
                    {balanceReduceUpadte ? (
                      <>
                        <div className="d-flex mt-3">
                          <div className={styles.dot}></div>
                          <h1 className="font20 mb-3 text-dark ">
                            Message Topic
                          </h1>
                        </div>
                        <Container fluid className="mt-2">
                          <Row>
                            <Form className="bg-white p-4 rounded containerShadow">
                              <Row>
                                <Col md={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="font16 text-dark fw-medium">
                                      Email Subject Heading{" "}
                                      <span className="fw-normal text-secondary"></span>
                                    </Form.Label>

                                    <JoditEditor
                                      ref={editor18}
                                      value={balanceReduceEmailSubject}
                                      onBlur={(e) =>
                                        setBalanceReduceEmailSubject(e)
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="font16 text-dark fw-medium">
                                      Mail Heading{" "}
                                      <span className="fw-normal text-secondary"></span>
                                    </Form.Label>

                                    <JoditEditor
                                      ref={editor19}
                                      value={balanceReduceEmailHead}
                                      onBlur={(e) =>
                                        setBalanceReduceEmailHead(e)
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="font16 text-dark fw-medium">
                                      Mail Body 1{" "}
                                      <span className="fw-normal text-secondary"></span>
                                    </Form.Label>

                                    <JoditEditor
                                      ref={editor20}
                                      value={balanceReduceEmail1}
                                      onBlur={(e) => setBalanceReduceEmail1(e)}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="font16 text-dark fw-medium">
                                      Mail Body 2{" "}
                                      <span className="fw-normal text-secondary"></span>
                                    </Form.Label>

                                    <JoditEditor
                                      ref={editor21}
                                      value={balanceReduceEmail2}
                                      onBlur={(e) => setBalanceReduceEmail2(e)}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="font16 text-dark fw-medium">
                                      Mail Body 3{" "}
                                      <span className="fw-normal text-secondary"></span>
                                    </Form.Label>

                                    <JoditEditor
                                      ref={editor22}
                                      value={balanceReduceEmail3}
                                      onBlur={(e) => setBalanceReduceEmail3(e)}
                                    />
                                  </Form.Group>
                                </Col>
                                {/*working here */}
                                <>
                                  <div className="d-flex">
                                    <div className="flex-fill">
                                      <h1 className="font16 text-dark mb-1">
                                        Upload Email Icon{" "}
                                      </h1>
                                      <div className="d-flex">
                                        <div>
                                          <Button
                                            className="me-2"
                                            variant="light"
                                          >
                                            <ReactFileReader
                                              handleFiles={handleEmailIcon5}
                                              fileTypes={".png,.jpg"}
                                            >
                                              {logo5 ? (
                                                <div
                                                  className={styles.emaillogos}
                                                >
                                                  {/* <img src='https://res.cloudinary.com/klizo-solutions/image/upload/v1662394148/ncentiva/Email-templates/Check_2_-_16pxtick_sk9c93.png' alt="" /> */}
                                                  <img src={logo5} alt="" />
                                                </div>
                                              ) : (
                                                <FooterICon />
                                              )}
                                            </ReactFileReader>
                                          </Button>
                                          {balanceReduceIconText &&
                                            logoname5 && (
                                              <p className="mb-0 text-danger font14 bottom-0 w-100 text-center">
                                                {logoname5}{" "}
                                                <span
                                                  className="text-dark"
                                                  onClick={() => {
                                                    setLogoname5("");
                                                    setLogo5("");
                                                  }}
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  x
                                                </span>
                                              </p>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <h1 className="font16 text-dark fw-medium mb-2">
                                    Email Header Image
                                  </h1>
                                  <Container
                                    fluid
                                    className="p-4 rounded shadow position-relative"
                                  >
                                    {emailHeaderColor4 ? (
                                      <div
                                        style={{
                                          backgroundImage: `linear-gradient(93.59deg,${balanceReduceEmailColorCombination},${balanceReduceEmailColorCombination1})`,
                                          width: "100%",
                                          height: "250px",
                                        }}
                                        className={styles.headDiv}
                                      ></div>
                                    ) : (
                                      <>
                                        {image6 ? (
                                          <img
                                            className={styles.headimage}
                                            src={image6}
                                            alt=""
                                            width={"100%"}
                                            height={"250px"}
                                          />
                                        ) : (
                                          <img
                                            className={styles.headimage}
                                            src={bannerImage}
                                            alt=""
                                            width={"100%"}
                                            height={"250px"}
                                          />
                                        )}
                                      </>
                                    )}

                                    <div className={styles.headInput}>
                                      <Button
                                        variant="primary"
                                        style={{ width: "158px" }}
                                        className="fullRounded px-4 py-2 font14 me-2 fw-semibold fs-6"
                                      >
                                        <ReactFileReader
                                          handleFiles={handleFiless4}
                                          fileTypes={".png,.jpg"}
                                        >
                                          {" "}
                                          <div className="d-flex align-items-center justify-content-center">
                                            <FaCloudUploadAlt />
                                            <span className="ms-2">
                                              Upload File
                                            </span>
                                          </div>{" "}
                                        </ReactFileReader>
                                      </Button>
                                      {fileNameEmail4 && (
                                        <p className="mb-0 text-danger font16 bottom-0 w-100 text-center">
                                          {fileNameEmail4}{" "}
                                          <span
                                            className="text-dark "
                                            onClick={() => {
                                              setFilenameEmail4("");
                                              setImage6("");
                                            }}
                                            style={{ cursor: "pointer" }}
                                          >
                                            x
                                          </span>
                                        </p>
                                      )}
                                      <ModalColor4
                                        homeBannerColor={emailHeaderColor4}
                                        setHomeBannerColor={
                                          setEmailHeaderColor4
                                        }
                                        setImage={setImage6}
                                        setFilename={setFilenameEmail4}
                                        primaryColor={primaryColor}
                                        secondaryColor={secondaryColor}
                                        setHomeBannerColorCombination={
                                          setBalanceReduceEmailColorCombination
                                        }
                                        setHomeBannerColorCombination1={
                                          setBalanceReduceEmailColorCombination1
                                        }
                                        homeBannerColorCombination={
                                          balanceReduceEmailColorCombination
                                        }
                                        homeBannerColorCombination1={
                                          balanceReduceEmailColorCombination1
                                        }
                                      />
                                    </div>
                                  </Container>
                                </>
                              </Row>
                            </Form>

                            <div className="d-flex justify-content-end mt-3 px-2">
                              <Button onClick={handleShowRemoveFundMail}>
                                Preview
                              </Button>
                            </div>
                          </Row>
                        </Container>
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                  {isLoading ? (
                    <Button
                      variant="primary"
                      className="fullRounded px-4 px-lg-3 px-xl-4 py-2 fw-bold font12"
                      style={{ width: "133.75px" }}
                    >
                      <span>Proceeding...</span>
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      onClick={() => {
                        handlePost("mailing");
                      }}
                      className="fullRounded px-4 px-lg-3 px-xl-4 py-2 fw-bold font12 my-2"
                      style={{ width: "133.75px" }}
                    >
                      <span>Save</span>
                    </Button>
                  )}
                </>
              ) : (
                ""
              )}

              <div>
                <Container
                  fluid
                  className="mt-3 px-4 py-2 rounded shadow"
                  style={{ backgroundColor: "#FF7020" }}
                  onClick={handlepdfed}
                >
                  <div className={styles.homepagehead}>
                    <h1 className="font20 text-light ">Advertisement</h1>
                    {pdfHandle ? (
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="40"
                          height="40"
                          rx="8"
                          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 40 40)"
                          fill="white"
                        />
                        <path
                          d="M20 27L20 13"
                          stroke="#4C00EF"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M27 20L20 13L13 20"
                          stroke="#4C00EF"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    ) : (
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="40"
                          height="40"
                          rx="8"
                          transform="matrix(1.19249e-08 1 1 -1.19249e-08 0 0)"
                          fill="white"
                        />
                        <path
                          d="M20 13L20 27"
                          stroke="#4C00EF"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M13 20L20 27L27 20"
                          stroke="#4C00EF"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                  </div>
                </Container>
                {pdfHandle ? (
                  <>
                    <div className="d-flex mt-3">
                      <div className={styles.dot}></div>
                      <h1 className="font20 mb-3 text-dark ">
                        Upload Advertisement
                      </h1>
                    </div>

                    <Container
                      fluid
                      className="mt-2 mb-3"
                      style={{
                        backgroundColor: "white",
                        boxShadow: "0px 8px 16px 0px grey",
                        borderRadius: "5px",
                      }}
                    >
                      <Row>
                        <Form className="bg-white p-4 rounded containerShadow">
                          <Row>
                            <>
                              <h1 className="font16 text-dark fw-medium mb-2">
                                Upload Advertisement
                              </h1>
                              <div className={styles.container}>
                                {/* className={styles.headInput} */}
                                <Container
                                  fluid
                                  className=" rounded shadow position-relative"
                                >
                                  {/* style={{ backgroundImage: `url(${pdfimagePreview})`, backgroundSize: '100% 100%',backgroundRepeat:'no-repeat',width:'100%', height:'100%'}} */}
                                  <div
                                    className="d-flex align-items-center justify-content-center flex-column"
                                    style={{ width: "100%", height: "100%" }}
                                  >
                                    <label
                                      htmlFor="file"
                                      className={styles.formContainer}
                                    >
                                      <Button
                                        className="  fileReader_style font14 me-2 fw-semibold fs-6 d-flex align-items-center justify-content-center"
                                        style={{
                                          background: "white",
                                          border: "none",
                                          width: "100%",
                                          height: "100%",
                                          padding: "0",
                                        }}
                                      >
                                        <ReactFileReader
                                          handleFiles={handlePdf}
                                          fileTypes={[
                                            ".pdf",
                                            ".png",
                                            ".jpg",
                                            ".jpeg",
                                            ".gif",
                                          ]}
                                          style={{ position: "absolute" }}
                                        ></ReactFileReader>
                                        <div className="d-flex align-items-center justify-content-center flex-column">
                                          <img
                                            src="/assets/images/upload.svg"
                                            alt="i"
                                          />
                                          <span className="ms-2">
                                            Upload Advertisement
                                          </span>
                                        </div>
                                      </Button>
                                    </label>
                                    {pdfName && (
                                      <div className={styles.fileSelected}>
                                        <p className="mb-0 text-danger font16 bottom-0 w-100 text-center">
                                          {pdfName}{" "}
                                          <span className="arrow">
                                            <img src={okIMG} alt="" />
                                          </span>
                                        </p>
                                      </div>
                                    )}
                                    {pdfName?.length == 0 &&
                                      pdfPreviewApi &&
                                      pdfFile == "" && (
                                        <div
                                          className=""
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "18px",
                                          }}
                                        >
                                          {pdfPreview.length === 0 ? (
                                            ""
                                          ) : (
                                            <p className=" mb-0 text-danger font16 bottom-0 w-100 text-center">
                                              Promotional.pdf
                                            </p>
                                          )}
                                        </div>
                                      )}

                                    {imageName && (
                                      <div className={styles.fileSelected}>
                                        <p className="mb-0 text-danger font16 bottom-0 w-100 text-center">
                                          {imageName}{" "}
                                          <span className="arrow">
                                            <img src={okIMG} alt="" />
                                          </span>
                                        </p>
                                      </div>
                                    )}

                                    {imageName?.length == 0 ? (
                                      pdfName?.length > 0 ||
                                      pdfimagePreview?.length === 0 ? (
                                        ""
                                      ) : (
                                        <p className="mb-0 text-danger font16 bottom-0 w-100 text-center">
                                          Promotional.png
                                        </p>
                                      )
                                    ) : (
                                      ""
                                    )}

                                    {/* <ModalColor homeBannerColor={emailHeaderColor} setHomeBannerColor={setEmailHeaderColor} setImage={setImage} setFilename={setFilenameEmail} primaryColor={primaryColor} secondaryColor={secondaryColor} setHomeBannerColorCombination={setBalanceReduceEmailColorCombination} setHomeBannerColorCombination1={setBalanceReduceEmailColorCombination1} homeBannerColorCombination={balanceReduceEmailColorCombination} homeBannerColorCombination1={balanceReduceEmailColorCombination1} /> */}
                                  </div>
                                </Container>
                              </div>
                              <div className="d-flex justify-content-end align-items-center py-1">
                                <Button
                                  type="button"
                                  className="btn btn-secondary"
                                  style={{ marginRight: "10px" }}
                                  onClick={resetInfo}
                                >
                                  Clear
                                </Button>
                                <div className="py-1">
                                  {pdfPreview.length > 0 ||
                                  pdfimagePreview.length > 0 ? (
                                    <Button onClick={handleShow}>
                                      Preview
                                    </Button>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </>
                          </Row>
                        </Form>
                      </Row>

                      <div className="d-flex align-items-center justify-content-center">
                        <Modal show={show} centered>
                          {/* <Modal.Header closeButton>
          
        </Modal.Header> */}
                          <Modal.Body
                            style={{
                              backgroundColor: primaryColor,
                              border: "2px solid",
                              margin: "0",
                              padding: "3px",
                              borderRadius: "8px",
                            }}
                          >
                            {pdfFile?.length > 0 ? (
                              <div
                                style={{ width: "100%" }}
                                className="scroll-modal d-flex align-items-center justify-content-center"
                              >
                                <img
                                  className="pdf-viewer-container img-thumbnail position_relative"
                                  src={`data:image/png;base64,${pdfFile}`}
                                  alt="pdfimage"
                                  //  style={{ width: '100%', height: '900px', zoom: '100%', overflowX: 'hidden' }}
                                />
                                <div className="button_closed">
                                  <p
                                    style={{
                                      backgroundColor: primaryColor,
                                      border: `2px dotted ${primaryColor}`,
                                    }}
                                    className="  button_style"
                                    onClick={handleClose}
                                  >
                                    X
                                  </p>
                                </div>
                              </div>
                            ) : (
                              <div
                                style={{ width: "100%" }}
                                className="scroll-modal d-flex align-items-center justify-content-center"
                              >
                                <img
                                  className="pdf-viewer-container img-thumbnail position_relative"
                                  src={pdfimagePreview}
                                  alt="pdfimage"
                                  //  style={{ width: '100%', height: '900px', zoom: '100%', overflowX: 'hidden' }}
                                />
                                <div className="button_closed">
                                  <p
                                    style={{
                                      backgroundColor: primaryColor,
                                      border: `2px dotted ${primaryColor}`,
                                    }}
                                    className="  button_style"
                                    onClick={handleClose}
                                  >
                                    X
                                  </p>
                                </div>
                              </div>
                            )}
                            <style>
                              {`
              .scroll-modal::-webkit-scrollbar {
                width: 6px;
                height: 4px;
              }
              .scroll-modal::-webkit-scrollbar-thumb {
                background-color: ${secondaryColor};
                border-radius: 6px;
                
              }
              .scroll-modal::-webkit-scrollbar-track {
                background-color: transparent;
              }
            `}
                            </style>

                            <div>{error && <p>Error: {error.message}</p>}</div>
                          </Modal.Body>
                        </Modal>
                      </div>
                    </Container>

                    {isLoading ? (
                      <Button
                        variant="primary"
                        className="fullRounded px-4 px-lg-3 px-xl-4 py-2 fw-bold font12"
                        style={{ width: "133.75px" }}
                      >
                        <span>Proceeding...</span>
                      </Button>
                    ) : (
                      <Button
                        variant="primary"
                        onClick={() => {
                          handlePost("advertisement");
                        }}
                        className="fullRounded px-4 px-lg-3 px-xl-4 py-2 fw-bold font12 my-2"
                        style={{ width: "133.75px" }}
                      >
                        <span>Save</span>
                      </Button>
                    )}
                  </>
                ) : (
                  ""
                )}

                {/* ?.replace(/<\/?(p|strong|em|u)>/g, '') */}

                {/* home page email preview */}
                <div className="d-flex align-items-center justify-content-center">
                  <Modal show={showWelcomeMail} centered size="lg">
                    <Modal.Body
                      style={{
                        backgroundColor: primaryColor,
                        border: "2px solid",
                        margin: "0",
                        padding: "3px",
                        borderRadius: "8px",
                        // width: "800px",
                      }}
                      className="custom-modal-res"
                    >
                      <div
                        style={{ width: "100%" }}
                        className="scroll-modal d-flex align-items-center justify-content-center "
                      >
                        <div style={{ background: "white", padding: "1rem" }}>
                          <div className="py-2">
                            <div>
                              {emailHeaderColor ? (
                                <div
                                  style={{
                                    backgroundImage: `linear-gradient(93.59deg,${welcomeEmailColorCombination},${welcomeEmailColorCombination1})`,
                                    width: "100%",
                                    height: "250px",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                  className={styles.headDiv}
                                >
                                  <span>
                                    {logo1 && (
                                      <img
                                        src={logo1}
                                        alt="welcome header logo "
                                        style={{
                                          width: "100px",
                                          height: "100px",
                                        }}
                                      />
                                    )}
                                  </span>
                                  <p>{welcomeEmailHeadModal}</p>
                                </div>
                              ) : (
                                <>
                                  {image2 ? (
                                    <div
                                      className={styles.headDiv}
                                      style={{
                                        backgroundImage: `url(${image2})`,
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        width: "100%",
                                        height: "250px",
                                        border: "1px solid black",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <span>
                                        {logo1 && (
                                          <img
                                            src={logo1}
                                            alt="welcome header logo "
                                            style={{
                                              width: "100px",
                                              height: "100px",
                                            }}
                                          />
                                        )}
                                      </span>
                                      <p>{welcomeEmailHeadModal}</p>
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        backgroundImage: `linear-gradient(93.59deg,${welcomeEmailColorCombination},${welcomeEmailColorCombination1})`,
                                        width: "100%",
                                        height: "250px",
                                        border: "1px solid black",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                      className={styles.headDiv}
                                    >
                                      <span>
                                        {logo1 && (
                                          <img
                                            src={logo1}
                                            alt="welcome header logo "
                                            style={{
                                              width: "100px",
                                              height: "100px",
                                            }}
                                          />
                                        )}
                                      </span>
                                      <p>{welcomeEmailHeadModal}</p>
                                    </div>
                                  )}
                                </>
                              )}
                            </div>

                            <hr />
                            <p className="py-2">{welcomeEmailModal}</p>

                            <p className="py-2">{welcomeEmail1Modal}</p>

                            <p className="py-2">{welcomeEmail2Modal}</p>

                            <span className="d-flex">
                              <h6>Email :</h6> example@example.com
                            </span>
                            <span className="d-flex">
                              <h6>Password :</h6> password{" "}
                            </span>
                            <div className="py-4">
                              <button
                                style={{
                                  backgroundColor: primaryColor,
                                  color: "white",
                                  border: "0px",
                                  padding: "7px 15px",
                                  borderRadius: "999px",
                                }}
                              >
                                Go to {brand_name ? brand_name : "Brand"}
                              </button>
                            </div>
                          </div>
                          <hr />
                          <div className="d-flex justify-content-center">
                            <p>
                              If you have any questions, don't hesitate to
                              contact us at{" "}
                              <span style={{ color: "#4221e5" }}>
                                support@ncentiva.com
                              </span>
                            </p>
                          </div>

                          <div className="d-flex justify-content-center py-4">
                            <p>powered by: </p>
                            <img
                              src={`${process.env.REACT_APP_URL}/assets/images/footer-logo.png`}
                              alt=""
                              style={{ width: "auto", height: "34px" }}
                            />
                          </div>
                        </div>

                        <div className="button_closed_modal">
                          <p
                            style={{
                              backgroundColor: primaryColor,
                              border: `2px dotted ${primaryColor}`,
                            }}
                            className="  button_style"
                            onClick={handleCloseShowWelcomeMail}
                          >
                            X
                          </p>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
                {/* home page email preview */}

                {/* home page order email preview */}
                <div className="d-flex align-items-center justify-content-center">
                  <Modal show={showOrderMail} centered size="lg">
                    <Modal.Body
                      style={{
                        backgroundColor: primaryColor,
                        border: "2px solid",
                        margin: "0",
                        padding: "3px",
                        borderRadius: "8px",
                        // width: "800px",
                      }}
                      className="custom-modal-res"
                    >
                      <div
                        style={{ width: "100%" }}
                        className="scroll-modal d-flex align-items-center justify-content-center "
                      >
                        <div style={{ background: "white", padding: "1rem" }}>
                          {/* <p>order:- {welcomeEmailSubject}</p> */}
                          <div className="py-2">
                            <div>
                              {emailHeaderColor1 ? (
                                <div
                                  style={{
                                    backgroundImage: `linear-gradient(93.59deg,${orderEmailColorCombination},${orderEmailColorCombination1})`,
                                    width: "100%",
                                    height: "250px",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                  className={styles.headDiv}
                                >
                                  <span>
                                    {logo2 && (
                                      <img
                                        src={logo2}
                                        alt="order header logo "
                                        style={{
                                          width: "100px",
                                          height: "100px",
                                        }}
                                      />
                                    )}
                                  </span>
                                  <p> {messageEmailHeadModal}</p>
                                </div>
                              ) : (
                                <>
                                  {image3 ? (
                                    <div
                                      className={styles.headDiv}
                                      style={{
                                        backgroundImage: `url(${image3})`,
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        width: "100%",
                                        height: "250px",
                                        border: "1px solid black",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <span>
                                        {logo2 && (
                                          <img
                                            src={logo2}
                                            alt="order header logo "
                                            style={{
                                              width: "100px",
                                              height: "100px",
                                            }}
                                          />
                                        )}
                                      </span>
                                      <p>{messageEmailHeadModal}</p>
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        backgroundImage: `linear-gradient(93.59deg,${orderEmailColorCombination},${orderEmailColorCombination1})`,
                                        width: "100%",
                                        height: "250px",
                                        border: "1px solid black",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                      className={styles.headDiv}
                                    >
                                      <span>
                                        {logo2 && (
                                          <img
                                            src={logo2}
                                            alt="order header logo "
                                            style={{
                                              width: "100px",
                                              height: "100px",
                                            }}
                                          />
                                        )}
                                      </span>
                                      <p>{messageEmailHeadModal}</p>
                                    </div>
                                  )}
                                </>
                              )}
                            </div>

                            <hr />
                            <p className="py-2">{messageTopicEmailModal}</p>

                            <p className="py-2">{messageTopicEmail1Modal}</p>

                            <h6>SUMMARY OF ORDER:</h6>

                            <hr />
                            <div className="d-flex justify-content-between">
                              <p>Funds used in Giftcard(s)</p> <p>$ xx.xx</p>{" "}
                            </div>
                            <div
                              className="d-flex justify-content-between"
                              style={{ color: "#4221e5" }}
                            >
                              <p>Total Bonus</p> <p>$ xx.xx</p>{" "}
                            </div>
                            <div
                              className="d-flex justify-content-between"
                              style={{ color: "rgb(245, 196, 0)" }}
                            >
                              <p>Total Bonus Cash</p> <p>$ xx.xx</p>{" "}
                            </div>
                          </div>
                          <hr />
                          <div className="d-flex justify-content-center flex-column">
                            <div>
                              <table style={{ width: "100%" }}>
                                <tr>
                                  <th className="text-center">Brand</th>
                                  <th className="text-center">Delivery In</th>
                                  <th className="text-center">Qty</th>
                                  <th className="text-center">
                                    Gift Card Cost
                                  </th>
                                  <th className="text-center">
                                    Gift Card Value
                                  </th>
                                </tr>
                                <tr>
                                  <td className="text-center">xxxx</td>
                                  <td className="text-center">
                                    Within 30 minutes
                                  </td>
                                  <td className="text-center">1</td>
                                  <td className="text-center">$ xx.xx</td>
                                  <td className="text-center">$ xx.xx</td>
                                </tr>
                              </table>
                            </div>
                            <div className="py-4 d-flex justify-content-center">
                              <p>
                                If you have any questions, don't hesitate to
                                contact us at{" "}
                                <span style={{ color: "#4221e5" }}>
                                  support@ncentiva.com
                                </span>
                              </p>
                            </div>
                          </div>

                          <div className="d-flex justify-content-center py-4">
                            <p>powered by: </p>
                            <img
                              src={`${process.env.REACT_APP_URL}/assets/images/footer-logo.png`}
                              alt=""
                              style={{ width: "auto", height: "34px" }}
                            />
                          </div>
                        </div>

                        <div className="button_closed_modal">
                          <p
                            style={{
                              backgroundColor: primaryColor,
                              border: `2px dotted ${primaryColor}`,
                            }}
                            className="  button_style"
                            onClick={handleCloseShowOrderMail}
                          >
                            X
                          </p>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
                {/* home page order email preview */}

                {/* home page refund email preview */}
                <div className="d-flex align-items-center justify-content-center">
                  <Modal show={showRefundMail} centered size="lg">
                    <Modal.Body
                      style={{
                        backgroundColor: primaryColor,
                        border: "2px solid",
                        margin: "0",
                        padding: "3px",
                        borderRadius: "8px",
                        // width: "800px",
                      }}
                      className="custom-modal-res"
                    >
                      <div
                        style={{ width: "100%" }}
                        className="scroll-modal d-flex align-items-center justify-content-center "
                      >
                        <div style={{ background: "white", padding: "1rem" }}>
                          <div className="py-2">
                            <div>
                              {emailHeaderColor2 ? (
                                <div
                                  style={{
                                    backgroundImage: `linear-gradient(93.59deg,${refundEmailColorCombination},${refundEmailColorCombination1})`,
                                    width: "100%",
                                    height: "250px",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                  className={styles.headDiv}
                                >
                                  <span>
                                    {logo3 && (
                                      <img
                                        src={logo3}
                                        alt="welcome header logo "
                                        style={{
                                          width: "100px",
                                          height: "100px",
                                        }}
                                      />
                                    )}
                                  </span>
                                  <p>{refundEmailSubjectModal}</p>
                                </div>
                              ) : (
                                <>
                                  {image4 ? (
                                    <div
                                      className={styles.headDiv}
                                      style={{
                                        backgroundImage: `url(${image4})`,
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        width: "100%",
                                        height: "250px",
                                        border: "1px solid black",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <span>
                                        {logo3 && (
                                          <img
                                            src={logo3}
                                            alt="welcome header logo "
                                            style={{
                                              width: "100px",
                                              height: "100px",
                                            }}
                                          />
                                        )}
                                      </span>
                                      <p>{refundEmailSubjectModal}</p>
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        backgroundImage: `linear-gradient(93.59deg,${refundEmailColorCombination},${refundEmailColorCombination1})`,
                                        width: "100%",
                                        height: "250px",
                                        border: "1px solid black",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                      className={styles.headDiv}
                                    >
                                      <span>
                                        {logo3 && (
                                          <img
                                            src={logo3}
                                            alt="welcome header logo "
                                            style={{
                                              width: "100px",
                                              height: "100px",
                                            }}
                                          />
                                        )}
                                      </span>
                                      <p>{refundEmailSubjectModal}</p>
                                    </div>
                                  )}
                                </>
                              )}
                            </div>

                            <hr />
                            <p className="py-2">{refundEmailModal}</p>

                            <p className="py-2">{refundEmail1Modal}</p>

                            <div
                              className="d-flex justify-content-between"
                              style={{ color: "#4221e5" }}
                            >
                              <p>Successfull Gift card(s)</p>
                            </div>
                            <div>
                              <table className="" style={{ width: "100%" }}>
                                <tr>
                                  <th className="text-center">
                                    {" "}
                                    <h6>Brand</h6>
                                  </th>
                                  <th className="text-center">
                                    <h6>Qty</h6>{" "}
                                  </th>
                                  <th className="text-center">
                                    <h6>Gift Card Cost</h6>{" "}
                                  </th>
                                  <th className="text-center">
                                    <h6>Gift Card Value</h6>{" "}
                                  </th>
                                </tr>
                                <tr>
                                  <td className="text-center">xxxx</td>
                                  <td className="text-center">1231</td>
                                  <td className="text-center">$ xx.xx</td>
                                  <td className="text-center">$ xx.xx</td>
                                </tr>
                              </table>
                            </div>
                            <hr />
                            <div
                              className="d-flex justify-content-between"
                              style={{ color: "rgb(245, 196, 0)" }}
                            >
                              <p>Failed Gift card(s)</p>
                            </div>
                            <div>
                              <table style={{ width: "100%" }}>
                                <tr>
                                  <th className="text-center">
                                    {" "}
                                    <h6>Brand</h6>
                                  </th>
                                  <th className="text-center">
                                    <h6>Qty</h6>{" "}
                                  </th>
                                  <th className="text-center">
                                    <h6>Gift Card Cost</h6>{" "}
                                  </th>
                                  <th className="text-center">
                                    <h6>Gift Card Value</h6>{" "}
                                  </th>
                                </tr>
                                <tr>
                                  <td className="text-center">xxxx</td>
                                  <td className="text-center">1</td>
                                  <td className="text-center">$ xx.xx</td>
                                  <td className="text-center">$ xx.xx</td>
                                </tr>
                              </table>
                            </div>
                          </div>
                          <hr />
                          <div className="d-flex justify-content-center">
                            <p>
                              If you have any questions, don't hesitate to
                              contact us at{" "}
                              <span style={{ color: "#4221e5" }}>
                                support@ncentiva.com
                              </span>
                            </p>
                          </div>

                          <div className="d-flex justify-content-center py-4">
                            <p>powered by: </p>
                            <img
                              src={`${process.env.REACT_APP_URL}/assets/images/footer-logo.png`}
                              alt=""
                              style={{ width: "auto", height: "34px" }}
                            />
                          </div>
                        </div>

                        <div className="button_closed_modal">
                          <p
                            style={{
                              backgroundColor: primaryColor,
                              border: `2px dotted ${primaryColor}`,
                            }}
                            className="  button_style"
                            onClick={handleCloseShowrefundMail}
                          >
                            X
                          </p>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
                {/* home page refund email preview */}

                {/* home page add fund email preview */}
                <div className="d-flex align-items-center justify-content-center">
                  <Modal show={showAddfundMail} centered size="lg">
                    <Modal.Body
                      style={{
                        backgroundColor: primaryColor,
                        border: "2px solid",
                        margin: "0",
                        padding: "3px",
                        borderRadius: "8px",
                        // width: "800px",
                      }}
                      className="custom-modal-res"
                    >
                      <div
                        style={{ width: "100%" }}
                        className="scroll-modal d-flex align-items-center justify-content-center "
                      >
                        <div style={{ background: "white", padding: "1rem" }}>
                          <div className="py-2">
                            <div>
                              {emailHeaderColor3 ? (
                                <div
                                  style={{
                                    backgroundImage: `linear-gradient(93.59deg,${balanceEmailColorCombination},${balanceEmailColorCombination1})`,
                                    width: "100%",
                                    height: "250px",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                  className={styles.headDiv}
                                >
                                  <span>
                                    {logo4 && (
                                      <img
                                        src={logo4}
                                        alt="welcome header logo "
                                        style={{
                                          width: "100px",
                                          height: "100px",
                                        }}
                                      />
                                    )}
                                  </span>
                                  <p>{balanceEmailHeadModal}</p>
                                </div>
                              ) : (
                                <>
                                  {image5 ? (
                                    <div
                                      className={styles.headDiv}
                                      style={{
                                        backgroundImage: `url(${image5})`,
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        width: "100%",
                                        height: "250px",
                                        border: "1px solid black",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <span>
                                        {logo4 && (
                                          <img
                                            src={logo4}
                                            alt="welcome header logo "
                                            style={{
                                              width: "100px",
                                              height: "100px",
                                            }}
                                          />
                                        )}
                                      </span>
                                      <p>{balanceEmailHeadModal}</p>
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        backgroundImage: `linear-gradient(93.59deg,${balanceEmailColorCombination},${balanceEmailColorCombination1})`,
                                        width: "100%",
                                        height: "250px",
                                        border: "1px solid black",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                      className={styles.headDiv}
                                    >
                                      <span>
                                        {logo4 && (
                                          <img
                                            src={logo4}
                                            alt="welcome header logo "
                                            style={{
                                              width: "100px",
                                              height: "100px",
                                            }}
                                          />
                                        )}
                                      </span>
                                      <p>{balanceEmailHeadModal}</p>
                                    </div>
                                  )}
                                </>
                              )}
                            </div>

                            <hr />
                            <p className="py-2">{balanceEmail1Modal}</p>

                            <p className="py-2">{balanceEmail2Modal}</p>

                            <p className="py-2">{balanceEmail3Modal}</p>

                            <span className="d-flex">
                              <h6>Email :</h6> example@example.com
                            </span>
                            <span className="d-flex">
                              <h6>Password :</h6> password{" "}
                            </span>
                            <div className="py-4">
                              <button
                                style={{
                                  backgroundColor: primaryColor,
                                  color: "white",
                                  border: "0px",
                                  padding: "7px 15px",
                                  borderRadius: "999px",
                                }}
                              >
                                Go to {brand_name ? brand_name : "Brand"}
                              </button>
                            </div>
                          </div>
                          <hr />
                          <div className="d-flex justify-content-center">
                            <p>
                              If you have any questions, don't hesitate to
                              contact us at{" "}
                              <span style={{ color: "#4221e5" }}>
                                support@ncentiva.com
                              </span>
                            </p>
                          </div>

                          <div className="d-flex justify-content-center py-4">
                            <p>powered by: </p>
                            <img
                              src={`${process.env.REACT_APP_URL}/assets/images/footer-logo.png`}
                              alt=""
                              style={{ width: "auto", height: "34px" }}
                            />
                          </div>
                        </div>

                        <div className="button_closed_modal">
                          <p
                            style={{
                              backgroundColor: primaryColor,
                              border: `2px dotted ${primaryColor}`,
                            }}
                            className="  button_style"
                            onClick={handleCloseAddFundMail}
                          >
                            X
                          </p>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
                {/* home page add fund email preview */}

                {/* home page Reduce fund email preview */}
                <div className="d-flex align-items-center justify-content-center">
                  <Modal show={showReducefundMail} centered size="lg">
                    <Modal.Body
                      style={{
                        backgroundColor: primaryColor,
                        border: "2px solid",
                        margin: "0",
                        padding: "3px",
                        borderRadius: "8px",
                        // width: "800px",
                      }}
                      className="custom-modal-res"
                    >
                      <div
                        style={{ width: "100%" }}
                        className="scroll-modal d-flex align-items-center justify-content-center "
                      >
                        <div style={{ background: "white", padding: "1rem" }}>
                          <div className="py-2">
                            <div>
                              {emailHeaderColor4 ? (
                                <div
                                  style={{
                                    backgroundImage: `linear-gradient(93.59deg,${balanceReduceEmailColorCombination},${balanceReduceEmailColorCombination1})`,
                                    width: "100%",
                                    height: "250px",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                  className={styles.headDiv}
                                >
                                  <span>
                                    {logo5 && (
                                      <img
                                        src={logo5}
                                        alt="welcome header logo "
                                        style={{
                                          width: "100px",
                                          height: "100px",
                                        }}
                                      />
                                    )}
                                  </span>
                                  <p>{balanceReduceEmailHeadModal}</p>
                                </div>
                              ) : (
                                <>
                                  {image6 ? (
                                    <div
                                      className={styles.headDiv}
                                      style={{
                                        backgroundImage: `url(${image6})`,
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        width: "100%",
                                        height: "250px",
                                        border: "1px solid black",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <span>
                                        {logo5 && (
                                          <img
                                            src={logo5}
                                            alt="welcome header logo "
                                            style={{
                                              width: "100px",
                                              height: "100px",
                                            }}
                                          />
                                        )}
                                      </span>
                                      <p>{balanceReduceEmailHeadModal}</p>
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        backgroundImage: `linear-gradient(93.59deg,${balanceReduceEmailColorCombination},${balanceReduceEmailColorCombination1})`,
                                        width: "100%",
                                        height: "250px",
                                        border: "1px solid black",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                      className={styles.headDiv}
                                    >
                                      <span>
                                        {logo5 && (
                                          <img
                                            src={logo5}
                                            alt="welcome header logo "
                                            style={{
                                              width: "100px",
                                              height: "100px",
                                            }}
                                          />
                                        )}
                                      </span>
                                      <p>{balanceReduceEmailHeadModal}</p>
                                    </div>
                                  )}
                                </>
                              )}
                            </div>

                            <hr />
                            <p className="py-2">{balanceReduceEmail1Modal}</p>

                            <p className="py-2">{balanceReduceEmail2Modal}</p>

                            <p className="py-2">{balanceReduceEmail3Modal}</p>

                            <button
                              style={{
                                backgroundColor: primaryColor,
                                color: "white",
                                border: "0px",
                                padding: "7px 15px",
                                borderRadius: "999px",
                              }}
                            >
                              Go to {brand_name ? brand_name : "Brand"}
                            </button>
                          </div>
                          <hr />
                          <div className="d-flex justify-content-center">
                            <p>
                              If you have any questions, don't hesitate to
                              contact us at{" "}
                              <span style={{ color: "#4221e5" }}>
                                support@ncentiva.com
                              </span>
                            </p>
                          </div>

                          <div className="d-flex justify-content-center py-4">
                            <p>powered by: </p>
                            <img
                              src={`${process.env.REACT_APP_URL}/assets/images/footer-logo.png`}
                              alt=""
                              style={{ width: "auto", height: "34px" }}
                            />
                          </div>
                        </div>

                        <div className="button_closed_modal">
                          <p
                            style={{
                              backgroundColor: primaryColor,
                              border: `2px dotted ${primaryColor}`,
                            }}
                            className="  button_style"
                            onClick={handleCloseRemoveFundMail}
                          >
                            X
                          </p>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
                {/* home page reduce fund email preview */}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Customize;
