import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { PieChart } from 'react-minimal-pie-chart';
import "../../../src/App.scss"
import { toast } from "react-toastify";
import { getNewPartnerToken } from "../../config/cookie.config";

function Businessdashboard() {

    const businessPartner = JSON.parse(window.localStorage.getItem('business-client'));
    let userrole;
    let usersid;
    let navigate = useNavigate();
    if (businessPartner) {
        userrole = businessPartner?.user?.role;
        usersid = businessPartner?.user?._id;
    } else {
        userrole = null;
        usersid = null;
        localStorage.removeItem('business-client')
        navigate("/business-login");
    }


    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [bpartner, setBpartner] = useState([]);
    const [totalFunds, setTotalFunds] = useState('');
    const [allTransaction, setAllTransaction] = useState(false);
    const [allCards, setAllCards] = useState(false);
    const [transactionCsvData, setTransactionCsvData] = useState([]);
    const [topSellCarts, setTopSellCarts] = useState([]);
    const [latsMonthsTopSellCarts, setLatsMonthsTopSellCarts] = useState([]);
    const [isClickCarts, setISClickCarts] = useState(false);

    const id = businessPartner?.user?._id;

    const parseCSV = (csv) => {
        const lines = csv.split('\n');
        const headers = lines[0].split(',');
        return lines.slice(1).map(line => {
            const values = line.split(',');
            const obj = {};
            headers.forEach((header, index) => {
                obj[header] = values[index];
            });
            return obj;
        });
    };

    useEffect(() => {
        setIsLoading(true)
        let token;
        if (businessPartner) {
            token = businessPartner.token;
        }
        else {
            token = null;
        }
        // if (token) {

            axios.get(` ${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/dashboard/details/${id}`, {
                headers: {
                    'Authorization': `Bearer ${getNewPartnerToken()}`
                }
            })
                .then((response) => {
                    if (response?.data?.message == "Please Login") {
                        localStorage.removeItem('business-client');
                        navigate('/business-login');
                        toast.error("Session Expired Please Login Again")
                    }
                    setTopSellCarts(response.data.topSellingGiftCards)
                    setLatsMonthsTopSellCarts(response.data.lastMonthTopSellingGiftCards)
                    setBpartner(response.data);
                    const parsedData = parseCSV(response.data.csvData);
                    setTransactionCsvData(parsedData)
                    setData([
                        { value: (+response?.data?.percentageOfActiveCustomer), color: '#F5C400' },
                        { value: (100 - (+response?.data?.percentageOfActiveCustomer)), color: '#FF7020' }
                    ])
                    if (response.data.businessPartnes.credit_amount !== "NaN" && response.data.businessPartnes.credit_amount !== "0") {
                        setTotalFunds(response.data.businessPartnes.credit_amount)
                    } else if (response.data.businessPartnes.debit_amount !== "NaN" && response.data.businessPartnes.debit_amount !== "0") {
                        setTotalFunds(response.data.businessPartnes.debit_amount)
                    } else {
                        setTotalFunds('Invoice')
                    }
                    setIsLoading(false);
                })
                .catch((error) => {
                    if (error?.response?.data?.message === "Unauthorized User") {
                        localStorage.removeItem('business-client');
                        navigate('/business-login');
                        toast.error("Session Expired Please Login Again")
                    }
                    console.error(`Error: ${error}`);
                    setIsLoading(false);
                });

        // } else {
        //     token = null;
        // }



    }, [])


    //  Function to convert JSON to CSV format
    const convertToCSV = (transactionCsvData) => {
        if (!Array.isArray(transactionCsvData) || transactionCsvData.length === 0) {
            return '';
        }
        const header = Object.keys(transactionCsvData[0]).join(',') + '\n';
        const rows = transactionCsvData.map(row => Object.values(row).join(',')).join('\n');
        return header + rows;
    };

    // Function to download data as CSV
    const downloadCSV = () => {
        if (transactionCsvData.length === 0) {
            alert('No data to download');
            return;
        }
        const csvData = convertToCSV(transactionCsvData);
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', 'gift_card_transactions.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <>
            {isLoading ? '' :
                <div className="p-3 p-lg-4 p-xl-5 container-fluid text-dark fontGilroy">
                    <Row>
                        <Col lg={12}>
                            <Container
                                fluid
                                className="ps-4 welcomencentiva bg-white mt-2 mt-lg-0"
                            >
                                <Row className="align-items-center" >
                                    <Col md={6}>
                                        <div className=" font12">
                                            <div className="d-xl-flex align-items-center">

                                                <h2 className="font22 text-dark fw-semibold   me-0 me-xl-2">
                                                    Welcome To
                                                </h2>
                                                <img style={{ width: "100%", maxWidth: "187px", }} src="/assets/images/logo.png" alt="" />
                                            </div>
                                            <p className="font16">
                                                Ncentiva provides a unique payout platform to disburse funds to your end customers.
                                            </p>
                                            {/* <Button
                                        type="button"
                                        variant="primary"
                                        className="fw-bold font12 fullRounded shadowPrimary px-4 py-2 mt-4"
                                    >
                                        View Profile
                                    </Button> */}
                                        </div>
                                    </Col>
                                    <Col md={6} className="pe-0">
                                        <div className="welcome-img-business">

                                            <img
                                                src="/assets/images/welcome.png"
                                                className="img-fluid"
                                                alt=""
                                            />
                                        </div>


                                    </Col>
                                </Row>
                            </Container>

                            <Container fluid className="mt-4">
                                <Row className="business-dashRow g-4">
                                    <Col xs={12} md={12} lg={6} xl={4} >
                                        <Row fluid className="bg-white text-center" style={{ borderRadius: '15px', height: "100%" }}>
                                            <img className="coinImg mb-4" src="/assets/images/Coins.png" alt="" />
                                            <h2 className="font20 text-dark fw-semibold change-head mb-4">Available Funds</h2>
                                            <div style={{ width: '130px', height: '2px', border: '1px solid #DADADA', margin: 'auto' }}></div>
                                            <h2 className="font20 text-dark fw-semibold mb-2 change-font mt-4 mb-3" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                <span style={{ color: '#008000' }}>
                                                    ${Number(bpartner?.businessPartnes?.available_balance).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                </span>
                                            </h2>
                                        </Row>
                                    </Col>
                                    <Col xs={12} md={12} lg={6} xl={4} className="">
                                        <Row fluid className="bg-white ms-lg-1" style={{ borderRadius: '15px', height: "100%" }}>
                                            <Col xs={6} className="p-4">
                                                <h6 className="font15  ">Total Customers</h6>
                                                <h2 className="font20 fw-semibold mb-3 change-font" style={{ color: "#FF7020" }}>{bpartner?.totalCustomer}</h2>
                                                <div style={{ width: '100%', height: '2px', border: '1px solid #DADADA' }}></div>
                                                <h6 className="font15  mt-4">Active Customers</h6>
                                                <h2 className="font20 fw-semibold mb-3 change-font" style={{ color: "#F5C400" }}>{bpartner?.activeCustomer}</h2>
                                            </Col>
                                            <Col xs={6} className="mt-3 ps-lg-0">
                                                {/* <div  className="bigCirclePie">
             
          </div> */}
                                                <div style={{ maxHeight: '165px', maxWidth: '165px', position: 'relative' }}>
                                                    {data ? <PieChart
                                                        data={data}
                                                        radius={50}
                                                        lineWidth={25}
                                                        startAngle={0}
                                                        viewBoxSize={[100, 100]}
                                                        animate
                                                    /> : ''}
                                                    <div className="smallCirclePie">
                                                        <h2 className="font20 fw-semibold " style={{ color: "white", marginBottom: '0px', fontSize: '25px' }}>{parseInt(bpartner?.percentageOfActiveCustomer)}%</h2>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Row className="mb-2 mb-md-0">
                                                <Col xs={6} >
                                                    <div className="d-flex align-items-center">
                                                        <div className="customerDot resposive_dot"></div>
                                                        <h6 className="font15 responsivePartner fw-semibold">Total Customers</h6>
                                                    </div>
                                                </Col>
                                                <Col xs={6}>
                                                    <div className="d-flex align-items-center">
                                                        <div className="customerDotactive resposive_dot"></div>
                                                        <h6 className="font15 responsivePartner fw-semibold">Active Customers</h6>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Row>

                                    </Col>
                                    <Col xs={12} md={12} lg={6} xl={4} className="">
                                        <Row fluid className="bg-white ms-lg-1" style={{ borderRadius: '15px', height: "50%" }}>
                                            <Container fluid className="mt-2 p-2 rounded shadow" style={{ backgroundColor: '#FF7020', width: '95%', cursor: 'pointer' }} onClick={() => setAllTransaction(!allTransaction)}>
                                                <div className='d-flex justify-content-between'>
                                                    <h1 className="font20 text-light ">Sort</h1>
                                                    <div className="d-flex">
                                                        {allTransaction ? <h1 className="font20 text-light me-3">All Transaction</h1> : <h1 className="font20 text-light me-3">This Month</h1>}
                                                        <svg width="22" height="22" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12.5 13.75L9.5 10.75H11.75V3.25H9.5L12.5 0.25L15.5 3.25H13.25V10.75H15.5M0.5 12.25V10.75H8V12.25M0.5 7.75V6.25H5.75V7.75M0.5 3.25V1.75H3.5V3.25H0.5Z" fill="white" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </Container>

                                            <div className="d-flex mt-2 justify-content-between">
                                                <div>

                                                    <h6 className="font15 p-2 pb-0 fw-semibold">Number of Transactions</h6>
                                                    {allTransaction ? <h2 className="font20 ps-2 fw-semibold mb-1 change-font" style={{ color: "#FF7020" }}>{bpartner?.totalTransaction}</h2> :
                                                        <div className="d-flex">
                                                            <h2 className="font20 ps-2 fw-semibold mb-1 change-font" style={{ color: "#FF7020" }}>{bpartner?.lastMonthTransaction}</h2>
                                                            <div className="d-flex mt-2 ms-2">
                                                                <svg className="mt-2" width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M9 0V1H12.293L8 5.293L5.8535 3.1465C5.75974 3.05276 5.63258 3.00011 5.5 3.00011C5.36742 3.00011 5.24026 3.05276 5.1465 3.1465L0 8.293L0.707 9L5.5 4.207L7.6465 6.3535C7.74026 6.44724 7.86742 6.49989 8 6.49989C8.13258 6.49989 8.25974 6.44724 8.3535 6.3535L13 1.707V5H14V0H9Z" fill="#008000" />
                                                                </svg>
                                                                <p className="ms-1" style={{ color: "#008000" }}>
                                                                    This Month Transactions
                                                                </p>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="pt-2">
                                                    <img src="/assets/images/group227.png" alt="" />
                                                </div>
                                            </div>
                                        </Row>


                                        <Row fluid className="bg-white ms-lg-1 mt-2" style={{ borderRadius: '15px', height: "47%" }}>
                                            <Container fluid className="mt-2 p-2 rounded shadow" style={{ backgroundColor: '#FF7020', width: '95%', cursor: 'pointer' }} onClick={() => setAllCards(!allCards)}>
                                                <div className='d-flex justify-content-between'>
                                                    <h1 className="font20 text-light ">Sort</h1>
                                                    <div className="d-flex">
                                                        {allCards ? <h1 className="font20 text-light me-3">All Gift Cards</h1> : <h1 className="font20 text-light me-3">This Month</h1>}
                                                        <svg width="22" height="22" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12.5 13.75L9.5 10.75H11.75V3.25H9.5L12.5 0.25L15.5 3.25H13.25V10.75H15.5M0.5 12.25V10.75H8V12.25M0.5 7.75V6.25H5.75V7.75M0.5 3.25V1.75H3.5V3.25H0.5Z" fill="white" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </Container>

                                            <div className="d-flex mt-2 justify-content-between">
                                                <div>
                                                    <h6 className="font15 p-2 pb-0 fw-semibold">Number of cards requested</h6>
                                                    {allCards ? <h2 className="font20 ps-2 fw-semibold mb-1 change-font" style={{ color: "#FF7020" }}>{bpartner?.totalGiftcard}</h2> : <h2 className="font20 ps-2 fw-semibold mb-1 change-font" style={{ color: "#FF7020" }}>{bpartner?.lastMonthGiftCard}</h2>}
                                                </div>
                                                <div className="pt-2">
                                                    <img src="/assets/images/Gift.png" alt="" />
                                                </div>
                                            </div>
                                        </Row>
                                    </Col>

                                    

                                </Row>

                                <Row className="business-dashRow g-4">
                                <Col xs={12} md={12} lg={6} xl={4} >
                                <div className="md-4 mt-2 d-flex justify-content-center">
                                        <div className="bg-white ms-lg-1 mt-2" style={{ borderRadius: '15px', height: "47%" }}>
                                            <Button className="" style={{ background: "#ff7020", color: '#f8f9fa', fontWeight: 'bolder', border: '1px solid #ff7020' }} onClick={downloadCSV}>
                                                Download Historical Transactions
                                                <span className="ms-2">
                                                    <svg width="28px" height="28px" viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path opacity="0.5" d="M3 15C3 17.8284 3 19.2426 3.87868 20.1213C4.75736 21 6.17157 21 9 21H15C17.8284 21 19.2426 21 20.1213 20.1213C21 19.2426 21 17.8284 21 15" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M12 3V16M12 16L16 11.625M12 16L8 11.625" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                                                </span>
                                            </Button>
                                        </div>
                                    </div>
                                </Col>


                                    <Col xs={12} md={12} lg={6} xl={8}>
                                        <div className="md-4 mt-1 py-2 d-flex justify-content-start">
                                            <Container fluid className="mt-2 p-2 rounded shadow" style={{ backgroundColor: '#FF7020', width: '100%', cursor: 'pointer' }} onClick={() => setISClickCarts(!isClickCarts)}>
                                                <div className='d-flex justify-content-between'>
                                                    <h1 className="font20 text-light">Top 10 Brands</h1>
                                                    <div className="d-flex">
                                                        {isClickCarts ? <h1 className="font20 text-light me-3">All Brands</h1> : <h1 className="font20 text-light me-3">This Month</h1>}
                                                        <svg width="22" height="22" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12.5 13.75L9.5 10.75H11.75V3.25H9.5L12.5 0.25L15.5 3.25H13.25V10.75H15.5M0.5 12.25V10.75H8V12.25M0.5 7.75V6.25H5.75V7.75M0.5 3.25V1.75H3.5V3.25H0.5Z" fill="white" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </Container>
                                        </div>
                                        <div className="mt-2 p-2">
                                            <div className="table-responsive">
                                                <table className="table " style={{ verticalAlign: "middle" }}>
                                                    <thead className="bgLightRed font15">
                                                        <tr className="text-center">

                                                            <th className="py-3">
                                                                {" "}
                                                                <div className="d-flex align-items-center justify-content-center ">
                                                                    {" "}
                                                                    <span className="me-1 ">Brand</span>{" "}
                                                                </div>{" "}
                                                            </th>

                                                            <th className="py-3">
                                                                <div className="d-flex align-items-center justify-content-center" style={{ cursor: 'pointer' }}>
                                                                    <span className="me-1">Brand Name</span>
                                                                </div>
                                                            </th>
                                                            <th className="py-3">
                                                                <div className="d-flex align-items-center justify-content-center" style={{ cursor: 'pointer' }}>
                                                                    <span className="me-1">Brand Categories</span>
                                                                </div>
                                                            </th>
                                                            <th className="py-3">
                                                                {" "}
                                                                <div className="d-flex align-items-center justify-content-center ">
                                                                    {" "}
                                                                    <span className="me-1 ">Quantities</span>{" "}
                                                                </div>
                                                            </th>
                                                            <th className="py-3">
                                                                {" "}
                                                                <div className="d-flex align-items-center justify-content-center ">
                                                                    {" "}
                                                                    <span className="me-1 ">Values</span>{" "}
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="fw-semibold font15 text-center">
                                                        {(isClickCarts ? topSellCarts : latsMonthsTopSellCarts)?.map((carts) => (
                                                            <tr key={carts._id}>
                                                                <td className="py-3 fw-semibold font15">
                                                                    <img src={carts.img} alt="img" width="100px" />
                                                                </td>
                                                                <td className="py-3 fw-semibold font15">
                                                                    {carts.productName}
                                                                </td>
                                                                <td className="py-3 fw-semibold font15">
                                                                    {carts.brandCategory}
                                                                </td>
                                                                <td className="py-3 fw-semibold font15">
                                                                    {carts.count}
                                                                </td>
                                                                <td className="py-3 fw-semibold font15">
                                                                ${Number(carts.totalValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                 </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </Col>

                                </Row>

                            </Container>
                        </Col>
                    </Row>
                </div>}
        </>
    );
}

export default Businessdashboard;
