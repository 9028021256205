
import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import { Row } from 'react-bootstrap';
import { Col, Form, Button } from 'react-bootstrap';
import { toast } from "react-toastify";
// import { DropdownButton } from 'react-bootstrap';
// import { Dropdown } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { FaSortUp, FaSortDown } from "react-icons/fa";
import { Bars } from "react-loader-spinner";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import axios from 'axios';
import styles from './customersByBusiness.module.css'
import { getNewToken } from '../config/cookie.config';



const  CustomerOfBuisnessPartner = (props) => {


 let navigate = useNavigate();
  var token;
  var userrole;
  var usersid;
  const user = JSON.parse(window.localStorage.getItem("client"));
//   let id;
  const businessPartner = JSON.parse( window.localStorage.getItem('business-client'));
  const { id, customerId} = useParams();

 const [customerData, setCustomerData] = useState([]);
const [downloadCustomerData, setDownloadCustomerData] = useState([]);
  const [order, setOrder] = useState("ASC");
  const [emailorder, setEmailorder] = useState("ASC");
  const [partnerorder, setPartnerorder] = useState("ASC");
  const [fundorder, setFundorder] = useState("ASC");
  const [updateorder, setUpdateorder] = useState("ASC");
  const [sort, setSort] = useState("");
  const [search, setSearch] =useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(false); 
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [partnerId, setPartnerId] = useState(id);
  const [showPage,setShowPage] = useState(1);
  const [zero,setZero] = useState(false);

  const handleSearch = (value) => {
    setSearch(value);
    setPageNumber(0);
    setShowPage(1);
  };

  useEffect(()=>{
        let token;
       const user = JSON.parse(window.localStorage.getItem("client"));
         if (user) {
          token = user.token;
        } else {
           token = null;
         }

    let apiUrl ;
    if(id && customerId){
    apiUrl = `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/get-customers/${id}/${customerId}?page=${pageNumber}&search=${search}`;  
    }

    axios
    .get(apiUrl, {
      headers: {
        'Authorization' : `Bearer ${getNewToken()}`
      }
    })
    .then((response) => {
      if(response.data.customers?.length < 1){
        setZero(true);
      }else{
        setZero(false);
        const totalPages = response.data.totalPages;
        setNumberOfPages(totalPages);
        const allcustomers = response.data.customers;
        setCustomerData(allcustomers);
      }
      })
    .catch((error) => console.error(`Error: ${error}`));
  },[pageNumber,search]);

  const pagenumberSearch = (e)=>{
    var pages =parseInt(e) ;
    var page= pages -1;
    if(pages<1){
      setPageNumber(0);
      setShowPage(1);
    }else if(pages>numberOfPages){
      setPageNumber(numberOfPages-1);
      setShowPage(numberOfPages);
    }else if(pages>=1 && pages<=numberOfPages){
      setPageNumber(page);
      setShowPage(pages);
    }
    else{
      setPageNumber(0);
      setShowPage(pages);
    }
    }

  let items = [];
  let leftSide = pageNumber - 1;

  if (leftSide <= 0) leftSide = 0;
  let rightSide = pageNumber + 2;

  if (rightSide > numberOfPages) rightSide = numberOfPages;
  for (let number = leftSide; number < rightSide; number++) {

    items.push(
      <li key={number} className={(number === pageNumber ? "active" : "")} onClick={() => { setPageNumber(number);setShowPage(number+1) }}>
        {number + 1}
      </li>,
    );
  }

  const nextPage = () => {
    if (pageNumber < numberOfPages) {
      setPageNumber(pageNumber + 1);
      setShowPage(pageNumber +2);
    }
  }

  const prevPage = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1);
      setShowPage(pageNumber);
    }
  }

  return (
    <div className="p-3 p-lg-4 p-xl-5">
       <div className="d-flex mb-4 align-items-center">
          <Link
          onClick={()=>navigate(-1)}
            to="/admin/businesspartners"
            className="p-0 btn btnOrangeTransparent me-3 fw-semibold"
            variant="white"
          >
            <span className="me-2 fw-semibold">
              <img
                src="/assets/images/back-org.png"
                className="img-fluid"
                alt=""
              />
            </span>
            <span>Back</span>
          </Link>
      <h1 className="font20 text-dark">Customers</h1>
        </div>
      {/* <div className="p-5"> */}
        {/* <h1 className="font20 mb-4 text-dark">Customers</h1> */}
        <Container fluid className="bg-white p-3 rounded shadow">
        <Row className="align-items-center">
          <Container>
            <Row>
              <Col xl={5} xxl={6} lg={4} className="mt-1">
                <div className="textGray font12">

                  <div style={{ maxWidth: "214px" }}>
                    <div className="d-flex align-items-center searchWrapper w-100 ms-0">
                    <Form.Control
                        type="text"
                        className="border-0 searchInput"
                        placeholder="Search"
                        onChange={({ currentTarget: input }) =>
                          handleSearch(input.value)
                        }
                      />
                      <Button
                        variant="primary"
                        title="Click here to search"
                        className="searchBtn d-flex justify-content-center align-items-center"
                      >
                        <img
                          src="/assets/images/search.png"
                          className="img-fluid"
                          alt=""
                        />
                      </Button>
                    </div>
                  </div>

                </div>
              </Col>
              {/* <Col lg={{ span: 3, offset: 1 }} className="mt-1" > */}
              
            </Row>
          </Container>
            <Col md={12} className="mt-4">
              <div className="table-responsive">
                <table  className="table fontGilroy"
                style={{ verticalAlign: "middle" }}>
                <thead className="bgLightRed font14">
                  <tr>
                    <th className="text-center">
                      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="me-1">Name</span>
                        {/* {order === "ASC" ? (
                          <FaSortDown onClick={() => sorting("DSC")} />
                        ) : (
                          <FaSortUp onClick={() => sorting("ASC")} />
                        )} */}
                      </div>
                    </th>
                    <th className="text-center">
                      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="me-1"> Email
                          {/* {emailorder === "ASC" ? (
                            <FaSortDown onClick={() => emailsorting("DSC")} />
                          ) : (
                            <FaSortUp onClick={() => emailsorting("ASC")} />
                          )} */}
                        </span>

                      </div>
                    </th>
                    <th className="text-center">
                      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="me-1"> Partner</span>
                        {/* {partnerorder === "ASC" ? (
                          <FaSortDown onClick={() => partnersorting("DSC")} />
                        ) : (
                          <FaSortUp onClick={() => partnersorting("ASC")} />
                        )} */}
                      </div>
                    </th>

                    <th className="text-center">
                      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="me-1">Available Funds</span>
                        {/* {fundorder === "ASC" ? (
                          <FaSortDown onClick={() => fundsorting("DSC")} />
                        ) : (
                          <FaSortUp onClick={() => fundsorting("ASC")} />
                        )} */}
                      </div>
                    </th>

                    <th className="text-center">
                      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="me-1">Updated At</span>
                        {/* {updateorder === "ASC" ? (
                          <FaSortDown onClick={() => updatesorting("DSC")} />
                        ) : (
                          <FaSortUp onClick={() => updatesorting("ASC")} />
                        )} */}
                      </div>
                    </th>
                    <th className="text-center">
                      <div className="d-flex align-items-center">
                        <span className="me-1"> Status</span>
                      </div>
                    </th>
                    <th className='text-center'></th>                          
                    {/* <th className="text-end">Action</th> */}
                  </tr>
                </thead>
                  <tbody className="font14 fw-medium">
                  {isLoading === true ? (
                    <tr style={{ backgroundColor: "#eee" }}>
                    <td colSpan="8">
                    <Bars
                    height="80"
                    width="100%"
                    margin='auto'
                    color="#FF7020"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    />
                    </td>
                  </tr>
                  ) : 
                  (
                  <> 

                    {zero? <tr >
                      <td colSpan="9" className="text-center">
                      <h1 className="font16 text-dark" >No Customers</h1>
                      </td>
                    </tr>:
                    customerData?.map((cust) => (
                      <tr key={cust._id}>
                      <td className="font14 fw-medium">
                        {cust.first_name} {cust.last_name}
                      </td>
                      <td className="font14 fw-medium">
                        {/* <Link to={`/admin/customers/${cust._id}/edit`}> */}
                          {cust.email} {cust.id}
                        {/* </Link> */}
                      </td>
                      <td className="font14 fw-medium">{cust.business_partner}</td>
                      <td className="font14 fw-medium ps-xxl-5 ps-4">${Number(cust.balance).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td>
                      <td className="font14 fw-medium">{cust.updatedAt.slice(0, 16).replace('T', '      ')}</td>
                      <td className="font14 fw-medium">
                      {cust?.status ? "Active" : "Inactive"}
                      </td>
                      
                    </tr>
                    ))
                        }
                        </>
                    )}
                  </tbody>
                </table>
              </div>
            </Col>
            <Col className="mt-5 mb-4">
            {
              <ul className="custPagination d-flex justify-content-center list-unstyled font14">


                {pageNumber === 0 ? (
                  ""
                ) : (

                  <li onClick={prevPage}>Previous </li>

                )}


                {items}


                {numberOfPages - 1 === pageNumber ? (
                  ""
                  ) : (
                    <li onClick={nextPage}>Next</li>
                    )}
                  <li className="font14"><input  style={{width:"25px",border:'1px solid #4C00EF',borderRadius:'4px',textAlign:'center'}} value={showPage?showPage:''} type="text" name="number" id="" onChange={(e)=> {pagenumberSearch(e.target.value)}}  ></input> <span style={{color:'#4C00EF'}}>/</span> {numberOfPages}</li>
              </ul>
            }
          </Col>
          </Row>
        </Container>
      </div>
  )
}

export default CustomerOfBuisnessPartner;