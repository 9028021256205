import { Button, Col, Nav, Row } from "react-bootstrap";
import "../styles/leftsidebar.scss";
import { useNavigate, NavLink, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { toast } from "react-toastify";
import axios from "axios";
import { deleteCookie, getNewCustomerToken } from "../config/cookie.config";
const CustomerLeftSidebar = (props) => {
    const [isToggled, setIsToggled] = useState(false);
    const nameDomain = (window.location.host.split('.'));
    const [btnColor, setBtnColor] = useState('');
    const [btnText, setBtnText] = useState('');
    const [primaryColor, setPrimaryColor] = useState("");
    const [secondaryColor, setSecondaryColor] = useState("")
    const [image, setImage] = useState("");
    const [imageError, setImageError] = useState(true);
    const [url, setUrl] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const user = JSON.parse(window.localStorage.getItem('client'));
    var userrole;
    let id;
    if (user !== null) {
        userrole = user.user.role;
    } else {
        userrole = null;
    }

    const [role] = useState(userrole);
    const navigate = useNavigate();

    const customerUser = JSON.parse(window.localStorage.getItem('customer'));
    let token;

    if (customerUser) {
        id = customerUser._id
        token = customerUser.token;
    } else {
        id = null;
        token = null;
    };

    const logout = async () => {
        // toast.success("You are Successfully Logged Out")
        // setTimeout(() => {
        //     localStorage.removeItem('customer');
        //     localStorage.removeItem('customer_data');
        //     navigate('/login');
        //     // window.location.reload(); 
        // }, 1000);

        await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/logout`, {
            headers: {
              'Authorization': `Bearer ${getNewCustomerToken()}`
            }
          }).then((res)=> {
            toast.success("You are Successfully Logged Out")
            setTimeout(() => {
                // Delete cookies
                deleteCookie('customer_OAC-01');
                deleteCookie('customer_AAC-02');

                // Remove token from localStorage
                window.localStorage.removeItem("customer_CAACAO-012");
                localStorage.removeItem('customer');
                localStorage.removeItem('customer_data');
                localStorage.removeItem('listItem');
                localStorage.removeItem('ncentiva_wallet');
                localStorage.removeItem('payoutItem');
                navigate('/login');
        
            }, 1000);
          }).catch((err)=> {
            console.log('Error :',err);
          })

    }

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
    }

    const handleShow = () => {
        setShow(true);
    }
    const customerEmail = JSON.parse(window.localStorage.getItem('customer'));
    let email = customerEmail?.customer?.email;

    const handleButtonClick = async (e) => {
        e.preventDefault();

        try {
            const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/send-reset-password-email`, {
                email,
            });

            if (data && data.message) {
                if (data.message === "Email doesn't exist") {
                    toast.warning(data.message);
                } else if (data.message === "Email Field is Required") {
                    toast.warning(data.message);
                } else {
                    toast.success(data.message);
                }

                if (data.status === "success") {
                    // Handle success
                } else if (data.status === "failed") {
                    // Handle failure
                }
            } else {
                // Handle unexpected response format
                console.error("Unexpected response format:", data);
            }
        } catch (err) {
            console.error("Error:", err);
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error("An error occurred while processing your request.");
            }
        }

    };
    useEffect(() => {
        if (nameDomain[0] !== "dev" || "staging" || "payouts" || "uat") {
            axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getcms/${nameDomain[0]}`)
                .then((res) => {
                    if (res.data?.cms.default_ncentiva === false) {
                        setBtnColor(res.data?.cms?.btn_color);
                        setBtnText(res.data?.cms?.btn_text);
                        setPrimaryColor(res.data?.cms?.primary_color);
                        setSecondaryColor(res.data?.cms?.secondary_color);
                        if (res.data?.cms.brand_logo) {
                            setImage(res.data?.cms.brand_logo)
                        }
                    } else {
                        setImage("https://dev.ncentiva.com/assets/images/logo.png");
                        setBtnColor('#3D05B6');
                        setBtnText('white');
                        setPrimaryColor("#FF7020");
                        setSecondaryColor("#F5C400");
                    }
                    if(res.data?.cms?.brand_logo){
                        setImageError(false)
                      }else{
                        setImageError(true);
                      }
                    setIsLoading(false);
                }).catch((err) => {
                    console.log(err);
                })
        } else {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        const currentURL = window.location.href;
        var value = currentURL.split("customers")
        if (value[1] === '/monetarytransaction' || '/profile' || '/transaction') {
            setUrl(value[1]);
        } else {
            setUrl('/profile');
        }
    }, []);
    const LogoStyle = {
        width: "170px",
        marginTop:'10px'
    }

    useEffect(() => {
        let timeoutId;
        function onUserActivity() {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                localStorage.removeItem('customer');
                // Delete cookies
                deleteCookie('customer_OAC-01');
                deleteCookie('customer_AAC-02');

                // Remove token from localStorage
                window.localStorage.removeItem("customer_CAACAO-012");
                navigate('/login');
                toast.error("Session Expired. Please Login Again");
            }, 900000); // 15 minutes in milliseconds
        }
        
        function resetTimeout() {
            onUserActivity();
        }
    
        // Events for desktop
        window.addEventListener('mousemove', resetTimeout);
        window.addEventListener('keydown', resetTimeout);
    
        // Events for mobile
        window.addEventListener('touchstart', resetTimeout);
        window.addEventListener('touchmove', resetTimeout);
        window.addEventListener('touchend', resetTimeout);
    
        // Initialize timeout on component mount
        onUserActivity();
    
        // Cleanup event listeners and timeout on component unmount
        return () => {
            // Cleanup
            window.removeEventListener('mousemove', resetTimeout);
            window.removeEventListener('keydown', resetTimeout);
            window.removeEventListener('touchstart', resetTimeout);
            window.removeEventListener('touchmove', resetTimeout);
            window.removeEventListener('touchend', resetTimeout);
            clearTimeout(timeoutId);
        };
      }, [navigate]);
        

    return (
        <div className={`leftSideBar bg-white position-fixed top-0 left-0 overflow-hidden  ${isToggled ? '' : 'active'}`}>
            <div className="logoWrapper" style={{width: '100%', height:'40px'}}>
            {imageError ? "" : (
                            <Link to='/' >
                            <img
                                src={image}
                                loading="lazy"
                                alt=""
                                className="img-fluid "
                                style={LogoStyle}
                            /></Link>
                        )} 
            </div>
            <div>
                <ul className="list-unstyled menuItemWrapper">
                    <li >
                        <Nav.Link
                            as={NavLink}
                            to="/"
                            className="d-flex align-items-center w-100"
                        >
                            <span className="icon">
                                <svg
                                    width="15"
                                    height="15"
                                    viewBox="0 0 15 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M7.03125 1.875V5.15625C7.03073 5.65337 6.83308 6.12996 6.48153 6.48153C6.12997 6.83309 5.65337 7.03074 5.15625 7.03125H1.875C1.37788 7.03073 0.901286 6.83308 0.549723 6.48153C0.198161 6.12997 0.000508929 5.65337 0 5.15625V1.875C0.000523152 1.37788 0.198171 0.901286 0.549723 0.549723C0.901275 0.198161 1.37788 0.000508929 1.875 0H5.15625C5.65337 0.000523152 6.12996 0.198171 6.48153 0.549723C6.83309 0.901275 7.03074 1.37788 7.03125 1.875ZM13.125 0H9.84375C9.34663 0.000523152 8.87004 0.198171 8.51847 0.549723C8.16691 0.901275 7.96926 1.37788 7.96875 1.875V5.15625C7.96927 5.65337 8.16692 6.12996 8.51847 6.48153C8.87003 6.83309 9.34663 7.03074 9.84375 7.03125H13.125C13.6221 7.03073 14.0987 6.83308 14.4503 6.48153C14.8018 6.12997 14.9995 5.65337 15 5.15625V1.875C14.9995 1.37788 14.8018 0.901286 14.4503 0.549723C14.0987 0.198161 13.6221 0.000508929 13.125 0ZM5.15625 7.96875H1.875C1.37788 7.96927 0.901286 8.16692 0.549723 8.51847C0.198161 8.87003 0.000508929 9.34663 0 9.84375V13.125C0.000523152 13.6221 0.198171 14.0987 0.549723 14.4503C0.901275 14.8018 1.37788 14.9995 1.875 15H5.15625C5.65337 14.9995 6.12996 14.8018 6.48153 14.4503C6.83309 14.0987 7.03074 13.6221 7.03125 13.125V9.84375C7.03073 9.34663 6.83308 8.87004 6.48153 8.51847C6.12997 8.16691 5.65337 7.96926 5.15625 7.96875ZM13.125 7.96875H9.84375C9.34663 7.96927 8.87004 8.16692 8.51847 8.51847C8.16691 8.87003 7.96926 9.34663 7.96875 9.84375V13.125C7.96927 13.6221 8.16692 14.0987 8.51847 14.4503C8.87003 14.8018 9.34663 14.9995 9.84375 15H13.125C13.6221 14.9995 14.0987 14.8018 14.4503 14.4503C14.8018 14.0987 14.9995 13.6221 15 13.125V9.84375C14.9995 9.34663 14.8018 8.87004 14.4503 8.51847C14.0987 8.16691 13.6221 7.96926 13.125 7.96875Z"
                                        fill="#FF7020"
                                    />
                                </svg>
                            </span>
                            <span>Home</span>
                        </Nav.Link>
                    </li>

                    <li>
                        <Nav.Link
                            as={NavLink}

                            to="/customers/profile"
                            className="d-flex align-items-center w-100"
                        >
                            <span className="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-badge" viewBox="0 0 16 16">
                                    <path d="M6.5 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                    <path d="M4.5 0A2.5 2.5 0 0 0 2 2.5V14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2.5A2.5 2.5 0 0 0 11.5 0h-7zM3 2.5A1.5 1.5 0 0 1 4.5 1h7A1.5 1.5 0 0 1 13 2.5v10.795a4.2 4.2 0 0 0-.776-.492C11.392 12.387 10.063 12 8 12s-3.392.387-4.224.803a4.2 4.2 0 0 0-.776.492V2.5z" />
                                </svg>

                            </span>
                            <span> My Profile</span>
                        </Nav.Link>
                    </li>


                    <li>
                        <Nav.Link
                            as={NavLink}
                            to="/customers/transaction"
                            className="d-flex align-items-center w-100 "
                        >
                            <span className="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-wallet-fill" viewBox="0 0 16 16">
                                    <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542.637 0 .987-.254 1.194-.542.226-.314.306-.705.306-.958a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2h-13z" />
                                    <path d="M16 6.5h-5.551a2.678 2.678 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5c-.963 0-1.613-.412-2.006-.958A2.679 2.679 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-6z" />
                                </svg>
                            </span>
                            <span>My Giftcards</span>
                        </Nav.Link>
                    </li>
                    <li>
                        <Nav.Link
                            as={NavLink}
                            to={`/customers/monetarytransaction`}
                            className="d-flex align-items-center w-100 "
                        >
                            <span className="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-credit-card-2-front-fill" viewBox="0 0 16 16">
                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2.5 1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-2zm0 3a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm3 0a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm3 0a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm3 0a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z" />
                                </svg>
                            </span>
                            <span>Monetary Transactions</span>
                        </Nav.Link>
                    </li>


                    <li>
                        <Button
                            style={{backgroundColor:'white',color:'black',border:'1px solid white',}}
                            onClick={handleButtonClick}
                            className="d-flex align-items-center w-100 customer_resetbutton"
                        >
                            
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" className="px-1">
                            <path
                             d="M336 352c97.2 0 176-78.8 176-176S433.2 0 336 0S160 78.8 160 176c0 18.7 2.9 36.8 8.3 53.7L7 391c-4.5 4.5-7 10.6-7 17v80c0 13.3 10.7 24 24 24h80c13.3 0 24-10.7 24-24V448h40c13.3 0 24-10.7 24-24V384h40c6.4 0 12.5-2.5 17-7l33.3-33.3c16.9 5.4 35 8.3 53.7 8.3zM376 96a40 40 0 1 1 0 80 40 40 0 1 1 0-80z"/></svg>
                           
                            <span className="px-3">
                                 Reset Password
                            </span>
                           
                        </Button>
                    </li>


                    <li>
                        <Nav.Link
                            onClick={() => handleShow()}
                            className="d-flex align-items-center w-100 "
                        // to="/admin"
                        >
                            <span className="icon">
                                <svg
                                    width="12"
                                    height="15"
                                    viewBox="0 0 12 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M6.68426 7.60306H5.04378C3.70605 7.60306 2.42323 8.13447 1.47726 9.08032C0.531294 10.0263 0 11.3093 0 12.647V13.4649C0 13.872 0.161691 14.2625 0.449565 14.5504C0.737459 14.8383 1.12783 15 1.53506 15H10.1931C10.6003 15 10.9907 14.8383 11.2786 14.5504C11.5665 14.2625 11.7282 13.872 11.7282 13.4649V12.647C11.7282 11.3093 11.1969 10.0263 10.2509 9.08032C9.30494 8.13451 8.02211 7.60306 6.68438 7.60306H6.68426Z"
                                        fill="#333333"
                                    />
                                    <path
                                        d="M5.86368 6.95181C6.78564 6.95181 7.66997 6.58544 8.3218 5.93347C8.97363 5.28153 9.33982 4.3972 9.33966 3.47524C9.33935 2.55312 8.97284 1.66911 8.3207 1.01728C7.6686 0.365605 6.78427 -0.000430388 5.86215 3.79781e-07C4.94019 0.000306303 4.05618 0.366979 3.4045 1.01939C2.75299 1.67165 2.38723 2.55614 2.3877 3.4781C2.38953 4.39916 2.75636 5.28207 3.40787 5.93312C4.05939 6.58432 4.9425 6.95052 5.86368 6.95177V6.95181Z"
                                        fill="#333333"
                                    />
                                </svg>
                            </span>
                            <span>Logout</span>
                        </Nav.Link>
                    </li>
                </ul>
            </div>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="md"
                centered
            >

                <Modal.Body>
                    <Col>
                        <img className="modal-img"
                            src="/assets/images/exclamation.svg"
                            // className="img-fluid"
                            alt=""
                        />
                        <br />
                        <br />
                        <h3 className="modal-text">Are you sure, you want to Log out?</h3>
                        <br />
                        <Row className="modal-row">
                            <Col md={1}></Col>
                            <Col md={4}>
                                <Button className="btn btn-danger danger-btn" onClick={logout}>

                                    <span><b>Yes, Logout</b></span>
                                </Button>
                            </Col>
                            <Col className="middle-col" md={2}></Col>
                            <Col md={4}>
                                <Button className="btn btn-success success-btn" onClick={handleClose} >
                                    <span><b>Cancel</b></span>
                                </Button>
                            </Col>
                            <Col md={1}></Col>

                        </Row>
                        <br />
                    </Col>
                </Modal.Body>

            </Modal>

            <Button
                onClick={() => setIsToggled(!isToggled)}
                variant="white"
                className={`sideToggler fullRounded d-flex d-md-none justify-content-center align-items-center p-2  ${isToggled ? 'rotate' : ''}`}
                type="button"
                aria-label="Click to expand sidebar"
                style={{ width: "35px", height: "35px" }}
            >
                <svg
                    width="30px"
                    height="30px"
                    viewBox="0 0 76 76"
                    enableBackground="new 0 0 76.00 76.00"
                >
                    <path
                        fill="#000000"
                        fillOpacity="1"
                        strokeWidth="0.2"
                        strokeLinejoin="round"
                        d="M 35.8724,37.6042L 39.0391,40.7708L 50.5182,51.8542L 40.2266,51.8542L 25.1849,37.6041L 40.2266,23.3542L 50.5182,23.3542L 39.0391,34.4375L 35.8724,37.6042 Z "
                    />
                </svg>
            </Button>

            {/* <Button
                onClick={() =>{props.setToggleTwo(!props.toggleTwo)}}
                variant="white"
                className={` new_close_button d-none d-xl-none   d-md-flex justify-content-center align-items-center ${props.toggleTwo ? 'rotate' : ""}`}
                type="button"
                aria-label="Click to expand sidebar"
            >
                <svg
                    width="25px"
                    height="25px"
                    viewBox="0 0 76 76"
                    enableBackground="new 0 0 76.00 76.00"
                >
                    <path
                        fill="#fff"
                        fillOpacity="1"
                        strokeWidth="0.2"
                        strokeLinejoin="round"
                        d="M 35.8724,37.6042L 39.0391,40.7708L 50.5182,51.8542L 40.2266,51.8542L 25.1849,37.6041L 40.2266,23.3542L 50.5182,23.3542L 39.0391,34.4375L 35.8724,37.6042 Z "
                    />
                </svg>
            </Button> */}

        </div>
    );
};

export default CustomerLeftSidebar;