import React, { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";
import { Link, useParams, useNavigate } from "react-router-dom";
import {  toast } from 'react-toastify';
import Resizer from "react-image-file-resizer";
import axios from "axios";
import { FaCloudUploadAlt } from "react-icons/fa";
import {
  // import Category from './elements/Category';
  Button,
  Col,
  Container,
  Row,
  Form,
} from "react-bootstrap";
import ReactFileReader from "react-file-reader";
import { getNewToken } from "../config/cookie.config";
const CouponEdit = () => {
  var token
  const { id } = useParams();
  const [active, setActive] = useState(false);
  const [filename, setFilename] = useState('');
  const [brandname, setBrandname] = useState('');
  const [currency, setCurrency] = useState('');
  const [fee, setFee] = useState('');
  const [denomenation, setDenomenation] = useState('');
  const [image, setImage] = useState('');
  const [filesize, setFilesize] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    if (id) {
      getcoupons();
    }
  }, []);
  const getcoupons = () => {


    axios
        .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getcoupon/${id}`)
        .then((response) => {
          setBrandname(response.data.coupon.productname);
            setCurrency(response.data.coupon.currency);
            setFee(response.data.coupon.value);
            setDenomenation(response.data.coupon.denominationtype);
        })
        .catch((error) => console.error(`Error: ${error}`));
  };
  const handleFiles = (files) => {
    setFilename(files[0].name);
    setFilesize(files[0].size);
    let file = files[0];
    Resizer.imageFileResizer(file, 720, 500, "JPEG", 100, 0, async (uri) => {
      setImage(uri);
    })
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (image === "") {
      toast.warning("Please upload image");
      return false;
    }
    if (filesize >= 2097152) {
      toast.warning("Image size less then 2MB supported.");
      return false;
    }
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    try {
        const  {data}  = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/coupon/image`, {
            id,
            image,
        }, {
          headers: {
            'Authorization' : `Bearer ${getNewToken()}`
          }
        });
        toast.success(data.message);
        if (data.status === "success") {
            navigate('/admin/coupons')
        }else{
            toast.success(data.message);
        }   
    } catch (err) {
      console.log(`Error : ${err}`);
      toast.error(err);
    }
  }

  return (
    <>
      <div className="p-3 p-lg-4 p-xl-5">
        <Container fluid className="bg-white p-4 rounded shadow">
          <Row className="align-items-center pb-5">
            <Col md={5}>
              <div>
                <Link
                  to="/admin/coupons"
                  className="p-0 btn btnOrangeTransparent fw-semibold"
                  variant="white"
                >
                  <span className="me-2 fw-semibold">
                    <img
                      src="/assets/images/back-org.png"
                      className="img-fluid"
                      alt=""
                    />
                  </span>
                  <span>Back</span>
                </Link>
              </div>
            </Col>
            <Col md={7}></Col>
            <Col md={12} className="mt-4">
              <h1 className="font20 text-dark fw-bold mb-4">Coupons</h1>
              <Form className="bg-white p-4 rounded containerShadow" onSubmit={handleSubmit}>
                <Col md={6}>
                  <Row className="mb-4 align-items-center">
                    <Col lg={2}>
                      <div className="font12 text-dark">Brand Name</div>
                    </Col>
                    <Col lg={10}>
                      <Form.Control
                        type="text"
                        value={brandname}
                        onChange={(e) => setBrandname(e.target.value)}
                        className="font14"
                      />
                    </Col>
                  </Row>
                  <Row className="mb-4 align-items-center">
                    <Col lg={2}>
                      <div className="font12 text-dark">Currency</div>
                    </Col>
                    <Col lg={10}>
                      <Form.Control
                        type="text"
                        value={currency}
                        placeholder="$2305.00"
                        className="font14"
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={12}>
                  <Row className="mb-4 align-items-center">
                    <Col lg={1}>
                      <div className="font12 text-dark">Fee</div>
                    </Col>
                    <Col lg={5}>
                      <Form.Control
                        type="text"
                        value={fee}
                        placeholder="10"
                        className="font14"
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={12}>
                  <Row className="mb-4 align-items-center">
                    <Col lg={1}>
                      <div className="font12 text-dark">
                        Type Denomenation
                      </div>
                    </Col>
                    <Col lg={5}>
                      <Form.Control
                        type="text"
                        value={denomenation}
                        placeholder="Game"
                        className="font14"
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={12}>
                  <Row className="mb-4 align-items-center">
                    <Col lg={1}>
                      <div className="font12 text-dark">
                        Upload Image
                      </div>
                    </Col>
                    <Col lg={5}>
                      <div className="d-flex align-items-center">
                        <Button variant="outline-primary" className="fullRounded px-4 py-2 font14 me-3 fw-semibold">
                          <ReactFileReader handleFiles={handleFiles} fileTypes={".png,.jpg"}> <div className="d-flex align-items-center"><FaCloudUploadAlt /> <span className="ms-2">Upload Image</span></div> </ReactFileReader>
                        </Button>
                        {filename && <p className="mb-0 text-danger font12">{filename} <span className="text-dark " onClick={() => setFilename('')} style={{ cursor: 'pointer' }} >x</span></p>}
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={12} className="mt-4">
                  <div className="d-flex align-items-center">
                    <Button
                      type="submit"
                      variant="primary"
                      className="fullRounded px-4 py-2 font12 me-3 shadowPrimary"
                    >
                      Save Changes
                    </Button>
                    <Form.Check
                      type="switch"
                      label={active ? "Active" : "Inactive"}
                      className="switchLabel font12"
                      checked={active}
                      onChange={() => {
                        setActive(!active);
                      }}
                    />
                  </div>
                </Col>
              </Form>
            </Col>
          </Row>
        </Container>
        {/* <ToastContainer position="top-center" /> */}
      </div>
    </>
  );
};
export default CouponEdit;