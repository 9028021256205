import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import ReactPaginate from "react-paginate";
import { FaSortUp, FaSortDown } from "react-icons/fa";
import { Bars } from 'react-loader-spinner';
import { Button, Col, Container, Row, Form, DropdownButton, Dropdown, Modal } from "react-bootstrap";
import { DateRangePicker } from 'react-date-range'
import { useSelector } from "react-redux";
import { getNewCustomerToken } from "../../config/cookie.config";


const CustomerMonetaryTransaction = () => {
    useEffect(()=>{
        document.title = "ncentiva || Customer Monetary Transactions"
    },[])
    const cmsData = useSelector((state) => state.cmsReducer);
    const defaultNcentiva = cmsData?.data?.cms?.default_ncentiva;

    const [isLoading, setIsLoading] = useState(false);
    const [montransactionData, setmonTransactionData] = useState();
    const [transactionData, setTransactionData] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [search, setSearch] = useState("");
    const [order, setOrder] = useState("ASC");
    const [emailorder, setEmailorder] = useState("ASC");
    const [valueorder, setValueorder] = useState("DSC");
    const [updateorder, setUpdateorder] = useState("DSC");
    const [showPage, setShowPage] = useState(1);
    const [sort, setSort] = useState("");
    const [show, setShow] = useState(false);
    const [allproducts, setAllProducts] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [custFilter, setCustFilter] = useState("");
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [apiResponse, setApiResponse] = useState(false);
    const [getCustomer, setCustomer] = useState([]);
    const [monetaryData, setMonetaryData] = useState();
    const [totalFund, setTotalFund] = useState([]);
    const [usedFund, setUsedFund] = useState([]);
    const [noData, setNoData] = useState(false);
    const [noshow,setNoShow] = useState(false);
    const [btnColor,setBtnColor] = useState('');
    const [btnText,setBtnText] = useState('');
    const nameDomain = ( window.location.host.split('.')); 
    const [image,setImage] = useState("");
    
    const {id} = useParams();
    let token;
    let navigate = useNavigate();


    // Page Search
    const pagenumberSearch = (e) => {
        var pages = parseInt(e);
        var page = pages - 1;
        if (pages < 1) {
            setPageNumber(0);
            setShowPage(1);
        } else if (pages > numberOfPages) {
            setPageNumber(numberOfPages - 1);
            setShowPage(numberOfPages);
        } else if (pages >= 1 && pages <= numberOfPages) {
            setPageNumber(page);
            setShowPage(pages);
        }
        else {
            setPageNumber(0);
            setShowPage(pages);
        }
    }
    // Next page
    const nextPage = () => {
        if (pageNumber < numberOfPages) {
            setPageNumber(pageNumber + 1);
            setShowPage(pageNumber + 2);
        }
    }
    // previous Page
    const prevPage = () => {
        if (pageNumber > 0) {
            setPageNumber(pageNumber - 1);
            setShowPage(pageNumber);
        }
    }
    // for pagination
    let items = [];
    let leftSide = pageNumber - 1;

    if (leftSide <= 0) leftSide = 0;
    let rightSide = pageNumber + 2;

    if (rightSide > numberOfPages) rightSide = numberOfPages;
    for (let number = leftSide; number < rightSide; number++) {

        items.push(
            <li key={number} className={(number === pageNumber ? "active" : "")} style={defaultNcentiva
                ? {
                  backgroundColor: number === pageNumber ? `${btnColor}80` : "",
                  color: number !== pageNumber ? "black" : "white",
                  border: `1px solid transparent`
                }
                : {
                  backgroundColor: number === pageNumber ? `${btnColor}` : "",
                  color: number !== pageNumber ? "black" : "white",
                  border: `1px solid transparent`
                }
              }  onClick={() => { setPageNumber(number); setShowPage(number + 1) }}>
                {number + 1}
            </li>,
        );
    }

    // name
    const sorting = (order) => {
        if (order === "DSC") {
            setSort("dscName");

            setOrder("DSC");

        } else {
            setSort("ascName");

            setOrder("ASC");

        }
    };
    const emailsorting = (emailorder) => {
        if (emailorder === "DSC") {
            setSort("dscEmail");

            setEmailorder("DSC");
        } else {
            setSort("ascEmail");

            setEmailorder("ASC");
        }
    };

    const valuesorting = (valueorder) => {
        if (valueorder === "DSC") {
            setSort("dscValue");

            setValueorder("DSC");
        } else {
            setSort("ascValue");

            setValueorder("ASC");
        }
    };

    const updatesorting = (updateorder) => {
        if (updateorder === "DSC") {
            setSort("dscUpdate");

            setUpdateorder("DSC");
        } else {
            setSort("ascUpdate");

            setUpdateorder("ASC");
        }
    };


    useEffect(() => {
        setIsLoading(true);
        // getAllData(pageNumber, startDate, endDate, custFilter);
        setIsLoading(false);
    }, [pageNumber, search, sort, custFilter]);





    const selectionRange = {
        startDate: startDate,
        endDate: endDate,
        key: 'selection',
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const formatDate = (date) => {
        // Convert the date to EST time zone
        const estDate = new Date(date);
        const estOptions = {
            timeZone: "America/New_York",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "numeric",
            minute: "numeric",
        };


        return estDate.toLocaleString("en-US", estOptions).replace(",", "");
    };

    // search
    // const handleSearch = (e) => {
    //     setSearch(e.target.value);
    //     setPageNumber(0);
    //     setShowPage(1);
    // };

   

    // Search with Date

    const handleSelect = (date) => {
        let filtered = allproducts.filter((product) => {
                let productDate = new Date(product["createdAt"]);
                return (
                    productDate >= date.selection.startDate &&
                    productDate <= date.selection.endDate
                );
            });
            setStartDate(date.selection.startDate);
            setEndDate(date.selection.endDate);
            
            getAllData(pageNumber, date.selection.startDate, date.selection.endDate);
            setNumberOfPages(0);
            setPageNumber(0);
            setShowPage(1);
        };
    


    //api
    let customer_id;


    let business_partner;
    const getAllData = (pageNumber, start, end) => {
        setIsLoading(true)
        const user = JSON.parse(window.localStorage.getItem("customer"));
        if (user) {
            token = user.token;
            customer_id = user.customer._id;

            business_partner = user.customer.business_partner_id;
        } else {
            token = null;
            customer_id = null;
            business_partner = null;
        }

        if (user && customer_id && business_partner) {
            axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/customermonetrytransaction/${business_partner}?page=${pageNumber}&search=${search}&startDate=${start}&sort=${sort}&endDate=${end}&custFilter=${customer_id}`

                , {
                    headers: {
                        'Authorization': `Bearer ${getNewCustomerToken()}`
                    }
                },)
                .then((response) => {
                    if (response.data.message === "Please Login") {
                        localStorage.removeItem('customer');
                        // localStorage.removeItem('listItem');
                        navigate('/login');
                        toast.error("Session Expired Please Login Again")
                        // window.location.reload();
                    }
                    const allData = response.data.monetary;
                    const totalPages = response.data.totalPages;
                    setIsLoading(false)
                    if(allData.length < 1){
                        setNoShow(true)
                    }else{
                        setNoShow(false)
                    }
                    setNumberOfPages(totalPages);
                    setTransactionData(allData)
                    setApiResponse(true);
                    setCustomer(response.data.customerFilter);
                    if (response.data.getFund === "NaN" || "0") {
                        setTotalFund(0.00);
                    } else {
                        setTotalFund(response.data.getFund);
                    }
                    if (response.data.usedFund === "NaN" || "0") {
                        setUsedFund(0.00);
                    } else {
                        setUsedFund(response.data.usedFund);
                    }
                    setMonetaryData(response.data);
                    const alltransaction = response.data.monetary;

                    setCustomerData(response?.data?.monetary);
                    setNumberOfPages(totalPages);
                    if (alltransaction.length === 0) {
                        setNoData(true);
                    }

                    // setIsLoading(false);
                    if (response.data.message === "Please Login") {
                        localStorage.removeItem('customer');
                        window.location.reload();
                        
                        navigate('/login');
                        toast.warning("Session Expired Please Login Again")
                        
                    }

                })
                .catch((error) => {
                    console.log(error)

                    if (error.message === "Request failed with status code 401") {
                        localStorage.removeItem('customer');
                        navigate('/login');
                        toast.warning("Session Expired Please Login Again")
                        window.location.reload();
                    }
                })
        }
    }
    useEffect(() => {
        getAllData(pageNumber, startDate, endDate, custFilter);
    }, [customer_id, business_partner, pageNumber, search, startDate, endDate, sort])
    const [apiResponses, setApiResponses] = useState(false);
    const [primaryColor,setPrimaryColor]= useState('');
    useEffect(() => {
        setIsLoading(true)
        if (nameDomain[0] !== "dev" || "staging" || "payouts" || "uat") {
            axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getcms/${nameDomain[0]}`)
                .then((res) => {
                    setApiResponses(true)
                    if (res.data?.cms?.default_ncentiva === false) {
                        setBtnColor(res.data?.cms?.btn_color);
                        setBtnText(res.data?.cms?.btn_text);
                        setPrimaryColor(res.data?.cms?.primary_color);
                        if (res.data?.cms?.brand_logo) {
                            setImage(res.data?.cms?.brand_logo)
                        }
                    } else {
                        setImage("https://dev.ncentiva.com/assets/images/logo.png");
                        setBtnColor('#3D05B6');
                        setBtnText('white');
                        setPrimaryColor("#FF7020");
                    }
                    setIsLoading(false);
                }).catch((err) => {
                    console.log(err);
                })
        } else {
            setIsLoading(false);
        }
    }, []);


    const clearDateRange = () => {
        setStartDate('');
        setEndDate('');
        getAllData(pageNumber, startDate, endDate);
        setNumberOfPages(0);
        setPageNumber(0);
        setShowPage(1);
    };




    return (
        <>
            <div className='px-3 p-3 p-lg-5'>
            <Container fluid>
             <h3 className='mt-5 fs-5 text-dark'>Montary Transactions</h3> 
               </Container>
                <Container fluid className="bg-white p-4 mt-4 rounded shadow">
                    <Row className="g-3">
                        <Col lg={4}>

                            {/* <div style={{ maxWidth: "214px" }}>
                                <div className="d-flex align-items-center searchWrapper w-100 ms-0">
                                    <Form.Control
                                        type="text"
                                        className="border-0 searchInput"
                                        placeholder="Search"
                                        onChange={handleSearch}
                                    />
                                    <Button
                                        variant="primary"
                                        title="Click here to search"
                                        className="searchBtn d-flex justify-content-center align-items-center"
                                    >
                                        <img
                                            src="/assets/images/search.png"
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </Button>
                                </div>
                            </div> */}
                        </Col>

                        <Col lg={8} className="date-range-modal">
                            <div className="d-flex justify-content-lg-end button_style transaction-date-range">
                                {apiResponses ? 
                                <Button 
                                    type="button"

                                    className="fullRounded fw-bold font14 me-3 mb-2 mb-sm-0 button_hover"
                                    style={defaultNcentiva
                                        ? { backgroundColor: `${btnColor}80`, color: btnText, border: `1px solid transparent` }
                                        : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }}
                                        onClick={handleShow}>
                                    <span>Search With Date</span>
                                </Button>: ""}
                                <Modal
                                    show={show}
                                    onHide={handleClose}
                                    size="lg"
                                    centered
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title>Date Range</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <DateRangePicker ranges={[selectionRange]} onChange={handleSelect} 
                                        
                                        />
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button 
                                        type="button"

                                        className="fullRounded fw-bold font14 me-3 mb-2 mb-sm-0 button_hover"
                                        onClick={()=>clearDateRange()}
                                        style={defaultNcentiva
                                            ? { backgroundColor: `${btnColor}80`, color: btnText, border: `1px solid transparent` }
                                            : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }}
                                         >Clear Date</Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        </Col>
                    </Row>

                    <Row className="align-items-center">
                        <Container>
                            <Row>
                                <Col xl={7} xxl={8} lg={7} className="mt-1" >
                                    <div className="text-dark font12">
                                    <div className="d-flex align-items-center w-100 ms-0">
                                            {apiResponse ?
                                                <h3 className="font16 text-dark">Total Bonus Generated: ${Number(monetaryData?.totalBonus)?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h3>
                                                : ""}
                                        </div>
                                        <div className="d-flex align-items-center w-100 ms-0"> {
                                            apiResponse ?
                                                <h3 className="font16 text-dark">Total Funds Transferred : ${Number(monetaryData?.getFund)?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h3>
                                                :
                                                ""
                                        }
                                        </div>
                                        <div className="d-flex align-items-center w-100 ms-0">
                                            {
                                                apiResponse ?
                                                    <h3 className="font16 text-dark">Total Funds Used : ${Number(monetaryData?.usedcost)?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h3>
                                                    : ""
                                            }
                                        </div>
                                       
                                    </div>
                                </Col>
                                <Col xl={5} xxl={4} lg={5} className="mt-1" >

                                </Col>

                            </Row>
                        </Container>
                        <Col md={12} className="mt-4">
                            <div className="table-responsive">
                                <table className="table " style={{ verticalAlign: "middle" }}>
                                    <thead className="bgLightRed font14">
                                        <tr className="text-center">
                                            <th className="py-3">
                                                {" "}
                                                <div className="d-flex align-items-center justify-content-center ">
                                                    {" "}
                                                    <span className="me-1 "> Date</span>{" "}
                                                    {updateorder === "ASC" ? (
                                                        <FaSortDown onClick={() => updatesorting("DSC")} />
                                                    ) : (
                                                        <FaSortUp onClick={() => updatesorting("ASC")} />
                                                    )}
                                                </div>{" "}
                                            </th>
                                           
                                            <th className="py-3">
                                                <div className="d-flex align-items-center justify-content-center" style={{ cursor: 'pointer' }}>
                                                    <span className="me-1"> Transaction Type </span>
                                                </div>
                                            </th>

                                            <th className="py-3">
                                                <div className="d-flex align-items-center justify-content-center" style={{ cursor: 'pointer' }}>
                                                    <span className="me-1"> Value </span>
                                                    {valueorder === "ASC" ? (
                                                        <FaSortDown onClick={() => valuesorting("DSC")} />
                                                    ) : (
                                                        <FaSortUp onClick={() => valuesorting("ASC")} />
                                                    )}
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="font14 fw-medium text-center">
                                        {isLoading === true ? (
                                            <tr style={{ backgroundColor: "#eee" }}>
                                                <td colSpan="9">
                                                    <Bars
                                                        height="80"
                                                        width="100%"
                                                        margin='auto'
                                                        color={primaryColor}
                                                        ariaLabel="bars-loading"
                                                        wrapperStyle={{}}
                                                        wrapperClass=""
                                                        visible={true}
                                                    />
                                                </td>
                                            </tr>
                                        ) : (
                                            <>
                                            {noshow ? <tr >
                          <td colSpan="9" className="text-center">
                            <h1 className="font16 text-dark" >No Monetary Transactions</h1>
                          </td>
                        </tr>
                          :
                                
                                            transactionData?.map((cust) => (
                                                <tr key={cust.customer_id}>
                                                    <td className="py-3 font14 fw-medium">
                                                        {" "}
                                                        {formatDate(cust.updatedAt).slice(0, 16).replace("T", "      ")}
                                                    </td>
                                                    {/* <td className="font14 fw-medium  py-3">
                                                    {cust.customer_name}
                                                </td> */}
                                                    <td className="py-3 font14 fw-medium">
                                                        {cust.transaction_type}
                                                    </td>

                                                    <td className="py-3 font14 fw-medium ">
                                                        {/* {cust.transaction_type === "Funds Updated" ? ( */}
                                                        {cust.deduct ? (
                                                            <span style={{ color: "red" }}>
                                                                -
                                                            </span>
                                                        ) : (
                                                            <span style={{ color: "green" }}>
                                                                +
                                                            </span>
                                                        )}
                                                        {/* ) : ""} */}

                                                        ${Number(cust.amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    </td>
                                                </tr>
                                            ))
                                            }
                                            </>
                                        )

                                        }

                                    </tbody>
                                </table>
                                {
                                    numberOfPages === 0 ? "" :
                                        <Col className="mt-5 mb-4">
                                            {
                                                <ul className="custPagination d-flex justify-content-center list-unstyled font14">
                                                    {pageNumber === 0 ? (
                                                        ""
                                                    ) : (

                                                        <li onClick={prevPage} >Previous </li>

                                                    )}


                                                    {items}


                                                    {numberOfPages - 1 === pageNumber ? (
                                                        ""
                                                    ) : (
                                                        <li onClick={nextPage}>Next</li>
                                                    )}
                                                    <li className="font14"><input 
                                                    // style={{ width: "25px", border: `1px solid ${btnColor}80`, borderRadius: '4px', textAlign: 'center' }} 
                                                    style={defaultNcentiva
                                                        ? {   border: `1px solid ${btnColor}80`, width: "25px", borderRadius: '4px', textAlign: 'center' }
                                                        : {   border: `1px solid ${btnColor}80`, width: "25px", borderRadius: '4px', textAlign: 'center' }}
                                                    value={showPage ? showPage : ''} type="text" name="number" id="" onChange={(e) => {
                                                       
                                                        pagenumberSearch(e.target.value)
                                                        
                                                    }}  ></input> <span style={{ color: `${btnColor}` }}>/</span> {numberOfPages}</li>
                                                </ul>
                                            }
                                        </Col>
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
};
export default CustomerMonetaryTransaction;