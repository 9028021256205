import axios from "axios";
import { GET_CUSTOMER_BALANCE_REQUEST, GET_CUSTOMER_BALANCE_SUCCESS, GET_CUSTOMER_BALANCE_FAILURE, GET_CUSTOMER_LOGOUT } from './actionType';
import { getNewCustomerToken } from "../../config/cookie.config";

export const balance_req = () => ({
    type: GET_CUSTOMER_BALANCE_REQUEST
})

export const balance_succ = (data) => ({
    type: GET_CUSTOMER_BALANCE_SUCCESS,
    payload: data
})

export const balance_fail = (err) => ({
    type: GET_CUSTOMER_BALANCE_FAILURE,
    payload: err
})

export const customer_logout = () =>({
    type: GET_CUSTOMER_LOGOUT
})

const customer = JSON.parse(window.localStorage.getItem('customer'));
// let token;

/******** Get Request for the customers balance ********/

export const getCustomer = (id, token) => (dispatch) => {
    // if(customer){
    //     token=customer.token;
    // }else{
    //     token=null;
    // }
    dispatch(balance_req());
     return axios({
         method:'get',
         url:`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/customerfrontend/${id}`,
            headers: {
              'Authorization': `Bearer ${getNewCustomerToken()}`
            }
          
     })
     .then((res)=>{
        if (res.data.message === "Please Login") {
            localStorage.removeItem('customer');
            localStorage.removeItem('listItem');
            dispatch(customer_logout());
        }
        dispatch(balance_succ(res.data.customer))})
     .catch((err)=>{
         console.log('api error in redux:',err?.response?.data?.message)
        if (err?.response?.data?.message === "Unauthorized User") {
            localStorage.removeItem('customer');
            localStorage.removeItem('listItem');
            dispatch(customer_logout())
            window.location.href = '/login';
        }
     dispatch(balance_fail(err))})
 }
